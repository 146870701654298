import type { AutomationRule, AutomationRuleFilter, AutomationRuleRaw } from '#automation/types'
import {
  AUTOMATION_RULES_QUERY,
  UPDATE_CREATE_AUTOMATION_RULE,
  EXECUTE_AUTOMATION_RULE,
  AUTOMATION_RULE_QUERY,
  TOGGLE_ACTIVE_AUTOMATION_RULE,
  DUPLICATE_AUTOMATION_RULE,
  DELETE_AUTOMATION_RULE
} from '#automation/schema'

export const useUpdateCreateAutomationRuleMutation = (params: AutomationRuleRaw) => {
  return useMutation(UPDATE_CREATE_AUTOMATION_RULE, {
    variables: {
      params
    }
  })
}

export const useAutomationRulesQuery = (filter: AutomationRuleFilter) => {
  return useQuery<{
    automationRules: AutomationRule[]
  }>(AUTOMATION_RULES_QUERY, filter)
}

export const useLazyAutomationRuleQuery = (id: string) => {
  return useLazyQuery<{
    automationRule: AutomationRule
  }>(AUTOMATION_RULE_QUERY, {
    id
  })
}

export const useLazyAutomationRulesQuery = (filter?: AutomationRuleFilter) => {
  return useLazyQuery<{
    automationRules: AutomationRule[]
  }>(AUTOMATION_RULES_QUERY, filter)
}

export const useToggleActiveAutomationRuleMutation = (id: string, isActive: boolean) => {
  return useMutation<{
    success: boolean
  }>(TOGGLE_ACTIVE_AUTOMATION_RULE, {
    variables: {
      id,
      isActive
    }
  })
}

export const useDuplicateAutomationRuleMutation = (id: string) => {
  return useMutation(DUPLICATE_AUTOMATION_RULE, {
    variables: {
      id
    }
  })
}

export const useDeleteAutomationRuleMutation = (id: string) => {
  return useMutation(DELETE_AUTOMATION_RULE, {
    variables: {
      id
    }
  })
}

export const useExecuteAutomationRuleMutation = (ruleId: string, taskId: string) => {
  return useMutation<{
    executeAutomationRule: {
      success: boolean
    }
  }>(EXECUTE_AUTOMATION_RULE, {
    variables: {
      ruleId,
      taskId
    }
  })
}
