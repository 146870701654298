/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/
import { LABEL_FRAGMENT } from '#label/fragment'
import { STATUS_FRAGMENT } from '#status/fragment'
import { TASK_TYPE_FRAGMENT } from '#task-type/fragment'

export const SETTINGS_PACK_LOADER_FRAGMENT = gql`
  ${LABEL_FRAGMENT}
  ${STATUS_FRAGMENT}
  ${TASK_TYPE_FRAGMENT}
  fragment SettingsPackLoader on SettingsPackType {
    id
    name
    description
    labels @client {
      ...Label
    }
    type @client {
      ...TaskType
    }
    status @client {
      ...Status
    }
  }
`
