<template>
  <div class="bg-white rounded-md shadow-md px-2 py-1 pl-0" data-prevent-close-editor data-prevent-close-task>
    <div class="flex items-center gap-2 divide-x divide-gray-200 ">
      <div
        v-for="toolbarList in toolbarGroups"
        :key="toolbarList[0].title"
        class="flex gap-1 pl-2"
      >
        <UTooltip
          v-for="toolbar in toolbarList"
          :key="toolbar.key"
          :popper="{ placement: 'top', arrow: true }"
          :text="toolbar.title"
        >
          <UButton
            variant="ghost"
            size="lg"
            color="gray"
            class="flex items-center p-1 hover:bg-gray-100 size-7"
            @click="handleClickToolbar(toolbar)"
          >
            <Suspense>
              <Icon v-if="toolbar.icon" :name="toolbar.icon" class="size-4" />
            </Suspense>
          </UButton>
        </UTooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Editor } from '@tiptap/core'

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
  onAlignLeft: {
    type: Function as PropType<() => void>,
    required: true,
  },
  onAlignCenter: {
    type: Function as PropType<() => void>,
    required: true,
  },
  onAlignRight: {
    type: Function as PropType<() => void>,
    required: true,
  },
  destroy: {
    type: Function as PropType<() => void>,
    required: true,
  },
  imageUrl: {
    type: String as PropType<string>,
    required: true,
  },
})

const toolbarGroups = [
  [
    {
      key: 'left',
      title: 'Align left',
      icon: 'leanbase:align-left',
      click: () => {
        props.onAlignLeft()
      },
    },
    {
      key: 'center',
      title: 'Align center',
      icon: 'leanbase:align-center',
      click: () => {
        props.onAlignCenter()
      },
    },
    {
      key: 'right',
      title: 'Align right',
      icon: 'leanbase:align-right',
      click: () => {
        props.onAlignRight()
      },
    },
  ],
  [
    {
      key: 'delete',
      title: 'Delete',
      icon: 'heroicons-trash',
      click: () => {
        props.editor
          .chain()
          .focus()
          .deleteSelection()
          .run()
      },
    },
    {
      key: 'download',
      title: 'Download',
      icon: 'heroicons-arrow-down-tray',
      click: () => {
        const link = document.createElement('a')
        link.href = props.imageUrl
        link.target = '_blank'
        link.download = 'image'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
    },
  ]
]

const handleClickToolbar = (toolbar: { click: () => void }) => {
  toolbar.click()
  props.destroy()
}
</script>
