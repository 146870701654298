import { BASE_WORKSPACE_FRAGMENT } from '../workspace/fragment'
import {
  BASE_USER_FRAGMENT,
  USER_PROFILE_FRAGMENT,
  ROLE_FRAGMENT,
  INVITE_ENTRY_FRAGMENT
} from '#auth/fragment'

export const CURRENT_USER_QUERY = gql`
  ${BASE_USER_FRAGMENT}

  query CurrentUserQuery {
    currentUser @sessionPersist {
      ...BaseUser
    }
  }
`

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!, $connectSocialAccountToken: String) {
    tokenAuth(email: $email, password: $password, connectSocialAccountToken: $connectSocialAccountToken) {
      token
      refreshExpiresIn
      messageCode
      provider
    }
  }
`

export const SIGNUP_MUTATION = gql`
  ${BASE_USER_FRAGMENT}

  mutation SignUpMutation($email: String!, $password: String!, $inviteBy: String, $invitationToken: String, $inviteType: String) {
    createUser(email: $email, password: $password, createWorkspace: true, inviteBy: $inviteBy, invitationToken: $invitationToken, inviteType: $inviteType) {
      user {
        ...BaseUser
      }
      workspace {
        handle
      }
    }
  }
`

export const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($email: String!, $token: String!) {
    verifyEmail(email: $email, token: $token) {
      success
    }
  }
`

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    deleteTokenCookie {
      deleted
    }
  }
`

export const UPDATE_USER_PROFILE_PHOTO_MUTATION = gql`
  ${USER_PROFILE_FRAGMENT}

  mutation UpdateUserProfileMutation($input: UpdateUserInput!, $photo: Upload!) {
    updateUserPhoto(photo: $photo) {
      photo
    }
    updateUser(input: $input) {
      user {
        ...UserProfile
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_USER_PROFILE_MUTATION = gql`
  ${USER_PROFILE_FRAGMENT}

  mutation UpdateUserProfileMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UserProfile
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_USER_SECURITY_MUTATION = gql`
  mutation UpdateUserSecurityMutation($input: UpdateUserSecurityInput!) {
    updateUserSecurity(input: $input) {
      user {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const USER_PROFILE_QUERY = gql`
  ${USER_PROFILE_FRAGMENT}

  query UserProfileQuery($id: ID!, $workspaceId: ID!) {
    user(id: $id, workspaceId: $workspaceId) {
      ...UserProfile
    }
  }
`

export const COMPLETE_TUTORIAL = gql`
  mutation CompleteTutorial {
    completeTutorial {
      success
    }
  }
`

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation RequestResetPasswordMutation($email: String!) {
    requestResetPassword(email: $email) {
      success
    }
  }
`

export const VALIDATE_RESET_PASSWORD_MUTATION = gql`
  mutation ValidateResetPasswordTokenMutation($email: String!, $token: String!) {
    validateResetPasswordToken(email: $email, token: $token) {
      valid
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($email: String!, $token: String!, $password: String!) {
    resetPassword(email: $email, token: $token, password: $password) {
      success
    }
  }
`

export const SET_LAST_ACTIVE_WORKSPACE_MUTATION = gql`
  mutation SetLastActiveWorkspaceMutation($workspaceId: String!) {
    setLastActiveWorkspace(workspaceId: $workspaceId) {
      success
    }
  }
`

export const RESEND_VERIFICATION_EMAIL_MUTATION = gql`
  mutation ResendVerificationEmailMutation {
    resendVerificationEmail {
      success
    }
  }
`

export const WORKSPACE_MEMBERS_QUERY = gql`
  ${BASE_USER_FRAGMENT}
  ${ROLE_FRAGMENT}
  query WorkspaceMembersQuery(
    $workspaceId: ID!
    $filter: WorkspaceMemberFilterInput
  ) {
    workspaceMembers(workspaceId: $workspaceId, filter: $filter) {
      total
      items {
        id
        user {
          ...BaseUser
        }
        role {
          ...Role
        }
        active
        invitationEmail
      }
    }
  }
`

export const BOARD_MEMBERS_QUERY = gql`
  ${BASE_USER_FRAGMENT}
  ${ROLE_FRAGMENT}
  query BoardMembersQuery(
    $boardId: ID!
    $filter: BoardMemberFilterInput
  ) {
    boardMembers(boardId: $boardId, filter: $filter) {
      total
      items {
        id
        user {
          ...BaseUser
        }
        role {
          ...Role
        }
        invitationWorkspaceMember {
          id
          invitationEmail
        }
      }
    }
  }
`

export const BOARD_MEMBER_QUERY = gql`
  ${BASE_USER_FRAGMENT}
  ${ROLE_FRAGMENT}
  query BoardMember($boardId: ID!) {
    boardMember(boardId: $boardId) {
      id
      user {
        ...BaseUser
      }
      role {
        ...Role
      }
    }
  }
`

export const ROLES_QUERY = gql`
  ${ROLE_FRAGMENT}
  query RolesQuery($id: ID, $type: String) {
    roles(id: $id, type: $type) {
      ...Role
    }
  }
`

export const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRoleMutation($id: ID!) {
    deleteRole(id: $id) {
      success
    }
  }
`

export const ADD_UPDATE_ROLE_MUTATION = gql`
  ${ROLE_FRAGMENT}
  mutation AddUpdateRoleMutation($input: AddUpdateRoleInput!) {
    addUpdateRole(input: $input) {
      role {
        ...Role
      }
      errors {
        field
        messages
      }
    }
  }
`

export const PERMISSION_QUERY = gql`
  query PermissionQuery($type: String!) {
    permissions(type: $type) {
      code
      path
      defaultLevels
      label
      helpText
      children {
        code
        path
        defaultLevels
        label
        helpText
        children {
          code
          path
          defaultLevels
          label
          helpText
        }
      }
    }
  }
`

export const INVITATION_QUERY = gql`
  query InvitationQuery($type: String!, $token: String!, $email: String) {
    invitation(type: $type, token: $token, email: $email) {
      invitationEmail
      isNeedSignup
      alreadyMember
      invalidInvitation
      invalidDomain
      invalidEmail
      workspace {
        id
        name
        logo
        handle
        membershipRestrictions
        membershipRestrictionsDomains
      }
      board {
        id
        name
        handle
      }
    }
  }
`

export const UPDATE_WORKSPACE_MEMBER_MUTATION = gql`
  mutation UpdateWorkspaceMember($id: ID!, $active: Boolean, $roleId: ID) {
    updateWorkspaceMember(id: $id, active: $active, roleId: $roleId) {
      workspaceMember {
        id
        active
        role {
          id
          name
        }
      }
    }
  }
`

export const DELETE_WORKSPACE_MEMBER_MUTATION = gql`
  mutation DeleteWorkspaceMemberMutation($id: ID!) {
    deleteWorkspaceMember(id: $id) {
      success
    }
  }
`

export const UPDATE_BOARD_MEMBER_MUTATION = gql`
  mutation UpdateBoardMember($id: ID!, $roleId: ID) {
    updateBoardMember(id: $id, roleId: $roleId) {
      boardMember {
        id
        role {
          id
          name
        }
      }
    }
  }
`

export const DELETE_BOARD_MEMBER_MUTATION = gql`
  mutation DeleteBoardMemberMutation($id: ID!) {
    deleteBoardMember(id: $id) {
      success
    }
  }
`

export const WORKSPACE_MEMBER_QUERY = gql`
  query WorkspaceMember($workspaceId: ID!) {
    workspaceMember(workspaceId: $workspaceId) {
      id
      user {
        id
        email
        fullName
      }
      role {
        id
        name
        level
        allowedPermissions
      }
      created
      modified
    }
  }
`

export const RESEND_WORKSPACE_MEMBER_INVITATION_MUTATION = gql`
  mutation ResendWorkspaceMemberInvitationMutation($id: ID!) {
    resendWorkspaceMemberInvitation(id: $id) {
      success
    }
  }
`

export const RESEND_BOARD_MEMBER_INVITATION_MUTATION = gql`
  mutation ResendBoardMemberInvitationMutation($id: ID!) {
    resendBoardMemberInvitation(id: $id) {
      success
    }
  }
`

export const INVITE_MEMBERS_MUTATION = gql`
  ${INVITE_ENTRY_FRAGMENT}
  mutation InviteMembers($workspaceId: ID, $boardId: ID, $workspaceRoleId: ID, $boardRoleId: ID, $entries: [InviteEntryInput!]!) {
    inviteMembers(workspaceId: $workspaceId, boardId: $boardId, workspaceRoleId: $workspaceRoleId, boardRoleId: $boardRoleId, entries: $entries) {
      success
      memberFound {
        ...InviteEntry
      }
      invalid{
        ...InviteEntry
      }
      invited{
        ...InviteEntry
      }
    }
  }
`

export const ACCEPT_INVITATION_MUTATION = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  ${BASE_USER_FRAGMENT}
  mutation AcceptInvitationMutation($token: String!, $inviteBy: String!, $inviteType: String!) {
    acceptInvitation(token: $token, inviteBy: $inviteBy, inviteType: $inviteType) {
      success,
      workspace {
        ...BaseWorkspace
      },
      user {
        ...BaseUser
      },
    }
  }
`

export const DECLINE_INVITATION_MUTATION = gql`
  mutation DeclineInvitationMutation($token: String!, $inviteBy: String!, $inviteType: String!) {
    declineInvitation(token: $token, inviteBy: $inviteBy, inviteType: $inviteType) {
      success
    }
  }
`
