import Code from '@tiptap/extension-code'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import type { Extensions } from '@tiptap/core'
import { InputRule } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import TaskItem from '@tiptap/extension-task-item'
import Highlight from '@tiptap/extension-highlight'
import Table from '@tiptap/extension-table'
import TaskList from '@tiptap/extension-task-list'
import TiptapUnderline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Gapcursor from '@tiptap/extension-gapcursor'
import BulletList from '@tiptap/extension-bullet-list'
import OrderList from '@tiptap/extension-ordered-list'
import { OnBlurHighlight } from './highlight'
import { createLinkExtension } from '#core/packages/tiptap/link'
import Selection from '#core/packages/tiptap/selection'
import type { LinkPreviewOption, ImageOptions } from '#core/types/packages/tiptap'
import createImageExtension from '#core/packages/tiptap/image'

interface ExtensionKitOptions {
  linkOption: LinkPreviewOption
  imageOption?: ImageOptions
}

const ExtensionKit = (options: ExtensionKitOptions) => {
  const { imageOption, linkOption } = options

  const extensions: Extensions = [
    TiptapUnderline,
    TextStyle,
    TableRow,
    TableCell,
    TableHeader,
    Gapcursor,
    TaskList,
    Selection,
    OnBlurHighlight,
    StarterKit.configure({
      horizontalRule: false,
      dropcursor: {
        color: '#B4F5D2',
        width: 2,
      },
      gapcursor: false,
      code: false,
      bulletList: false,
      orderedList: false,
    }),
    TaskItem.configure({
      nested: true,
    }),
    Table.configure({
      resizable: true,
      allowTableNodeSelection: true,
    }),

    Highlight.configure({
      multicolor: true,
    }),
    BulletList.extend({
      addKeyboardShortcuts() {
        return {
          'Mod-Shift-b': () => this.editor.commands.toggleBulletList(),
        }
      },
    }),
    OrderList.extend({
      addKeyboardShortcuts() {
        return {
          'Mod-Shift-o': () => this.editor.commands.toggleOrderedList(),
        }
      },
    }),
    Code.extend({
      addKeyboardShortcuts() {
        return {
          'Mod-Alt-m': () => this.editor.commands.toggleCode(),
        }
      },
    }),
    HorizontalRule.extend({
      addInputRules() {
        return [
          new InputRule({
            find: /^(?:---|—-|___\s|\*\*\*\s)$/,
            handler: ({ state, range }) => {
              const attributes = {}
              const { tr } = state
              const start = range.from
              const end = range.to
              tr.insert(start - 1, this.type.create(attributes)).delete(
                tr.mapping.map(start),
                tr.mapping.map(end)
              )
            },
          }),
        ]
      },
    }),
    createLinkExtension(linkOption),
  ]

  if (imageOption) {
    extensions.push(createImageExtension(imageOption))
  }

  return extensions
}

export default ExtensionKit
