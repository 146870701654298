<template>
  <div class="pt-3" data-test="assignee-popover">
    <div class="flex items-center justify-between px-4">
      <p class="font-semibold text-sm text-gray-900">Assignee</p>
      <UButton
        size="xs"
        icon="i-heroicons-x-mark"
        color="gray"
        variant="ghost"
        @click="$emit('close')"
      />
    </div>
    <div class="mt-2 px-4">
      <UInput
        v-model="search"
        icon="i-heroicons-magnifying-glass"
        size="sm"
        autofocus
        placeholder="Search by name or email"
      />
    </div>
    <slot name="custom-members" />
    <div class="mt-4 pl-2">
      <div class="text-xs font-semibold text-gray-900 px-2 mb-1">
        <span>Members</span>
      </div>
      <div
        class="max-h-[22.25rem] pb-2 pr-0.5 scroll-stable overflow-y-auto minimal-scrollbar"
      >
        <div
          v-for="(member, index) in boardMembers"
          ref="optionRefs"
          :key="member.id"
          :class="[
            'px-2 py-1.5 rounded-md mt-0.5 cursor-pointer flex items-center justify-between hover:bg-gray-100',
            {
              'bg-gray-100':
                member.id === currentAssignee?.id
                || currentFocusIndex === index,
            },
          ]"
          @mouseenter="currentFocusIndex = index"
          @mouseleave="currentFocusIndex = -1"
          @click.prevent.stop="onSelectMember(member)"
        >
          <div class="flex items-center gap-2">
            <Avatar
              :id="member.id"
              :src="member.photo"
              :name="member.fullName"
              size="2xs"
            />
            <Tooltip :text="member.fullName" arrow-class="!top-[unset]">
              <template #default="{ getTextRef }">
                <p
                  :ref="getTextRef"
                  class="text-gray-900 text-sm line-clamp-1 break-all"
                  data-test="member-name-item"
                >
                  {{ member.fullName }}
                </p>
              </template>
            </Tooltip>
          </div>
          <UButton
            v-if="currentAssignee?.id === member.id"
            variant="ghost"
            color="white"
            size="2xs"
            icon="i-heroicons-x-mark"
            data-test="remove-assignee-icon"
            @click.prevent.stop="$emit('remove')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash-es'
import type { TaskDetail } from '#task/types'
import type { BaseUser } from '#auth/types'

const props = defineProps({
  boardId: {
    type: String,
    required: false,
  },
  currentAssignee: {
    type: Object as PropType<TaskDetail['assignee']>,
    default: () => ({}),
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['select', 'remove', 'close', 'forceUpdate'])

const { auth } = storeToRefs(useAuthStore())

const optionRefs = ref<HTMLElement[]>([])
const currentFocusIndex = ref(-1)
const search = ref('')

const { boardData } = useBoardSharedState()

const boardMembers = computed(() => {
  const users = cloneDeep(boardData.value?.users || [])
  bringMemberToTop(users, auth.value.id)
  if (props.currentAssignee?.id) {
    bringMemberToTop(users, props.currentAssignee.id)
  }

  return users.filter((user) => {
    const fullName = user.fullName.toLowerCase()
    const email = user.email.toLowerCase()
    const searchValue = search.value.toLowerCase()
    return fullName.includes(searchValue) || email.includes(searchValue)
  })
})

const bringMemberToTop = (users: BaseUser[], id: string) => {
  const userIndex = users.findIndex((user) => user?.id === id)
  if (userIndex !== -1) {
    const cloneMember = users[userIndex]
    users.splice(userIndex, 1)
    users.unshift(cloneMember)
  }
}

const onSelectMember = (member: BaseUser) => {
  emit('select', member.id)
  if (props.closeOnSelected) {
    emit('close')
  }
}

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

defineShortcuts({
  arrowup: {
    usingInput: true,
    handler: () => {
      if (currentFocusIndex.value > 0) {
        currentFocusIndex.value--
      } else {
        currentFocusIndex.value = boardMembers.value?.length - 1
      }

      scrollToView(currentFocusIndex.value)
    },
  },
  arrowdown: {
    usingInput: true,
    handler: () => {
      if (currentFocusIndex.value < boardMembers.value?.length - 1) {
        currentFocusIndex.value++
      } else {
        currentFocusIndex.value = 0
      }

      scrollToView(currentFocusIndex.value)
    },
  },
  enter: {
    usingInput: true,
    handler: () => {
      if (
        currentFocusIndex.value >= 0 &&
        boardMembers.value![currentFocusIndex.value]
      ) {
        if (
          props.currentAssignee?.id ===
          boardMembers.value[currentFocusIndex.value].id
        ) {
          return emit('remove')
        }

        onSelectMember({
          ...boardMembers.value[currentFocusIndex.value],
          emailVerified:
            boardMembers.value[currentFocusIndex.value].emailVerified || false,
        })
      }
    },
  },
})

defineExpose({
  focusIndex: (index: number) => {
    currentFocusIndex.value = index
    scrollToView(index)
  },
})

watch(
  () => [search.value, boardMembers.value.length],
  ([searcValue, boardMembersLength]) => {
    if (searcValue && boardMembersLength) {
      currentFocusIndex.value = 0
      nextTick(() => {
        emit('forceUpdate')
      })
    }
  }
)
</script>
