<template>
  <div class="flex py-2" data-test="activity-log">
    <div class="mr-3">
      <UAvatar
        v-if="isAutomation"
        icon="heroicons:bolt-solid"
        color="orange"
        :ui="{
          background: 'bg-orange-50',
          icon: {
            base: 'text-orange-500',
          }
        }"
      />
      <Avatar
        v-else
        :id="action.creator.id"
        :src="action.creator.photo"
        :name="action.creator.fullName"
        size="sm"
      />
    </div>
    <div class="flex-1 text-sm">
      <p
        class="text-sm mb-1" :style="{
          wordBreak: 'break-word'
        }"
      >
        <span class="font-semibold text-gray-700">
          {{ isAutomation ? 'Automation' : action.creator.fullName }}
        </span>

        <!-- BOARD ACTIONS LOGS -->
        <span v-if="action.type === LogTypeEnum.CREATE_TASK && type === 'board'" data-test="activity-log-content">
          added <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink> to this board
        </span>
        <span v-if="action.type === LogTypeEnum.UPDATE_DESCRIPTION && type === 'board'" data-test="activity-log-content">
          updated description of <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.DELETE_TASK && type === 'board'" data-test="activity-log-content">
          deleted <span class="font-semibold">{{ getFieldValue('data.task.name') }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.UNASSIGN_TASK && type === 'board'" data-test="activity-log-content">
          unassigned <span class="font-semibold">@{{ getFieldValue('data.assignee.full_name') }}</span> from <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.ASSIGN_TASK && type === 'board'" data-test="activity-log-content">
          assigned <span class="font-semibold">@{{ getFieldValue('data.assignee.full_name') }}</span> to <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.SET_TASK_STATUS && type === 'board'" data-test="activity-log-content">
          set <span class="font-semibold">{{ getFieldValue('data.status_name') }}</span> to <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.COPY_TASK && type === 'board'" data-test="activity-log-content">
          copied <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink> from <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(getFieldValue('data.target_task') as TaskItem)">{{ getFieldValue('data.target_task.name') }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.MOVE_TO_BOARD && type === 'board'" data-test="activity-log-content">
          transferred <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink> from <a :href="`/b/${getFieldValue('data.from_board.handle')}`" class="text-primary font-semibold">{{ getFieldValue('data.from_board.name') }}</a>
        </span>
        <span v-if="action.type === LogTypeEnum.MOVE_TO_SECTION && type === 'board'" data-test="activity-log-content">
          moved <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink> from <span class="font-semibold">{{ getFieldValue('data.from_section.name') }}</span> to <span class="font-semibold">{{ getFieldValue('data.to_section.name') }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.ARCHIVE_TASK && type === 'board'" data-test="activity-log-content">
          archived <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.UNARCHIVE_TASK && type === 'board'" data-test="activity-log-content">
          unarchived <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.CREATE_SECTION && type === 'board'" data-test="activity-log-content">
          created a section <span class="font-semibold">{{ action.section.name }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.UPDATE_SECTION_NAME && type === 'board'" data-test="activity-log-content">
          updated a section name from <span class="font-semibold">{{ getFieldValue('data.old_section_name') }}</span> to <span class="font-semibold">{{ getFieldValue('data.new_section_name') }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.ARCHIVE_SECTION && type === 'board'" data-test="activity-log-content">
          archived section <span class="font-semibold">{{ getFieldValue('section.name') }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.UNARCHIVE_SECTION && type === 'board'" data-test="activity-log-content">
          unarchived section <span class="font-semibold">{{ getFieldValue('section.name') }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.CREATE_BOARD && type === 'board'" data-test="activity-log-content">
          created this board
        </span>
        <span v-if="action.type === LogTypeEnum.UPDATE_BOARD_NAME && type === 'board'" data-test="activity-log-content">
          updated the board name from <span class="font-semibold">{{ getFieldValue('data.old_board_name') }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.JOIN_BOARD && type === 'board'" data-test="activity-log-content">
          joined this board
        </span>
        <span v-if="action.type === LogTypeEnum.LEAVE_BOARD && type === 'board'" data-test="activity-log-content">
          leaved this board
        </span>
        <span v-if="action.type === LogTypeEnum.DELETE_BOARD_MEMBER && type === 'board'" data-test="activity-log-content">
          removed {{ getFieldValue('data.removed_user_full_name') }} to this board
        </span>
        <span v-if="action.type === LogTypeEnum.ADD_SUB_TASK && type === 'board'" data-test="activity-log-content">
          created <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(getFieldValue('data.subtask') as TaskItem)">{{ getFieldValue('data.subtask.name') }}</NuxtLink> of
          <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.ADD_ATTACHMENT_TO_TASK && type === 'board'" data-test="activity-log-content">
          attached <a :href="`${getFieldValue('data.file.url')}`" class="text-primary font-semibold">{{ getFieldValue('data.file.name') }} </a> to <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(getFieldValue('task') as TaskItem)">{{ getFieldValue('task.name') }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.SET_DUE_DATE && type === 'board'" data-test="activity-log-content">
          set due date to <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.UPDATE_DUE_DATE && type === 'board'" data-test="activity-log-content">
          updated due date to <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.SET_MODULE && type === 'board'" data-test="activity-log-content">
          set <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(getFieldValue('data.module') as TaskItem)">{{ getFieldValue('data.module.name') }}</NuxtLink> to <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.CHANGE_TASK_TYPE && type === 'board'" data-test="activity-log-content">
          change <span class="font-semibold">{{ getFieldValue('data.to_type_name') }}</span> from <span class="font-semibold">{{ getFieldValue('data.from_type_name') }}</span> to <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(action.task as TaskItem)">{{ action.task.name }}</NuxtLink>
        </span>
        <!-- BOARD ACTIONS LOGS -->

        <!-- TASK ACTIONS LOGS -->
        <span v-if="action.type === LogTypeEnum.CREATE_TASK && type === 'task'" data-test="activity-log-content">
          created this task
        </span>
        <span v-if="action.type === LogTypeEnum.UPDATE_DESCRIPTION && type === 'task'" data-test="activity-log-content">
          updated the task description
        </span>
        <span v-if="action.type === LogTypeEnum.UNASSIGN_TASK && type === 'task'" data-test="activity-log-content">
          unassigned <span class="font-semibold">@{{ getFieldValue('data.assignee.full_name') }}</span> from this task
        </span>
        <span v-if="action.type === LogTypeEnum.ASSIGN_TASK && type === 'task'" data-test="activity-log-content">
          assigned <span class="font-semibold">@{{ getFieldValue('data.assignee.full_name') }}</span> to this task
        </span>
        <span v-if="action.type === LogTypeEnum.SET_TASK_STATUS && type === 'task'" data-test="activity-log-content">
          set <span class="font-semibold">{{ getFieldValue('data.status_name') }}</span> to this task
        </span>
        <span v-if="action.type === LogTypeEnum.COPY_TASK && type === 'task'" data-test="activity-log-content">
          copied this task from <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(getFieldValue('data.target_task') as TaskItem)">{{ getFieldValue('data.target_task.name') }}</NuxtLink>
        </span>
        <span v-if="action.type === LogTypeEnum.MOVE_TO_BOARD && type === 'task'" data-test="activity-log-content">
          transferred this task from <a :href="`/b/${getFieldValue('data.from_board.handle')}`" class="text-primary font-semibold">{{ getFieldValue('data.from_board.name') }}</a>
        </span>
        <span v-if="action.type === LogTypeEnum.MOVE_TO_SECTION && type === 'task'" data-test="activity-log-content">
          moved this task from <span class="font-semibold">{{ getFieldValue('data.from_section.name') }}</span> to <span class="font-semibold">{{ getFieldValue('data.to_section.name') }}</span>
        </span>
        <span v-if="action.type === LogTypeEnum.ARCHIVE_TASK && type === 'task'" data-test="activity-log-content">
          archived this task
        </span>
        <span v-if="action.type === LogTypeEnum.UNARCHIVE_TASK && type === 'task'" data-test="activity-log-content">
          unarchived this task
        </span>
        <span v-if="action.type === LogTypeEnum.ADD_SUB_TASK && type === 'task'" data-test="activity-log-content">
          created <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(getFieldValue('data.subtask') as TaskItem)">{{ getFieldValue('data.subtask.name') }}</NuxtLink> of this task
        </span>
        <span v-if="action.type === LogTypeEnum.ADD_ATTACHMENT_TO_TASK && type === 'task'" data-test="activity-log-content">
          attached <a :href="`${getFieldValue('data.file.url')}`" class="text-primary font-semibold">{{ getFieldValue('data.file.name') }} </a> to this task
        </span>
        <span v-if="action.type === LogTypeEnum.SET_DUE_DATE && type === 'task'" data-test="activity-log-content">
          set due date to this task
        </span>
        <span v-if="action.type === LogTypeEnum.UPDATE_DUE_DATE && type === 'task'" data-test="activity-log-content">
          update due date to this task
        </span>
        <span v-if="action.type === LogTypeEnum.SET_MODULE && type === 'task'" data-test="activity-log-content">
          set <NuxtLink class="text-primary font-semibold hover:cursor-pointer" @click="onOpenTask(getFieldValue('data.module') as TaskItem)">{{ getFieldValue('data.module.name') }}</NuxtLink> to this task
        </span>
        <span v-if="action.type === LogTypeEnum.CHANGE_TASK_TYPE && type === 'task'" data-test="activity-log-content">
          change <span class="font-semibold">{{ getFieldValue('data.to_type_name') }}</span> from <span class="font-semibold">{{ getFieldValue('data.from_type_name') }}</span> to this task
        </span>
        <!-- TASK ACTIONS LOGS -->
      </p>
      <span class="text-xs text-gray-500">{{ formattedTime }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as _ from 'lodash-es'
import { formatTime } from '#action/utils'
import type { ActionType } from '#action/types'
import { LogTypeEnum } from '#action/constant'
import type { TaskItem } from '#task/types'
import { TaskViewMode } from '#task/constant'
import { BoardView } from '#board/constant'

const props = defineProps({
  action: {
    type: Object as PropType<ActionType>,
    required: true,
  },
  type: {
    type: String,
    required: false
  }
})

const { setCurrentTask } = useWorkspaceSharedState()
const { activeView, closeBoardSettingsSlideover } = useBoardSharedState()

const isAutomation = computed(() => props.action.isAutomation)

const actionData = computed(() => ({ ...props.action, data: safeParseStringToJSON(props.action.data, props.action.data) }))
const formattedTime = computed(() => formatTime(props.action.created))

const getFieldValue = (field: string) => {
  return _.get(actionData.value, field)
}

const onOpenTask = (task: TaskItem, hash = '') => {
  const { handle, id, name } = task
  closeBoardSettingsSlideover()
  let prefix: string | undefined = undefined
  let viewMode = TaskViewMode.MODAL
  if (activeView.value === BoardView.TIMELINE || activeView.value === BoardView.LIST) {
    prefix = '/list'
    viewMode = TaskViewMode.SIDEBAR
  }

  updateTaskUrl(task, prefix, hash)
  setCurrentTask({
    id,
    name,
    handle,
    viewMode,
  })
}
</script>
