export const downloadFile = (url: string, filename: string) => {
  const a = document.createElement('a')
  a.href = url
  a.style.display = 'none'
  a.download = filename
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const setMouseState = (state: string) => {
  window._mouseState = state
}
