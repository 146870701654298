/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/
import { STATUS_FRAGMENT } from '#status/fragment'

export const STATUS_LOADER_FRAGMENT = gql`
  ${STATUS_FRAGMENT}
  fragment StatusLoader on StatusType {
    ...Status
    settingsPack @client {
      ...SettingsPackLoader
    }
  }
`
