import { ApolloError } from '@apollo/client/core'
import type { ErrorResponse } from '@nuxtjs/apollo'
import type { FormError } from '#core/types'

export const quickToast = (
  message: string,
  title: string = 'Error',
  color = 'red'
) => {
  const toast = useToast()
  toast.add({
    title: title,
    description: message,
    color,
  })
}

export const handleSingleGraphQLError = (
  error: unknown,
  showToast: boolean = false,
  toastTitle: string = 'Error'
) => {
  logger.error('Unexpected Error:', error)

  let message

  if (error instanceof ApolloError) {
    const { graphQLErrors } = error
    message = graphQLErrors.length ? graphQLErrors[0].message : error.message
  } else {
    message = 'An unexpected error occurred. Please try again later.'
  }

  if (showToast) {
    quickToast(message, toastTitle)
  }

  return message
}

export const parseGqlErrors = (errors: ErrorResponse['graphQLErrors']) => {
  if (errors?.length) {
    try {
      const [error] = errors
      const parseErrors = JSON.parse(error.message)
      return Object.entries(parseErrors).reduce((acc, curr) => {
        const [path, message] = curr as [string, string]
        acc.push({
          path,
          message,
        })
        return acc
      }, [] as Array<{ path: string; message: string }>)
    } catch (e) {
      return []
    }
  }

  return []
}

export const parseFormErrors = (errors: FormError[]) => {
  const fieldErrors = []
  for (const error of errors) {
    const { field: path, messages } = error
    fieldErrors.push({
      path,
      message: messages.join(', '),
    })
  }

  return fieldErrors
}
