import revive_payload_client_by6S7yKAux from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lNCGLTLFu4 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gbvarlpcsg from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nE7EIxuXsA from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_k4fJj3GPMI from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WFteG20226 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FRyzqZyi6M from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Mv1plkYg3W from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_BcTopRAwjC from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_FsALcZQ6rA from "/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.27.3_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import slideovers_rWhMwnTXIO from "/app/node_modules/.pnpm/@nuxt+ui@2.19.2_patch_hash=dh5dsjkgj7znuiay5pdrqvlh2y_change-case@5.4.4_magicast@0.3.5_rollup_mlpjageu7qzx756rjd5d4s7o5i/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_eyHGg0SQVr from "/app/node_modules/.pnpm/@nuxt+ui@2.19.2_patch_hash=dh5dsjkgj7znuiay5pdrqvlh2y_change-case@5.4.4_magicast@0.3.5_rollup_mlpjageu7qzx756rjd5d4s7o5i/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_HvVBnLLPdw from "/app/node_modules/.pnpm/@nuxt+ui@2.19.2_patch_hash=dh5dsjkgj7znuiay5pdrqvlh2y_change-case@5.4.4_magicast@0.3.5_rollup_mlpjageu7qzx756rjd5d4s7o5i/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_XSNUQ8V3Lg from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.27.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_tDhu2vngq4 from "/app/node_modules/.pnpm/@nuxt+icon@1.8.2_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.1_sass@1.81.0_ters_z7tmyujegehqyrnyyrzwonn5ie/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_hBNAVHMLqm from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_change-case@5.4.4_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22._lw4jjadml5s44vuj4qmie6nnwm/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import apollo_TUfb6yUCu5 from "/app/layers/core/plugins/apollo.ts";
import app_jEMrYfhH6W from "/app/layers/core/plugins/app.ts";
import hydration_GkVcf8eLEW from "/app/layers/core/plugins/hydration.ts";
import sync_hover_cQ0llqSKSM from "/app/layers/core/plugins/sync-hover.ts";
import sync_scroll_aaOsshRrjw from "/app/layers/core/plugins/sync-scroll.ts";
import presets_tGWxZ10EIU from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_change-case@5.4.4_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22._lw4jjadml5s44vuj4qmie6nnwm/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_BNny6o84sa from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_change-case@5.4.4_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22._lw4jjadml5s44vuj4qmie6nnwm/node_modules/@nuxt/ui-pro/plugins/variables.ts";
export default [
  revive_payload_client_by6S7yKAux,
  unhead_lNCGLTLFu4,
  router_gbvarlpcsg,
  payload_client_nE7EIxuXsA,
  navigation_repaint_client_k4fJj3GPMI,
  check_outdated_build_client_WFteG20226,
  chunk_reload_client_FRyzqZyi6M,
  plugin_vue3_Mv1plkYg3W,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BcTopRAwjC,
  plugin_FsALcZQ6rA,
  slideovers_rWhMwnTXIO,
  modals_eyHGg0SQVr,
  colors_HvVBnLLPdw,
  plugin_client_XSNUQ8V3Lg,
  plugin_tDhu2vngq4,
  scrollbars_client_hBNAVHMLqm,
  apollo_TUfb6yUCu5,
  app_jEMrYfhH6W,
  hydration_GkVcf8eLEW,
  sync_hover_cQ0llqSKSM,
  sync_scroll_aaOsshRrjw,
  presets_tGWxZ10EIU,
  variables_BNny6o84sa
]