/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

import { FIELD_FRAGMENT } from '#field/fragment'

export const CUSTOM_FIELD_LOADER_FRAGMENT = gql`
  ${FIELD_FRAGMENT}
  fragment CustomFieldLoader on TaskFieldType {
    id
    value
    fieldId
    field @client {
      ...Field
    }
  }
`

export const FIELD_LOADER_FRAGMENT = gql`
  ${FIELD_FRAGMENT}
  fragment FieldLoader on FieldType {
    ...Field
    settingsPack @client {
      ...SettingsPackLoader
    }
  }
`
