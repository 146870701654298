import { endsWith, has, pick } from 'lodash-es'
import { TASK_TYPE_NAME } from '#task/constant'
import type {
  TaskItem,
  TaskSyncPayload,
  TaskUpdatePayload,
  BaseTask,
} from '#task/types'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'
import { TASK_FRAGMENT } from '#task/fragment'

export const useTaskDataConvert = () => {
  const { client } = useApolloClient()

  const getTaskIdentifier = (id: string) => getIdentifier(id, TASK_TYPE_NAME)
  const getTaskObject = (id: string) =>
    client.readFragment<BaseTask>(
      {
        id,
        fragment: TASK_FRAGMENT,
        fragmentName: 'Task',
      },
      true
    )

  const getTaskLabels = (
    taskLabels: string[],
    labelIds: string[],
    detachLabelIds: string[]
  ) => {
    const currentLabels = new Set(taskLabels)
    labelIds.forEach((id) =>
      currentLabels.has(id) ? currentLabels.delete(id) : currentLabels.add(id)
    )
    detachLabelIds.forEach(
      (id) => !currentLabels.has(id) && currentLabels.add(id)
    )
    return Array.from(currentLabels)
  }

  const fields = [
    'name',
    'handle',
    'boardId',
    'sectionId',
    'number',
    'position',
    'level',
    'parentId',
    'cover',
    'coverBackgroundColor',
    'closed',
    'created',
    'startDate',
    'dueDate',
    'subtasksCount',
    'lastActivity',
    'labelIds',
    'typeId',
    'statusId',
    'assigneeId',
    'taskFields',
    'description',
    'parentClosed',
    'sectionClosed',
    'dependencies',
  ]

  const defaultAttachment = {
    name: '',
    link: '',
  }

  // For app.add
  const getOptimisticTask = (
    id: string | null,
    payload: TaskUpdatePayload,
    forFields = false
  ): Partial<TaskItem> & { __typename?: string } => {
    const { currentBoard } = useWorkspaceSharedState()
    const defaults = {
      closed: false,
      parentClosed: false,
      sectionClosed: false,
      labelIds: [],
      taskFields: [],
      subtasksCount: 0,
      boardId: currentBoard.value.id,
    }
    const task = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? task : { ...task, __typename: TASK_TYPE_NAME }
  }

  /**
   * For app.update
   * ----------------
   * Need to convert update payload to task fields
   * status => statusId, status ref object
   * same for assignee, type, section, board
   */
  const getOptimisticTaskFields = (payload: TaskUpdatePayload) => {
    // Convert uplodate payload to sync payload
    const syncPayload: TaskSyncPayload = {}
    for (const field of fields) {
      if (has(payload, field)) {
        syncPayload[field] = payload[field]
        continue
      } else if (endsWith(field, 'Id')) {
        const moduleField = field.replace('Id', '') as keyof TaskUpdatePayload
        if (has(payload, moduleField)) {
          syncPayload[field] = payload[moduleField]
        }
      }
    }

    return getSyncTaskFields(syncPayload)
  }

  /**
   * For socket.add
   * ----------------
   * Need to convert sync payload to task loader & task details fields
   * Socket message already send the correct type (see task/sync.py > TaskSync.get_sync_object)
   * statusId => status and same for assigneeId, typeId -> use status, assignee, type @client in schema
   * labelIds => labels - use labels @client in schema
   * taskFields processing -> return like schema response, no client processing needed
   */

  // For socket.update
  const getSyncTaskFields = (payload: TaskSyncPayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)

    // Get fields to update
    const toUpdateFields = Object.keys(payload)

    const modifiers = pick(
      fieldModifiers,
      toUpdateFields
    ) as FieldsModifier<TaskItem>

    // Add field to update if fieldId is present
    toUpdateFields
      .filter((field) => endsWith(field, 'Id'))
      .forEach((field) => {
        const modelField = field.replace('Id', '') as keyof TaskItem
        modifiers[modelField] = () => {
          if (!has(payload, field) || !payload[field]) {
            return null
          }

          let typeName
          if (field === 'typeId') {
            typeName = 'TaskTypeType'
          } else if (field === 'assigneeId') {
            typeName = 'UserType'
          } else if (field === 'parentId') {
            typeName = 'TaskType'
          } else {
            typeName = capitalizeFirstChar(field.replace('Id', 'Type'))
          }

          return { __ref: getIdentifier(payload[field], typeName) }
        }
      })

    return modifiers
  }

  return {
    getTaskIdentifier,
    getTaskObject,
    getTaskLabels,
    getOptimisticTask,
    getOptimisticTaskFields,
    getSyncTaskFields,
    defaultAttachment,
  }
}
