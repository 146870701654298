import type { ApolloClient, Reference, NormalizedCacheObject } from '@apollo/client'
import { has } from 'lodash-es'

export enum BOARD_CACHE {
  DATA = 'boardData',
  SECTION = 'boardSections',
  TASK = 'boardTasks',
}

export enum ACTION {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export abstract class ModelListCache<T> {
  protected client: ApolloClient<NormalizedCacheObject>
  protected modelTypeName: string

  constructor(modelTypeName: string) {
    const { client } = useApolloClient()
    this.client = client
    this.modelTypeName = modelTypeName
  }

  /**
  * This function returns a list of boardCache to process.
  */
  getBoardCacheKeys(): (BOARD_CACHE | { key: BOARD_CACHE, subKey: string })[] {
    // return [BOARD_CACHE.DATA, BOARD_CACHE.SECTION, BOARD_CACHE.TASK]
    return []
  }

  /**
   * Update the cache with the new item
   */
  updateListCache(
    readField: (fieldName: string, ref: Reference) => unknown,
    cacheKey: string,
    action: ACTION,
    existing: Reference[] | {
      [key: string]: Reference[];
    },
    payload: Partial<T & { id: string }>,
    subKey: string | null = null,
  ) {
    // Skip if no id in payload
    if (!has(payload, 'id') || !payload.id) {
      return existing
    }

    // Update the item in the list cache if closed in payload (close/ reopen)
    if (has(payload, 'closed')) {
      if (payload.closed) {
        action = ACTION.DELETE
      } else {
        action = ACTION.ADD
      }
    }

    // For boardData, we need to process sub items
    if (subKey && typeof existing === 'object' && has(existing, subKey)) {
      // Remove the item from the cache
      let listValue = existing[subKey] as Array<Reference>
      if (action === ACTION.DELETE) {
        listValue = listValue.filter((ref: Reference) => {
          return readField('id', ref) !== payload.id
        })
        return { ...existing, [subKey]: listValue }
      }

      // Check if the item already exists in the cache
      const isExisting = listValue.some((ref: Reference) => {
        return readField('id', ref) === payload.id
      })

      // If the item already exists, return the existing items
      if (isExisting) {
        return existing
      }

      // Return the existing items with the new item
      const newListValue = [...listValue, { __ref: getIdentifier(payload.id, this.modelTypeName) }]
      return { ...existing, [subKey]: newListValue }
    } else if (Array.isArray(existing)) {
      // Process item from list for boardTasks, boardSections
      // Remove the item from the cache
      if (action === ACTION.DELETE) {
        return existing.filter((ref: Reference) => {
          return readField('id', ref) !== payload.id
        })
      }

      // Check if the item already exists in the cache
      const isExisting = existing.some((ref: Reference) => {
        return readField('id', ref) === payload.id
      })

      // If the item already exists, return the existing items
      if (isExisting) {
        return existing
      }

      // Return the existing items with the new item
      return [...existing, { __ref: getIdentifier(payload.id, this.modelTypeName) }]
    } else {
      logger.error(`Invalid existing cache value for action ${action}`, existing)
    }
  }

  /**
   * Build fields objects to update the cache
   */
  buildFields(action: ACTION, boardId: string, payload: Partial<T & { id: string }>) {
    // Build the fields object to update the cache
    const fields = {}

    this.getBoardCacheKeys().forEach((cacheKey) => {
      let key, subKey
      if (typeof cacheKey === 'string') {
        key = cacheKey
        subKey = null
      } else {
        key = cacheKey.key
        subKey = cacheKey.subKey
      }

      const f = (
        existingItems: Reference[] = [],
        { readField, storeFieldName }: {
          readField: (fieldName: string, ref: Reference) => unknown, storeFieldName: string
        }
      ) => {
        // Only process the cache if the boardId matches
        const storeField = extractStoreFieldName<{ boardId: string }>(
          storeFieldName
        )
        if (storeField.boardId !== boardId) {
          return existingItems
        }

        // Update the cache
        return this.updateListCache(readField, key, action, existingItems, payload, subKey)
      }

      fields[key] = f
    })

    return fields
  }

  add(boardId: string, payload: Partial<T & { id: string }>): void {
    const fields = this.buildFields(ACTION.ADD, boardId, payload)
    this.client.cache.modify({ id: 'ROOT_QUERY', fields })
  }

  update(boardId: string, payload: Partial<T & { id: string }>): void {
    const fields = this.buildFields(ACTION.UPDATE, boardId, payload)
    this.client.cache.modify({ id: 'ROOT_QUERY', fields })
  }

  delete(boardId: string, id: string): void {
    const payload = { id } as Partial<T & { id: string }>
    const fields = this.buildFields(ACTION.DELETE, boardId, payload)
    this.client.cache.modify({ id: 'ROOT_QUERY', fields })
  }
}
