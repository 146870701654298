<template>
  <UAvatar
    :size="size"
    :text="alt"
    :src="src"
    :ui="{
      rounded,
      size: uiSize,
      text: `w-full h-full flex text-${size} justify-center items-center text-gray-800 ${rounded} ${margin} ${avatarBackgroundColor}`,
    }"
    v-bind="$attrs"
  />
</template>

<script lang="ts" setup>
import type { AvatarSize } from '#ui/types'

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  size: {
    type: String as PropType<AvatarSize>,
    default: 'xs',
  },
  uiSize: {
    type: Object,
    default: () => ({ xs: 'h-5 w-5' }),
  },
  margin: {
    type: String,
    default: '',
  },
  rounded: {
    type: String,
    default: 'rounded-full',
  },
  length: {
    type: Number,
    default: 2,
  },
})

const alt = computed(() => {
  const text = props.name
    .split(' ')
    .map(([name]) => name)
    .join('')
  const truncatedText = text.substring(0, props.length)
  return truncatedText.toUpperCase()
})

const avatarBackgroundColor = computed(() => {
  const colors = [
    'violet-400',
    'primary-500',
    'sky-400',
    'yellow-400',
    'orange-400',
    'red-400',
  ]
  const key = props.id || props.name

  const charCode = [key.charCodeAt(0), key.charCodeAt(1)].reduce(
    (acc, val) => acc + val,
    0
  )
  return `bg-${colors[charCode % colors.length]}`
})
</script>
