import { clone } from 'lodash-es'
import { TASK_LOADER_FRAGMENT } from '#task/loader_fragment'
import { TASK_LOADER_FRAGMENT_NAME } from '#task/constant'
import type { TaskItemLoader } from '#task/types'

const $useBoardTasksLoader = () => {
  const currentBoardId = ref<string | null>(null)

  const { result, loading, load, refetch } = useTasksLazyQuery()
  const { client } = useApolloClient()
  const { getTaskIdentifier } = useTaskDataConvert()

  const tasks = computed<TaskItemLoader[]>(() => {
    return clone(result.value?.tasks || [])
      .filter((task) => !task.closed)
      .sort(sortPos)
  })

  // Load tasks
  const loadTasks = (boardId: string | null = null) => {
    if (!boardId) {
      const { currentBoard } = useWorkspaceSharedState()
      boardId = currentBoard.value.id
    }

    if (currentBoardId.value === boardId) {
      return
    }

    currentBoardId.value = boardId
    const loadResponse = load(null, { boardId })
    if (loadResponse === false) {
      return refetch({ boardId })
    }

    return loadResponse
  }

  /** Read tasks */
  // Read task from the cache using readFragment
  const readTaskFromId = (taskId: string) => {
    // Read task from the cache using readFragment
    return client.readFragment({
      id: getTaskIdentifier(taskId),
      fragment: TASK_LOADER_FRAGMENT,
      fragmentName: TASK_LOADER_FRAGMENT_NAME,
    })
  }

  // Read tasks from the cache
  const readTasksFromIds = (taskIds: string[]) => {
    // Read tasks from the cache
    return taskIds.map((id) => readTaskFromId(id))
  }

  /** Watch tasks, remember to unsubscribe on unmount */
  // Watch task from the cache using watchFragment
  const watchTaskFromId = (taskId: string) => {
    return useWatchFragment<TaskItemLoader>({
      fragment: TASK_LOADER_FRAGMENT,
      fragmentName: TASK_LOADER_FRAGMENT_NAME,
      id: getTaskIdentifier(taskId),
    })
  }

  // Todo: revise useWatchFragment to support multiple IDs & revise unsubscribe
  const watchTasksFromIds = (taskIds: string[] | Set<string>) => {
    const idsArray = Array.isArray(taskIds) ? taskIds : Array.from(taskIds)
    const fragments = idsArray.map((id) => ({
      fragment: TASK_LOADER_FRAGMENT,
      fragmentName: TASK_LOADER_FRAGMENT_NAME,
      id: getTaskIdentifier(id),
    }))
    return useWatchFragments<TaskItemLoader>(fragments)
  }

  return {
    loading,
    tasks,
    loadTasks,
    // Read
    readTaskFromId,
    readTasksFromIds,
    // Watch
    watchTaskFromId,
    watchTasksFromIds,
  }
}

export const useBoardTasksLoader = createSharedComposable($useBoardTasksLoader)
