import type { CreateLabelParams, UpdateLabelParams } from '#label/types'

export const useCreateLabel = () => {
  const { getOptimisticLabel } = useLabelDataConvert()

  const createLabel = async (params: CreateLabelParams) => {
    const settingsPackId = params.source === 'settings-pack' ? params.parentId : null
    const { mutate, error, loading } = useAddUpdateLabelMutation({
      variables: {
        input: params,
      },
      optimisticResponse: {
        addUpdateLabel: {
          label: getOptimisticLabel(null, { ...params, settingsPackId }),
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateLabel?.label && params.source === 'board') {
          const { listCache } = useLabelSync()
          listCache.add(params.parentId, data.addUpdateLabel.label)
        }
      },
    })

    if (loading.value) {
      return
    }

    const data = await mutate()

    if (error.value || !data?.data?.addUpdateLabel) {
      return {
        error: error.value,
      }
    }

    return { data: data?.data?.addUpdateLabel }
  }

  return {
    createLabel,
  }
}

export const useUpdateLabel = () => {
  const { client } = useApolloClient()
  const { getLabelIdentifier, getOptimisticLabelFields } = useLabelDataConvert()

  const updateLabel = async (params: Partial<UpdateLabelParams>) => {
    client.cache.modify({
      id: getLabelIdentifier(params.id as string),
      fields: getOptimisticLabelFields(params),
      optimistic: true,
    })

    const { mutate, error } = useAddUpdateLabelMutation({
      variables: {
        input: params,
      },
      update: (_, { data }) => {
        if (data?.addUpdateLabel?.label) {
          // Update the cache with the new item
          const { listCache } = useLabelSync()
          listCache.update(params.id as string, data.addUpdateLabel.label)
        }
      },
    })

    await mutate()
    if (error.value) {
      return {
        error: error.value,
      }
    }
  }

  return {
    updateLabel,
  }
}

export const useDeleteLabel = () => {
  const { client } = useApolloClient()
  const { getLabelIdentifier } = useLabelDataConvert()

  const deleteLabel = async (id: string) => {
    client.cache.evict({
      id: getLabelIdentifier(id),
    })

    const { mutate, error } = useDeleteLabelMutation(id)

    const data = await mutate()

    if (error.value || !data?.data?.deleteLabel) {
      return {
        error: error.value,
      }
    }

    return { data: data?.data?.deleteLabel }
  }

  return {
    deleteLabel,
  }
}
