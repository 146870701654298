export const $useModalManager = () => {
  const preventCloseState = ref<Set<string>>(new Set([]))
  const isPreventOpenTaskDetail = ref(false)

  return {
    preventCloseState,
    isPreventOpenTaskDetail,
  }
}

export const useModalManager = createSharedComposable($useModalManager)
