<template>
  <DeferredPopover
    ref="popoverRef"
    :ui="{
      width: 'min-w-64 max-w-[280px]',
      trigger: `flex ${disabled ? '!cursor-not-allowed' : ''}`,
    }"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <template #content>
      <PopoverContentTaskTypeSelect
        ref="popoverContentRef"
        source="board"
        :popover-ref="popoverRef"
        :parent-id="parentId"
        :selected-task-type-id="selectedTaskTypeId"
        :accept-levels="acceptLevels"
        :disable-setting="disableSetting"
        @close="popoverRef.close()"
        @change="$emit('change', $event)"
        @selected-type="selectedTaskType = $event"
      >
      </PopoverContentTaskTypeSelect>
    </template>
    <template #default="{ open, close, isOpen }">
      <slot
        :open="open"
        :close="close"
        :is-open="isOpen"
        :selected="selectedTaskType"
      />
    </template>
  </DeferredPopover>
</template>

<script lang="ts" setup>
import type { TaskLevel } from '#task/constant'

defineProps({
  selectedTaskTypeId: {
    type: String,
  },
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  acceptLevels: {
    type: Array as PropType<TaskLevel[]>,
    default: () => [],
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disableSetting: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['change'])

const popoverRef = ref()
const selectedTaskType = ref()

defineExpose({
  open: () => popoverRef.value?.open(),
})
</script>
