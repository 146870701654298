import type { WatchHandle } from 'vue'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import { dropTargetForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview'
import type {
  BaseEventPayload,
  CleanupFn,
  DropTargetLocalizedData,
  ElementDragType,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import type { Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item'
import {
  attachInstruction,
  extractInstruction,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item'
import type { ElementState, TargetElement } from '#core/types'
import type {
  TaskItem,
  TaskDraggableData,
  TaskDraggableModifier,
  SpreadsheetTaskRow,
} from '#task/types'
import { TASK_EMPTY_ID, TaskLevel } from '#task/constant'

export const useTaskAsDropTarget = (params: {
  item: TaskItem | Ref<TaskItem>
  targetElement?: TargetElement
  /**
   * The wrapper element is used to create a drop target for the entire task item
   * for handle case update task parent
   */
  wrapperTargetElement?: TargetElement
  dragHandleTarget?: TargetElement
  modifiers?: TaskDraggableModifier[]
  canDrag?: () => boolean
}) => {
  const { currentBoard } = useWorkspaceSharedState()
  const { currentDraggingTask } = useCurrentDraggingTask()
  const { updateTaskPosition } = useTaskMove()
  const { isIgnoreHoverTask } = useKanbanSharedState()

  const isDraggingOver = ref(false)
  const cleanup = ref<CleanupFn>()
  const state = ref<ElementState>({
    type: 'idle',
  })
  const closestEdge = ref<Edge | null>(null)
  const canDrop = ref<null | boolean>(null)
  let watcher: WatchHandle

  const computeCanDrop = (
    item: TaskDraggableData,
    destinationItem: TaskDraggableData
  ) => {
    /**
     * Prevent dragging a task into its own subtasks
     */
    if (
      item.taskId === destinationItem.parentId &&
      params.modifiers?.includes('parent')
    ) {
      return false
    }

    /**
     * Prevent dragging a module task into its own subtasks or a nearby a task that is not a module
     */
    if (
      item.level === TaskLevel.MODULE &&
      destinationItem.parentId &&
      params.modifiers?.includes('parent')
    ) {
      return false
    }

    /**
     * Prevent dragging a subtask same level with task or module
     */
    if (
      item.level === TaskLevel.SUBTASK &&
      destinationItem.level !== TaskLevel.SUBTASK
    ) {
      return false
    }

    if (
      item.level === TaskLevel.TASK &&
      destinationItem.level === TaskLevel.SUBTASK
    ) {
      return false
    }

    return true
  }

  const resetState = () => {
    isDraggingOver.value = false
    canDrop.value = null
    closestEdge.value = null
    currentDraggingTask.closestEdge = closestEdge.value
    currentDraggingTask.item = {}
  }

  const isMakeInstructionChild = (instruction: Instruction | null) => {
    if (!instruction) {
      return false
    }

    return (
      instruction.type === 'make-child' ||
      (instruction?.type === 'instruction-blocked' &&
      instruction.desired.type === 'make-child')
    )
  }

  const getAllowedTaskIdsForDrop = (params: {
    defaultItems: TaskDraggableData[]
    destinationItem: TaskDraggableData
    instruction: Instruction | null
  }): string[] => {
    const { defaultItems, destinationItem, instruction } = params
    let tasks = defaultItems
    /**
     * TODO: Implement getSelectedTasks for all views, not just list view
     * Currently, this function is only used in list view
     */
    const { getSelectedTasks } = useListViewSharedState()
    const selectedTasks = getSelectedTasks() as SpreadsheetTaskRow[]
    if (selectedTasks.length > 0) {
      tasks = selectedTasks.map((task) => ({
        taskId: task.id,
        sectionId: task.sectionId,
        level: task.level,
        position: task.position,
        type: 'task',
        parentId: task.parentId,
      }))
    }

    const isMakeChild = isMakeInstructionChild(instruction)
    if (!instruction || !isMakeChild) {
      return tasks.reduce((acc, task) => {
        if (computeCanDrop(task, destinationItem)) {
          acc.push(task.taskId)
        }

        return acc
      }, [] as string[])
    }

    if (isMakeChild) {
      const levelOrder: Record<TaskLevel, number> = {
        [TaskLevel.SUBTASK]: 0,
        [TaskLevel.TASK]: 1,
        [TaskLevel.MODULE]: 2,
      }

      return tasks.reduce((acc, task) => {
        const destinationLevel = levelOrder[destinationItem.level]
        const currentLevel = levelOrder[task.level]
        if (
          destinationLevel - currentLevel === 1 &&
          task.parentId !== destinationItem.taskId
        ) {
          acc.push(task.taskId)
        }

        return acc
      }, [] as string[])
    }

    return []
  }

  const onDropNextToNeighbour = async (
    args: BaseEventPayload<ElementDragType> & DropTargetLocalizedData
  ) => {
    const { source, self } = args
    const item = source.data as TaskDraggableData
    const destinationItem = self.data as TaskDraggableData
    if (!canDrop.value) {
      return resetState()
    }

    const allowChangeParentLevels = [TaskLevel.SUBTASK]
    const canChangeParent =
      allowChangeParentLevels.includes(item.level) &&
      destinationItem.level === item.level
    const currClosestEdge = closestEdge.value
    /**
     * Sometimes when dragging and dropping tasks, we only want to update a specific field to avoid affecting the logic's correctness.
     * For example, when the user groups by module, we only need to change the parentId instead of the entire list of IDs that the task interacts with.
     * If the modifiers array is empty, the function will only update the position.
     */
    const modifyFields = {
      sectionId: item.sectionId,
      parentId: item.parentId,
    }
    if (params.modifiers?.includes('section')) {
      modifyFields.sectionId = destinationItem.sectionId
    }

    if (params.modifiers?.includes('parent') || canChangeParent) {
      modifyFields.parentId = destinationItem.parentId
    }

    resetState()
    const tasks = useReadTasksFromCache(currentBoard.value.id)
    let indexOfTarget = tasks.findIndex(
      (task) => task.id === destinationItem.taskId
    )
    if (indexOfTarget === -1) {
      indexOfTarget = tasks.length
    }

    const startIndex = tasks.findIndex((task) => task.id === item.taskId)
    const destinationIndex = getReorderDestinationIndex({
      startIndex,
      indexOfTarget,
      closestEdgeOfTarget: currClosestEdge,
      axis: 'vertical',
    })
    const dropTaskIds = getAllowedTaskIdsForDrop({
      destinationItem: self.data as TaskDraggableData,
      defaultItems: [item],
      instruction: null,
    })
    for (const taskId of dropTaskIds) {
      await updateTaskPosition({
        taskIds: [taskId],
        sectionId: modifyFields.sectionId,
        index: destinationIndex,
        parentId: modifyFields.parentId,
      })
    }
  }

  const createDraggable = () => {
    const {
      item,
      targetElement,
      wrapperTargetElement,
      dragHandleTarget,
      canDrag,
    } = params

    const taskItem = unref(item)
    const data = {
      type: 'task',
      taskId: taskItem.id == TASK_EMPTY_ID ? null : taskItem.id,
      sectionId: taskItem.sectionId,
      position: taskItem.position,
      level: taskItem.level,
      parentId: taskItem.parentId,
    }
    let element = undefined
    let dragHandleElement = undefined
    let wrapperElement = undefined
    if (targetElement) {
      element = unrefElement(evaluativeFn(targetElement))
    }

    if (dragHandleTarget) {
      dragHandleElement = unrefElement(evaluativeFn(dragHandleTarget))
    }

    if (wrapperTargetElement) {
      wrapperElement = unrefElement(evaluativeFn(wrapperTargetElement))
    }

    const draggableFunctions = []
    if (element) {
      draggableFunctions.push(
        draggable({
          element,
          dragHandle: dragHandleElement,
          getInitialData: (): TaskDraggableData => ({
            type: 'task',
            taskId: taskItem.id,
            sectionId: taskItem.sectionId,
            position: taskItem.position,
            level: taskItem.level,
            parentId: taskItem.parentId,
          }),
          canDrag,
          onGenerateDragPreview: ({ source, nativeSetDragImage }) => {
            const rect = source.element.getBoundingClientRect()
            currentDraggingTask.rect = rect
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: pointerOutsideOfPreview({
                x: '4px',
                y: '4px',
              }),
              render({ container }) {
                state.value = {
                  type: 'preview',
                  container,
                  rect,
                }
                return () => {
                  state.value = {
                    type: 'dragging',
                  }
                }
              },
            })
          },
          onDragStart: () => {
            state.value = {
              type: 'dragging',
            }
            addChromiumDragAndDropWorkaround()
          },
          onDrop: () => {
            currentDraggingTask.rect = undefined
            state.value = {
              type: 'idle',
            }
            isIgnoreHoverTask.value = true
            clearChromiumDragAndDropWorkaround()
            unblockPointerEventsOnEverything()
          },
        }),
        dropTargetForExternal({
          element,
        })
      )

      if (!wrapperElement) {
        draggableFunctions.push(
          dropTargetForElements({
            element,
            canDrop: (args) => args.source.data.type === 'task',
            getIsSticky: () => true,
            getData: ({ input, element }) => {
              if (!currentDraggingTask.closestEdge) {
                currentDraggingTask.closestEdge = 'none'
              }

              return attachClosestEdge(data, {
                input,
                element,
                allowedEdges: ['top', 'bottom'],
              })
            },
            onDragEnter: (args) => {
              const item = args.source.data
              if (item?.taskId !== taskItem.id) {
                closestEdge.value = extractClosestEdge(args.self.data)
                currentDraggingTask.closestEdge = closestEdge.value
              }

              const dropTaskIds = getAllowedTaskIdsForDrop({
                defaultItems: [item as TaskDraggableData],
                destinationItem: args.self.data as TaskDraggableData,
                instruction: null,
              })
              canDrop.value = dropTaskIds.length > 0
            },
            onDrag: (args) => {
              const item = args.source.data
              currentDraggingTask.item = item
              if (item?.taskId !== taskItem.id) {
                closestEdge.value = extractClosestEdge(args.self.data)
                currentDraggingTask.closestEdge = closestEdge.value
              }
            },
            onDragLeave: () => {
              closestEdge.value = null
              currentDraggingTask.closestEdge = null
            },
            onDrop: async (args) => {
              onDropNextToNeighbour(args)
            },
          })
        )
      }
    }

    if (wrapperElement) {
      /**
       * Create a drop target for the entire task item for handle case update task parent
       * when dragging a task into a other task
       */
      draggableFunctions.push(
        dropTargetForElements({
          element: wrapperElement,
          canDrop: ({ source }) => source.data.type === 'task',
          getIsSticky: () => true,
          getData: ({ input, element }) => {
            return attachInstruction(data, {
              input,
              element,
              currentLevel: taskItem.level,
              indentPerLevel: 25,
              mode: 'standard',
              block: ['reorder-above', 'reorder-below', 'make-child'],
            })
          },
          onDrag: (args) => {
            const item = args.source.data as TaskDraggableData
            const destinationItem = args.self.data as TaskDraggableData
            currentDraggingTask.item = item
            const extractedInstruction = extractInstruction(destinationItem)
            const dropTaskIds = getAllowedTaskIdsForDrop({
              defaultItems: [item],
              destinationItem,
              instruction: extractedInstruction,
            })
            canDrop.value = dropTaskIds.length > 0
            closestEdge.value = null
            isDraggingOver.value = false
            if (extractedInstruction?.type === 'instruction-blocked') {
              switch (extractedInstruction.desired.type) {
                case 'reorder-above':
                  closestEdge.value = 'top'
                  break
                case 'reorder-below':
                  closestEdge.value = 'bottom'
                  break
                case 'make-child':
                  isDraggingOver.value = true
              }
            }
          },
          onDragLeave: () => {
            isDraggingOver.value = false
            closestEdge.value = null
            currentDraggingTask.closestEdge = null
          },
          onDrop: async (args) => {
            if (!canDrop.value) {
              return resetState()
            }

            const item = args.source.data as TaskDraggableData
            const destinationItem = args.self.data as TaskDraggableData
            const extractedInstruction = extractInstruction(destinationItem)
            const isMakeChild = isMakeInstructionChild(extractedInstruction)

            if (!isMakeChild) {
              return onDropNextToNeighbour(args)
            }

            resetState()
            const dropTaskIds = getAllowedTaskIdsForDrop({
              destinationItem: destinationItem,
              defaultItems: [item],
              instruction: extractedInstruction,
            })
            for (const taskId of dropTaskIds) {
              await updateTaskPosition({
                taskIds: [taskId],
                sectionId: item.sectionId as string,
                index: 1e9,
                parentId: destinationItem.taskId as string,
              })
            }
          },
        })
      )
    }

    cleanup.value = combine(...draggableFunctions)
  }

  /**
   * @issue Can not drag task into module task was created by optimistic response
   * Watch for item dependencies change to re-create draggable
   */
  if (isRef(params.item)) {
    const item = params.item as Ref<TaskItem>
    watcher = watch(
      () => item.value,
      () => {
        cleanup.value?.()
        Queue.addTask(createDraggable)
      }
    )
  }

  onMounted(() => {
    Queue.addTask(createDraggable)
  })

  onBeforeUnmount(() => {
    cleanup.value?.()
    watcher?.()
  })

  return {
    canDrop,
    state,
    closestEdge,
    isDraggingOver,
  }
}
