export const ATTACHMENT_FRAGMENT = gql`
  fragment Attachment on AttachmentType {
    id
    name
    type
    url
    mimeType
    created
  }
`
