import lunr from 'lunr'

import {
  cloneDeep,
  differenceBy,
  isEqual,
  debounce,
  difference,
} from 'lodash-es'
import type { SortType } from '#board/types'
import { FieldType } from '#field/constant'
import type { Field } from '#field/types'
import type { BaseTaskType } from '#task-type/types'
import {
  ActivityType,
  DateFieldFilter,
  DueDateType,
  TaskFilterCriteria,
  TaskLevel,
} from '#task/constant'
import type {
  TaskGlobalFilter,
  TaskGlobalFilterCustomField,
  TaskItemLoader,
} from '#task/types'
import { BoardView, GroupBy } from '#board/constant'

export const useApplyTaskFilter = (options: {
  tasks: Ref<TaskItemLoader[]>
  ready?: Ref<boolean> | boolean
}) => {
  const {
    globalFilter,
    filteredTasksCount,
    isHasFilter,
    filteredSubtaskIds,
    filterLoaded,
    tasksCreatedByCurrentUser,
  } = useTaskFilterContext()
  const { sortBy, boardData, activeView, groupBy } = useBoardSharedState()
  const { auth } = storeToRefs(useAuthStore())
  const { origin } = useRequestURL()
  const ready: Ref<boolean> = toRef(options.ready ?? true)
  const isFirstFiltered = ref(false)
  const lastQuery = ref({})
  const isFiltered = ref<boolean>(false)

  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    )
  }

  const rangeDateBetween = (date1: Date, date2: Date) => {
    const dates = []
    const currentDate = new Date(date1)
    while (currentDate <= date2) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
  }

  const getStartOfNextWeek = (startDay: number = 1): Date => {
    const today = new Date()
    const currentDay = today.getDay()
    const daysUntilNextWeek = (7 - currentDay + startDay) % 7 || 7
    const nextWeekStart = new Date(today)
    nextWeekStart.setDate(today.getDate() + daysUntilNextWeek)

    return nextWeekStart
  }

  const getStartOfNextMonth = (): Date => {
    const today = new Date()
    const nextMonth = today.getMonth() + 1
    const startOfNextMonth = new Date(today.getFullYear(), nextMonth, 1)
    return startOfNextMonth
  }

  const getEndOfNextMonth = (): Date => {
    const today = new Date()
    const nextMonth = today.getMonth() + 1
    const startOfMonthAfterNext = new Date(
      today.getFullYear(),
      nextMonth + 1,
      1
    )
    const endOfNextMonth = new Date(startOfMonthAfterNext.getTime() - 1)
    return endOfNextMonth
  }

  /**
   * Using web worker for better performance and prevent blocking main thread
   */
  const { workerStatus, workerFn, workerTerminate } = useWebWorkerFn(
    (boardTasks, query, define, options) =>
      applyFilterAndSortTasks(
        JSON.parse(boardTasks),
        JSON.parse(query),
        JSON.parse(define),
        JSON.parse(options)
      ),
    {
      localDependencies: [
        applyFilterAndSortTasks,
        isSameDay,
        rangeDateBetween,
        getStartOfNextWeek,
        getStartOfNextMonth,
        getEndOfNextMonth,
      ],
      dependencies: [
        `${origin}/assets/js/lunr.min.js`,
        `${origin}/assets/js/lunr.stemmer.support.min.js`, // For language support
        `${origin}/assets/js/lunr.multi.min.js`, // For multiple language support
        `${origin}/assets/js/all-languages.js`,
      ],
      timeout: 5000,
    }
  )

  const filteredTasks = ref<TaskItemLoader[]>([])

  const isHasQuery = computed(() => isHasFilter.value || sortBy.field)

  syncRef(
    computed(() => filteredTasks.value.length),
    filteredTasksCount,
    {
      direction: 'ltr',
      transform: {
        ltr: (value) => {
          return value
        },
      },
    }
  )

  syncRef(isFiltered, filterLoaded, {
    direction: 'both',
    transform: {
      ltr: (value) => {
        return value
      },
      rtl: (value) => {
        return value
      },
    },
  })

  syncRef(filteredTasks, filteredSubtaskIds, {
    direction: 'ltr',
    transform: {
      ltr: (tasks: TaskItemLoader[]) => {
        if (!isHasFilter.value) {
          return {}
        }

        return tasks.reduce((acc, task) => {
          if (task.parentId) {
            if (!acc[task.parentId]) {
              acc[task.parentId] = []
            }

            acc[task.parentId].push(task.id)
          }

          return acc
        }, {} as Record<string, string[]>)
      },
    },
  })

  /**
   * Build params for filter, add default params to here
   * NOTE: Dont pass value is a function to worker
   */
  const buildParams = <T extends 'query' | 'define' | 'options'>(
    pick: T,
    mergeParams: Record<string, unknown> = {}
  ) => {
    const boardFields = boardData.value.taskTypes.flatMap(
      (taskType: BaseTaskType & { fields: Field[] }) => {
        if (taskType.fields) {
          return taskType.fields
        }

        return []
      }
    )
    const params = {
      query: {
        filter: globalFilter,
        sort: sortBy,
      },
      define: {
        authId: auth.value.id,
        criteria: TaskFilterCriteria,
        dueDateType: DueDateType,
        fieldType: FieldType,
        taskLevel: TaskLevel,
        activityType: ActivityType,
        dateFieldFilter: DateFieldFilter,
        boardFields,
      },
      options: {
        skipFilter: false,
        skipSort: false,
        includeParentModule: false,
      },
    }
    return {
      ...params[pick],
      ...mergeParams,
    } as (typeof params)[T]
  }

  function applyFilterAndSortTasks(
    tasks: TaskItemLoader[],
    query: { filter: TaskGlobalFilter; sort: SortType },
    define: {
      authId: string
      criteria: typeof TaskFilterCriteria
      dueDateType: typeof DueDateType
      fieldType: typeof FieldType
      taskLevel: typeof TaskLevel
      activityType: typeof ActivityType
      dateFieldFilter: typeof DateFieldFilter
      boardFields: Field[]
    },
    filterOptions?: {
      skipFilter?: boolean
      skipSort?: boolean
      includeParentModule?: boolean
    }
  ) {
    // commented some languages due to got error when build
    const supportedLanguages = [
      'ar',
      'da',
      'de',
      'el',
      'es',
      'fi',
      'fr',
      'he',
      'hu',
      'hy',
      'it',
      'ko',
      'nl',
      'no',
      'pt',
      'ro',
      'ru',
      'sv',
      'tr',
      'vi',
    ]
    const { filter, sort } = query
    const {
      authId,
      criteria,
      dueDateType,
      fieldType,
      activityType,
      dateFieldFilter,
      boardFields,
      taskLevel,
    } = define
    let filteredTasks: TaskItemLoader[] = tasks
    if (!filteredTasks.length) {
      return []
    }

    const tasksMap = tasks.reduce((acc, task) => {
      acc[task.id] = task
      return acc
    }, {} as Record<string, TaskItemLoader>)

    const isInWebWorker =
      typeof WorkerGlobalScope !== 'undefined' &&
      self instanceof WorkerGlobalScope

    const handleFilterByKeyword = () => {
      if (filter[criteria.SEARCH]) {
        /**
         * If the function is running in web worker, we need to import Lunr from the importScripts
         */
        const LunrInstance = isInWebWorker ? self.lunr : lunr
        if (isInWebWorker) {
          LunrInstance.multiLanguage('en', ...supportedLanguages)
        }

        const idx = LunrInstance(function () {
          this.field('name')
          this.field('number')
          this.ref('id')

          filteredTasks.forEach((task) => {
            this.add(task)
          })
        })

        const matchingTerms = idx.search(`${filter[criteria.SEARCH]}`)
        const refs = matchingTerms.map((term) => term.ref)
        filteredTasks = filteredTasks.filter((task) => refs.includes(task.id))
      }
    }

    const handleFilterByMember = () => {
      if (
        filter[criteria.INCLUDE_MEMBER].members.length ||
        Object.values(filter[criteria.INCLUDE_MEMBER].condition).some(Boolean)
      ) {
        const { condition, members } = filter[criteria.INCLUDE_MEMBER]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (condition?.no) {
            scopedConditions.push(!task.assigneeId)
          }

          if (condition?.all) {
            scopedConditions.push(
              !!task.assigneeId && task.assigneeId !== authId
            )
          }

          if (members.length) {
            scopedConditions.push(members.includes(task.assigneeId as string))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }

      if (filter[criteria.EXCLUDE_MEMBER].members.length) {
        const { members } = filter[criteria.EXCLUDE_MEMBER]
        filteredTasks = filteredTasks.filter(
          (task) => !members.includes(task.assigneeId as string)
        )
      }
    }

    const handleFilterByLastActivity = () => {
      if (filter[criteria.ACTIVITY].length) {
        const today = new Date()
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (filter[criteria.ACTIVITY].includes(activityType.LAST_WEEK)) {
            const lastWeek = new Date(today)
            lastWeek.setDate(today.getDate() - 7)
            scopedConditions.push(
              !!task.lastActivity && new Date(task.lastActivity) > lastWeek
            )
          }

          if (filter[criteria.ACTIVITY].includes(activityType.LAST_TWO_WEEKS)) {
            const lastTwoWeeks = new Date(today)
            lastTwoWeeks.setDate(today.getDate() - 14)
            scopedConditions.push(
              !!task.lastActivity && new Date(task.lastActivity) > lastTwoWeeks
            )
          }

          if (
            filter[criteria.ACTIVITY].includes(activityType.LAST_FOUR_WEEKS)
          ) {
            const lastFourWeeks = new Date(today)
            lastFourWeeks.setDate(today.getDate() - 28)
            scopedConditions.push(
              !!task.lastActivity && new Date(task.lastActivity) > lastFourWeeks
            )
          }

          if (
            filter[criteria.ACTIVITY].includes(
              activityType.WITHOUT_LAST_FOUR_WEEKS
            )
          ) {
            const lastFourWeeks = new Date(today)
            lastFourWeeks.setDate(today.getDate() - 28)
            scopedConditions.push(
              !task.lastActivity || new Date(task.lastActivity) < lastFourWeeks
            )
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFilterByDueDate = () => {
      if (filter[criteria.DUE_DATE].length) {
        const today = new Date()
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (filter[criteria.DUE_DATE].includes(dueDateType.NO_DATE)) {
            scopedConditions.push(!task.dueDate)
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.OVERDUE)) {
            scopedConditions.push(
              !!task.dueDate && new Date(task.dueDate) < today
            )
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.TODAY)) {
            scopedConditions.push(
              !!task.dueDate && isSameDay(new Date(task.dueDate), today)
            )
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.DUE_NEXT_DAY)) {
            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)
            scopedConditions.push(
              !!task.dueDate && isSameDay(new Date(task.dueDate), tomorrow)
            )
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.DUE_NEXT_WEEK)) {
            const nextWeek = getStartOfNextWeek()
            const endNextWeek = new Date(nextWeek)
            endNextWeek.setDate(nextWeek.getDate() + 6)
            const isInNextWeekDates = rangeDateBetween(
              nextWeek,
              endNextWeek
            ).some(
              (date) => task.dueDate && isSameDay(new Date(task.dueDate), date)
            )
            scopedConditions.push(!!task.dueDate && isInNextWeekDates)
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.DUE_NEXT_MONTH)) {
            const startNextMonth = getStartOfNextMonth()
            const endNextMonth = getEndOfNextMonth()
            const isInNextMonthDates = rangeDateBetween(
              startNextMonth,
              endNextMonth
            ).some(
              (date) => task.dueDate && isSameDay(new Date(task.dueDate), date)
            )
            scopedConditions.push(!!task.dueDate && isInNextMonthDates)
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFilterByLabel = () => {
      if (
        filter[criteria.INCLUDE_LABEL].labels.length ||
        Object.values(filter[criteria.INCLUDE_LABEL].condition).some(Boolean)
      ) {
        const { condition, labels } = filter[criteria.INCLUDE_LABEL]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (condition?.no) {
            scopedConditions.push(!task.labelIds.length)
          }

          if (condition?.all) {
            scopedConditions.push(!!task.labelIds.length)
          }

          if (labels.length) {
            scopedConditions.push(
              labels.some((label) => task.labelIds.includes(label))
            )
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }

      if (filter[criteria.EXCLUDE_LABEL].labels.length) {
        const { labels } = filter[criteria.EXCLUDE_LABEL]
        if (labels.length) {
          filteredTasks = filteredTasks.filter((task) => {
            return !labels.some((label) => task.labelIds.includes(label))
          })
        }
      }
    }

    const handleFilterByStatus = () => {
      if (
        filter[criteria.INCLUDE_STATUS].statuses.length ||
        Object.values(filter[criteria.INCLUDE_STATUS].condition).some(Boolean)
      ) {
        const { condition, statuses } = filter[criteria.INCLUDE_STATUS]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []

          if (condition?.all) {
            scopedConditions.push(!!task.statusId)
          }

          if (statuses.length) {
            scopedConditions.push(statuses.includes(task.statusId))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }

      if (filter[criteria.EXCLUDE_STATUS].statuses.length) {
        const { statuses } = filter[criteria.EXCLUDE_STATUS]
        if (statuses.length) {
          filteredTasks = filteredTasks.filter(
            (task) => !statuses.includes(task.statusId)
          )
        }
      }
    }

    const handleFilterByModule = () => {
      if (
        filter[criteria.INCLUDE_MODULE].tasks.length ||
        Object.values(filter[criteria.INCLUDE_MODULE].condition).some(Boolean)
      ) {
        const { condition, tasks } = filter[criteria.INCLUDE_MODULE]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []

          if (condition?.no) {
            scopedConditions.push(
              !task.parentId && task.level == taskLevel.TASK
            )
          }

          if (condition?.all) {
            scopedConditions.push(
              !!task.parentId && task.level == taskLevel.TASK
            )
          }

          if (tasks.length) {
            scopedConditions.push(tasks.includes(task.parentId as string))
            scopedConditions.push(tasks.includes(task.id as string))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFilterByTaskType = () => {
      if (
        filter[criteria.INCLUDE_TASK_TYPE].taskTypes.length ||
        Object.values(filter[criteria.INCLUDE_TASK_TYPE].condition).some(
          Boolean
        )
      ) {
        const { condition, taskTypes } = filter[criteria.INCLUDE_TASK_TYPE]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []

          if (condition?.all) {
            scopedConditions.push(!!task.typeId)
          }

          if (taskTypes.length) {
            scopedConditions.push(taskTypes.includes(task.typeId))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFiterByCustomField = () => {
      const getScopedConditions = (
        field: TaskGlobalFilterCustomField,
        task: TaskItemLoader
      ) => {
        const scopedConditions: boolean[] = []
        const taskField = task.taskFields.find(
          (customField) => customField.field.id === field.id
        )

        if (field.condition?.all) {
          if (taskField?.field.type === fieldType.DATE) {
            scopedConditions.push(!!taskField?.value)
          }

          if (taskField?.field.type === fieldType.DROPDOWN) {
            scopedConditions.push(!!taskField?.value)
          }

          if (taskField?.field.type === fieldType.CHECKBOX) {
            scopedConditions.push(
              !!taskField?.value && JSON.parse(taskField.value).length > 0
            )
          }
        }

        if (field.options.length) {
          if (taskField?.field.type === fieldType.DATE) {
            const isMatchCondition = field.options.some((option) => {
              if (option === dateFieldFilter.TODAY) {
                return isSameDay(new Date(taskField.value), new Date())
              }

              if (option === dateFieldFilter.NEXT_DAY) {
                const tomorrow = new Date()
                tomorrow.setDate(tomorrow.getDate() + 1)
                return isSameDay(new Date(taskField.value), tomorrow)
              }

              if (option === dateFieldFilter.NEXT_WEEK) {
                const nextWeek = getStartOfNextWeek()
                const endNextWeek = new Date(nextWeek)
                endNextWeek.setDate(nextWeek.getDate() + 6)
                return rangeDateBetween(nextWeek, endNextWeek).some((date) =>
                  isSameDay(new Date(taskField.value), date)
                )
              }

              if (option === dateFieldFilter.NEXT_MONTH) {
                const startNextMonth = getStartOfNextMonth()
                const endNextMonth = getEndOfNextMonth()
                return rangeDateBetween(startNextMonth, endNextMonth).some(
                  (date) => isSameDay(new Date(taskField.value), date)
                )
              }

              return false
            })
            scopedConditions.push(isMatchCondition)
          }

          if (taskField?.field.type === fieldType.DROPDOWN) {
            const isContainOption =
              !!taskField.value &&
              field.options.includes(taskField.value as string)
            scopedConditions.push(isContainOption)
          }

          if (taskField?.field.type === fieldType.CHECKBOX) {
            const isContainOption =
              !!taskField.value &&
              field.options.some((option) =>
                JSON.parse(taskField.value).includes(option)
              )
            scopedConditions.push(isContainOption)
          }
        }

        return scopedConditions
      }

      if (Object.keys(filter[criteria.INCLUDE_FIELD]).length) {
        Object.entries(filter[criteria.INCLUDE_FIELD]).forEach(([_, field]) => {
          filteredTasks = filteredTasks.filter((task) => {
            const scopedConditions: boolean[] = [
              !field.options.length && !field.condition?.all,
              ...getScopedConditions(field, task),
            ]
            return scopedConditions.some(Boolean)
          })
        })
      }

      if (Object.keys(filter[criteria.EXCLUDE_FIELD]).length) {
        Object.entries(filter[criteria.EXCLUDE_FIELD]).forEach(([_, field]) => {
          filteredTasks = filteredTasks.filter((task) => {
            const scopedConditions: boolean[] = [
              true,
              ...getScopedConditions(field, task).map(
                (condition) => !condition
              ),
            ]

            return scopedConditions.every(Boolean)
          })
        })
      }
    }

    const filterChains = [
      handleFilterByKeyword,
      handleFilterByMember,
      handleFilterByDueDate,
      handleFilterByLabel,
      handleFilterByStatus,
      handleFilterByModule,
      handleFilterByTaskType,
      handleFilterByLastActivity,
      handleFiterByCustomField,
    ]

    if (!filterOptions?.skipFilter) {
      filterChains.forEach((applyFilter) => applyFilter())
    }

    /**
     * Handle sorting tasks
     */
    let sortFunction = (a: TaskItemLoader, b: TaskItemLoader) =>
      a.position - b.position
    if (sort.key) {
      const { field, key, direction } = sort
      if (key && !Object.values(fieldType).includes(key as FieldType)) {
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          let comparison: number
          const aField = a.taskFields.find((item) => item.field.id === key)!
          const bField = b.taskFields.find((item) => item.field.id === key)!
          const defaultValue =
            boardFields.find((item) => item.id === key)?.default || ''
          const valueA = aField ? aField.value : defaultValue
          const valueB = bField ? bField.value : defaultValue
          if (field === fieldType.NUMBER) {
            comparison = +(aField?.value ?? 0) - +(bField?.value ?? 0)
          } else if (field === fieldType.DROPDOWN) {
            let fieldOptions =
              boardFields.find((item) => item.id === key)?.options || []
            if (typeof fieldOptions === 'string') {
              fieldOptions = JSON.parse(fieldOptions)
            }

            const indexA =
              fieldOptions.findIndex((option) => option.value === valueA) || -1
            const indexB =
              fieldOptions.findIndex((option) => option.value === valueB) || -1
            comparison = indexA - indexB
          } else {
            comparison = valueA.localeCompare(valueB)
          }

          return direction === 'asc' ? comparison : -comparison
        }
      } else if (field === fieldType.STATUS) {
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          const statusComparison = (a.status?.type ?? 0) - (b.status?.type ?? 0)
          const posComparison = a.status.position - b.status.position
          const comparison =
            statusComparison !== 0 ? statusComparison : posComparison
          return direction === 'asc' ? comparison : -comparison
        }
      } else if (field === fieldType.ASSIGNEE) {
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          const assigneeComparison = (a.assignee?.fullName ?? '').localeCompare(
            b.assignee?.fullName ?? ''
          )
          const posComparison = a.position - b.position
          const comparison =
            assigneeComparison !== 0 ? assigneeComparison : posComparison
          return direction === 'asc' ? comparison : -comparison
        }
      } else if (field === fieldType.TIME_RANGE) {
        /**
         * The comparison is done in the following order:
         * 1. If both tasks have start dates, compare the start dates.
         * 2. If one task has a start date and the other does not, the task with the start date is considered smaller.
         * 3. If both tasks have due dates, compare the due dates.
         * 4. If one task has a due date and the other does not, the task with the due date is considered smaller.
         * 5. If both tasks have neither start nor due dates, or if the start and due dates are equal, compare the task positions.
         */
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          const aHasStartDate = !!a.startDate
          const bHasStartDate = !!b.startDate
          const aHasDueDate = !!a.dueDate
          const bHasDueDate = !!b.dueDate

          let comparison = 0
          if (aHasStartDate && bHasStartDate) {
            comparison = a.startDate!.localeCompare(b.startDate!)
          } else if (aHasStartDate) {
            comparison = -1
          } else if (bHasStartDate) {
            comparison = 1
          }

          if (comparison === 0) {
            if (aHasDueDate && bHasDueDate) {
              comparison = a.dueDate!.localeCompare(b.dueDate!)
            } else if (aHasDueDate) {
              comparison = -1
            } else if (bHasDueDate) {
              comparison = 1
            }
          }

          if (comparison === 0) {
            comparison = a.position - b.position
          }

          return direction === 'asc' ? comparison : -comparison
        }
      }
    }

    const pushedTasks = new Set<string>([])
    const parentCache = new Map<string, TaskItemLoader[]>()

    filteredTasks = filteredTasks.reduce((acc, task) => {
      let parentId = task.parentId as string
      const parentsToAdd: TaskItemLoader[] = []

      while (parentId) {
        if (parentCache.has(parentId)) {
          parentsToAdd.push(...parentCache.get(parentId)!)
          break
        }

        const parentTask = tasksMap[parentId]

        if (
          !parentTask ||
          (parentTask.level === taskLevel.MODULE &&
          !filterOptions?.includeParentModule) ||
          parentId === parentTask.parentId
        ) {
          break
        }

        parentsToAdd.push(parentTask)
        parentId = parentTask.parentId as string
      }

      parentCache.set(task.id, parentsToAdd)

      for (const parent of parentsToAdd) {
        if (!pushedTasks.has(parent.id)) {
          acc.push(parent)
          pushedTasks.add(parent.id)
        }
      }

      if (!pushedTasks.has(task.id)) {
        acc.push(task)
        pushedTasks.add(task.id)
      }

      return acc
    }, [] as TaskItemLoader[])

    if (!filterOptions?.skipSort) {
      filteredTasks = filteredTasks.sort(sortFunction)
    }

    return filteredTasks
  }

  const removeTaskFromFilteredTasks = (task: TaskItemLoader) => {
    const index = filteredTasks.value.findIndex(
      (filteredTask) => filteredTask.id === task.id
    )
    if (index > -1) {
      filteredTasks.value.splice(index, 1)
    }
  }

  const applySortTasks = (tasks: TaskItemLoader[]) => {
    return applyFilterAndSortTasks(
      tasks,
      buildParams('query'),
      buildParams('define'),
      buildParams('options', {
        skipFilter: true,
        includeParentModule:
          activeView.value !== BoardView.BOARD &&
          groupBy.value == GroupBy.MODULE,
      })
    )
  }

  watch(
    [globalFilter, sortBy, () => ready.value],
    debounce(async ([filter, sort, isReady]) => {
      if (workerStatus.value !== 'SUCCESS') {
        workerTerminate()
      }

      if (isReady) {
        const query = cloneDeep({ filter, sort })
        const isQueryChanged = !isEqual(lastQuery.value, query)
        lastQuery.value = query

        if (!isQueryChanged) {
          return
        }

        if (!isHasQuery.value) {
          filteredTasks.value = options.tasks.value
          return
        }

        const resolvedTasks = await workerFn(
          JSON.stringify(options.tasks.value),
          JSON.stringify(buildParams('query')),
          JSON.stringify(buildParams('define')),
          JSON.stringify(
            buildParams('options', {
              includeParentModule:
                activeView.value !== BoardView.BOARD &&
                groupBy.value == GroupBy.MODULE,
            })
          )
        )
        isFiltered.value = true
        isFirstFiltered.value = true
        filteredTasks.value = resolvedTasks || []
        workerTerminate()
      }
    }, 500),
    {
      immediate: true,
    }
  )

  /**
   * Apply filter when tasks change
   */
  watch(
    [() => options.tasks.value, () => ready.value],
    ([tasks, isReady], [oldTasks]) => {
      if (isReady) {
        oldTasks = oldTasks || []
        if (!isHasQuery.value || !tasks.length) {
          filteredTasks.value = tasks
          return
        }

        const addedTaskByOthers: TaskItemLoader[] = []
        if (isFirstFiltered.value) {
          if (tasks.length > oldTasks.length) {
            /**
             * If a new task is added, we need ignore it from filter
             */
            const addedTasks = differenceBy(tasks, oldTasks, 'id')
            addedTasks.forEach((task) => {
              if (tasksCreatedByCurrentUser.value.includes(task.id)) {
                filteredTasks.value.push(task)
                filteredTasks.value = applySortTasks(filteredTasks.value)
                return
              }

              addedTaskByOthers.push(task)
            })
          } else if (tasks.length === oldTasks.length) {
            /**
             * If a task is updated, we need to update it in the filtered tasks and reapply the filter without filter
             */
            const updatedTasks = difference(tasks, oldTasks)
            updatedTasks.forEach((task) => {
              const index = filteredTasks.value.findIndex(
                (filteredTask) => filteredTask.id === task.id
              )
              /**
               * If index is -1, it means the task is not in the filtered tasks, maybe it was created or just sync optimistic task
               */
              if (index === -1) {
                const findOldTasks = difference(oldTasks, tasks)
                // Find if the task is optimistic task then update it by real task
                const optimisticTask = findOldTasks.find((oldTask) => {
                  return (
                    isOptimisticId(oldTask.id) && oldTask.name === task.name
                  )
                })
                if (optimisticTask) {
                  const index = filteredTasks.value.findIndex(
                    (t) => t.id === optimisticTask.id
                  )
                  if (index !== -1) {
                    filteredTasks.value[index] = task
                  }

                  return
                }

                if (task.parentId) {
                  const parent = tasks.find((_) => _.id === task.parentId)
                  if (parent) {
                    removeTaskFromFilteredTasks(parent)
                    addedTaskByOthers.push(parent)
                    const subtasks = tasks.filter(
                      (task) => task.parentId == parent.id
                    )
                    subtasks.forEach((subtask) => {
                      removeTaskFromFilteredTasks(subtask)
                      addedTaskByOthers.push(subtask)
                    })
                  }
                }

                const children = tasks.filter(
                  (childTask) => childTask.parentId === task.id
                )
                children.forEach((childTask) => {
                  if (task.level === TaskLevel.SUBTASK) {
                    const subtasks = tasks.filter(
                      (task) => task.parentId === childTask.id
                    )
                    subtasks.forEach((subtask) => {
                      removeTaskFromFilteredTasks(subtask)
                      addedTaskByOthers.push(subtask)
                    })
                  }

                  removeTaskFromFilteredTasks(childTask)
                  addedTaskByOthers.push(childTask)
                })
                removeTaskFromFilteredTasks(task)
                addedTaskByOthers.push(task)
              } else {
                // When update a subtask, we need to check if its parent task should be updated
                if (task.parentId) {
                  const parent = tasks.find((_) => _.id === task.parentId)
                  if (parent) {
                    removeTaskFromFilteredTasks(parent)
                    addedTaskByOthers.push(parent)
                    const subtasks = tasks.filter(
                      (task) => task.parentId == parent.id
                    )
                    subtasks.forEach((subtask) => {
                      removeTaskFromFilteredTasks(subtask)
                      addedTaskByOthers.push(subtask)
                    })
                  }
                }

                const children = tasks.filter(
                  (childTask) => childTask.parentId === task.id
                )
                children.forEach((childTask) => {
                  if (task.level === TaskLevel.MODULE) {
                    const subtasks = tasks.filter(
                      (task) => task.parentId === childTask.id
                    )
                    subtasks.forEach((subtask) => {
                      removeTaskFromFilteredTasks(subtask)
                      addedTaskByOthers.push(subtask)
                    })
                  }

                  removeTaskFromFilteredTasks(childTask)
                  addedTaskByOthers.push(childTask)
                })
                removeTaskFromFilteredTasks(task)
                addedTaskByOthers.push(task)
              }
            })
          } else {
            /**
             * If a task is deleted, we need to remove it from the filtered tasks
             */
            const deletedTasks = differenceBy(oldTasks, tasks, 'id')
            deletedTasks.forEach((task) => {
              removeTaskFromFilteredTasks(task)
            })
          }

          if (addedTaskByOthers.length) {
            const filteredAddedTasks = applyFilterAndSortTasks(
              addedTaskByOthers,
              buildParams('query'),
              buildParams('define'),
              buildParams('options', {
                skipFilter: false,
                skipSort: true,
                includeParentModule:
                  activeView.value !== BoardView.BOARD &&
                  groupBy.value == GroupBy.MODULE,
              })
            )
            if (filteredAddedTasks.length) {
              filteredTasks.value.push(...filteredAddedTasks)
              filteredTasks.value = applySortTasks(filteredTasks.value)
            }
          }
        }
      }
    },
    {
      immediate: true,
    }
  )

  return {
    filteredTasksCount,
    filteredTasks,
    applyFilterAndSortTasks,
  }
}
