<template>
  <div class="flex items-center justify-between px-4 pt-3 pb-2">
    <p class="font-semibold text-sm text-gray-900">Change status</p>
    <UButton
      size="xs"
      icon="i-heroicons-x-mark"
      color="gray"
      variant="ghost"
      data-test="close-status-btn"
      @click.prevent.stop="$emit('close')"
    />
  </div>
  <div class="px-4 mb-2">
    <UInput
      v-model="search"
      icon="i-heroicons-magnifying-glass"
      size="sm"
      autofocus
      placeholder="Search status..."
      @click.prevent.stop
    />
  </div>
  <div class="pl-2 pt-1">
    <div
      class="max-h-[19.375rem] overflow-y-auto scroll-stable minimal-scrollbar pr-0.5 space-y-0.5 pb-0.5"
    >
      <div
        v-for="(status, index) in statuses"
        ref="optionRefs"
        :key="status.id"
        class="flex items-center text-gray-900 justify-between px-2 py-1.5 cursor-pointer hover:bg-gray-100 rounded-md"
        :class="{
          'bg-gray-100':
            status.id === selectedStatusId || currentFocusStatusIndex === index,
        }"
        data-test="status-select-popover-item"
        @mouseenter="currentFocusStatusIndex = index"
        @mouseleave="currentFocusStatusIndex = -1"
        @click.prevent.stop="onSelectStatus(status)"
      >
        <StatusBadge
          class="flex items-center gap-1 text-xs hover:border-inherit max-h-6"
          :type="status.type"
          :label="status.name"
          :tooltip="{
            arrowClass: '!top-[unset]',
          }"
        />
        <div class="flex items-center gap-1">
          <Tooltip
            v-if="status.settingsPackId"
            :text="getSettingsPackName(status.settingsPackId)"
            arrow-class="!top-[unset]"
          >
            <IconSettingPack />
          </Tooltip>
          <Icon
            v-if="status.id === selectedStatusId"
            name="heroicons:check"
            :size="20"
            class="min-w-5"
          />
        </div>
      </div>
    </div>
    <div v-if="!disableSetting && can('dashboard.data.manage_tasks_sections')" class="pb-2 pt-1.5 pr-2">
      <UButton
        class="w-full flex justify-center"
        variant="soft"
        color="gray"
        @click="onOpenStatusSetting"
      >
        Manage statuses
      </UButton>
    </div>
  </div>
  <template v-if="enableRemove">
    <UDivider />
    <UButton
      size="sm"
      color="gray"
      variant="ghost"
      class="w-full"
      icon="heroicons:trash"
      :ui="{
        padding: { sm: 'py-2' },
      }"
      @click="onRemoveStatus"
    >
      Remove status
    </UButton>
  </template>
</template>

<script lang="ts" setup>
import type { Status } from '#status/types'
import { STATUSES_QUERY } from '#status/schema'
import { type STATUS_TYPE } from '#status/constant'
import type { CurrentTask } from '#task/types'

const props = defineProps({
  selectedStatusId: {
    type: String,
  },
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
  acceptTypes: {
    type: Array as PropType<STATUS_TYPE[]>,
    default: () => [],
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  enableRemove: {
    type: Boolean,
    default: false,
  },
  disableSetting: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change', 'remove', 'close'])

const { boardData } = useBoardSharedState()
const { currentBoard, currentTask, setCurrentTask } = useWorkspaceSharedState()
const { can } = useBoardAbility()
const { setSettingTab } = useBoardSettingsNavigator()

const optionRefs = ref<HTMLElement[]>([])
const search = ref('')
const variables = reactive({
  parentId: props.parentId,
  source: props.source,
  loadFull: true,
})

const { result, load } = useLazyQuery<{ statuses: Status[] }>(
  STATUSES_QUERY,
  variables
)

const currentFocusStatusIndex = ref(-1)

const isCurrentBoard = computed(() => currentBoard.value.id === props.parentId)

const statuses = computed(() => {
  let statuses = conditionalValue<Status[]>(
    isCurrentBoard.value,
    boardData.value.statuses,
    result.value?.statuses as Status[],
    []
  )
  if (props.acceptTypes.length) {
    statuses = statuses.filter((taskType) =>
      props.acceptTypes.includes(taskType.type)
    )
  }

  return statuses.filter((status) =>
    status.name.toLowerCase().includes(search.value.toLowerCase())
  )
})

const getSettingsPackName = (id: string) => {
  const settingsPacks = boardData.value.settingsPacks
  return settingsPacks?.find((settingsPack) => settingsPack.id === id)?.name
}

const onSelectStatus = (status: Status) => {
  emit('change', status.id)
  if (props.closeOnSelected) {
    emit('close')
  }
}

const onRemoveStatus = () => {
  emit('remove')
  if (props.closeOnSelected) {
    emit('close')
  }
}

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

const { isPreventOpenTaskDetail } = useModalManager()

const onOpenStatusSetting = () => {
  isPreventOpenTaskDetail.value = true

  nextTick(() => {
    setSettingTab('statuses')
  })
}

defineShortcuts({
  arrowup: {
    usingInput: true,
    handler: () => {
      if (currentFocusStatusIndex.value > 0) {
        currentFocusStatusIndex.value--
      } else {
        currentFocusStatusIndex.value = statuses.value?.length - 1
      }

      scrollToView(currentFocusStatusIndex.value)
    },
  },
  arrowdown: {
    usingInput: true,
    handler: () => {
      if (currentFocusStatusIndex.value < statuses.value?.length - 1) {
        currentFocusStatusIndex.value++
      } else {
        currentFocusStatusIndex.value = 0
      }

      scrollToView(currentFocusStatusIndex.value)
    },
  },
  enter: {
    usingInput: true,
    handler: () => {
      if (
        currentFocusStatusIndex.value >= 0 &&
        statuses.value![currentFocusStatusIndex.value]
      ) {
        onSelectStatus(statuses.value[currentFocusStatusIndex.value])
      }
    },
  },
})

onMounted(async () => {
  currentFocusStatusIndex.value = -1
  extend(variables, {
    parentId: props.parentId,
  })
  if (!isCurrentBoard.value) {
    await load()
  }
})
</script>
