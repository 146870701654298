<template>
  <UModal
    v-model="visible"
    class="task-detail-modal"
    data-test="task-detail-modal"
    :ui="{ width: 'w-full sm:max-w-[832px]', inner: 'scroll-stable minimal-scrollbar', modalId: 'task-detail-scroller', container: 'sm:items-start' }"
    :prevent-close="preventCloseState.has('task-detail')"
    @close="onCloseTaskView"
  >
    <UCard v-if="currentTask.id" :ui="{ body: 'p-0' }">
      <TaskDetail
        :task="currentTask"
        @loaded="(task) => (taskDetail = task)"
        @close="onCloseTaskView"
      />
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { TaskDetail, CurrentTask } from '#task/types'

const { currentTask, currentBoard, setCurrentTask } = useWorkspaceSharedState()
const { preventCloseState, isPreventOpenTaskDetail } = useModalManager()
const { focusCurrentTask } = useKanbanSharedState()
const { syncFilterToURL } = useSyncTaskFilterURL()
const taskDetail = ref<TaskDetail>()
const visible = ref(false)

const onCloseTaskView = () => {
  window.history.pushState(null, '', `/b/${taskDetail.value?.board.handle}`)
  syncFilterToURL()
  setCurrentTask({} as CurrentTask)
  focusCurrentTask()
}

watch(
  () => [currentTask.value.id, isPreventOpenTaskDetail.value],
  ([taskId, isPreventOpen]) => {
    if (isPreventOpen) {
      visible.value = false
      return
    }

    visible.value = !!taskId
    if (visible.value) {
      useHead({
        title: () => `${currentTask.value.name} | ${currentBoard.value.name}`,
      })
    }
  },
  {
    immediate: true,
  }
)
</script>
