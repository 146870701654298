import { pick } from 'lodash-es'
import { LABEL_TYPE_NAME } from '#label/constant'
import type { LabelBase, LabelUpdatePayload, LabelSyncPayload } from '#label/types'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'

export const useLabelDataConvert = () => {
  const getLabelIdentifier = (id: string) => getIdentifier(id, LABEL_TYPE_NAME)
  const fields = ['name', 'color', 'settingsPackId']

  const getOptimisticLabel = (id: string | null, payload: LabelUpdatePayload, forFields = false): Partial<LabelBase> & { __typename?: string } => {
    const defaults = {}
    const label = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? label : { ...label, __typename: LABEL_TYPE_NAME }
  }

  const getOptimisticLabelFields = (payload: LabelUpdatePayload) => {
    const label = getOptimisticLabel(null, payload, true)
    return convertObjectToFields(label)
  }

  const getSyncLabelFields = (payload: LabelSyncPayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<LabelBase>
  }

  return {
    getLabelIdentifier,
    getOptimisticLabel,
    getOptimisticLabelFields,
    getSyncLabelFields
  }
}
