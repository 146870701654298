/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

import { FIELD_FRAGMENT } from '#field/fragment'
import { STATUS_LOADER_FRAGMENT } from '#status/loader_fragment'
import { LABEL_LOADER_FRAGMENT } from '#label/loader_fragment'
import { SETTINGS_PACK_LOADER_FRAGMENT } from '#settings-pack/loader_fragment'
import { TASK_TYPE_LOADER_FRAGMENT } from '#task-type/loader_fragment'
import { USER_LOADER_FRAGMENT } from '#auth/loader_fragment'

export const BOARD_DATA_LOADER_QUERY = gql`
  ${SETTINGS_PACK_LOADER_FRAGMENT}
  ${STATUS_LOADER_FRAGMENT}
  ${LABEL_LOADER_FRAGMENT}
  ${FIELD_FRAGMENT}
  ${TASK_TYPE_LOADER_FRAGMENT}
  ${USER_LOADER_FRAGMENT}
  query BoardDataQuery($boardId: ID!) {
    boardData(boardId: $boardId) {
      settingsPacks {
        ...SettingsPackLoader
      }
      statuses {
        ...StatusLoader
      }
      labels {
        ...LabelLoader
      }
      fields {
        ...Field
      }
      taskTypes {
        ...TaskTypeLoader
      }
      users {
        ...UserLoader
      }
    }
  }
`
