<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 5.8C4.77025 5.8 5.8 4.77025 5.8 3.5C5.8 2.22975 4.77025 1.2 3.5 1.2C2.22974 1.2 1.2 2.22974 1.2 3.5C1.2 4.77025 2.22975 5.8 3.5 5.8ZM7 3.5C7 5.433 5.433 7 3.5 7C1.567 7 0 5.433 0 3.5C0 1.567 1.567 0 3.5 0C5.433 0 7 1.567 7 3.5ZM14.8 1.2H10.2V5.8H14.8V1.2ZM14.8 0H16V1.2V5.8V7H14.8H10.2H9V5.8V1.2V0H10.2H14.8ZM4.79922 9C4.79922 8.66863 4.53059 8.4 4.19922 8.4C3.86785 8.4 3.59922 8.66863 3.59922 9V11.6012H1C0.668629 11.6012 0.4 11.8698 0.4 12.2012C0.4 12.5325 0.668629 12.8012 1 12.8012H3.59922V15.4C3.59922 15.7314 3.86785 16 4.19922 16C4.53059 16 4.79922 15.7314 4.79922 15.4V12.8012H7.4C7.73137 12.8012 8 12.5325 8 12.2012C8 11.8698 7.73137 11.6012 7.4 11.6012H4.79922V9ZM11.8292 10.3416L12.5 9L13.1708 10.3416L15.4 14.8L16 16H14.6584H10.3416H9L9.6 14.8L11.8292 10.3416ZM10.9416 14.8L12.5 11.6833L14.0584 14.8H10.9416Z"
      fill="url(#paint0_linear_2684_172989)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2684_172989"
        x1="3"
        y1="2.5"
        x2="16"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAB308" />
        <stop offset="0.37" stop-color="#60A5FA" />
        <stop offset="0.541519" stop-color="#00C16A" />
        <stop offset="0.942601" stop-color="#F97316" />
      </linearGradient>
    </defs>
  </svg>
</template>
