<template>
  <div ref="placeholderRef" :class="$attrs.class">
    <UTextarea
      v-model="taskName"
      placeholder="What needs to be done"
      size="md"
      autoresize
      autofocus
      data-test="kanban-add-task-input"
      data-name="leanbase-add-task-input"
      :rows="2"
      :ui="{
        rounded: 'rounded-lg',
        color: { white: { outline: 'ring-0 focus:ring-0' } },
        padding: {
          md: 'pb-10',
        },
      }"
      @focus="handleFocus"
      @blur="handleBlur"
      @keydown.enter.prevent.stop="throttleHandleAddTask"
      @keyup.esc="emit('cancel')"
    >
      <div class="absolute bottom-2 left-2">
        <TaskTypeSelectPopover
          v-if="!readOnly"
          source="board"
          :parent-id="boardId"
          :selected-task-type-id="selectedTaskType?.id"
          :accept-levels="acceptLevels"
          :show-header="false"
          disable-setting
          @change="selectedTaskType = $event"
        >
          <UButton size="2xs" color="gray" variant="soft" data-test="task-type-select-btn">
            <template #leading>
              <TaskTypeIcon
                v-if="selectedTaskType"
                :background="selectedTaskType?.background"
                :icon="selectedTaskType?.icon"
                size="xs"
              />
              <div>{{ selectedTaskType?.name }}</div>
              <Icon class="text-gray-900" name="heroicons:chevron-down" />
            </template>
          </UButton>
        </TaskTypeSelectPopover>
        <UButton v-else size="2xs" color="gray" variant="soft">
          <template #leading>
            <TaskTypeIcon
              v-if="moduleType"
              :background="moduleType?.background"
              :icon="moduleType?.icon"
              size="xs"
            />
            <div>{{ moduleType?.name }}</div>
          </template>
        </UButton>
      </div>
    </UTextarea>

    <div class="flex items-center justify-end gap-2 mt-2">
      <UButton
        size="sm"
        :ui="{ base: '!bg-gray-300', font: '!text-gray-600' }"
        data-test="kanban-add-task-cancel-btn"
        @click="$emit('cancel')"
      >
        Cancel
      </UButton>
      <UButton
        size="sm"
        :disabled="!isValidTaskName"
        data-test="kanban-add-task-btn"
        @click="throttleHandleAddTask"
      >
        {{ ctaText }}
      </UButton>
    </div>
  </div>
  <UPopover
    v-model:open="isCreateMultiTaskVisible"
    :ui="{ width: 'w-64', trigger: 'block' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    class="block"
  >
    <template #panel>
      <div class="flex items-center justify-between px-3 py-2">
        <p class="font-semibold text-sm text-gray-900">Create</p>
        <UButton
          size="xs"
          icon="i-heroicons-x-mark"
          color="gray"
          variant="ghost"
          @click="isCreateMultiTaskVisible = false"
        />
      </div>
      <div class="p-3 pt-0 text-xs text-gray-500">
        If you want, we can create a card for every new line ({{
          listTaskToCreate.length
        }}). You can also create one card with a long title.
      </div>
      <div class="px-4 p-1">
        <div
          class="h-8 rounded-md bg-gray-100 flex items-center justify-center gap-1 cursor-pointer"
          data-test="kanban-create-multi-task-btn"
          @click="manualAddTask('multi')"
        >
          <Icon name="heroicons:plus-small" :size="20" />
          <span class="text-sm">
            Create {{ listTaskToCreate.length }} tasks
          </span>
        </div>
      </div>
      <div class="p-4 pt-1">
        <div
          class="h-8 rounded-md bg-gray-100 flex items-center justify-center gap-1 cursor-pointer"
          data-test="kanban-create-single-task-btn"
          @click="manualAddTask('single')"
        >
          <span class="text-sm">Just one task</span>
        </div>
      </div>
    </template>
    <div class="hidden h-0" />
  </UPopover>
</template>

<script lang="ts" setup>
import { throttle } from 'lodash-es'
import { TaskLevel } from '#task/constant'
import type { RootAddPlacement } from '#core/types'

const props = defineProps({
  boardId: {
    type: String,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  insertPlacement: {
    type: String as PropType<RootAddPlacement>,
    default: 'bottom',
  },
  ctaText: {
    type: String,
    default: 'Add task',
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  acceptLevels: {
    type: Array as PropType<TaskLevel[]>,
    default: () => [TaskLevel.MODULE, TaskLevel.TASK],
  },
})

const emit = defineEmits(['cancel', 'created'])

const { getDefaultTaskType, boardData } = useBoardSharedState()
const { createTask } = useCreateTask()
const { isCreatingTask } = useKanbanSharedState()

const taskName = ref('')
const listTaskToCreate = ref<string[]>([])
const selectedTaskType = ref(getDefaultTaskType())
const isCreateMultiTaskVisible = ref(false)
const placeholderRef = ref()

const isValidTaskName = computed(() => taskName.value?.trim().length)

const moduleType = computed(() => {
  return boardData.value?.taskTypes.find(
    (taskType) => taskType.level === TaskLevel.MODULE
  )
})

const createTaskOptions = computed(() => ({
  board: props.boardId,
  section: props.sectionId,
  type: (moduleType.value && props.readOnly) ? moduleType.value.id : selectedTaskType.value?.id,
  level: (moduleType.value && props.readOnly) ? TaskLevel.MODULE : selectedTaskType.value?.level,
  placement: props.insertPlacement,
}))

const manualAddTask = async (type: 'single' | 'multi') => {
  isCreateMultiTaskVisible.value = false
  if (type === 'multi') {
    for (const taskName of listTaskToCreate.value) {
      await createTask({
        ...createTaskOptions.value,
        name: taskName.slice(0, 255),
      })
    }

    listTaskToCreate.value = []
    taskName.value = ''
    return emit('created')
  }

  await createTask({
    ...createTaskOptions.value,
    name: taskName.value.slice(0, 255),
  })
  taskName.value = ''
  emit('created')
}

const handleFocus = () => {
  isCreatingTask.value = true
}

const handleBlur = () => {
  isCreatingTask.value = false
}

const handleAddTask = async () => {
  if (!isValidTaskName.value) {
    return
  }

  const name = taskName.value.trim()

  const nonBlankLine = taskName.value
    .split(/(\r\n|\n|\r)/)
    .filter((line) => line.trim() !== '')

  // Create task if only one line
  if (nonBlankLine.length === 1) {
    createTask({
      ...createTaskOptions.value,
      name: name.slice(0, 255),
    })

    taskName.value = ''
    return emit('created')
  }

  // Show popover if multiple lines
  listTaskToCreate.value = nonBlankLine
  isCreateMultiTaskVisible.value = true
}

const throttleHandleAddTask = throttle(handleAddTask, 200, { trailing: false })

onClickOutside(placeholderRef, () => {
  if (isValidTaskName.value) {
    return throttleHandleAddTask()
  }

  emit('cancel')
})
</script>
