import validate from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/pages/runtime/validate.js";
import prefetch_45auth_45global from "/app/layers/auth/middleware/prefetch-auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  prefetch_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/layers/auth/middleware/auth.ts"),
  guest: () => import("/app/layers/auth/middleware/guest.ts")
}