export const ACCESS_TOKEN_KEY = 'lb_access_token'

export const SERVER_ERROR_MESSAGE = 'Failed to fetch'

export enum RoleTypeList {
    WORKSPACE = 'WORKSPACE',
    BOARD = 'BOARD'
}

export enum RoleLevel {
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER',
    CUSTOM = 'CUSTOM',
    GUEST = 'GUEST'
}

export enum BaseLevel {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER',
    GUEST = 'GUEST',
}

export const BaseLevelOptions = [
  { value: BaseLevel.ADMIN as string, label: 'Admin' },
  { value: BaseLevel.MEMBER as string, label: 'Member' },
  { value: BaseLevel.GUEST as string, label: 'Guest' },
]

export const CORE_ROLES = [RoleLevel.OWNER, RoleLevel.ADMIN, RoleLevel.MEMBER, RoleLevel.GUEST]

export enum MembershipRestrictions {
    ANYBODY = 'ANYBODY',
    DOMAIN = 'DOMAIN'
}

export enum InviteBy {
    EMAIL = 'email',
    LINK = 'link'
}

export enum InviteType {
    WORKSPACE = 'workspace',
    BOARD = 'board'
}

export enum PROVIDER {
    GOOGLE = 'google',
}

export enum AUTH_ERROR_CODE {
    EMAIL_EXISTS = 'email_exists',
}

export enum AUTH_STORAGE_KEY {
    CONNECT_SOCIAL_ACCOUNT_TOKEN = 'connect_social_account_token',
}

export enum MESSAGE_CODE {
  CONNECT_SOCIAL_ACCOUNT_SUCCESS = 'connect_social_account_success',
}

export const RESOURCE_PERMISSION_DENIED_ERROR_CODE = 'RESOURCE_PERMISSION_DENIED'

export const USER_TYPE_NAME = 'UserType'
