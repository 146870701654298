import { FieldType } from '#field/constant'
import {
  ActivityType,
  DateFieldFilter,
  DueDateType,
  TaskFilterCriteria
} from '#task/constant'
import type {
  TaskGlobalFilterCustomField,
  TaskGlobalFilterLabel,
  TaskGlobalFilterMember,
  TaskGlobalFilterModule,
  TaskGlobalFilterStatus,
  TaskGlobalFilterTaskType
} from '#task/types'

export const useSyncTaskFilterURL = () => {
  const { globalFilter, isHasFilter, filterCriteria } = useTaskFilterContext()
  const { auth } = useAuthStore()
  const transformCondition = <T extends Record<string, boolean>>(
    criteria: TaskFilterCriteria,
    condition: T
  ) => {
    const transformArr = Object.keys(condition).reduce((acc, key) => {
      if (condition[key]) {
        acc.push([criteria, key])
      }

      return acc
    }, [] as string[][])
    return transformArr
  }

  const transformSelectedValues = (
    criteria: TaskFilterCriteria,
    values: string[]
  ) => {
    const transformArr = values.reduce((acc, value) => {
      acc.push([criteria, value])
      return acc
    }, [] as string[][])
    return transformArr
  }

  const syncFilterToURL = () => {
    const url = new URL(location.href)
    const params = new URLSearchParams(location.search)
    if (!isHasFilter.value) {
      params.delete('filter')
      url.search = params.toString()
      return history.replaceState({}, '', url.toString())
    }

    const normalizeFilter = Object.entries(filterCriteria.value).reduce(
      (acc, entry) => {
        const [key, value] = entry
        if (key === TaskFilterCriteria.SEARCH) {
          acc.push([TaskFilterCriteria.SEARCH, value as string])
        }

        if (key === TaskFilterCriteria.INCLUDE_MEMBER) {
          const interalValue = value as TaskGlobalFilterMember
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_MEMBER,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_MEMBER,
                interalValue.members
              )
            )
          } else {
            if (interalValue.members.includes(auth.id)) {
              acc.push(
                ...transformSelectedValues(
                  TaskFilterCriteria.INCLUDE_MEMBER,
                  [auth.id]
                )
              )
            }
          }
        }

        if (key === TaskFilterCriteria.EXCLUDE_MEMBER) {
          const interalValue = value as TaskGlobalFilterMember
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.EXCLUDE_MEMBER,
              interalValue.members
            )
          )
        }

        if (key === TaskFilterCriteria.DUE_DATE) {
          const interalValue = value as string[]
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.DUE_DATE,
              interalValue
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_LABEL) {
          const interalValue = value as TaskGlobalFilterLabel
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_LABEL,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_LABEL,
                interalValue.labels
              )
            )
          }
        }

        if (key === TaskFilterCriteria.EXCLUDE_LABEL) {
          const interalValue = value as TaskGlobalFilterLabel
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.EXCLUDE_LABEL,
              interalValue.labels
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_STATUS) {
          const interalValue = value as TaskGlobalFilterStatus
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_STATUS,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_STATUS,
                interalValue.statuses
              )
            )
          }
        }

        if (key === TaskFilterCriteria.EXCLUDE_STATUS) {
          const interalValue = value as TaskGlobalFilterStatus
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.EXCLUDE_STATUS,
              interalValue.statuses
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_MODULE) {
          const interalValue = value as TaskGlobalFilterModule
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_MODULE,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_MODULE,
                interalValue.tasks
              )
            )
          }
        }

        if (key === TaskFilterCriteria.INCLUDE_TASK_TYPE) {
          const interalValue = value as TaskGlobalFilterTaskType
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_TASK_TYPE,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_TASK_TYPE,
                interalValue.taskTypes
              )
            )
          }
        }

        if (key === TaskFilterCriteria.ACTIVITY) {
          const interalValue = value as ActivityType[]
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.ACTIVITY,
              interalValue
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_FIELD) {
          const interalValue = value as Record<
            string,
            TaskGlobalFilterCustomField
          >
          acc.push(
            ...Object.entries(interalValue).reduce((acc, entry) => {
              const [fieldId, field] = entry
              acc.push(
                ...transformCondition(
                  `[${field.type}@${fieldId}]` as TaskFilterCriteria,
                  field.condition
                )
              )
              if (!field.condition.all) {
                acc.push(
                  ...transformSelectedValues(
                    `[${field.type}@${fieldId}]` as TaskFilterCriteria,
                    field.options
                  )
                )
              }

              return acc
            }, [] as string[][])
          )
        }

        if (key === TaskFilterCriteria.EXCLUDE_FIELD) {
          const interalValue = value as Record<
            string,
            TaskGlobalFilterCustomField
          >
          acc.push(
            ...Object.entries(interalValue).reduce((acc, entry) => {
              const [fieldId, field] = entry
              acc.push(
                ...transformSelectedValues(
                  `_[${field.type}@${fieldId}]` as TaskFilterCriteria,
                  field.options
                )
              )

              return acc
            }, [] as string[][])
          )
        }

        return acc
      },
      [] as string[][]
    )
    params.set(
      'filter',
      normalizeFilter.map((entry) => entry.join(':')).join('|')
    )
    url.search = decodeURIComponent(params.toString())
    history.replaceState({}, '', url.toString())
  }

  const syncURLToFilter = () => {
    const params = new URLSearchParams(location.search)
    const filter = params.get('filter')
    if (filter) {
      filter.split('|').forEach((entry) => {
        const [criteria, value] = entry.split(':')
        const isValueID = isID(value)

        if (criteria === TaskFilterCriteria.SEARCH) {
          globalFilter[criteria] = value
        }

        if (criteria === TaskFilterCriteria.INCLUDE_MEMBER) {
          const { condition, members } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            members.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.EXCLUDE_MEMBER) {
          const { members } = globalFilter[criteria]

          if (isValueID) {
            members.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.DUE_DATE) {
          if (Object.values(DueDateType).includes(value as DueDateType)) {
            globalFilter[TaskFilterCriteria.DUE_DATE].push(value as DueDateType)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_LABEL) {
          const { condition, labels } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            labels.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.EXCLUDE_LABEL) {
          const { labels } = globalFilter[criteria]
          if (isValueID) {
            labels.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_STATUS) {
          const { condition, statuses } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            statuses.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.EXCLUDE_STATUS) {
          const { statuses } = globalFilter[criteria]
          if (isValueID) {
            statuses.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_MODULE) {
          const { condition, tasks } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            tasks.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_TASK_TYPE) {
          const { condition, taskTypes } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            taskTypes.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.ACTIVITY) {
          if (Object.values(ActivityType).includes(value as ActivityType)) {
            globalFilter[TaskFilterCriteria.ACTIVITY].push(
              value as ActivityType
            )
          }
        }

        /**
         * Filter for include custom field
         */
        if (/^\[.*\]$/.test(criteria)) {
          const field = criteria.slice(1, -1)
          if (field.includes('@')) {
            const [fieldType, fieldId] = field.split('@')
            const includeField = globalFilter[TaskFilterCriteria.INCLUDE_FIELD]
            if (!includeField[fieldId]) {
              includeField[fieldId] = {
                id: fieldId,
                type: fieldType as FieldType,
                condition: {
                  all: false,
                },
                options: [],
              }
            }

            const { condition, options } = includeField[fieldId]
            if (Object.keys(condition).includes(value)) {
              condition[value as keyof typeof condition] = true
            }

            if (
              [FieldType.CHECKBOX, FieldType.DROPDOWN].includes(
                fieldType as FieldType
              ) &&
              isValueID
            ) {
              options.push(value)
            }

            if (
              fieldType === FieldType.DATE &&
              Object.values(DateFieldFilter).includes(value as DateFieldFilter)
            ) {
              options.push(value)
            }
          }
        }

        /**
         * Filter for exclude custom field
         */
        if (/^_\[.*\]$/.test(criteria)) {
          const field = criteria.slice(2, -1)
          if (field.includes('@')) {
            const [fieldType, fieldId] = field.split('@')
            const excludeField = globalFilter[TaskFilterCriteria.EXCLUDE_FIELD]
            if (!excludeField[fieldId]) {
              excludeField[fieldId] = {
                id: fieldId,
                type: fieldType as FieldType,
                condition: {
                  all: false,
                },
                options: [],
              }
            }

            const { options } = excludeField[fieldId]
            if (isValueID) {
              options.push(value)
            }
          }
        }
      })
    }
  }

  watchDebounced(
    () => filterCriteria.value,
    () => {
      syncFilterToURL()
    }
  )

  onMounted(async () => {
    await nextTick()
    syncURLToFilter()
  })

  return { syncFilterToURL }
}
