import { provide, inject, computed } from 'vue'
import type { LinkOption } from '#core/types/packages/tiptap'

const EditorSymbol = Symbol('EditorContext')

interface EditorState {
  linkOption: LinkOption
  isShowLinkEditForm: boolean
}

interface EditorContext {
  linkOption: ComputedRef<LinkOption>
  isShowLinkEditForm: ComputedRef<boolean>
  setLinkOption: (option: LinkOption) => void
  setIsShowLinkEditForm: (isShow: boolean) => void
  showLinkEditForm: (option: LinkOption) => void
  resetLinkEditForm: () => void
}

export const provideEditorContext = () => {
  const defaultLinkOption: LinkOption = {
    position: { top: 0, left: 0, right: 0, bottom: 0 },
    range: { from: 0, to: 0 },
    href: '',
    text: '',
  }
  const state = reactive<EditorState>({
    linkOption: defaultLinkOption,
    isShowLinkEditForm: false,
  })

  const context: EditorContext = {
    linkOption: computed(() => state.linkOption),
    isShowLinkEditForm: computed(() => state.isShowLinkEditForm),
    setLinkOption: (option) => (state.linkOption = option),
    setIsShowLinkEditForm: (isShow) => (state.isShowLinkEditForm = isShow),
    showLinkEditForm: (option) => {
      state.linkOption = option
      state.isShowLinkEditForm = true
    },
    resetLinkEditForm: () => (state.linkOption = defaultLinkOption),
  }

  provide(EditorSymbol, context)
  return context
}

export const useEditorContext = () => {
  const context = inject<EditorContext>(EditorSymbol)
  if (!context) {
    throw new Error('useEditorContext must be used within EditorProvider')
  }

  return context
}
