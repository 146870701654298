<template>
  <div>
    <component
      :is="AttachmentComponent.get(attachment.resolver.type)"
      v-for="attachment in attachments"
      :key="attachment.id"
      :attachment="attachment"
      :task-id="taskId"
    />
  </div>
</template>

<script lang="ts" setup>
import type { TaskAttachmentResolver } from '#task/types'
import type { AsyncComponent } from '#core/types'

defineProps({
  taskId: {
    type: String,
    required: true,
  },
  attachments: {
    type: Array as PropType<TaskAttachmentResolver[]>,
    required: true,
  },
})

const AttachmentComponent = new Map<string, AsyncComponent>([
  [
    'task',
    defineAsyncComponent(
      () => import('#attachment/components/AttachmentLeanbaseTask.vue')
    ),
  ],
])
</script>
