<template>
  <div v-if="mode == 'click'" :class="['relative', containerClass]" :data-test="dataTest">
    <UPopover
      v-if="open"
      ref="popoverRef"
      v-model:open="open"
      :popper="{
        placement: 'bottom-start',
        strategy: 'fixed',
      }"
      class="absolute inset-0"
      v-bind="$attrs"
      :ui="{
        ...ui,
        trigger: `flex absolute inset-0 ${disabled ? '!cursor-not-allowed' : ''} ${ui?.trigger}`,
      }"
      :disabled="disabled"
      @update:open="onUpdateOpen"
    >
      <template #panel="panel">
        <slot name="content" v-bind="panel" />
      </template>
      <p class="hidden" />
    </UPopover>
    <div :class="buttonClass" @click.prevent.stop="onOpen">
      <slot :open="() => (open = true)" :close="() => open = false" :is-open="open" />
    </div>
  </div>
  <div v-else :class="['relative', containerClass]" :data-test="dataTest">
    <UPopover
      ref="popoverRef"
      mode="hover"
      :popper="{
        placement: 'bottom-start',
        strategy: 'fixed',
      }"
      class="absolute inset-0"
      v-bind="$attrs"
      :ui="{
        ...ui,
        trigger: `flex absolute inset-0 ${disabled ? '!cursor-not-allowed' : ''} ${ui?.trigger}`,
      }"
      :disabled="disabled"
      @update:open="$emit('update:open')"
    >
      <template #panel="panel">
        <slot name="content" v-bind="panel" />
      </template>
      <slot />
      <p class="hidden" />
    </UPopover>
  </div>
</template>

<script lang="ts" setup>
import type Popover from '#ui/ui.config/overlays/popover'

defineProps({
  mode: {
    type: String as PropType<'click' | 'hover'>,
    default: 'click',
  },
  ui: {
    type: Object as PropType<Partial<typeof Popover>>,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataTest: {
    type: String,
  },
  containerClass: {
    type: String,
  },
  buttonClass: {
    type: String,
  },
})

defineOptions({
  inheritAttrs: false,
})

defineEmits(['update:open'])

const open = defineModel('open', {
  type: Boolean,
  default: false,
})

const lastUpdate = ref(0)
const popoverRef = ref()

const onOpen = () => {
  if (Date.now() - lastUpdate.value < 200) return

  nextTick(() => {
    open.value = !open.value
  })
}

const onUpdateOpen = () => {
  lastUpdate.value = Date.now()
}

defineExpose({
  isOpen: open.value,
  open: () => (open.value = true),
  close: () => (open.value = false),
  ref: () => popoverRef.value,
})
</script>
