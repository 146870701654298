/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/
import { SECTION_LOADER_FRAGMENT } from '#section/loader_fragment'

export const BOARD_SECTIONS_LOADER_QUERY = gql`
  ${SECTION_LOADER_FRAGMENT}
  query BoardSectionsQuery($boardId: ID!) {
    sections: boardSections(boardId: $boardId) {
      ...SectionLoader
    }
  }
`
