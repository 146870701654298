<template>
  <div
    v-if="items.length > 0"
    ref="commandListContainer"
    data-prevent-close-task
    class="z-50 h-auto max-h-[330px] w-72 overflow-y-auto minimal-scrollbar rounded-md border border-stone-200 bg-white px-1 py-2 shadow-md transition-all"
    @mousedown.prevent
  >
    <button
      v-for="(item, index) in items"
      :key="index"
      class="flex items-center w-full px-2 py-1 space-x-2 text-sm text-left rounded-md text-stone-900 hover:bg-stone-100"
      :class="index === selectedIndex ? 'bg-stone-100 text-stone-900' : ''"
      @click="onSelectItem(index)"
    >
      <div
        class="flex items-center justify-center w-10 h-10 bg-white border rounded-md border-stone-200"
      >
        <Suspense :timeout="1000">
          <Icon v-if="item.icon" :name="item.icon" size="18" />
        </Suspense>
      </div>
      <div>
        <p class="font-medium">{{ item.title }}</p>
        <p class="text-xs text-stone-500">{{ item.description }}</p>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { Editor, Range } from '@tiptap/core'
import type {
  FileChangeParams,
  SuggestionItem,
} from '#core/types/packages/tiptap'
import { TaskAttachmentAcceptMimeTypes } from '#task/constant'
import { UploadType } from '#core/constant'

const props = defineProps({
  items: {
    type: Array as PropType<SuggestionItem[]>,
    required: true,
  },
  command: {
    type: Function,
    required: true,
  },
  editor: {
    type: Object as PropType<
      Editor & { params: { onFileChange?: (params: FileChangeParams) => void } }
    >,
    required: true,
  },
  range: {
    type: Object as PropType<Range>,
    required: true,
  },
})

const selectedIndex = ref(0)
const commandListContainer = ref<HTMLDivElement>()
const uploadFileType = ref<UploadType>()
const fileDialog = useFileDialog({
  accept: TaskAttachmentAcceptMimeTypes.join(' '),
  multiple: false,
})

const navigationKeys = ['ArrowUp', 'ArrowDown', 'Enter']

const onSelectItem = (index: number) => {
  const item = props.items[index]
  selectedIndex.value = index

  if (item.uploadType) {
    fileDialog.open()
    uploadFileType.value = item.uploadType
    return
  }

  props.command(item)
}

fileDialog.onChange(async (files) => {
  if (files?.length) {
    const callback = (src: string) => {
      if (!src) {
        return
      }

      switch (uploadFileType.value) {
        case UploadType.IMAGE:
          props.editor.commands.setImage({ src })
          break
      }

      const item = props.items[selectedIndex.value]
      props.command(item)
      fileDialog.reset()
    }

    props.editor.params?.onFileChange?.({
      file: files[0],
      uploadType: uploadFileType.value,
      callback,
    })
  }
})

const onKeyDown = (e: KeyboardEvent) => {
  if (navigationKeys.includes(e.key)) {
    e.preventDefault()
    if (e.key === 'ArrowUp') {
      selectedIndex.value =
        (selectedIndex.value + props.items.length - 1) % props.items.length
      scrollToSelected()
      return true
    }

    if (e.key === 'ArrowDown') {
      selectedIndex.value = (selectedIndex.value + 1) % props.items.length
      scrollToSelected()
      return true
    }

    if (e.key === 'Enter') {
      onSelectItem(selectedIndex.value)
      return true
    }

    return false
  }
}

const updateScrollView = (container: HTMLElement, item: HTMLElement) => {
  const containerRect = container.getBoundingClientRect()
  const itemRect = item.getBoundingClientRect()

  // Calculate relative positions
  const itemTop = itemRect.top - containerRect.top
  const itemBottom = itemRect.bottom - containerRect.top

  // Check if item is outside visible area
  if (itemTop < 0) {
    // Scroll up if item is above visible area
    container.scrollTop += itemTop
  } else if (itemBottom > containerRect.height) {
    // Scroll down if item is below visible area
    container.scrollTop += itemBottom - containerRect.height
  }
}

const scrollToSelected = () => {
  const container = commandListContainer.value
  const item = container?.children[selectedIndex.value] as HTMLElement

  if (container && item) {
    updateScrollView(container, item)
  }
}

defineExpose({
  onKeyDown,
})
</script>
