/* eslint-disable @typescript-eslint/no-explicit-any */
import { InMemoryCache } from '@apollo/client/core'
import type { ApolloPersistOptions } from 'apollo3-cache-persist/lib/types'
import { CachePersistor } from 'apollo3-cache-persist'
import { getRef, getIdentifier } from '#core/utils/apollo'

/**
 * This is a workaround to fix the issue with cache.readFragment when the object is not in cache
 */
const __id_check: Record<string, string[]> = {}
const getFragment = (modelName: string) => {
  return gql`fragment ${modelName}X on ${modelName} {id}`
}

const toRefOrNull = (modelName: string, id?: string | null) => {
  if (!id) {
    return null
  }

  const typeName = modelName + 'Type'

  // If id in id_check, return ref
  if (__id_check[typeName]?.includes(id)) {
    return getRef(id, typeName)
  }

  // Read from cache
  const { client } = useApolloClient()
  const identifier = getIdentifier(id, typeName)
  const fragment = getFragment(modelName)

  const objInCache = client.readFragment({
    id: identifier,
    fragment,
  })

  // If object is not in cache, return null
  if (!objInCache) {
    return null
  }

  // Add id to id_check
  if (!__id_check[typeName]) {
    __id_check[typeName] = []
  }

  __id_check[typeName].push(id)

  // Return ref
  return getRef(id, typeName)
}

const settingsPackFieldPolicy = {
  read(
    _: any,
    {
      readField,
    }: {
      readField: (fieldName: string) => any
      toReference: (obj: { __typename: string; id: string }) => any
    }
  ) {
    const settingsPackId = readField('settingsPackId')
    return toRefOrNull('SettingsPack', settingsPackId)
  },
}

const statusFieldPolicy = {
  read(
    _: any,
    {
      readField,
    }: {
      readField: (fieldName: string) => any
      toReference: (obj: { __typename: string; id: string }) => any
    }
  ) {
    const statusId = readField('statusId')
    return toRefOrNull('Status', statusId)
  },
}

export const apolloCache = new InMemoryCache({
  typePolicies: {
    StatusType: {
      fields: {
        settingsPack: settingsPackFieldPolicy,
      },
    },
    LabelType: {
      fields: {
        settingsPack: settingsPackFieldPolicy,
      },
    },
    TaskTypeType: {
      fields: {
        settingsPack: settingsPackFieldPolicy,
      },
    },
    SectionType: {
      fields: {
        board: {
          read(_, { readField }) {
            const boardId = readField('boardId')
            return toRefOrNull('Board', boardId as string)
          },
        },
        status: statusFieldPolicy,
      },
    },
    TaskType: {
      fields: {
        type: {
          read(_, { readField }) {
            const typeId = readField('typeId')
            return toRefOrNull('TaskType', typeId as string)
          },
        },
        status: statusFieldPolicy,
        section: {
          read(_, { readField }) {
            const sectionId = readField('sectionId')
            return toRefOrNull('Section', sectionId as string)
          },
        },
        parent: {
          read(_, { readField }) {
            const parentId = readField('parentId')
            return toRefOrNull('Task', parentId as string)
          },
        },
        labels: {
          read(_, { readField }) {
            const labelIds = readField('labelIds')
            // Return empty array if labelIds is null or not array
            if (!labelIds || !Array.isArray(labelIds)) {
              return []
            }

            return labelIds
              .map((id: string) => toRefOrNull('Label', id))
              .filter((ref) => ref !== null)
          },
        },
        assignee: {
          read(_, { readField }) {
            const assigneeId = readField('assigneeId')
            return toRefOrNull('User', assigneeId as string)
          },
        },
      },
    },
    TaskFieldType: {
      fields: {
        field: {
          read(_, { readField }) {
            const fieldId = readField('fieldId')
            return toRefOrNull('Field', fieldId as string)
          },
        },
      },
    },
  },
})

export const createCachePersistor = (
  storage: ApolloPersistOptions<unknown>['storage'],
  directive: string
) => {
  return new CachePersistor({
    cache: apolloCache,
    storage,
    trigger: 'write',
    debug: true,
    persistenceMapper: async (data: string) => {
      const parsed = JSON.parse(data)

      const mapped: Record<string, unknown> = {}
      const persistEntities: unknown[] = []
      const rootQuery = parsed.ROOT_QUERY

      mapped.ROOT_QUERY = Object.keys(rootQuery).reduce(
        (acc: Record<string, unknown>, key: string) => {
          if (key === '__typename') return acc

          const directiveRegex = new RegExp(directive)
          if (directiveRegex.test(key)) {
            acc[key] = rootQuery[key]

            if (Array.isArray(rootQuery[key])) {
              const entities = rootQuery[key].map(
                (item: Record<string, unknown>) => item.__ref
              )
              persistEntities.push(...entities)
              return acc
            }

            const entity = rootQuery[key].__ref
            persistEntities.push(entity)
          }

          return acc
        },
        { __typename: 'Query' }
      )

      persistEntities.reduce((acc: Record<string, unknown>, key: unknown) => {
        acc[key as string] = parsed[key as string]
        return acc
      }, mapped)

      return JSON.stringify(mapped)
    },
  })
}
