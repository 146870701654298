<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-64', trigger: 'flex' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
  >
    <template #panel>
      <div class="py-3 px-4">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-sm text-gray-900">Attachment</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click="open = false"
          />
        </div>
        <p class="text-xs text-gray-600">You can also drag and drop files to upload them</p>
        <div class="mt-3">
          <UButton
            color="gray"
            variant="soft"
            icon="i-heroicons-arrow-up-tray"
            block
            :loading="uploading"
            :disabled="inserting"
            @click="fileDialog.open"
          >
            Upload file
          </UButton>
        </div>
        <UDivider class="my-4" />
        <UForm
          ref="form"
          :schema="schema"
          :state="formState"
          :validate-on="['change', 'submit']"
          class="space-y-4"
          @submit="onSubmit"
        >
          <UFormGroup label="Search or paste link" name="link">
            <UInput
              v-model="formState.link"
              placeholder="Find recent links or paste a new link"
              autofocus
            />
          </UFormGroup>
          <UFormGroup label="Link name" name="name">
            <UInput v-model="formState.name" placeholder="Name" />
          </UFormGroup>
          <div class="flex justify-end gap-2">
            <UButton
              variant="soft"
              color="gray"
              size="sm"
              @click="open = false"
            >
              Cancel
            </UButton>
            <UButton
              type="submit"
              size="sm"
              :disabled="uploading"
              :loading="inserting"
            >
              Insert
            </UButton>
          </div>
        </UForm>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import { TaskAttachmentAcceptMimeTypes } from '#task/constant'
import { TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION } from '#task/schema'
import { AttachmentType } from '#attachment/constant'

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
})

const toast = useToast()
const { createAttachment } = useUpdateTask()
const { defaultAttachment } = useTaskDataConvert()
const { upload } = usePresignedUpload()
const fileDialog = useFileDialog({
  accept: TaskAttachmentAcceptMimeTypes.join(' '),
  multiple: true,
})

const open = ref(false)
const formState = reactive(defaultAttachment)
const schema = z.object({
  name: z.string().nullable(),
  link: z.string().url(),
})
const uploading = ref(false)
const inserting = ref(false)

fileDialog.onChange(async (files) => {
  if (files?.length) {
    uploading.value = true
    const data = await upload(
      TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION,
      {
        taskId: props.taskId,
      },
      files
    )
    if (!data.success) {
      uploading.value = false
      return toast.add({
        color: 'red',
        title: data.message,
      })
    }

    await createAttachment(props.taskId, {
      attachments: data.presignedUrls,
      type: AttachmentType.FILE,
      name: '',
    })
    extend(formState, defaultAttachment)
    open.value = false
    uploading.value = false
    fileDialog.reset()
  }
})

const onSubmit = async () => {
  inserting.value = true
  await createAttachment(props.taskId, {
    attachments: [formState.link],
    type: AttachmentType.LINK,
    name: formState.name,
  })
  extend(formState, defaultAttachment)
  open.value = false
  inserting.value = false
}

defineExpose({
  open: () => open.value = true,
})
</script>
