<template>
  <TaskListSelectPopover
    ref="moduleRef"
    heading="Set module"
    search-placeholder="Find a module"
    :accept-levels="[TaskLevel.MODULE]"
    class="w-full"
    :board-id="boardId"
    :section-id="sectionId"
    :selected-task-id="selectedTaskId"
    v-bind="$attrs"
    read-only
    :show-unlink="showUnlink"
    @change="onSelect"
  >
    <slot />
  </TaskListSelectPopover>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'

defineProps({
  selectedTaskId: {
    type: String as PropType<string | null>,
    default: null,
  },
  boardId: {
    type: String,
    required: true,
  },
  sectionId: {
    type: String,
  },
  showUnlink: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'change', task: string | undefined | null): void
  (e: 'createModule'): void
}>()

const moduleRef = ref<{ open: () => void; close: () => void }>()

const onSelect = (task: TaskItem | null) => {
  emit('change', task?.id || null)
}

defineExpose({ open: () => moduleRef.value?.open() })
</script>
