import type * as Apollo from '@apollo/client'
import type { Label, LabelQueryParams } from '#label/types'

import {
  ADD_UPDATE_LABEL_MUTATION,
  DELETE_LABEL_MUTATION,
  LABELS_QUERY,
} from '#label/schema'

export const useLabelsQuery = (params: LabelQueryParams) => {
  return useQuery<{ labels?: Label[] }>(LABELS_QUERY, params)
}

export const useLabelsLazyQuery = (params: LabelQueryParams, options = {}) => {
  return useLazyQuery<{ labels?: Label[] }>(LABELS_QUERY, params, options)
}

export const useAddUpdateLabelMutation = (
  options: Apollo.MutationHookOptions<{
    addUpdateLabel: { label: Label }
  }>
) => {
  return useMutation(ADD_UPDATE_LABEL_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useDeleteLabelMutation = (id: string) => {
  return useMutation<{ deleteLabel: { success: boolean } }>(
    DELETE_LABEL_MUTATION,
    {
      variables: {
        id,
      },
      throws: 'never',
    }
  )
}
