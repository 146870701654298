<template>
  <div ref="dropZoneRef">
    <div v-if="attachments?.length" class="mb-1.5">
      <p class="text-sm mb-1.5 font-medium">Attachments</p>
      <div class="grid grid-cols-3 gap-2 max-h-28 overflow-auto pr-1 scroll-stable minimal-scrollbar">
        <div
          v-for="attachment in attachmentPresets"
          :key="attachment"
          class="cursor-pointer relative"
          @click="onUpdateCover(attachment)"
        >
          <Icon
            v-if="cover === attachment"
            name="heroicons:check"
            :size="20"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 shadow text-white"
          />
          <img :src="attachment" class="w-full h-12 object-cover rounded" />
        </div>
      </div>
    </div>
    <UButton
      color="gray"
      variant="soft"
      icon="i-heroicons-arrow-up-tray"
      block
      :loading="uploading"
      :disabled="inserting"
      @click="fileDialog.open"
    >
      Upload a cover image
    </UButton>
    <p class="mt-1.5 text-xs">Tip: Drag an image on to the task to upload it</p>
    <div v-if="cover" class="mt-4 flex justify-end">
      <UButton variant="soft" color="red" size="sm" @click="onRemoveCover">
        Remove cover
      </UButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION } from '#task/schema'
import type { Attachment } from '#attachment/types'
import { AttachmentType } from '#attachment/constant'

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  cover: {
    type: String as PropType<string | null>,
  },
  attachments: {
    type: Array as PropType<Attachment[]>,
  },
})

const emit = defineEmits(['close'])

const toast = useToast()
const { updateTask, createAttachment } = useUpdateTask()
const { upload } = usePresignedUpload()
const fileDialog = useFileDialog({
  accept: 'image/*',
  multiple: false,
})

const uploading = ref(false)
const inserting = ref(false)
const dropZoneRef = ref<HTMLDivElement>()

useDropZone(dropZoneRef, {
  dataTypes: (types) => {
    return types.every((type) => type.startsWith('image'))
  },
  onDrop: (files) => {
    onUploadFiles(files as unknown as FileList)
  },
})

const attachmentPresets = computed(() => {
  const imageAttachments = props.attachments
    ?.filter((attachment) => attachment?.mimeType?.startsWith('image'))
    .map((attachment) => attachment.url)

  return imageAttachments || []
})

const onUploadFiles = async (files: FileList | null) => {
  if (files?.length) {
    uploading.value = true
    const data = await upload(
      TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION,
      {
        taskId: props.taskId,
      },
      files
    )
    if (!data.success) {
      uploading.value = false
      return toast.add({
        color: 'red',
        title: data.message,
      })
    }

    uploading.value = true
    const attachments = await createAttachment(props.taskId, {
      attachments: data.presignedUrls,
      type: AttachmentType.FILE,
      name: '',
    })
    if (attachments?.length) {
      const [attachment] = attachments
      await onUpdateCover(attachment.url)
    }

    emit('close')
    uploading.value = false
    fileDialog.reset()
  }
}

const onUpdateCover = async (imageUrl: string) => {
  updateTask(props.taskId, {
    cover: imageUrl,
    coverBackgroundColor: await getImageAvgColorHex(imageUrl),
  })
}

const onRemoveCover = async () => {
  await updateTask(props.taskId, { cover: null, coverBackgroundColor: null })
  emit('close')
}

fileDialog.onChange(onUploadFiles)
</script>
