import { FIELD_FRAGMENT } from '#field/fragment'
import { TASK_TYPE_FRAGMENT, TASK_TYPE_WITH_FIELDS_FRAGMENT } from '#task-type/fragment'

export const TASK_TYPES_QUERY = gql`
  ${TASK_TYPE_FRAGMENT}
  query TaskTypesQuery($parentId: ID!, $source: String, $loadFull: Boolean) {
    taskTypes(parentId: $parentId, source: $source, loadFull: $loadFull) {
      ...TaskType
      settingsPack {
        id
        name
      }
    }
  }
`

export const TASK_TYPES_WITH_FIELDS_QUERY = gql`
  ${TASK_TYPE_WITH_FIELDS_FRAGMENT}
  query TaskTypesQuery($parentId: ID!, $source: String) {
    taskTypes(parentId: $parentId, source: $source) {
      ...TaskTypesWithFields
    }
  }
`

export const TASK_TYPE_DETAIL_QUERY = gql`
  ${TASK_TYPE_FRAGMENT}
  ${FIELD_FRAGMENT}
  query TaskTypeQuery($id: ID!) {
    taskType(id: $id) {
      ...TaskType
      description
      fieldsWithPosition {
        id
        field {
          ...Field
        }
        position
      }
      settingsPack {
        id
        name
      }
    }
  }
`

export const ADD_UPDATE_TASK_TYPE_MUTATION = gql`
  ${TASK_TYPE_FRAGMENT}
  mutation AddUpdateTaskTypeMutation($input: AddUpdateTaskTypeInput!) {
    addUpdateTaskType(input: $input) {
      taskType {
        ...TaskType
      }
    }
  }
`

export const ATTACH_FIELD_TO_TASK_TYPE_MUTATION = gql`
  mutation AttachFieldToTaskTypeMutation(
    $fieldId: ID!
    $taskTypeId: ID!
    $position: Float!
  ) {
    attachFieldToTaskType(
      fieldId: $fieldId
      taskTypeId: $taskTypeId
      position: $position
    ) {
      success
    }
  }
`

export const UNATTACH_FIELD_FROM_TASK_TYPE_MUTATION = gql`
  mutation UnattachFieldFromTaskTypeMutation(
    $fieldId: ID!
    $taskTypeId: ID!
  ) {
    unattachFieldFromTaskType(
      fieldId: $fieldId
      taskTypeId: $taskTypeId
    ) {
      success
    }
  }
`

export const UPDATE_TASK_TYPE_FIELD_POSITION_MUTATION = gql`
  mutation UpdateTaskTypeFieldPosMutation($taskTypeFieldId: ID!, $position: Float!) {
    updateTaskTypeFieldPosition(taskTypeFieldId: $taskTypeFieldId, position: $position) {
      success
    }
  }
`

export const DELETE_TASK_TYPE_MUTATION = gql`
  mutation DeleteTaskTypeMutation(
    $id: ID!
    $replaceTaskTypeId: ID!
  ) {
    deleteTaskType(
      id: $id
      replaceTaskTypeId: $replaceTaskTypeId
    ) {
      success
    }
  }
`
