import { format, formatDistanceToNow } from 'date-fns'

export const formatTime = (dateString: string) => {
  const date = new Date(dateString)
  const now = new Date()
  const diffInDays = (now.getTime() - date.getTime()) / (1000 * 3600 * 24)

  if (diffInDays < 1) {
    return formatDistanceToNow(date, { addSuffix: true })
  } else {
    return format(date, 'MMM d, yyyy, h:mm a')
  }
}
