/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

import { LABEL_FRAGMENT } from '#label/fragment'

export const LABEL_LOADER_FRAGMENT = gql`
  ${LABEL_FRAGMENT}
  fragment LabelLoader on LabelType {
    ...Label
    settingsPack @client {
      ...SettingsPackLoader
    }
  }
`
