import type { DirectiveBinding } from 'vue'

export default defineNuxtPlugin((nuxtApp) => {
  let isScrolling = false
  const groups: Record<string, Set<{ el: Element; dir?: string }>> = {}

  const applyScroll = (
    element: Element,
    target: { el: Element; dir?: string },
    dir?: string
  ) => {
    const { el, dir: targetDir } = target
    const scrollY = () => {
      if (!targetDir || targetDir === 'y') {
        el.scrollTop =
          (element.scrollTop * (element.scrollHeight - element.clientHeight)) /
          (element.scrollHeight - element.clientHeight)
      }
    }

    const scrollX = () => {
      if (!targetDir || targetDir === 'x') {
        el.scrollLeft =
          (element.scrollLeft * (element.scrollWidth - element.clientWidth)) /
          (element.scrollWidth - element.clientWidth)
      }
    }

    if (dir === 'x') {
      return scrollX()
    }

    if (dir === 'y') {
      return scrollY()
    }

    scrollX()
    scrollY()
  }

  const onSrcoll = (binding: DirectiveBinding, el: Element) => {
    if (!isScrolling) {
      isScrolling = true
      Array.from(groups[binding.value])
        .filter((e) => e.el !== el)
        .forEach((e) => applyScroll(el, e, binding.arg))
      requestAnimationFrame(() => {
        isScrolling = false
      })
    }
  }

  nuxtApp.vueApp.directive('sync-scroll', {
    mounted(el, binding) {
      if (!groups[binding.value]) {
        groups[binding.value] = new Set([])
      }

      /**
       * We should sync the scroll position of the new element with the rest of the group
       */
      Array.from(groups[binding.value]).forEach((e) => {
        applyScroll(el, e, binding.arg)
      })

      groups[binding.value].add({
        el,
        dir: binding.arg,
      })
      el.addEventListener('scroll', onSrcoll.bind(null, binding, el), {
        passive: true,
      })
    },
    unmounted(el, binding) {
      groups[binding.value].forEach((e) => {
        if (e.el === el) {
          e.el.removeEventListener('scroll', onSrcoll.bind(null, binding, el))
          groups[binding.value].delete(e)
        }
      })
    },
    getSSRProps() {
      return {}
    },
  })
})
