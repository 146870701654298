<template>
  <div
    :id="'comment-' + action.comment.id"
    :class="['flex py-2 -ml-5 pl-5', { 'border-l-4 border-primary-500 bg-primary-50 !pl-4 rounded-tr rounded-br -mr-2 pr-2': isActive }]"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="mr-3">
      <Avatar
        :id="action.creator.id"
        :src="action.creator.photo"
        :name="action.creator.fullName"
        size="sm"
      />
    </div>
    <div class="flex-1 text-sm">
      <div
        class="flex justify-start items-center mb-2 text-gray-500 gap-1 flex-wrap w-full"
      >
        <span class="font-semibold text-gray-900">{{
          action.creator.fullName
        }}</span>
        <NuxtLink class="text-xs hover:underline hover:cursor-pointer" @click="onClickTime">{{ formattedTime }}</NuxtLink>
        <span v-if="action.comment.created !== action.comment.modified">(edited)</span>
        <span class="relative">
          <UseClipboard v-if="isHovered" v-slot="{ copy, copied }" :source="commentUrl">
            <UTooltip
              v-if="!copied"
              text="Copy comment URL"
              :popper="{ placement: 'top' }"
              class="absolute -top-3"
            >
              <UButton
                size="xs"
                icon="i-heroicons-link"
                variant="ghost"
                color="gray"
                @click="copy()"
              />
            </UTooltip>
            <UTooltip
              v-if="copied"
              text="Copied!"
              :popper="{ placement: 'top' }"
              class="absolute -top-3"
            >
              <UButton
                size="xs"
                icon="i-heroicons-link"
                variant="ghost"
                color="gray"
                @click="copy()"
              />
            </UTooltip>
          </UseClipboard>
        </span>
      </div>
      <div
        v-if="!updating"
        class="px-3 py-2 rounded-lg border w-full bg-white break-words ProseMirror"
        style="word-break: break-word;"
        v-html="action.comment.content"
      ></div>
      <Editor
        v-else
        v-model:content="updateContent"
        v-model:is-empty="isEditorEmpty"
        class="rounded-md px-3 pt-2 text-sm border-primary-500 border-2 bg-white"
        placeholder="Add a comment, '/' for commands…"
        :autofocus="true"
        :additional-extensions="additionalExtensions"
      />
      <div v-if="!updating" class="flex items-center gap-1 flex-wrap py-1">
        <div
          v-for="(reaction, index) in reactions"
          :key="index"
          :class="[
            'pl-0.5 pr-2 border border-primary-200 rounded-full hover:bg-primary-100  hover:border-primary-400 hover:cursor-pointer flex items-center py-0.5',
            {
              'bg-primary-100': reaction[1].isReacted,
            }
          ]"
          @click="() => onSelectEmoji({ i: reaction[0] }, () => {})"
        >
          <span class="text-base -my-1 mr-0.5">{{
            reaction[0]
          }}</span>
          <span class="text-xs font-medium text-primary-800">{{ reaction[1].count }}</span>
        </div>
        <UPopover v-model:open="openEmojiPicker">
          <UButton
            variant="ghost"
            color="gray"
            size="2xs"
            class="text-2xl"
            icon="i-heroicons-face-smile"
          />
          <template #panel="{ close }">
            <EmojiPicker
              v-if="showAllEmoji"
              :native="true"
              :disable-skin-tones="true"
              @select="(emoji) => onSelectEmoji(emoji, close)"
            />
            <div v-else class="flex gap-1 p-2">
              <span
                v-for="(emoji, index) in defaultEmoji"
                :key="index"
                variant="ghost"
                color="gray"
                class="text-xl size-8 p-0 flex justify-center items-center hover:bg-gray-100 rounded-md cursor-pointer"
                @click="() => onSelectEmoji({ i: emoji }, close)"
              >
                {{ emoji }}
              </span>
              <UButton
                icon="i-heroicons-plus" color="gray" variant="ghost"
                class="size-8"
                @click="showAllEmoji = true"
              />
            </div>
          </template>
        </UPopover>
        <UButton
          v-if="action.creator.id !== auth.id"
          variant="ghost"
          color="gray"
          size="2xs"
          @click="reply"
        >
          Reply
        </UButton>
        <UButton
          v-if="action.creator.id === auth.id"
          variant="ghost"
          color="gray"
          size="2xs"
          @click="edit"
        >
          Edit
        </UButton>
        <UPopover v-if="(action.creator.id === auth.id || isAdmin) && isHasLink">
          <UButton
            variant="ghost"
            color="gray"
            size="2xs"
            @click="openDeletePopover = true"
          >
            Add link as attachment
          </UButton>
          <template #panel="{ close }">
            <ConfirmPopover
              title="Attach links?"
              confirm-button-text="Attach"
              confirm-button-color="primary"
              :close="close"
              @confirm="attachLinks(linksInComment)"
            >
              <p v-for="(link, index) in linksInComment" :key="index">{{ link }}</p>
            </ConfirmPopover>
          </template>
        </UPopover>
        <UPopover v-if="action.creator.id === auth.id || isAdmin">
          <UButton
            variant="ghost"
            color="gray"
            size="2xs"
            @click="openDeletePopover = true"
          >
            Delete
          </UButton>
          <template #panel="{ close }">
            <ConfirmPopover
              title="Delete comment?"
              confirm-button-text="Delete"
              :close="close"
              @confirm="deleteComment"
            >
              Deleting a comment is forever. There is no undo.
            </ConfirmPopover>
          </template>
        </UPopover>
      </div>
      <div v-else class="flex gap-2">
        <UButton
          v-if="updating"
          color="primary"
          class="mt-2"
          :disabled="isEditorEmpty"
          :loading="loading"
          @click="updateComment()"
          @keyup.enter="updateComment()"
        >
          Save changes
        </UButton>
        <UButton
          v-if="updating"
          color="gray"
          class="mt-2"
          variant="soft"
          @click="editingItemId = ''"
          @keyup.enter="editingItemId = ''"
        >
          Discard
        </UButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'vue3-emoji-picker/css'
import EmojiPicker from 'vue3-emoji-picker'
import { UseClipboard } from '@vueuse/components'
import { formatTime } from '#action/utils'
import type { ActionType } from '#action/types'
import type { TaskItem } from '#task/types'
import SlashCommandExtension, { COMMAND_LIST } from '#core/packages/tiptap/slash-command'
import Mention from '#core/packages/tiptap/mention'
import type { UserLoader } from '#auth/types'
import { AttachmentType } from '#attachment/constant'

const defaultEmoji = ['👍', '👏', '🔥', '❤️', '😲', '🤔']
const editingItemId = defineModel('editingItemId', {
  type: String,
  default: '',
})

const props = defineProps({
  action: { type: Object as PropType<ActionType>, required: true },
  boardMembers: { type: Array as PropType<UserLoader[]>, required: true },
  isActive: { type: Boolean }
})

const emit = defineEmits(['edit', 'reply', 'click-time'])

const { mutate, loading } = useAddUpdateCommentMutation()
const { mutate: toggleCommentMutate } = useToggleCommentReactionMutation()
const { mutate: deleteCommentMutate } = useDeleteCommentMutation(
  props.action.comment.id
)
const { createAttachment } = useUpdateTask()
const { auth } = useAuthStore()
const { isAdmin } = useBoardAbility()

const additionalExtensions = ref([
  SlashCommandExtension(
    COMMAND_LIST.filter(item => !['Table', 'Heading 1', 'Heading 2', 'Heading 3', 'Quote', 'Image'].includes(item.title))
  ),
  Mention(props.boardMembers)
])
const openEmojiPicker = ref(false)
const showAllEmoji = ref(false)
const updateContent = ref(props.action.comment.content)
const openDeletePopover = ref(false)
const isHovered = ref(false)
const isEditorEmpty = ref(true)

const updating = computed(() => editingItemId.value === props.action.comment.id)
const commentUrl = computed(() => `${window.location.origin}/t/${props.action.task.handle}#comment-${props.action.comment.id}`)
const reactions = computed(() => {
  const reactionMap = new Map<string, { count: number, isReacted: boolean }>()
  props.action.comment.reactions.forEach((reaction) => {
    if (reactionMap.has(reaction.emoji)) {
      reactionMap.set(reaction.emoji, {
        count: (reactionMap.get(reaction.emoji)?.count || 0) + 1,
        isReacted: reactionMap.get(reaction.emoji)?.isReacted || reaction.creator.id === auth.id,
      })
    } else {
      reactionMap.set(reaction.emoji, {
        count: 1,
        isReacted: reaction.creator.id === auth.id,
      })
    }
  })
  return reactionMap
})
const isHasLink = computed(() => {
  const div = document.createElement('div')
  div.innerHTML = props.action.comment.content
  return div.querySelector('a') !== null
})
const linksInComment = computed(() => {
  const div = document.createElement('div')
  div.innerHTML = props.action.comment.content
  const links = Array.from(div.querySelectorAll('a')).map(a => a.href)
  return links
})

const formattedTime = computed(() => formatTime(props.action.comment.created))

const reply = () => {
  emit('reply', props.action.creator.fullName)
}

const updateComment = async () => {
  await mutate({
    input: {
      id: props.action.comment.id,
      content: updateContent.value,
    },
  })
  editingItemId.value = ''
}

const edit = () => {
  updateContent.value = props.action.comment.content
  editingItemId.value = props.action.comment.id
  emit('edit', props.action.comment.id)
}

const onClickTime = () => {
  updateTaskUrl(props.action.task as TaskItem, undefined, `comment-${props.action.comment.id}`)
  emit('click-time')
}

const deleteComment = () => {
  deleteCommentMutate({
    commentId: props.action.comment.id,
  })
}

const onSelectEmoji = (emoji: { i: string }, close: () => void) => {
  toggleCommentMutate({
    commentId: props.action.comment.id,
    emoji: emoji.i,
  })
  showAllEmoji.value = false
  close()
}

const attachLinks = async (links: string[]) => {
  await createAttachment(props.action.task.id, {
    attachments: links,
    type: AttachmentType.LINK,
    name: ''
  })
}

watch(() => openEmojiPicker.value, (value) => {
  if (!value) {
    showAllEmoji.value = false
  }
})
</script>
