import { TASK_TYPE_FRAGMENT } from '#task-type/fragment'
import { CUSTOM_FIELD_FRAGMENT } from '#field/fragment'
import { SECTION_FRAGMENT } from '#section/fragment'
import { BOARD_BASE_FRAGMENT, BOARD_MIN_FRAGMENT } from '#board/fragment'
import { LABEL_FRAGMENT } from '#label/fragment'
import { STATUS_FRAGMENT } from '#status/fragment'
import { ATTACHMENT_FRAGMENT } from '#attachment/fragment'

export const TASK_FRAGMENT = gql`
  ${CUSTOM_FIELD_FRAGMENT}
  fragment Task on TaskType {
    id
    name
    handle
    boardId
    sectionId
    number
    position
    level
    parentId
    labelIds
    taskFields {
      ...CustomField
    }
    cover
    coverBackgroundColor
    closed
    created
    typeId
    statusId
    assigneeId
    parentClosed
    sectionClosed
  }
`

export const TASK_PREVIEW_FRAGMENT = gql`
  ${LABEL_FRAGMENT}
  ${TASK_FRAGMENT}
  ${STATUS_FRAGMENT}
  ${TASK_TYPE_FRAGMENT}
  ${CUSTOM_FIELD_FRAGMENT}
  fragment TaskPreview on TaskType {
    ...Task
    startDate
    dueDate
    subtasksCount
    lastActivity
    labelIds
    labels {
      ...Label
    }
    typeId
    type {
      ...TaskType
    }
    statusId
    status {
      ...Status
    }
    assigneeId
    assignee {
      id
      fullName
      photo
    }
    parentId
    parent {
      id
      name
      number
      handle
      type {
        id
        name
        icon
      }
    }
    taskFields {
      ...CustomField
    }
    parentClosed
    sectionClosed
  }
`

export const TASK_WITH_TASK_FIELDS_FRAGMENT = gql`
  ${TASK_FRAGMENT}
  ${CUSTOM_FIELD_FRAGMENT}
  fragment TaskWithTaskFields on TaskType {
    ...Task
    taskFields {
      ...CustomField
    }
  }
`

export const TASK_ATTACHMENT_PREVIEW_FRAGMENT = gql`
  ${TASK_FRAGMENT}
  ${BOARD_MIN_FRAGMENT}
  ${STATUS_FRAGMENT}
  ${SECTION_FRAGMENT}
  ${TASK_TYPE_FRAGMENT}
  fragment TaskAttachmentPreview on TaskType {
    ...Task
    startDate
    dueDate
    board {
      ...Board
    }
    section {
      ...Section
    }
    type {
      ...TaskType
    }
    assignee {
      id
      fullName
      photo
    }
    status {
      ...Status
    }
  }
`

export const TASK_DEPENDENCY_DETAIL_FRAGMENT = gql`
  fragment DependencyTask on TaskType {
    id
    name
    handle
    startDate
    dueDate
  }
  fragment TaskDependencyDetail on TaskDependencyType {
    id
    fromTask {
      ...DependencyTask
    }
    toTask {
      ...DependencyTask
    }
    type
  }
`

export const TASK_DEPENDENCY_FRAGMENT = gql`
  fragment TaskDependency on TaskDependencyType {
    id
    fromTaskId
    toTaskId
    type
  }
`

export const TASK_DETAIL_BASE_FRAGMENT = gql`
  ${TASK_FRAGMENT}
  ${LABEL_FRAGMENT}
  ${CUSTOM_FIELD_FRAGMENT}
  ${STATUS_FRAGMENT}
  ${TASK_TYPE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  ${SECTION_FRAGMENT}
  ${TASK_DEPENDENCY_DETAIL_FRAGMENT}
  fragment TaskDetailBase on TaskType {
    ...Task
    description
    startDate
    dueDate
    subtasksCount
    lastActivity
    parent {
      id
      name
      number
      handle
      type {
        id
        name
        icon
      }
    }
    assignee {
      id
      fullName
      photo
    }
    section {
      ...Section
    }
    status {
      ...Status
    }
    type {
      ...TaskType
    }
    labels {
      ...Label
    }
    taskFields {
      ...CustomField
    }
    attachments {
      ...Attachment
    }
    dependencies {
      ...TaskDependencyDetail
    }
  }
`

export const TASK_DETAIL_FRAGMENT = gql`
  ${TASK_DETAIL_BASE_FRAGMENT}
  ${BOARD_MIN_FRAGMENT}
  fragment TaskDetail on TaskType {
    ...TaskDetailBase
    board {
      ...Board
      workspace {
        id
      }
    }
  }
`

export const TASK_DETAIL_WITH_BOARD_DETAIL_FRAGMENT = gql`
  ${TASK_DETAIL_BASE_FRAGMENT}  
  ${BOARD_BASE_FRAGMENT}
  fragment TaskDetailWithBoardDetail on TaskType {
    ...TaskDetailBase
    board {
      ...BaseBoard
      workspace {
        id
      }
    }
  }
`

export const MIN_TASK_FRAGMENT = gql`
  fragment MinTask on TaskType {
    id
    name
    handle
  }
`
