import { clone, has } from 'lodash-es'
import type { BaseBoard } from '#board/types'
import type { CurrentTask } from '#task/types'
import type { BaseWorkspace } from '#workspace/types'
import type { SettingsPack } from '#settings-pack/types'
import { TaskViewMode } from '#task/constant'
import type { RootAddPlacement } from '#core/types'

export const useWorkspaceSharedState = () => {
  const toast = useToast()

  const currentWorkspace = useState<BaseWorkspace>(
    'currentWorkspace',
    () => ({} as BaseWorkspace)
  )
  const currentBoard = useState<BaseBoard>(
    'currentBoard',
    () => ({} as BaseBoard)
  )
  const currentTask = useState<CurrentTask>(
    'currentTask',
    () => ({} as CurrentTask)
  )
  const currentSettingsPack = useState<SettingsPack>(
    'currentSettingsPack',
    () => ({} as SettingsPack)
  )

  const setCurrentWorkspace = (payload: BaseWorkspace) => {
    currentWorkspace.value = payload
  }

  const activeBoards = useState<BaseBoard[]>('activeBoards', () => [])

  const setCurrentBoard = (payload: BaseBoard) => {
    currentBoard.value = payload
  }

  const setCurrentTask = (payload: CurrentTask, shouldUpdateUrl = false) => {
    // Don't open optimistic tasks in the UI
    if (has(payload, 'id') && isOptimisticId(payload.id)) {
      toast.add({
        title: 'Can\'t open task',
        description:
          'This task is being created, please wait for the server to respond before opening it.',
        color: 'yellow',
      })
      return
    }

    const nextTask = clone(payload)
    if (!nextTask.viewMode) {
      nextTask.viewMode = currentTask.value.viewMode || TaskViewMode.MODAL
    }

    currentTask.value = nextTask
    if (shouldUpdateUrl && nextTask.handle && import.meta.client) {
      const { pathname } = window.location
      const newPath = pathname.replace(/\/t\/[^/]+/, `/t/${nextTask.handle}`)
      window.history.pushState(null, '', newPath)
    }
  }

  const setCurrentSettingsPack = (payload: SettingsPack) => {
    currentSettingsPack.value = payload
  }

  const openTask = (payload: CurrentTask, view: string, viewMode = TaskViewMode.MODAL) => {
    const { id, name, handle } = payload
    updateTaskUrl(payload, view && `/${view}`)
    setCurrentTask({
      id,
      name,
      handle,
      viewMode,
    })
  }

  return {
    currentWorkspace,
    currentBoard,
    currentTask,
    currentSettingsPack,
    setCurrentWorkspace,
    setCurrentBoard,
    setCurrentTask,
    setCurrentSettingsPack,
    activeBoards,
    openTask,
  }
}

// Composable function to manage the state of adding a section placeholder
const $useRootAddPlaceholder = () => {
  const activeCode = ref<string | null>(null)
  const boardHasAdding = ref<boolean>(false)
  const lastCreatedId = ref<string | null>(null)

  const getCode = (rootId: string | null, placement: RootAddPlacement) => {
    return [rootId || '', placement].join('-')
  }

  const setActive = (rootId: string | null, placement: RootAddPlacement) => {
    activeCode.value = getCode(rootId, placement)
    boardHasAdding.value = true
  }

  const setInactive = () => {
    activeCode.value = null
    boardHasAdding.value = false
  }

  return {
    getCode,
    activeCode,
    boardHasAdding,
    setActive,
    setInactive,
    lastCreatedId,
  }
}

export const useRootAddPlaceholder = createSharedComposable(
  $useRootAddPlaceholder
)
