import {
  LocalStorageWrapper,
  SessionStorageWrapper,
} from 'apollo3-cache-persist'
import {
  ApolloClient,
  ApolloLink,
  from,
  type FetchPolicy,
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { removeDirectivesFromDocument } from '@apollo/client/utilities'
import { apolloCache, createCachePersistor } from '#core/apollo_cache'

export default defineNuxtPlugin({
  name: 'leanbase-apollo',
  dependsOn: ['pinia'],
  async setup(nuxtApp) {
    const runtimeConfig = useRuntimeConfig()
    const { accessToken } = storeToRefs(useAuthStore())
    const { $apollo } = useNuxtApp()

    const socketId = [Date.now(), uuid()].join('_')

    const authLink = setContext(async (_, { headers = {} }) => {
      if (accessToken.value) {
        extend(headers, {
          'Authorization': `JWT ${accessToken.value}`,
          'X-Socket-ID': socketId,
        })
      }

      return {
        headers,
      }
    })

    const httpLink = authLink.concat(
      createUploadLink({
        uri: String(runtimeConfig.public.graphqlApiEndpoint),
      })
    )

    const errorLink = onError((err) => {
      nuxtApp.callHook('apollo:error', err)
    })

    /**
     * Omit custom directives from the query
     */
    const omitDirectives = new ApolloLink((operation, forward) => {
      const query = removeDirectivesFromDocument(
        [{ name: 'sessionPersist' }, { name: 'localPersist' }],
        operation.query
      )
      if (query) {
        operation.query = query
      }

      return forward(operation)
    })

    /**
     * Persist cache to session storage
     * Usage: Put @sessionPersist or @localPersist directive on the fields you want to persist
     * Example: query { user @sessionPersist { id name } }
     */
    const sessionPersistor = createCachePersistor(
      new SessionStorageWrapper(window.sessionStorage),
      'sessionPersist'
    )
    const localPersistor = createCachePersistor(
      new LocalStorageWrapper(window.localStorage),
      'localPersist'
    )

    await Promise.all([sessionPersistor.restore(), localPersistor.restore()])

    const client = new ApolloClient({
      link: from([omitDirectives, errorLink, httpLink]),
      cache: apolloCache,
      connectToDevTools: true,
      ssrMode: false,
      defaultOptions: {
        query: {
          fetchPolicy: 'cache-and-network' as FetchPolicy,
        },
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    })

    $apollo.clients.default = client
    $apollo.defaultClient = client

    return {
      provide: {
        socketId,
      },
    }
  },
})
