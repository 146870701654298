import { maxBy } from 'lodash-es'
import { TASK_EMPTY_ID, TASK_TYPE_NAME, TaskLevel } from '#task/constant'
import {
  MAX_COLUMN_RESIZER_WIDTH,
  MIN_COLUMN_RESIZER_WIDTH,
} from '#core/constant'
import type { Label } from '#label/types'
import type { TaskItem, ActiveSpreadsheetView } from '#task/types'
import type { SelectTreeItem, SpreadsheetRow, ColumnType } from '#board/types'
import {
  SelectTreeGroupBy,
  BoardView,
  BoardViewValues,
  CheckboxState,
  SelectTreeLevel,
  SelectTreeAll,
} from '#board/constant'
import { SECTION_TYPE_NAME } from '#section/constant'
import type { RootAddPlacement } from '#core/types'

/**
 * ListView shared state
 */
const $useListViewSharedState = () => {
  const { toggleLabels } = useUpdateTask()
  const { setActive } = useRootAddPlaceholder()
  const { filterCriteriaCount, filterLoaded } = useTaskFilterContext()

  const isFilteredHasResult = computed(() => !!filterCriteriaCount.value && filterLoaded.value)

  // Shared state for all features
  const activeView = ref<ActiveSpreadsheetView>(BoardView.LIST)
  const boardId = ref<string>('')
  const items = ref<SpreadsheetRow[]>([])
  const columns = ref<ColumnType[]>([])
  const scrollerRef = ref()
  const tableColumnWidth = reactive<Record<string, number>>({
    name: MAX_COLUMN_RESIZER_WIDTH,
  })
  const lastCreatedTask = reactive({
    level: TaskLevel.TASK,
  })

  const itemsMapping = computed(() => {
    const group = ['id', 'index'] as const
    return items.value.reduce((acc, curr, index) => {
      group.forEach((key) => {
        if (!acc[key]) {
          acc[key] = {}
        }

        const item = {
          ...curr,
          index,
        }
        acc[key][item[key]] = item
      })
      return acc
    }, {} as Record<(typeof group)[number], Record<string, SpreadsheetRow & { index: number }>>)
  })
  // Filter task items to help with navigate tasks
  const taskItems = computed(() => {
    return items.value.filter(
      (item) => item.__typename === TASK_TYPE_NAME
    ) as TaskItem[]
  })

  const getTableColumnWidth = (key: string) => {
    return tableColumnWidth[key] || MIN_COLUMN_RESIZER_WIDTH
  }

  // Get row, column by relative index
  const getRelativeTask = (taskId: string, relativeIndex: number) => {
    const currentIndex = taskItems.value.findIndex((item) => item.id === taskId)

    let targetIndex = currentIndex + relativeIndex

    if (targetIndex < 0) {
      targetIndex = 0
    } else if (targetIndex >= taskItems.value.length) {
      targetIndex = taskItems.value.length - 1
    }

    return taskItems.value[targetIndex]
  }

  const setup = (flatItems: SpreadsheetRow[]) => {
    items.value = flatItems

    // Reset all shared state
    resetExpandedIds()
    resetSelectTree()
  }

  const getRelativeColumn = (columnKey: string, relativeIndex: number) => {
    const currentIndex = columns.value.findIndex(
      (column) => column.key === columnKey
    )

    let targetIndex = currentIndex + relativeIndex

    if (targetIndex < 0) {
      targetIndex = 0
    } else if (targetIndex >= columns.value.length) {
      targetIndex = columns.value.length - 1
    }

    return columns.value[targetIndex]
  }

  // Bulk update
  const updateSelectedTasksLabel = (label: Label, currentValue: string) => {
    const _selectedTaskIds = getSelectedTaskIds()
    if (_selectedTaskIds.size === 0) {
      return
    }

    const selectedTaskIds = Array.from(_selectedTaskIds)

    if (currentValue === 'unchecked' || currentValue === 'checked') {
      toggleLabels({
        taskIds: selectedTaskIds,
        labelIds: [label.id],
        detachLabelIds: [],
      })
      return
    }

    toggleLabels({
      taskIds: selectedTaskIds,
      labelIds: [],
      detachLabelIds: [label.id],
    })
  }

  /**
   * Expand tree logic - Start
   */
  const expandedIds = ref<Set<string>>(new Set([]))

  const resetExpandedIds = () => {
    expandedIds.value = new Set([])
  }

  const isExpanded = (id: string) => {
    return expandedIds.value.has(id) || isFilteredHasResult.value
  }

  const getIsExpandedRef = (id: string) => {
    return computed(() => isExpanded(id))
  }

  const toggleExpand = (id: string) => {
    if (expandedIds.value.has(id)) {
      expandedIds.value.delete(id)
    } else {
      expandedIds.value.add(id)
    }
  }

  const setExpanded = (id: string, expanded: boolean) => {
    if (expanded) {
      expandedIds.value.add(id)
    } else {
      expandedIds.value.delete(id)
    }
  }

  /**
   * Expand tree logic - End
   */

  /**
   * Select checkbox logic - Start
   */
  // Select checkbox states
  const selectTree = ref<Record<string, SelectTreeItem>>({})
  const selectedCount = computed(() => {
    return Object.keys(selectTree.value).filter(
      (id) => selectTree.value[id].state === CheckboxState.CHECKED
    ).length
  })
  const selectedTaskIds = computed(() => {
    return getSelectedTaskIds()
  })

  const resetSelectTree = () => {
    selectTree.value = {}
    selectTree.value[SelectTreeAll] = {
      state: CheckboxState.UNCHECKED,
      parent: null,
      level: SelectTreeLevel.All,
    }
  }

  const fillSelectTree = (groupType: SelectTreeGroupBy) => {
    items.value.forEach((item) => {
      let level: SelectTreeLevel = SelectTreeLevel.First
      let parent: string | null = null

      // Determine level
      // level GROUP if
      // - If group by section & item is Section
      // - If group by module & item is task & item task level is Module
      if (groupType === SelectTreeGroupBy.Section) {
        if (item.__typename === SECTION_TYPE_NAME) {
          level = SelectTreeLevel.Group
        } else if (
          item.__typename === TASK_TYPE_NAME &&
          'level' in item &&
          item.level !== TaskLevel.SUBTASK
        ) {
          // Level First if
          // - If group by section & item is task and item task level is not Subtask
          level = SelectTreeLevel.First
        } else {
          // Level Second if
          // - item is task and item task level is Subtask
          level = SelectTreeLevel.Second
        }
      } else if (groupType === SelectTreeGroupBy.Module) {
        if (
          item.__typename === TASK_TYPE_NAME &&
          'level' in item &&
          item.level === TaskLevel.MODULE
        ) {
          level = SelectTreeLevel.Group
        } else if (
          item.__typename === TASK_TYPE_NAME &&
          'level' in item &&
          item.level === TaskLevel.TASK
        ) {
          // Level First if
          // - If group by module & item is task and item task level is Task
          level = SelectTreeLevel.First
        } else {
          // Level Second if
          // - item is task and item task level is Subtask
          level = SelectTreeLevel.Second
        }
      }

      // Determine parent
      // - If level is Group, parent is null
      // - If level is First
      //  - If group by section, parent is sectionId
      //  - If group by module, parent is parentId
      // - If level is Second, parent is parentId
      if (level === SelectTreeLevel.Group) {
        parent = SelectTreeAll
      } else if (
        level === SelectTreeLevel.First &&
        'sectionId' in item &&
        'parentId' in item
      ) {
        if (groupType === SelectTreeGroupBy.Section) {
          parent = item.sectionId
        } else {
          // If group by module
          parent = item.parentId || TASK_EMPTY_ID
        }
      } else if (level === SelectTreeLevel.Second && 'parentId' in item) {
        parent = item.parentId
      }

      // Create new tree node if not existed
      if (!selectTree.value[item.id]) {
        selectTree.value[item.id] = {
          state: CheckboxState.UNCHECKED,
          parent: parent,
          level: level,
        }
      } else {
        // Update parent if existed
        selectTree.value[item.id].parent = parent
        selectTree.value[item.id].level = level
      }
    })
  }

  const getSelectState = (id: string) => {
    return selectTree.value[id]?.state || CheckboxState.UNCHECKED
  }

  const isItemChecked = (id: string) => {
    return getSelectState(id) === CheckboxState.CHECKED
  }

  const getIsItemCheckedRef = (id: string) => {
    return computed(() => isItemChecked(id))
  }

  const getSelectStateRef = (id: string) => {
    return computed(() => getSelectState(id))
  }

  const setSelectStateChildren = (state: CheckboxState, parentId: string) => {
    Object.keys(selectTree.value).forEach((id) => {
      if (selectTree.value[id].parent === parentId) {
        selectTree.value[id].state = state

        if (selectTree.value[id].level !== SelectTreeLevel.Second) {
          setSelectStateChildren(state, id)
        }
      }
    })
  }

  const setSelectStateParent = (childrenId: string) => {
    const parentId = selectTree.value[childrenId]?.parent
    if (!parentId) {
      return
    }

    const children = Object.keys(selectTree.value).filter(
      (id) => selectTree.value[id].parent === parentId
    )

    const checkedCount = children.filter(
      (id) => selectTree.value[id].state === CheckboxState.CHECKED
    ).length

    // Check parent if all children are checked
    let state = CheckboxState.INDETERMINATE
    if (checkedCount === children.length) {
      state = CheckboxState.CHECKED
    } else if (checkedCount === 0) {
      const incheckedCount = children.filter(
        (id) => selectTree.value[id].state === CheckboxState.INDETERMINATE
      ).length
      if (incheckedCount === 0) {
        state = CheckboxState.UNCHECKED
      }
    }

    selectTree.value[parentId].state = state

    if (selectTree.value[parentId].level > SelectTreeLevel.All) {
      setSelectStateParent(parentId)
    }
  }

  const setSelectState = (id: string, checked: boolean) => {
    if (!selectTree.value[id]) {
      selectTree.value[id] = {
        state: CheckboxState.UNCHECKED,
        parent: null,
        level: SelectTreeLevel.First,
      }
    }

    selectTree.value[id].state = checked
      ? CheckboxState.CHECKED
      : CheckboxState.UNCHECKED

    // Update check state for other nodes
    // - When a check box is checked
    //   - traverse and check all direct lower level that is direct children recursively
    //   - traverse all parent children and set checked if all checked, else in-check recursively
    //   - check for allCheck
    //     - checked if all group rows is checked
    //     - else in-check
    // - when a checkbox is unchecked
    //   - traverse and uncheck all direct lower level that is direct children recursively
    //   - traverse all parent children and set incheck if at least one checked, else unchecked
    //   - check for all Check
    //     - unchecked  if all group rows is unchecked
    //     - else in-check
    setSelectStateChildren(
      checked ? CheckboxState.CHECKED : CheckboxState.UNCHECKED,
      id
    )
    setSelectStateParent(id)
  }

  const scrollToItemId = (id: string) => {
    const item = itemsMapping.value.id[id]
    if (!item) {
      return
    }

    scrollerRef.value?.scrollToItem(item.index)
  }

  const showCreateTaskRow = (
    id: string,
    level: TaskLevel,
    placement: RootAddPlacement
  ) => {
    const item = itemsMapping.value.id?.[id]
    lastCreatedTask.level = level
    if (!item) {
      return setActive(id, placement)
    }

    /**
     * If item has children, set active to the last child
     */
    if (item.itemsCount) {
      const children = items.value.reduce((acc, curr, index) => {
        const child = {
          ...curr,
          index,
        }
        const parentId = item.id === TASK_EMPTY_ID ? null : item.id
        if (
          item.__typename === TASK_TYPE_NAME &&
          'parentId' in curr &&
          curr.parentId === parentId
        ) {
          if (child.itemsCount) {
            const subtaskIndexes = items.value.map((i) => {
              if ('parentId' in i && i.parentId === child.id) {
                return {
                  ...i,
                  index: itemsMapping.value.id[i.id].index,
                }
              }
            })
            acc.push(...(subtaskIndexes as SpreadsheetRow[]))
          }

          acc.push(child)
        }

        if (
          item.__typename === SECTION_TYPE_NAME &&
          'sectionId' in curr &&
          curr.sectionId === item.id
        ) {
          acc.push(child)
        }

        return acc
      }, [] as SpreadsheetRow[])
      const maxChild = maxBy(children, 'index')
      if (maxChild) {
        scrollerRef.value?.scrollToItem(maxChild.index)
        return setActive(maxChild.id, placement)
      }
    }

    scrollToItemId(item.id)
    setActive(item.id, placement)
  }

  const showCreateGroupRow = (id: string, placement: RootAddPlacement) => {
    const item = itemsMapping.value.id[id]
    const reversePlacement = placement === 'bottom' ? 'top' : 'left'
    if (!item) {
      return setActive(id, placement)
    }

    if (['bottom', 'right'].includes(placement)) {
      if (item.__typename === SECTION_TYPE_NAME) {
        const nextItem = items.value.find(
          (i, index) => i.__typename === SECTION_TYPE_NAME && index > item.index
        )
        if (nextItem) {
          scrollToItemId(nextItem.id)
          return setActive(nextItem.id, reversePlacement)
        }
      }

      if (item.__typename === TASK_TYPE_NAME && 'level' in item) {
        const nextItem = items.value.find((i, index) => {
          return (
            'level' in i &&
            i.level === item.level &&
            i.__typename === TASK_TYPE_NAME &&
            index > item.index
          )
        })
        if (nextItem) {
          scrollToItemId(nextItem.id)
          return setActive(nextItem.id, reversePlacement)
        }
      }
    }

    scrollToItemId(id)
    setActive(id, placement)
  }

  const resetAllToUnchecked = () => {
    Object.keys(selectTree.value).forEach((id) => {
      selectTree.value[id].state = CheckboxState.UNCHECKED
    })
  }

  const getSelectedIds = () => {
    const selectedIds = new Set<string>()
    items.value.forEach((item) => {
      if (isItemChecked(item.id)) {
        selectedIds.add(item.id)
      }
    })

    return selectedIds
  }

  const getSelectedTaskIds = () => {
    const selectedIds = getSelectedIds()

    return items.value.reduce((selectedTaskIds, item) => {
      if (selectedIds.has(item.id) && item.__typename === TASK_TYPE_NAME) {
        selectedTaskIds.add(item.id)
      }

      return selectedTaskIds
    }, new Set<string>())
  }

  const getSelectedTasks = () => {
    const selectedTaskIds = getSelectedTaskIds()
    return items.value.filter((item) => selectedTaskIds.has(item.id))
  }
  /**
   * Select checkbox logic - End
   */

  return {
    activeView,
    boardId,
    tableColumnWidth,
    getTableColumnWidth,
    updateSelectedTasksLabel,

    // Shared state setup
    items,
    taskItems,
    itemsMapping,
    columns,
    setup,

    // Relative get cell
    getRelativeTask,
    getRelativeColumn,

    // Expand tree
    expandedIds,
    resetExpandedIds,
    isExpanded,
    toggleExpand,
    setExpanded,
    getIsExpandedRef,

    // Select checkbox
    selectedCount,
    selectedTaskIds,
    getSelectState,
    getSelectStateRef,
    setSelectState,
    isItemChecked,
    getIsItemCheckedRef,
    resetSelectTree,
    fillSelectTree,
    getSelectedTaskIds,
    getSelectedTasks,
    resetAllToUnchecked,

    // Create row placeholder
    lastCreatedTask,
    scrollerRef,
    showCreateTaskRow,
    showCreateGroupRow,
    scrollToItemId,
  }
}

export const useListViewSharedState = createSharedComposable(
  $useListViewSharedState
)

export const useListViewTasksLoader = () => {
  const { activeView } = useListViewSharedState()
  if (BoardViewValues.includes(activeView.value)) {
    return useBoardTasksLoader()
  }

  return useExplorerTasksLoader()
}
