import type { DraggingState } from '#core/types'
import { FieldType } from '#field/constant'
import type { SpreadsheetColumnType, ActiveSpreadsheetView, ColumnType as TaskColumnType } from '#task/types'

const $useCurrentDraggingTask = () => {
  const currentDraggingTask = reactive<Partial<DraggingState>>({})

  return {
    currentDraggingTask,
  }
}

export const useCurrentDraggingTask = createSharedComposable(
  $useCurrentDraggingTask
)

const $useTaskColumnFields = (params: {
  activeView: ActiveSpreadsheetView
}) => {
  const { viewOptions } = useBoardSharedState()

  const defaultColumns = [
    {
      key: 'name',
      label: 'Task name',
      lock: true
    },
    {
      key: FieldType.STATUS,
      label: 'Status',
      field: FieldType.STATUS,
      isFixedType: true,
    },
    {
      key: FieldType.TIME_RANGE,
      label: 'Dates',
      field: FieldType.TIME_RANGE,
      isFixedType: true,
    },
    {
      key: FieldType.BOARD,
      label: 'Board',
      field: FieldType.BOARD,
      isFixedType: true,
    },
    {
      key: FieldType.LABEL,
      label: 'Labels',
      field: FieldType.LABEL,
      isFixedType: true,
    },
  ]

  const columns = computed(() => {
    const listSortableFields = [
      FieldType.TEXT,
      FieldType.NUMBER,
      FieldType.DATE,
      FieldType.DROPDOWN,
      FieldType.STATUS,
      FieldType.ASSIGNEE,
      FieldType.TIME_RANGE,
    ]
    const viewOptionsList = viewOptions.value?.[params.activeView]

    const mapColumn = (column: SpreadsheetColumnType<TaskColumnType>) => ({
      ...column,
      isFixedType: column?.isFixedType ?? false,
      isHidden: viewOptionsList?.hidden?.includes(column?.key as string),
      isSortable: listSortableFields.includes(column?.field as FieldType),
      width: viewOptionsList?.width?.find(
        (field) => field.field === column?.key
      )?.value,
      direction:
        viewOptionsList?.sortBy?.field === column?.key
          ? viewOptionsList?.sortBy?.value
          : undefined,
      settingsPack: column?.settingsPack,
    })

    if (viewOptionsList?.order) {
      const leftOverColumns = defaultColumns.reduce((acc, column) => {
        if (!viewOptionsList?.order?.includes(column.key)) {
          acc.push(mapColumn(column))
        }

        return acc
      }, [] as SpreadsheetColumnType<TaskColumnType>[])

      const viewOptionsColumns = viewOptionsList?.order.reduce(
        (acc: SpreadsheetColumnType<TaskColumnType>[], key: string) => {
          const column = defaultColumns.find((field) => field.key === key)
          if (column) acc.push(mapColumn(column))

          return acc
        },
        []
      )

      return [...viewOptionsColumns, ...leftOverColumns]
    }

    return defaultColumns.map(mapColumn)
  })

  return {
    columns
  }
}

export const useTaskColumnFields = createSharedComposable(
  $useTaskColumnFields
)
