<template>
  <div
    v-if="!disableChangeSection"
    class="space-y-3 mt-3"
    @click.prevent.stop
  >
    <DefineListItem v-slot="{ item, onClick, isSelected, isCurrent }">
      <div class="px-2 py-1.5 cursor-pointer hover:bg-gray-100 rounded-md" @click="onClick(item)">
        <div class="flex items-center gap-2 justify-between" data-test="menu-item">
          <div class="flex items-center gap-2">
            <div
              v-if="item.backgroundImage"
              :style="{
                backgroundImage: `url(${getBackgroundUrl(
                  item,
                  BackgroundImageQuality.THUMB
                )})`,
              }"
              class="size-5 rounded-md border bg-center bg-cover min-w-5"
            />
            <span class="flex gap-2 text-sm">
              <div class="line-clamp-1 break-all">{{ item.name }}</div>
              <div v-if="isCurrent" class="px-2">
                (Current)
              </div>
            </span>
          </div>

          <div v-if="isSelected">
            <Icon name="i-heroicons-check-20-solid" class="size-4 text-gray-900" />
          </div>
        </div>
      </div>
    </DefineListItem>

    <div>
      <p class="text-sm text-gray-700 mb-1 text-start">Select board</p>
      <UPopover
        ref="boardPopoverRef"
        v-model:open="boardPopoverOpen"
        :ui="popoverUi"
        :popper="popoverPopper"
        @update:open="onUpdateOpen"
      >
        <template #panel>
          <div class="p-2">
            <UInput
              v-model="searchText"
              icon="i-heroicons-magnifying-glass"
              placeholder="Find a board"
              autofocus
              data-test="board-search-input"
            />
          </div>
          <div class="flex flex-col mt-1 pl-1 pr-0.5 max-h-40 overflow-y-auto scroll-stable minimal-scrollbar hide-scrollbar pb-1">
            <ReuseListItem
              v-for="board in filteredBoards"
              :key="board.id"
              :item="{ ...board, backgroundImage: true }"
              :is-selected="board.id === selectedBoard"
              :is-current="board.id === task.boardId"
              :on-click="onSelectBoard"
            />
          </div>
        </template>
        <UButton
          color="gray" size="sm" class=" w-full flex justify-between px-2.5 py-1.5 !shadow-sm bg-white active:ring-2 active:ring-primary-500"
          trailing-icon="i-heroicons:chevron-down-20-solid"
          :ui="{
            icon: {
              base: 'text-gray-400 dark:text-gray-500',
            },
          }"
          data-test="board-move-menu"
          @click.prevent.stop="onOpenBoardPopover"
        >
          <div class="line-clamp-1 break-all text-gray-900 text-sm font-normal">
            {{ selectedBoardName }}
          </div>
        </UButton>
      </UPopover>
    </div>
    <div>
      <p class="text-sm text-gray-700 mb-1 text-start">Section</p>

      <UPopover
        v-model:open="sectionPopoverOpen"
        :ui="popoverUi"
        :popper="popoverPopper"
        @update:open="onUpdateOpen"
      >
        <template #panel>
          <div class="p-2">
            <UInput
              v-model="searchText"
              icon="i-heroicons-magnifying-glass"
              placeholder="Find a section"
              autofocus
              data-test="board-search-input"
            />
          </div>
          <div class="flex flex-col mt-1 pl-1 pr-0.5 max-h-40 overflow-y-auto scroll-stable minimal-scrollbar hide-scrollbar pb-1">
            <ReuseListItem
              v-for="section in filteredSections"
              :key="section.id"
              :item="section"
              :is-selected="section.id === selectedSection"
              :is-current="section.id === task.sectionId"
              :on-click="onSelectSection"
            />
          </div>
        </template>
        <UButton
          color="gray" size="sm" class=" w-full flex justify-between px-2.5 py-1.5 !shadow-sm bg-white active:ring-2 active:ring-primary-500"
          trailing-icon="i-heroicons:chevron-down-20-solid"
          :ui="{
            icon: {
              base: 'text-gray-400 dark:text-gray-500',
            },
          }"
          data-test="task-section-menu"
          @click.prevent.stop="onOpenSectionPopover"
        >
          <div class="line-clamp-1 break-all text-gray-900 text-sm font-normal">
            {{ selectedSectionName }}
          </div>
        </UButton>
      </UPopover>
    </div>
  </div>
  <slot
    name="footer"
    :state="{
      canMove,
      selectedBoard,
      selectedSection,
      selectedPosition,
      boards,
      selectedBoardName,
      selectedSectionName,
    }"
  />
</template>

<script lang="ts" setup>
import { filter, first, map } from 'lodash-es'
import type { SectionItem } from '#section/types'
import type { CopyMoveTaskItem } from '#task/types'
import { BackgroundImageQuality } from '#board/constant'
import type { BaseBoard } from '#board/types'

const props = defineProps({
  task: {
    type: Object as PropType<CopyMoveTaskItem>,
    required: true,
  },
  workspaceId: {
    type: String,
    required: true,
  },
  disableChangeSection: {
    type: Boolean,
    default: false,
  },
  uiMenu: {
    type: Object as PropType<{
      container: string
    }>,
    default: () => ({}),
  },
})
const { can } = useWorkspaceAbility()

const accessAllBoard = computed(() => can('dashboard.boards.manage_boards'))
const { result: workspaceBoardResult, load: loadWorkspaceBoard } =
  useListWorkspaceBoardsLazyQuery({
    workspaceId: props.workspaceId,
    exceptBaseLevels: accessAllBoard.value ? [] : ['GUEST'],
  })
const [DefineListItem, ReuseListItem] = createReusableTemplate()

const {
  result: sectionsResult,
  load: loadSections,
  onResult: onSectionsResult,
} = useSectionsLazyQuery()
const { currentBoard } = useWorkspaceSharedState()

const popoverUi = {
  width: 'w-64',
  transition: {
    enterActiveClass: 'duration-0',
  },
  trigger: 'flex ',
  base: '!absolute !z-50 top-[120%]',
}

const popoverPopper = {
  placement: 'bottom-start',
} as const

const boardPopoverOpen = ref(false)
const selectedBoard = ref(props.task.boardId)
const selectedSection = ref(props.task.sectionId)
const searchText = ref('')
const sectionPopoverOpen = ref(false)
const boardPopoverRef = ref()
let lastUpdate = 0

const selectedBoardName = computed(() => {
  const board = boards.value.find((b) => b.id === selectedBoard.value)
  return board?.name
})
const selectedSectionName = computed(() => {
  const section = sections.value.find((s) => s.id === selectedSection.value)
  return section?.name
})

const selectedPosition = ref(1)
const sections = ref<SectionItem[]>([])

const boards = computed(() => workspaceBoardResult.value?.boards ?? [currentBoard.value])

const filteredSections = computed(() => {
  return filter(sections.value, (section) => {
    return section.name.toLowerCase().includes(searchText.value.toLowerCase())
  })
})

const filteredBoards = computed(() => {
  return filter(boards.value, (board) => {
    return board.name.toLowerCase().includes(searchText.value.toLowerCase())
  })
})

const canMove = computed(
  () => selectedBoard.value && selectedSection.value && selectedPosition.value
)

onSectionsResult(() => {
  if (sectionsResult.value) {
    sections.value = sectionsResult.value.sections || []

    const isCurrentSection = map(sections.value, 'id').includes(
      props.task.sectionId
    )
    selectedSection.value = isCurrentSection
      ? props.task.sectionId
      : first(sections.value)?.id || ''
  }
})

const onSelectBoard = (board: BaseBoard) => {
  selectedBoard.value = board.id
  boardPopoverOpen.value = false
}

const onSelectSection = (section: SectionItem) => {
  selectedSection.value = section.id
  sectionPopoverOpen.value = false
}

const onOpenBoardPopover = () => {
  if (Date.now() - lastUpdate < 200) return

  boardPopoverOpen.value = true
}

const onOpenSectionPopover = () => {
  if (Date.now() - lastUpdate < 200) return

  sectionPopoverOpen.value = true
}

const onUpdateOpen = () => {
  lastUpdate = Date.now()
}

watch(
  () => selectedBoard.value,
  async (boardId) => {
    if (boardId) {
      loadSections(null, {
        boardId,
      })
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  loadWorkspaceBoard()
})
</script>
