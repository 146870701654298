import { SETTINGS_PACK_FRAGMENT } from '#settings-pack/fragment'
import {
  BOARD_BASE_FRAGMENT,
  BOARD_MEMBER_OPTIONS_FRAGMENT,
  BOARD_SETTINGS_FRAGMENT,
} from '#board/fragment'
import { FIELD_FRAGMENT } from '#field/fragment'

export const BOARD_QUERY = gql`
  ${BOARD_BASE_FRAGMENT}
  query BoardQuery($handle: String!) {
    board(handle: $handle) @localPersist {
      ...BaseBoard
      workspace {
        id
      }
    }
  }
`

export const BOARD_FIELDS_QUERY = gql`
  ${FIELD_FRAGMENT}
  query BoardFulfilledFieldsQuery($boardId: ID!) {
    boardFulfilledFields(boardId: $boardId) {
      ...Field
    }
  }
`

export const BOARD_MEMBER_OPTIONS_QUERY = gql`
  query BoardMemberOptionsQuery($boardId: ID!) {
    boardMemberOptions(boardId: $boardId) @localPersist {
      ...BoardMemberOptionsFields
    }
  }
  ${BOARD_MEMBER_OPTIONS_FRAGMENT}
`

export const BOARD_SETTINGS_PACKS_QUERY = gql`
  ${SETTINGS_PACK_FRAGMENT}
  query BoardSettingPacksQuery($filter: BoardSettingsPackFilterInput!) {
    boardSettingsPacks(filter: $filter) {
      settingsPack {
        ...SettingsPack
      }
      total
    }
  }
`

export const ADD_UPDATE_BOARD_MUTATION = gql`
  ${BOARD_BASE_FRAGMENT}
  mutation AddUpdateBoardMutation($input: AddUpdateBoardInput!) {
    addUpdateBoard(input: $input) {
      board {
        ...BaseBoard
      }
      errors {
        field
        messages
      }
    }
  }
`

export const TOGGLE_BOARD_STAR_MUTATION = gql`
  ${BOARD_MEMBER_OPTIONS_FRAGMENT}
  mutation ToggleBoardStarMutation($boardId: ID!, $star: Boolean!) {
    toggleStarBoard(boardId: $boardId, star: $star) {
      boardMember {
        ...BoardMemberOptionsFields
      }
    }
  }
`

export const GROUP_BY_BOARD_MUTATION = gql`
  ${BOARD_MEMBER_OPTIONS_FRAGMENT}
  mutation GroupByMutation($boardId: ID!, $groupBy: String!) {
    groupByBoard(boardId: $boardId, groupBy: $groupBy) {
      boardMember {
        ...BoardMemberOptionsFields
      }
    }
  }
`

export const SET_ACTIVE_BOARD_MUTATION = gql`
  ${BOARD_MEMBER_OPTIONS_FRAGMENT}
  mutation SetActiveBoardMutation($boardId: ID!) {
    setActiveBoard(boardId: $boardId) {
      boardMember {
        ...BoardMemberOptionsFields
      }
    }
  }
`

export const UPDATE_TASK_VIEW_OPTIONS_MUTATION = gql`
  ${BOARD_MEMBER_OPTIONS_FRAGMENT}
  mutation UpdateTaskViewOptionsMutation(
    $boardId: ID!
    $viewOptions: JSONString!
  ) {
    updateTaskViewOptions(boardId: $boardId, viewOptions: $viewOptions) {
      boardMember {
        ...BoardMemberOptionsFields
      }
    }
  }
`

export const COUNT_BOARDS_QUERY = gql`
  query CountBoardsQuery($workspaceId: ID!) {
    countBoards(workspaceId: $workspaceId)
  }
`

export const BOARD_SETTINGS_QUERY = gql`
  ${BOARD_SETTINGS_FRAGMENT}
  query BoardQuery($id: ID!) {
    board(id: $id) {
      ...BoardSettings
    }
  }
`

export const TOGGLE_BOARD_LINK_INVITATION_MUTATION = gql`
  ${BOARD_SETTINGS_FRAGMENT}
  mutation ToggleBoardLinkInvitation($id: ID!, $enable: Boolean!) {
    toggleBoardLinkInvitation(id: $id, enable: $enable) {
      board {
        ...BoardSettings
      }
    }
  }
`

export const LEAVE_BOARD_MUTATION = gql`
  mutation LeaveBoardMutation($boardId: ID!) {
    leaveBoard(boardId: $boardId) {
      success
      error
    }
  }
`

export const OPEN_BOARD_MUTATION = gql`
  ${BOARD_BASE_FRAGMENT}
  mutation OpenBoardMutation($boardId: ID!) {
    openBoard(boardId: $boardId) {
      board {
        ...BaseBoard
      }
    }
  }
`

export const CLOSE_BOARD_MUTATION = gql`
  ${BOARD_BASE_FRAGMENT}
  mutation CloseBoardMutation($boardId: ID!, $hardDelete: Boolean!) {
    closeBoard(boardId: $boardId, hardDelete: $hardDelete) {
      board {
        ...BaseBoard
      }
      hardDeleted
    }
  }
`

export const JOIN_BOARD_MUTATION = gql`
  mutation JoinBoardMutation($boardId: ID!) {
    joinBoard(boardId: $boardId) {
      success
    }
  }
`

export const BACKGROUND_PHOTO_QUERY = gql`
  query BackgroundPhotos($page: Int, $perPage: Int, $search: String) {
    backgroundPhotos(page: $page, perPage: $perPage, search: $search) {
      id
      width
      height
      color
      description
      altDescription
      urls {
        raw
        full
        regular
        small
        thumb
      }
      links {
        self
        html
        download
        downloadLocation
      }
    }
  }
`

export const EXPORT_BOARD_MUTATION = gql`
  mutation ExportBoardMutation($boardId: ID!, $option: ExportOptionsInput!) {
    exportBoard(boardId: $boardId, option: $option) {
      success
      isSendViaEmail
      downloadUrl
    }
  }
`

export const PREDICT_FIELD_TYPES_FOR_IMPORT_MUTATION = gql`
  mutation PredictFieldTypesForImportMutation($file: Upload!, $workspaceId: ID!) {
    predictFieldTypeImport(file: $file, workspaceId: $workspaceId) {
      success
      predictedFields {
        columnName
        type
        originType
        key
        ignoreTypes
        isDefaultField
      }
      ignoreFields
    }
  }
`

export const IMPORT_TASK_MUTATION = gql`
  mutation ImportTaskMutation($boardId: ID!, $file: Upload!, $matchFields: [MatchFieldsImportInput!]!, $totalTasks: Int!) {
    importTask(boardId: $boardId, file: $file, matchFields: $matchFields, totalTasks: $totalTasks) {
      success
    }
  }
`

export const UPDATE_CUSTOM_BACKGROUND_MUTATION = gql`
    ${BOARD_SETTINGS_FRAGMENT}
    mutation UpdateCustomBackgroundMutation($boardId: ID!, $file: Upload!) {
      updateCustomBackground(boardId: $boardId, file: $file) {
        board {
          ...BoardSettings
        }
      }
    }
  `
