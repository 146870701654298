<template>
  <DeferredPopover v-model:open="popoverOpen" @update:open="onUpdateOpen">
    <template #content="{ open }">
      <PopoverContentTaskDatePicker
        v-if="open"
        ref="datePickerRef"
        :start-date="startDate"
        :due-date="dueDate"
        @change="$emit('change', $event)"
        @close="popoverOpen = false"
      />
    </template>
    <slot />
  </DeferredPopover>
</template>

<script lang="ts" setup>
defineProps({
  startDate: {
    type: String,
  },
  dueDate: {
    type: String,
  },
})

const emit = defineEmits(['change', 'update:open'])

const popoverOpen = ref(false)
const datePickerRef = ref()

const onUpdateOpen = (value: boolean) => {
  popoverOpen.value = value
  emit('update:open', value)
}

defineExpose({
  open: () => {
    popoverOpen.value = true
  },
})

defineShortcuts({
  enter: {
    whenever: [popoverOpen],
    usingInput: false,
    handler: () => {
      datePickerRef.value.change()
      popoverOpen.value = false
    },
  },
})
</script>
