<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.59961 8.0001C1.59961 9.69748 2.27389 11.3253 3.47413 12.5256C4.67436 13.7258 6.30222 14.4001 7.99961 14.4001C9.69699 14.4001 11.3249 13.7258 12.5251 12.5256C13.7253 11.3253 14.3996 9.69748 14.3996 8.0001C14.3996 6.30271 13.7253 4.67485 12.5251 3.47462C11.3249 2.27438 9.69699 1.6001 7.99961 1.6001C6.30222 1.6001 4.67436 2.27438 3.47413 3.47462C2.27389 4.67485 1.59961 6.30271 1.59961 8.0001Z"
      stroke="#EAB308"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.80078 7.9998C4.80078 8.8485 5.13792 9.66243 5.73804 10.2625C6.33816 10.8627 7.15209 11.1998 8.00078 11.1998C8.84947 11.1998 9.66341 10.8627 10.2635 10.2625C10.8636 9.66243 11.2008 8.8485 11.2008 7.9998C11.2008 7.15111 10.8636 6.33718 10.2635 5.73706C9.66341 5.13695 8.84947 4.7998 8.00078 4.7998C7.15209 4.7998 6.33816 5.13695 5.73804 5.73706C5.13792 6.33718 4.80078 7.15111 4.80078 7.9998Z"
      fill="#EAB308"
    />
  </svg>
</template>
