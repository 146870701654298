<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
  >
    <circle cx="1.5" cy="2" r="1.5" fill="#71717A" />
    <circle cx="6.5" cy="2" r="1.5" fill="#71717A" />
    <circle cx="1.5" cy="7" r="1.5" fill="#71717A" />
    <circle cx="6.5" cy="7" r="1.5" fill="#71717A" />
    <circle cx="1.5" cy="12" r="1.5" fill="#71717A" />
    <circle cx="6.5" cy="12" r="1.5" fill="#71717A" />
  </svg>
</template>
