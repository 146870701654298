<template>
  <div class="pl-16 py-6 pr-6 min-h-96">
    <div class="grid grid-cols-3">
      <div class="col-span-3">
        <USkeleton class="h-8 w-20" />
      </div>
      <div class="col-span-2">
        <USkeleton class="h-8 w-96 mt-4" />
        <USkeleton class="h-6 w-full mt-6" />
        <USkeleton class="h-6 w-72 mt-2" />
        <USkeleton class="h-6 w-24 mt-2" />
      </div>
      <div class="col-span-1 flex flex-col items-end gap-2 pt-6">
        <USkeleton class="h-8 w-44" />
        <USkeleton class="h-8 w-44" />
        <USkeleton class="h-8 w-44" />
      </div>
    </div>
  </div>
</template>
