/* eslint-disable no-console */
const loggerHandler = {
  info: console.info,
  warn: console.warn,
  error: console.error,
  debug: console.debug,
  log: console.log,
  time: console.time,
  timeEnd: console.timeEnd,
}

export const logger = new Proxy(loggerHandler, {
  get(target, prop: keyof typeof loggerHandler) {
    const isDebug = useNuxtApp().$isDebug
    if (prop in target && isDebug) {
      return target[prop]
    }

    return () => {}
  },
})
