import { Icon } from '#components'
import TaskIcon from '#core/components/icon/Task.vue'
import StatusIcon from '#core/components/icon/Status.vue'
import type { ColorPreset } from '#core/types'

export const INDEX_STEP = 1099511627776 // 2^50

export const SCROLL_TYPE_ALLOW_LIST = new Set(['task', 'section'])

export const MIN_COLUMN_RESIZER_WIDTH = 140
export const MAX_COLUMN_RESIZER_WIDTH = 540

export const DRAG_SCROLL_ENABLED_ATTRIBUTE = 'data-drag-scroll-enabled'
export const DRAG_SCROLL_DISABLED_ATTRIBUTE = 'data-drag-scroll-disabled'

export const TimePickPresets = [
  {
    label: '12:00am',
    value: '00:00',
  },
  {
    label: '12:30am',
    value: '00:30',
  },
  {
    label: '1:00am',
    value: '01:00',
  },
  {
    label: '1:30am',
    value: '01:30',
  },
  {
    label: '2:00am',
    value: '02:00',
  },
  {
    label: '2:30am',
    value: '02:30',
  },
  {
    label: '3:00am',
    value: '03:00',
  },
  {
    label: '3:30am',
    value: '03:30',
  },
  {
    label: '4:00am',
    value: '04:00',
  },
  {
    label: '4:30am',
    value: '04:30',
  },
  {
    label: '5:00am',
    value: '05:00',
  },
  {
    label: '5:30am',
    value: '05:30',
  },
  {
    label: '6:00am',
    value: '06:00',
  },
  {
    label: '6:30am',
    value: '06:30',
  },
  {
    label: '7:00am',
    value: '07:00',
  },
  {
    label: '7:30am',
    value: '07:30',
  },
  {
    label: '8:00am',
    value: '08:00',
  },
  {
    label: '8:30am',
    value: '08:30',
  },
  {
    label: '9:00am',
    value: '09:00',
  },
  {
    label: '9:30am',
    value: '09:30',
  },
  {
    label: '10:00am',
    value: '10:00',
  },
  {
    label: '10:30am',
    value: '10:30',
  },
  {
    label: '11:00am',
    value: '11:00',
  },
  {
    label: '11:30am',
    value: '11:30',
  },
  {
    label: '12:00pm',
    value: '12:00',
  },
  {
    label: '12:30pm',
    value: '12:30',
  },
  {
    label: '1:00pm',
    value: '13:00',
  },
  {
    label: '1:30pm',
    value: '13:30',
  },
  {
    label: '2:00pm',
    value: '14:00',
  },
  {
    label: '2:30pm',
    value: '14:30',
  },
  {
    label: '3:00pm',
    value: '15:00',
  },
  {
    label: '3:30pm',
    value: '15:30',
  },
  {
    label: '4:00pm',
    value: '16:00',
  },
  {
    label: '4:30pm',
    value: '16:30',
  },
  {
    label: '5:00pm',
    value: '17:00',
  },
  {
    label: '5:30pm',
    value: '17:30',
  },
  {
    label: '6:00pm',
    value: '18:00',
  },
  {
    label: '6:30pm',
    value: '18:30',
  },
  {
    label: '7:00pm',
    value: '19:00',
  },
  {
    label: '7:30pm',
    value: '19:30',
  },
  {
    label: '8:00pm',
    value: '20:00',
  },
  {
    label: '8:30pm',
    value: '20:30',
  },
  {
    label: '9:00pm',
    value: '21:00',
  },
  {
    label: '9:30pm',
    value: '21:30',
  },
  {
    label: '10:00pm',
    value: '22:00',
  },
  {
    label: '10:30pm',
    value: '22:30',
  },
  {
    label: '11:00pm',
    value: '23:00',
  },
  {
    label: '11:30pm',
    value: '23:30',
  },
]

export const settingItems = [
  {
    name: 'taskTypes',
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-500',
    icon: TaskIcon,
  },
  {
    name: 'statuses',
    bgColor: 'bg-yellow-50',
    textColor: 'text-yellow-500',
    icon: StatusIcon,
  },
  {
    name: 'labels',
    bgColor: 'bg-green-50',
    textColor: 'text-green-500',
    icon: h(Icon, {
      name: 'heroicons:tag-solid',
      size: '16',
      class: 'text-green-500',
    }),
  },
  {
    name: 'automations',
    bgColor: 'bg-orange-50',
    textColor: 'text-orange-500',
    icon: h(Icon, {
      name: 'heroicons:bolt-solid',
      size: '16',
      class: 'text-green-500',
    }),
  }
]

export const ColorPresets: ColorPreset[] = [
  {
    name: 'light-green',
    backgroundColor: '#B4F5D2',
    color: '#142720',
  },
  {
    name: 'light-blue',
    backgroundColor: '#BAE6FD',
    color: '#1B262C',
  },
  {
    name: 'light-yelllow',
    backgroundColor: '#FEF08A',
    color: '#261E17',
  },
  {
    name: 'light-orange',
    backgroundColor: '#FED7AA',
    color: '#7C2D12',
  },
  {
    name: 'light-purple',
    backgroundColor: '#DDD6FE',
    color: '#4C1D95',
  },
  {
    name: 'light-red',
    backgroundColor: '#FECACA',
    color: '#7F1D1D',
  },
  {
    name: 'light-gray',
    backgroundColor: '#E4E4E7',
    color: '#18181B',
  },
  {
    name: 'green',
    backgroundColor: '#00C16A',
    color: '#142720',
  },
  {
    name: 'blue',
    backgroundColor: '#38BDF8',
    color: '#1B262C',
  },
  {
    name: 'yellow',
    backgroundColor: '#FACC15',
    color: '#261E17',
  },
  {
    name: 'orange',
    backgroundColor: '#FB923C',
    color: '#431407',
  },
  {
    name: 'purple',
    backgroundColor: '#A78BFA',
    color: '#211F33',
  },
  {
    name: 'red',
    backgroundColor: '#F87171',
    color: '#450A0A',
  },
  {
    name: 'gray',
    backgroundColor: '#A1A1AA',
    color: '#09090B',
  },
  {
    name: 'dark-green',
    backgroundColor: '#007F45',
    color: '#D9FBE8',
  },
  {
    name: 'dark-blue',
    backgroundColor: '#028EDE',
    color: '#E0F2FE',
  },
  {
    name: 'dark-yellow',
    backgroundColor: '#CA8A04',
    color: '#FEF9C3',
  },
  {
    name: 'dark-orange',
    backgroundColor: '#EA580C',
    color: '#FFEDD5',
  },
  {
    name: 'dark-purple',
    backgroundColor: '#7C3AED',
    color: '#EDE9FE',
  },
  {
    name: 'dark-red',
    backgroundColor: '#DC2626',
    color: '#FEE2E2',
  },
  {
    name: 'dark-gray',
    backgroundColor: '#3F3F46',
    color: '#F4F4F5',
  },
]

export enum UPLOAD_ROOT_PATH {
  USER_AVATAR = 'user_avatar',
  WORKSPACE_LOGO = 'workspace_logo',
  BOARD_BACKGROUND = 'board_background',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PLATFORM_HELP_LINKS {
  SAMPLE_CSV_IMPORT_FILE = 'https://drive.usercontent.google.com/u/2/uc?id=19RPTQ5zQViyOGUPt5WlsWsVGvHaOadoI&export=download',
  IMPORT_TASKS_HELP_DOC = 'https://wiki.leanflag.net/doc/how-to-import-and-export-in-leanbase-u4CGOb1aor',
  EXPORT_FROM_OTHER_TOOLS = 'https://example.com/1',
}

export enum UploadType {
  IMAGE = 'image',
  FILE = 'file',
}
