import type { TaskItemLoader } from '#task/types'

const $useExplorerTasksLoader = () => {
  const tasks: Ref<TaskItemLoader[]> = ref([])
  const loadTasks = () => {}

  return {
    tasks,
    loadTasks
  }
}

export const useExplorerTasksLoader = createSharedComposable(
  $useExplorerTasksLoader
)
