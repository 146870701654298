/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

import { BOARD_MIN_FRAGMENT } from '#board/fragment'
import { SECTION_FRAGMENT } from '#section/fragment'
import { STATUS_FRAGMENT } from '#status/fragment'

export const SECTION_LOADER_FRAGMENT = gql`
  ${SECTION_FRAGMENT}
  ${BOARD_MIN_FRAGMENT}
  ${STATUS_FRAGMENT}
  fragment SectionLoader on SectionType {
    ...Section
    position
    closed
    boardId
    board @client {
      ...Board
    }
    statusId
    status @client {
      ...Status
    }
  }
`
