export default defineNuxtPlugin((nuxtApp) => {
  const groups: Record<string, Set<Element>> = {}

  const onMouseEnter = (name: string, el: Element) => {
    requestAnimationFrame(() => {
      Array.from(groups[name] || [])
        .filter((e) => e !== el)
        .forEach((e) => {
          e.setAttribute('data-hover', name)
        })
    })
  }

  const onMouseLeave = (name: string) => {
    requestAnimationFrame(() => {
      if (groups[name]) {
        Array.from(groups[name] || []).forEach((e) => {
          e.removeAttribute('data-hover')
        })
      }
    })
  }

  const subscribeElement = (name: string, el: Element) => {
    if (name) {
      if (!groups[name]) {
        groups[name] = new Set()
      }

      if (!groups[name].has(el)) {
        groups[name].add(el)
        el.addEventListener('mouseenter', onMouseEnter.bind(null, name, el), {
          passive: true,
        })
        el.addEventListener('mouseleave', onMouseLeave.bind(null, name))
      }
    }
  }

  const unsubscribeElement = (name: string, el: Element) => {
    if (name && groups[name]) {
      groups[name].forEach((e) => {
        if (e === el) {
          e.removeEventListener('mouseenter', onMouseEnter.bind(null, name, el))
          e.removeEventListener('mouseleave', onMouseLeave.bind(null, name))
          groups[name].delete(e)
        }
      })
    }
  }

  nuxtApp.vueApp.directive('sync-hover', {
    mounted(el, binding) {
      subscribeElement(binding.value, el)
    },
    unmounted(el, binding) {
      unsubscribeElement(binding.value, el)
    },
    getSSRProps() {
      return {}
    },
  })
})
