import { map } from 'lodash-es'
import type { SidebarActionGroup, TaskDetail, TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'
import type { Label } from '#label/types'
import TaskAssigneePopover from '#task/components/TaskAssigneePopover.vue'
import TaskDatePickerPopover from '#task/components/TaskDatePickerPopover.vue'
import LabelListPopover from '#label/components/LabelListPopover.vue'
import TaskAttachmentPopover from '#task/components/TaskAttachmentPopover.vue'
import TaskCoverPopover from '#task/components/TaskCoverPopover.vue'
import TaskListModuleSelectPopover from '#task/components/TaskListModuleSelectPopover.vue'
import TaskMovePopover from '#task/components/TaskMovePopover.vue'
import TaskCopyPopover from '#task/components/TaskCopyPopover.vue'
import TaskSharePopover from '#task/components/TaskSharePopover.vue'
import type { AutomationRuleFilter } from '~/layers/automation/types'

enum SidebarActionKey {
  ASSIGNEE = 'Assignee',
  DATES = 'Dates',
  LABELS = 'Labels',
  DEPENDENCIES = 'Dependencies',
  SUBTASKS = 'Subtasks',
  ATTACHMENT = 'Attachment',
  COVER = 'Cover',
  CONVERT_TO_TASK = 'ConvertToTask',
  TASK_MODULE = 'TaskModule',
  MOVE = 'Move',
  COPY = 'Copy',
  SHARE = 'Share',
  ARCHIVE = 'Archive',
  DELETE = 'Delete',
  SEND_TO_BOARD = 'SendToBoard',
  AUTOMATION = 'Automation',
}

export const useTaskDetailSidebar = (
  props: { taskDetail: TaskDetail },
  emit: {
    (e: 'close'): void
    (e: 'click-dependency'): void
    (e: 'click-subtasks'): void
  }
) => {
  const toast = useToast()
  const modal = useModal()
  const { currentWorkspace } = useWorkspaceSharedState()
  const { updateTask, toggleLabels } = useUpdateTask()
  const { deleteTask } = useDeleteTask()
  const { can } = useBoardAbility()
  const {
    load: loadAutomation,
    onResult: onAutomationResult,
    refetch: refetchAutomation,
    stop: stopGetAutomation,
  } = useLazyAutomationRulesQuery({
    taskId: props.taskDetail.id,
    isActive: true,
    isButton: true
  } as AutomationRuleFilter)
  const { boardData } = useBoardSharedState()

  const { archiveTask } = useArchiveTask()

  const assigneePopoverRef = ref()
  const labelPopoverRef = ref()
  const datesPopoverRef = ref()
  const taskModuleRef = ref()
  const attachmentPopoverRef = ref()
  const coverPopoverRef = ref()
  const movePopoverRef = ref()
  const copyPopoverRef = ref()
  const sharePopoverRef = ref()

  const openMoreSetting = ref(false)
  const isPopoverOpen = ref(false)
  const automationButtons = ref<{
    id: string,
    label: string,
    icon: string,
    settingsPack: string,
  }[]>([])

  const filteredLabels = computed(() =>
    props.taskDetail.labels?.filter((label) => label.id)
  )

  const actionGroupLists = computed<SidebarActionGroup[]>(() => {
    return [
      {
        title: 'Add to task',
        actions: [
          {
            key: SidebarActionKey.ASSIGNEE,
            ref: (ref) => {
              assigneePopoverRef.value = ref
            },
            icon: 'i-heroicons-user-circle',
            text: 'Assignee',
            tooltip: 'Assignee member',
            shortcuts: ['A'],
            component: TaskAssigneePopover,
            onClick: () => openPopover(assigneePopoverRef),
            popoverProps: {
              currentAssignee: props.taskDetail.assignee,
              boardId: props.taskDetail.boardId,
              onSelect: (assignee: string) =>
                updateTask(props.taskDetail.id, { assignee }),
              onRemove: () =>
                updateTask(props.taskDetail.id, { assignee: null }),
            },
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.DATES,
            ref: (ref) => {
              datesPopoverRef.value = ref
            },
            icon: 'i-heroicons-calendar',
            text: 'Due date',
            tooltip: 'Add due date',
            shortcuts: ['D'],
            onClick: () => openPopover(datesPopoverRef),
            component: TaskDatePickerPopover,
            popoverProps: {
              startDate: props.taskDetail.startDate,
              dueDate: props.taskDetail.dueDate,
              onSelect: (dates: { startDate: string; dueDate: string }) =>
                updateTask(props.taskDetail.id, dates),
            },
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.LABELS,
            ref: (ref) => {
              labelPopoverRef.value = ref
            },
            icon: 'i-heroicons-tag',
            text: 'Labels',
            tooltip: 'Add labels',
            shortcuts: ['L'],
            onClick: () => openPopover(labelPopoverRef),
            component: LabelListPopover,
            popoverProps: {
              source: 'board',
              selectedIds: map(filteredLabels.value, 'id'),
              parentId: props.taskDetail.boardId,
              onSelect: onToggleLabels,
              onRemove: onToggleLabels,
            },
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.DEPENDENCIES,
            icon: 'i-heroicons-link',
            text: 'Dependencies',
            tooltip: 'Add dependencies',
            shortcuts: ['P'],
            onClick: onAddDependencies,
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.SUBTASKS,
            icon: 'leanbase:subtask-outline',
            show: props.taskDetail.level !== TaskLevel.SUBTASK,
            text: props.taskDetail.level === TaskLevel.MODULE
              ? 'Children tasks'
              : 'Subtasks',
            disabled: !can('dashboard.data.managing_tasks_sections'),
            onClick: onAddSubtasks,
          },
          {
            key: SidebarActionKey.ATTACHMENT,
            icon: 'i-heroicons-paper-clip',
            text: 'Attachment',
            component: TaskAttachmentPopover,
            ref: (ref) => {
              attachmentPopoverRef.value = ref
            },
            onClick: () => openPopover(attachmentPopoverRef),
            popoverProps: {
              taskId: props.taskDetail.id,
            },
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.COVER,
            icon: 'i-heroicons-photo',
            text: 'Cover',
            component: TaskCoverPopover,
            onClick: () => openPopover(coverPopoverRef),
            ref: (ref) => {
              coverPopoverRef.value = ref
            },
            popoverProps: {
              taskId: props.taskDetail.id,
              cover: props.taskDetail.cover,
              attachments: props.taskDetail.attachments,
            },
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.CONVERT_TO_TASK,
            icon: 'heroicons:arrow-path',
            show: props.taskDetail.level === TaskLevel.SUBTASK,
            text: 'Convert to task',
            onClick: onConvertToTask,
          },
          {
            key: SidebarActionKey.TASK_MODULE,
            component: TaskListModuleSelectPopover,
            ref: (ref) => {
              taskModuleRef.value = ref
            },
            onClick: () => openPopover(taskModuleRef),
            popoverProps: {
              selectedTaskId: props.taskDetail.id,
              boardId: props.taskDetail.boardId,
              showUnlink: !!props.taskDetail.parentId,
              onChange: (parent: string) =>
                updateTask(props.taskDetail.id, { parent: parent || null }),
            },
            show: props.taskDetail.level === TaskLevel.TASK,
            icon: 'i-heroicons-cube',
            text: 'Set module',
            tooltip: 'Add module',
            shortcuts: ['S'],
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
        ],
      },
      {
        title: 'Automations',
        actions: automationButtons.value.map((button) => ({
          key: SidebarActionKey.AUTOMATION + '-' + button.label,
          icon: button.icon,
          text: button.label,
          disabled: !can('dashboard.data.managing_tasks_sections'),
          onClick: () => onExecuteAutomation(button.id),
          buttonProps: {
            innerTooltipText: button.settingsPack ? getSettingsPackName(button.settingsPack) : undefined
          }
        })),
      },
      {
        title: 'Actions',
        actions: [
          {
            key: SidebarActionKey.MOVE,
            icon: 'i-heroicons-arrow-right',
            text: 'Move',
            component: TaskMovePopover,
            show: props.taskDetail.level !== TaskLevel.SUBTASK,
            ref: (ref) => {
              movePopoverRef.value = ref
            },
            onClick: () => openPopover(movePopoverRef),
            popoverProps: {
              workspaceId: currentWorkspace.value.id,
              tasks: [props.taskDetail],
            },
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.COPY,
            icon: 'i-heroicons-document-duplicate',
            text: 'Copy',
            component: TaskCopyPopover,
            ref: (ref) => {
              copyPopoverRef.value = ref
            },
            onClick: () => openPopover(copyPopoverRef),
            popoverProps: {
              workspaceId: currentWorkspace.value.id,
              tasks: [props.taskDetail],
            },
            disabled: !can('dashboard.data.managing_tasks_sections'),
          },
          {
            key: SidebarActionKey.SHARE,
            icon: 'i-heroicons-share',
            text: 'Share',
            component: TaskSharePopover,
            popoverProps: {
              task: props.taskDetail,
            },
            ref: (ref) => {
              sharePopoverRef.value = ref
            },
            onClick: () => openPopover(sharePopoverRef),
          },
          {
            key: SidebarActionKey.ARCHIVE,
            icon: 'i-heroicons-archive-box-arrow-down',
            text: 'Archive',
            tooltip: 'Archive task',
            shortcuts: ['C'],
            onClick: onArchiveTask,
            disabled: !can('dashboard.data.managing_tasks_sections'),
            show:
              !props.taskDetail.closed &&
              !props.taskDetail.sectionClosed &&
              !props.taskDetail.parentClosed,
            buttonProps: {
              dataTest: 'btn-archive-task',
            },
          },
          {
            key: SidebarActionKey.SEND_TO_BOARD,
            icon: 'i-heroicons-arrow-uturn-left',
            text: 'Send to board',
            onClick: () => updateTask(props.taskDetail.id, { closed: false }),
            disabled:
              !can('dashboard.data.managing_tasks_sections') ||
              props.taskDetail.sectionClosed ||
              props.taskDetail.parentClosed,
            show:
              props.taskDetail.closed ||
              props.taskDetail.sectionClosed ||
              props.taskDetail.parentClosed,
          },
          {
            key: SidebarActionKey.DELETE,
            icon: 'i-heroicons-trash',
            text: 'Delete',
            onClick: onDeleteTask,
            disabled: !can('dashboard.data.managing_tasks_sections'),
            show:
              props.taskDetail.closed ||
              props.taskDetail.sectionClosed ||
              props.taskDetail.parentClosed,
            buttonProps: {
              color: 'red',
              variant: 'soft',
            },
          },
        ],
      },
    ]
  })

  onAutomationResult((result) => {
    if (result.data?.automationRules) {
      automationButtons.value = result.data.automationRules.map((rule) => ({
        id: rule.id,
        label: rule.name,
        icon: rule.actions[0].icon,
        settingsPack: rule.source === 'settings_pack' ? rule.parentId : '',
      }))
    }
  })

  const onToggleLabels = (label: Label) =>
    toggleLabels({
      taskIds: [props.taskDetail.id],
      labelIds: [label.id],
      detachLabelIds: [],
    })

  const onArchiveTask = () => {
    openMoreSetting.value = false
    archiveTask(props.taskDetail as unknown as TaskItem)
  }

  const onAddDependencies = () => {
    openMoreSetting.value = false
    emit('click-dependency')
  }

  const onAddSubtasks = () => {
    openMoreSetting.value = false
    emit('click-subtasks')
  }

  const onDeleteTask = async () => {
    const data = await deleteTask([props.taskDetail.id])
    if (!data?.data?.deleteTask.success) {
      return toast.add({
        title: 'Something went wrong',
        color: 'red',
      })
    }

    toast.add({
      title: 'Task deleted',
      color: 'green',
    })
    openMoreSetting.value = false

    stop()
    stopGetAutomation()
    modal.close()
    emit('close')
  }

  const getSettingsPackName = (id: string) => {
    const settingsPacks = boardData.value.settingsPacks
    return settingsPacks?.find((settingsPack) => settingsPack.id === id)?.name
  }

  const onExecuteAutomation = async (ruleId: string) => {
    const { mutate } = useExecuteAutomationRuleMutation(ruleId, props.taskDetail.id)
    try {
      const data = await mutate()
      const { success } = data?.data?.executeAutomationRule || {}
      if (!success) {
        throw new Error()
      }

      toast.add({
        icon: 'i-heroicons-check-circle',
        title: 'Automation executed',
        color: 'green',
      })
    } catch (error) {
      toast.add({
        icon: 'i-heroicons-exclamation-triangle',
        title: 'Automation could not be executed because the rule does not meet the required conditions.',
        color: 'red',
      })
    }
  }

  const onConvertToTask = async () => {
    if (props.taskDetail.level !== TaskLevel.SUBTASK) {
      return
    }

    await updateTask(props.taskDetail.id, {
      level: TaskLevel.TASK,
      parent: null,
    })

    openMoreSetting.value = false
    toast.add({
      icon: 'i-heroicons-check-circle',
      color: 'green',
      title: 'Convert to task successfully',
    })
  }

  const onShowMoreSetting = () => {
    isPopoverOpen.value = true
    openMoreSetting.value = true
  }

  const onUpdateMoreSettingOpen = (isOpen: boolean) => {
    if (!isOpen && openMoreSetting.value) {
      isPopoverOpen.value = false
    }

    openMoreSetting.value = isOpen
  }

  const onUpdatePopoverOpen = (value: boolean) => {
    if (!value) {
      isPopoverOpen.value = false
    }
  }

  const openPopover = (ref: Ref<{ open: () => void }>) => {
    openMoreSetting.value = false
    isPopoverOpen.value = true
    ref.value?.open()
  }

  watch(
    () => props.taskDetail.id,
    () => {
      loadAutomation()
    },
    {
      immediate: true,
    }
  )

  watch(
    () => props.taskDetail.type,
    () => {
      refetchAutomation()
    }
  )

  return {
    isPopoverOpen,
    openMoreSetting,
    actionGroupLists,
    expose: {
      openTaskModulePopover: () => {
        if (props.taskDetail.level === TaskLevel.TASK) {
          openPopover(taskModuleRef)
        }
      },
      openDatesPopover: () => openPopover(datesPopoverRef),
      openLabelPopover: () => openPopover(labelPopoverRef),
      openAssigneePopover: () => openPopover(assigneePopoverRef),
    },
    actions: {
      onUpdateMoreSettingOpen,
      onUpdatePopoverOpen,
      onShowMoreSetting,
    },
  }
}
