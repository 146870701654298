export const SECTION_FRAGMENT = gql`
  fragment Section on SectionType {
    id
    name
  }
`

export const BOARD_SECTIONS_POSITION_FRAGMENT = gql`
  fragment BoardSectionsContextList on SectionType {
    id
    position
    board {
      id
    }
  }
`
