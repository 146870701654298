<template>
  <div>
    <slot name="external" :attachments="attachmentsContext?.external" />
    <slot name="internal" :attachments="attachmentsContext?.internal" />
  </div>
</template>

<script lang="ts" setup>
import type { TaskAttachmentResolver } from '#task/types'
import { TASK_ATTACHMENT_RESOLVE_URL_QUERY } from '#task/schema'
import type { MetaURLResolver } from '#core/types'
import type { Attachment } from '#attachment/types'
import { AttachmentType } from '#attachment/constant'

const props = defineProps({
  attachments: {
    type: Array as PropType<Attachment[]>,
    required: true,
  },
})

const { result, load } = useLazyQuery<{
  urls: MetaURLResolver[]
}>(
  TASK_ATTACHMENT_RESOLVE_URL_QUERY,
  {},
  {
    keepPreviousResult: true,
  }
)

const resolveUrlMap = computed(() => {
  return result.value?.urls.reduce((acc, curr) => {
    acc[curr.url] = curr
    return acc
  }, {} as Record<string, MetaURLResolver>)
})

const attachmentsContext = computed(() => {
  return props.attachments.reduce(
    (acc, curr) => {
      const resolver = resolveUrlMap.value?.[curr.url]
      if (resolver) {
        const attachment = {
          ...curr,
          resolver,
        }
        if (resolver.isInternal) {
          acc.internal.push(attachment)
        } else {
          acc.external.push(attachment)
        }
      } else {
        acc.external.push(curr)
      }

      return acc
    },
    {
      internal: [],
      external: [],
    } as {
      internal: TaskAttachmentResolver[]
      external: TaskAttachmentResolver[]
    }
  )
})

watch(
  () => props.attachments.length,
  (newVal, oldVal) => {
    if (oldVal === undefined || newVal > oldVal) {
      const urls = props.attachments.reduce((acc, curr) => {
        if (curr.type === AttachmentType.LINK) {
          acc.push(curr.url)
        }

        return acc
      }, [] as string[])
      load(null, {
        urls,
      })
    }
  },
  {
    immediate: true,
  }
)
</script>
