<template>
  <div v-if="show" class="bg-white text-gray-900 shadow-lg rounded-md border border-gray-200 p-1 pl-2 flex items-center gap-1">
    <Icon name="heroicons:arrow-top-right-on-square" class="w-3 h-3 " color="text-gray-500" />
    <a
      :href="href"
      target="_blank"
      rel="noopener noreferrer"
      class="hover:underline hover:text-primary text-xs truncate max-w-44 text-gray-500"
    >
      {{ href }}
    </a>
    <div class="flex items-center space-x-1">
      <UButton
        variant="ghost"
        color="gray"
        size="2xs"
        icon="heroicons:trash"
        class="p-1"
        @click="handleRemoveLink"
        @mousedown.prevent
      />
      <UButton
        variant="ghost"
        color="gray"
        size="2xs"
        class="px-2 py-1"
        @click="handleEditLink"
        @mousedown.prevent
      >
        Edit
      </UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Editor, Range } from '@tiptap/core'
import type { TiptapCoordination } from '#core/types/packages/tiptap'
import { useEditorContext } from '#core/editor_context'

const props = defineProps<{
  href: string
  editor: Editor
  range: Range
  position: TiptapCoordination
  text: string
  hideTooltip: () => void
}>()

const { showLinkEditForm } = useEditorContext()
const show = ref(true)

const handleEditLink = () => {
  props.hideTooltip()
  showLinkEditForm({
    position: props.position,
    range: props.range,
    href: props.href,
    text: props.text,
  })
  props.editor
    .chain()
    .setTextSelection(props.range)
    .focus()
    .run()
}

const handleRemoveLink = () => {
  const { from, to } = props.range
  props.editor
    .chain()
    .focus(from)
    .command(({ tr }) => {
      tr.removeMark(from, to, props.editor.schema.marks.link)
      tr.removeMark(from, to, props.editor.schema.marks.style)
      return true
    })
    .run()

  props.hideTooltip()
}
</script>
