import type { AsyncComponent } from '#core/types'

export enum FieldType {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  DROPDOWN = 'dropdown',
  STATUS = 'status',
  PEOPLE = 'people',
  ASSIGNEE = 'assignee',
  LABEL = 'label',
  TIME_RANGE = 'time-range',
  COVER = 'cover',
  MODULE = 'module',
  TASK_TYPE = 'task-type',
  TASK_PARENT = 'task-parent',
  SUBTASK = 'subtask',
  MATH_FUNCTION = 'math-function',
  NUMBER_ID = 'number-id',
  TASK_NAME = 'task-name',
  SECTION = 'section',
  START_DATE = 'start-date',
  DUE_DATE = 'due-date',
  DESCRIPTION = 'description',
  PARENT_ID = 'parent-id',
  BOARD = 'board',
}

export const FieldLists = [
  {
    label: 'Text',
    type: FieldType.TEXT,
  },
  {
    label: 'Date',
    type: FieldType.DATE,
  },
  {
    label: 'Number',
    type: FieldType.NUMBER,
  },
  {
    label: 'Checkbox',
    type: FieldType.CHECKBOX,
  },
  {
    label: 'Dropdown',
    type: FieldType.DROPDOWN,
  },
  {
    label: 'People',
    type: FieldType.PEOPLE,
  }
]

export const FieldFormComponents = new Map<FieldType, AsyncComponent>([
  [
    FieldType.TEXT,
    defineAsyncComponent(() => import('#field/components/form/Text.vue')),
  ],
  [
    FieldType.DATE,
    defineAsyncComponent(() => import('#field/components/form/Date.vue')),
  ],
  [
    FieldType.NUMBER,
    defineAsyncComponent(() => import('#field/components/form/Number.vue')),
  ],
  [
    FieldType.CHECKBOX,
    defineAsyncComponent(() => import('#field/components/form/Checkbox.vue')),
  ],
  [
    FieldType.DROPDOWN,
    defineAsyncComponent(() => import('#field/components/form/Dropdown.vue')),
  ],
  [
    FieldType.PEOPLE,
    defineAsyncComponent(() => import('#field/components/form/People.vue')),
  ]
])

export enum FieldOptionColor {
  SKY = '#E0F2FE',
  PRIMARY = '#D9FBE8',
  YELLOW = '#FEF9C3',
  ORANGE = '#FFEDD5',
  VIOLET = '#EDE9FE',
  RED = '#FEE2E2',
  GRAY = '#F4F4F5',
}

export const FieldOptionTextColor: Record<FieldOptionColor, string> = {
  [FieldOptionColor.PRIMARY]: '#016538',
  [FieldOptionColor.SKY]: '#065C8E',
  [FieldOptionColor.YELLOW]: '#854D0E',
  [FieldOptionColor.ORANGE]: '#9A3412',
  [FieldOptionColor.VIOLET]: '#5B21B6',
  [FieldOptionColor.RED]: '#991B1B',
  [FieldOptionColor.GRAY]: '#3F3F46',
}

export const DEFAULT_FIELD_OPTION_COLOR = FieldOptionColor.SKY

export const FIELD_TYPE_NAME = 'FieldType'

export const FieldPreviewComponents = new Map<FieldType, AsyncComponent>([
  [
    FieldType.TEXT,
    defineAsyncComponent(() => import('#field/components/preview/Text.vue')),
  ],
  [
    FieldType.DATE,
    defineAsyncComponent(() => import('#field/components/preview/Date.vue')),
  ],
  [
    FieldType.NUMBER,
    defineAsyncComponent(() => import('#field/components/preview/Number.vue')),
  ],
  [
    FieldType.DROPDOWN,
    defineAsyncComponent(
      () => import('#field/components/preview/Dropdown.vue')
    ),
  ],
  [
    FieldType.CHECKBOX,
    defineAsyncComponent(
      () => import('#field/components/preview/Checkbox.vue')
    ),
  ],
  [
    FieldType.STATUS,
    defineAsyncComponent(() => import('#field/components/preview/Status.vue')),
  ],
  [
    FieldType.ASSIGNEE,
    defineAsyncComponent(
      () => import('#field/components/preview/Assignee.vue')
    ),
  ],
  [
    FieldType.LABEL,
    defineAsyncComponent(() => import('#field/components/preview/Label.vue')),
  ],
  [
    FieldType.TASK_PARENT,
    defineAsyncComponent(
      () => import('#field/components/preview/TaskParent.vue')
    ),
  ],
  [
    FieldType.TIME_RANGE,
    defineAsyncComponent(
      () => import('#field/components/preview/DateTime.vue')
    ),
  ],
  [
    FieldType.MATH_FUNCTION,
    defineAsyncComponent(
      () => import('#field/components/preview/CalculatorDropdown.vue')
    ),
  ],
  [
    FieldType.PEOPLE,
    defineAsyncComponent(() => import('#field/components/preview/People.vue')),
  ],
  [
    FieldType.SECTION,
    defineAsyncComponent(() => import('#field/components/preview/Section.vue')),
  ]
])
