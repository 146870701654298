<template>
  <div v-bind="$attrs">
    <span v-if="start" data-test="start-date" class="whitespace-nowrap">
      {{ startDate }}
    </span>
    <span class="whitespace-nowrap">
      <span v-if="start && end" class="mx-0.5">-</span>
      <span v-if="end" data-test="due-date">
        {{ dueDate }}
      </span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { isSameMonth, isSameYear } from 'date-fns'

const props = defineProps({
  start: {
    type: String,
  },
  end: {
    type: String,
  },
  showTime: {
    type: Boolean,
    default: true,
  },
  format: {
    type: Object as PropType<
      Partial<Record<'year' | 'month' | 'day' | 'time', string>>
    >,
    default: () => ({}),
  },
})

const formatOption = computed(() => {
  return {
    year: props.format.year ?? 'yyyy',
    month: props.format.month ?? 'MMM',
    day: props.format.day ?? 'dd',
    time: props.format.time ?? 'h:mm aaa',
  }
})

const defaultFormat = computed(() => {
  const { year, month, day, time } = formatOption.value
  const formatArr = [year, month, day]
  if (props.showTime) {
    formatArr.push(time)
  }

  return formatArr.join(' ')
})
const isStartDueSameYear = computed(() => {
  const { start, end } = props
  if (!start || !end) {
    return false
  }

  return !isDiffYear(start, end)
})
const startDate = computed(() => {
  let format = defaultFormat.value
  const { start } = props
  if (start) {
    if (isStartOfDay(start)) {
      format = format.replace(formatOption.value.time, '')
    }

    if (isStartDueSameYear.value || !props.end) {
      format = format.replace(`${formatOption.value.year} `, '')
    }

    return dateFormat(start, format)
  }
})
const dueDate = computed(() => {
  const { start, end } = props as unknown as {
    start: Date
    end: Date
  }
  let format = defaultFormat.value
  if (isStartOfDay(end)) {
    format = format.replace(formatOption.value.time, '')
  }

  if (
    start &&
    isSameMonth(start, end) &&
    isSameYear(start, end) &&
    isStartOfDay(start)
  ) {
    format = format.replace(`${formatOption.value.month} `, '')
  }

  if (isStartDueSameYear.value || !start) {
    format = format.replace(`${formatOption.value.year} `, '')
  }

  return dateFormat(end, format)
})
</script>
