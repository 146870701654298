<template>
  <div ref="wrapperRef" class="relative">
    <template v-if="editor">
      <EditorExtensionsToolBarMenu :editor="editor" />
      <EditorExtensionsToolbarLink :editor="editor" />
    </template>
    <EditorContent
      :editor="editor"
      class="tiptap-editor min-h-20 cursor-text pb-1"
      :style="{ wordBreak: 'break-word' }"
      :data-test="dataTest"
    />
    <div class="h-12" :class="!isFocused ? 'cursor-pointer': 'sticky bottom-0 bg-white'" @click="focus">
      <EditorExtensionsMenuBar v-if="editor && isFocused" id="editor-menu-bar" :editor="editor" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { AnyExtension } from '@tiptap/core'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Placeholder from '@tiptap/extension-placeholder'
import { onClickOutside } from '@vueuse/core'
import EditorExtensionsToolBarMenu from './extensions/ToolBarMenu.vue'
import ExtensionKit from '#core/packages/tiptap/extensions'
import { useEditorContext } from '#core/editor_context'
import type { ImageOptions } from '#core/types/packages/tiptap'

const attrs = useAttrs()
const props = defineProps({
  placeholder: {
    type: String,
    default: 'Add detail description, \'/\' for commands…',
  },
  additionalExtensions: {
    type: Array as PropType<AnyExtension[]>,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataTest: {
    type: String,
  },
  imageOption: {
    type: Object as PropType<ImageOptions>,
  },
})

const { isShowLinkEditForm } = useEditorContext()

const emit = defineEmits(['blur', 'cancel', 'created'])
const content = defineModel('content', { type: String, default: '' })
const isEmpty = defineModel('isEmpty', { type: Boolean, default: true })
const isFocused = ref(false)

const editor = ref<Editor>()
const wrapperRef = ref<HTMLDivElement>()

const focus = () => {
  editor.value?.commands.focus()
}

const blur = () => {
  editor.value?.commands.blur()
}

const setContent = (value: string) => {
  editor.value?.commands.setContent(value, false)
}

onClickOutside(wrapperRef, (event) => {
  const shouldCloseEditor = (event.target as HTMLElement)?.closest('[data-prevent-close-editor]') === null

  if (shouldCloseEditor) {
    setContent(content.value)
    isFocused.value = false
  }
})

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      Placeholder.configure({
        placeholder: () => props.placeholder,
        includeChildren: true,
      }),
      ...ExtensionKit({
        linkOption: {
          disabled: () => !!isShowLinkEditForm.value,
        },
        imageOption: props.imageOption,
      }),
      ...props.additionalExtensions,
    ],
    content: content.value,
    editable: !props.disabled,
    autofocus: !!attrs.autofocus,
    onUpdate: () => {
      content.value = editor.value?.getHTML() || ''
      isEmpty.value = !editor.value?.state?.doc?.textContent?.trim()?.length
    },
    onFocus: () => {
      isFocused.value = true
    },
    onCreate: () => {
      setContent(content.value)
      isEmpty.value = !editor.value?.state.doc.textContent.trim().length
      emit('created')
    },
    onBlur: () => {
      const htmlContent = editor.value?.getHTML() || ''
      const cleanedContent = htmlContent.replace(/(<p>(?:<br>)?\s*<\/p>|&nbsp;|\s+)+$/, '')
      content.value = cleanedContent
      isFocused.value = false
      emit('blur')
    },
  })
})

onUnmounted(() => {
  editor.value?.destroy()
})

defineExpose({
  focus,
  setContent,
  isFocused: () => isFocused.value,
  getHeight: () => {
    return editor.value?.view?.dom?.scrollHeight || 0
  },
  setEditable: (value: boolean) => {
    nextTick(() => {
      editor.value?.setEditable(value)
    })
  },
  scrollIntoView: () => {
    wrapperRef.value?.scrollIntoView()
  }
})

defineShortcuts({
  escape: {
    whenever: [isFocused],
    usingInput: true,
    handler: () => {
      blur()
    },
  },
})
</script>
