import type * as Apollo from '@apollo/client'
import { BOARD_SECTIONS_LOADER_QUERY } from '#section/loader_schema'
import {
  ADD_UPDATE_SECTION_MUTATION,
  UPDATE_SECTION_POSITION_MUTATION,
  BOARD_ARCHIVED_SECTIONS_QUERY,
  DELETE_SECTION_MUTATION,
} from '#section/schema'
import type { ArchivedSectionPaginationType } from '#section/types'
import type { SectionLoader } from '#section/loader_types'

export const useAddUpdateSectionMutation = (
  options: Apollo.MutationHookOptions<{
    addUpdateSection: { section: SectionLoader }
  }>
) => {
  return useMutation(ADD_UPDATE_SECTION_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useUpdateSectionPositionMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{ updateSectionPosition: { success: boolean } }>(
    UPDATE_SECTION_POSITION_MUTATION,
    {
      errorPolicy: 'ignore',
      throws: 'never',
      ...options,
    }
  )
}

export const useLazyArchivedSectionsQuery = (boardId: string, filter: { nextCurors?: string, search?: string, limit?: number }) => {
  return useLazyQuery<{ boardArchivedSections: ArchivedSectionPaginationType }>(
    BOARD_ARCHIVED_SECTIONS_QUERY,
    {
      boardId,
      filter,
    }, {
      debounce: 500,
    }
  )
}

export const useSectionsLazyQuery = () => {
  return useLazyQuery<{ sections?: SectionLoader[] }>(BOARD_SECTIONS_LOADER_QUERY, {}, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })
}

export const useDeleteSectionMutation = (
  options: Apollo.MutationHookOptions<{ deleteSection: { success: boolean, id: string } }> = {}
) => {
  return useMutation<{ deleteSection: { success: boolean, id: string } }>(DELETE_SECTION_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
    update: (cache, { data }) => {
      if (data?.deleteSection.id) {
        cache.evict({ id: `SectionType:${data?.deleteSection.id}` })
      }
    },
  })
}
