<template>
  <div ref="taskDetailRef" class="relative">
    <div v-if="isOverDropZone" class="h-full max-h-screen w-full flex items-center justify-center absolute font-semibold text-md">
      Drop files to upload.
    </div>
    <div :class="isOverDropZone ? 'opacity-0': ''">
      <TaskDetailLoading v-if="loading" />
      <div
        v-else-if="taskDetail"
        ref="taskDetailDropZoneRef"
        class="relative"
        data-test="task-details"
      >
        <TaskDetailCover :task-detail="taskDetail" />

        <div class="absolute right-3 top-3 z-20 flex items-center">
          <LazyTaskDetailSidebarPopover
            v-if="currentTask.viewMode === TaskViewMode.SIDEBAR"
            ref="sidebarRef"
            :task-detail="taskDetail"
            @close="emit('close')"
            @click-dependency="taskDependencyListRef?.showCreateable()"
            @click-subtasks="subtasksTable?.showCreateSubtask()"
          />
          <div v-if="currentTask.viewMode === TaskViewMode.SIDEBAR" class="h-full min-h-6 w-[1px] bg-gray-200 mx-3" />
          <UButton
            variant="solid"
            color="white"
            size="sm"
            icon="heroicons:x-mark"
            :ui="{
              color: {
                white: {
                  solid: 'shadow-none ring-white',
                },
              },
            }"
            data-test="close-task-detail"
            @click.prevent.stop="$emit('close')"
          />
        </div>
        <div
          :class="[taskDetail.closed || taskDetail.cover ? 'pt-4' : 'pt-6', 'pl-16 pb-6 pr-6 z-10', { 'pr-8': currentTask.viewMode === TaskViewMode.SIDEBAR }]"
        >
          <TaskDetailBreadcrumb :task-detail="taskDetail" />
          <div class="flex items-start justify-between gap-8">
            <div class="flex-1">
              <UTextarea
                ref="titleRef"
                v-model="state.name"
                data-test="task-name"
                autoresize
                maxlength="255"
                :rows="1"
                :ui="{
                  base: 'w-full !ring-0 !shadow-none !text-2xl text-gray-900 font-semibold resize-none appearance-none overflow-hidden break-words focus:bg-gray-50 focus:outline-none focus:!ring-2 rounded-md p-1 -ml-2',
                }"
                :disabled="!can('dashboard.data.manage_tasks_sections')"
                @blur.prevent="onUpdateTaskProperty('name')"
                @keydown.enter.prevent.stop="$event.target.blur()"
                @keydown.esc.prevent.stop="cancelUpdateTitle"
              />
              <div class="flex flex-col mt-4 gap-2">
                <div
                  v-if="taskDetail.level !== TaskLevel.SUBTASK"
                  class="flex items-center gap-2"
                >
                  <div
                    class="w-32 min-w-32 cursor-pointer"
                    @click="taskMovePopoverRef?.open"
                  >
                    <div class="flex items-center gap-1.5 text-sm text-gray-500">
                      <Icon name="heroicons:document-check" /> In section
                    </div>
                  </div>
                  <TaskMovePopover
                    ref="taskMovePopoverRef"
                    :workspace-id="currentWorkspace.id"
                    :tasks="[taskDetail]"
                    class="w-full grow"
                  >
                    <UButton
                      variant="ghost"
                      color="gray"
                      trailing-icon="i-heroicons-chevron-down-20-solid"
                      class="max-w-full"
                      :disabled="!can('dashboard.data.manage_tasks_sections')"
                      data-test="task-detail-section-btn"
                    >
                      <Tooltip :text="taskDetail.section.name">
                        <template #default="{ getTextRef }">
                          <span :ref="getTextRef" class="line-clamp-1 break-all">
                            {{ taskDetail.section.name }}
                          </span>
                        </template>
                      </Tooltip>
                    </UButton>
                  </TaskMovePopover>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="w-32 min-w-32 cursor-pointer"
                    @click="statusPopoverRef?.open"
                  >
                    <div class="flex items-center gap-1.5 text-sm text-gray-500">
                      <Icon name="heroicons:ellipsis-horizontal-circle" />
                      Status
                    </div>
                  </div>
                  <StatusSelectPopover
                    ref="statusPopoverRef"
                    :selected-status-id="taskDetail.status?.id"
                    :parent-id="taskDetail.boardId"
                    class="w-full grow"
                    source="board"
                    @change="updateTask(taskDetail.id, { status: $event })"
                  >
                    <StatusBadge
                      v-if="taskDetail.status"
                      class="flex items-center gap-1 text-xs ml-2.5"
                      :type="taskDetail.status.type"
                      :label="taskDetail.status.name"
                      :disabled="!can('dashboard.data.manage_tasks_sections')"
                    />
                    <UButton
                      v-else
                      variant="ghost"
                      color="gray"
                      class="font-normal text-gray-400"
                      :disabled="!can('dashboard.data.manage_tasks_sections')"
                    >
                      Set status
                    </UButton>
                  </StatusSelectPopover>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="w-32 min-w-32 cursor-pointer"
                    :disabled="!can('dashboard.data.manage_tasks_sections')"
                    @click="assigneePopoverRef?.open"
                  >
                    <div class="flex items-center gap-1.5 text-sm text-gray-500">
                      <Icon name="heroicons:user" /> Assignee
                    </div>
                  </div>
                  <TaskAssigneePopover
                    ref="assigneePopoverRef"
                    :current-assignee="taskDetail.assignee"
                    class="w-full grow"
                    :board-id="taskDetail.boardId"
                    @select="(assignee) => updateTask(taskDetail.id, { assignee })"
                    @remove="updateTask(taskDetail.id, { assignee: null })"
                  >
                    <div
                      v-if="taskDetail.assignee"
                      class="flex items-center gap-0.5"
                    >
                      <UButton
                        variant="ghost"
                        color="gray"
                        class="text-gray-900 max-w-full"
                        :disabled="!can('dashboard.data.manage_tasks_sections')"
                      >
                        <template #leading>
                          <Avatar
                            :id="taskDetail.assignee.id"
                            size="2xs"
                            :name="taskDetail.assignee.fullName"
                            :src="taskDetail.assignee.photo"
                          />
                        </template>
                        <Tooltip :text="taskDetail.assignee.fullName">
                          <template #default="{ getTextRef }">
                            <span
                              :ref="getTextRef"
                              class="line-clamp-1 break-all"
                              data-test="task-assignee"
                            >
                              {{ taskDetail.assignee.fullName }}
                            </span>
                          </template>
                        </Tooltip>
                      </UButton>
                      <UButton
                        variant="ghost"
                        color="gray"
                        size="2xs"
                        class="font-normal text-gray-900"
                        icon="heroicons:x-mark"
                        :disabled="!can('dashboard.data.manage_tasks_sections')"
                        data-test="remove-assignee-icon"
                        @click.prevent.stop="
                          updateTask(taskDetail.id, { assignee: null })
                        "
                      />
                    </div>
                    <template v-else>
                      <UButton
                        variant="ghost"
                        color="gray"
                        icon="i-heroicons-user-circle"
                        class="font-normal text-gray-400"
                        :disabled="!can('dashboard.data.manage_tasks_sections')"
                      >
                        Assignee member
                      </UButton>
                    </template>
                  </TaskAssigneePopover>
                </div>
                <div class="flex items-center gap-2 text-sm">
                  <div
                    class="w-32 min-w-32 cursor-pointer"
                    @click="dueDatePopoverRef?.open"
                  >
                    <div class="flex items-center gap-1.5 text-sm text-gray-500">
                      <Icon name="heroicons:calendar" />
                      {{ dateTimeText }}
                    </div>
                  </div>
                  <TaskDatePickerPopover
                    ref="dueDatePopoverRef"
                    :start-date="taskDetail.startDate"
                    :due-date="taskDetail.dueDate"
                    @change="(dates) => updateTask(taskDetail.id, dates)"
                  >
                    <div
                      v-if="taskDetail.startDate || taskDetail.dueDate"
                      class="flex items-center gap-0.5"
                    >
                      <UButton
                        variant="ghost"
                        color="gray"
                        class="text-gray-900"
                        :disabled="!can('dashboard.data.manage_tasks_sections')"
                      >
                        <TaskDatesFormat
                          :start="taskDetail.startDate"
                          :end="taskDetail.dueDate"
                        />
                      </UButton>
                      <UButton
                        variant="ghost"
                        color="gray"
                        size="2xs"
                        class="font-normal text-gray-900"
                        icon="heroicons:x-mark"
                        :disabled="!can('dashboard.data.manage_tasks_sections')"
                        data-test="remove-due-date-icon"
                        @click.prevent.stop="onClearTaskDueDate"
                      />
                    </div>
                    <UButton
                      v-else
                      variant="ghost"
                      color="gray"
                      icon="heroicons:calendar"
                      class="font-normal text-gray-400"
                      :disabled="!can('dashboard.data.manage_tasks_sections')"
                    >
                      Add date
                    </UButton>
                  </TaskDatePickerPopover>
                </div>
                <div class="flex gap-2">
                  <div
                    class="w-32 min-w-32 leading-8 cursor-pointer py-1.5"
                    @click="addLabelPopoverRef?.open"
                  >
                    <div class="flex items-center gap-1.5 text-sm text-gray-500">
                      <Icon name="heroicons:tag" /> Labels
                    </div>
                  </div>
                  <div
                    class="flex items-center flex-wrap gap-1 flex-1 min-h-8"
                    :class="{
                      'pl-2.5': filteredLabels.length,
                    }"
                  >
                    <LabelItem
                      v-for="label in filteredLabels"
                      :key="label.id"
                      :label="label"
                      :expanded="true"
                    />
                    <LabelListPopover
                      ref="addLabelPopoverRef"
                      source="board"
                      heading="Add labels"
                      class="flex items-center"
                      :selected-ids="map(filteredLabels, 'id')"
                      :parent-id="taskDetail.boardId"
                      @select="onToggleLabels"
                      @remove="onToggleLabels"
                    >
                      <UButton
                        v-if="filteredLabels.length"
                        color="gray"
                        variant="soft"
                        size="2xs"
                        icon="i-heroicons-plus-small"
                        :disabled="!can('dashboard.data.manage_tasks_sections')"
                        data-test="add-label-icon"
                      />
                      <UButton
                        v-else
                        color="gray"
                        variant="ghost"
                        class="font-normal text-gray-400"
                        :disabled="!can('dashboard.data.manage_tasks_sections')"
                        data-test="add-label-icon"
                      >
                        Add labels
                      </UButton>
                    </LabelListPopover>
                  </div>
                </div>
                <div class="flex gap-2">
                  <div class="w-32 min-w-32 leading-8 cursor-pointer py-1.5">
                    <div class="flex items-center gap-1.5 text-sm text-gray-500">
                      <Icon name="heroicons:link" /> Dependencies
                    </div>
                  </div>
                  <div class="flex items-center flex-wrap gap-1 flex-1 min-h-8">
                    <TaskDependencyList
                      ref="taskDependencyListRef"
                      :task-id="taskDetail.id"
                      :board-id="taskDetail.boardId"
                      :dependencies="taskDetail.dependencies"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-6 relative">
                <Icon
                  name="heroicons:bars-3-center-left-20-solid"
                  class="absolute top-0.5 -left-8"
                  :size="20"
                />
                <p class="text-gray-900 font-semibold">Description</p>
                <TaskDetailDescription
                  ref="descriptionRef"
                  v-model:content="state.description"
                  :task-detail="taskDetail"
                  :disabled="!can('dashboard.data.manage_tasks_sections')"
                  @change="onUpdateTaskProperty('description')"
                />
              </div>
              <!-- Custom fields -->
              <TaskCustomFieldsTable
                class="mt-3"
                :task-fields="taskDetail.taskFields"
                :board-id="taskDetail.boardId"
                :type-id="taskDetail.typeId"
                @change="onUpdateTaskCustomField"
              />
              <LazyDeferredContent
                v-if="taskDetail.level !== TaskLevel.SUBTASK"
                :key="taskDetail.id"
              >
                <div class="relative mt-6">
                  <Icon
                    name="leanbase:subtask-outline"
                    class="absolute top-1.5 -left-8"
                    :size="20"
                  />
                  <div class="flex justify-between items-center">
                    <p class="text-gray-900 font-semibold">
                      {{
                        taskDetail.level === TaskLevel.MODULE
                          ? 'Children Tasks'
                          : 'Subtasks'
                      }}
                    </p>
                    <UButton
                      icon="heroicons:plus"
                      size="sm"
                      color="gray"
                      variant="ghost"
                      :disabled="!can('dashboard.data.manage_tasks_sections')"
                      @click="subtasksTable?.showCreateSubtask()"
                    />
                  </div>
                  <TaskSubtasksTable
                    ref="subtasksTable"
                    :parent="
                      pick(taskDetail, ['id', 'level', 'boardId', 'sectionId', 'closed'])
                    "
                  />
                </div>
              </LazyDeferredContent>
              <LazyDeferredContent
                v-if="taskDetail.attachments?.length"
                :key="taskDetail.id"
              >
                <TaskAttachmentContext
                  class="mt-2"
                  :attachments="taskDetail.attachments"
                >
                  <template #external="{ attachments }">
                    <div v-if="attachments?.length" class="relative mt-6">
                      <Icon
                        name="heroicons:paper-clip"
                        class="absolute top-0.5 -left-8"
                        :size="20"
                      />
                      <div class="flex justify-between items-center mb-3">
                        <p class="text-gray-900 font-semibold">Attachments</p>
                        <TaskAttachmentPopover :task-id="taskDetail.id">
                          <UButton
                            icon="heroicons:plus"
                            size="sm"
                            color="gray"
                            variant="ghost"
                            :disabled="
                              !can('dashboard.data.manage_tasks_sections')
                            "
                          />
                        </TaskAttachmentPopover>
                      </div>
                      <TaskExternalAttachment
                        :attachments="attachments"
                        :task-id="taskDetail.id"
                        :task-cover="taskDetail.cover"
                      />
                    </div>
                  </template>
                  <template #internal="{ attachments }">
                    <div v-if="attachments?.length" class="relative mt-6">
                      <Icon
                        name="leanbase:lighting"
                        class="absolute top-0.5 -left-8"
                        :size="20"
                      />
                      <div class="flex justify-between items-center mb-3">
                        <p class="text-gray-900 font-semibold">
                          Leanbase attachments
                        </p>
                        <TaskAttachmentPopover :task-id="taskDetail.id">
                          <UButton
                            icon="heroicons:plus"
                            size="sm"
                            color="gray"
                            variant="ghost"
                          />
                        </TaskAttachmentPopover>
                      </div>
                      <TaskInternalAttachment
                        class="grid grid-cols-2 gap-3"
                        :attachments="attachments"
                        :task-id="taskDetail.id"
                      />
                    </div>
                  </template>
                </TaskAttachmentContext>
              </LazyDeferredContent>
              <TaskActivity
                v-if="route.hash"
                :task-id="taskDetail.id"
                :board-id="taskDetail.boardId"
              />
              <LazyDeferredContent v-else :key="taskDetail.id">
                <TaskActivity
                  :task-id="taskDetail.id"
                  :board-id="taskDetail.boardId"
                />
              </LazyDeferredContent>
            </div>
            <!-- Right sidebar -->
            <TaskDetailSidebar
              v-if="currentTask.viewMode === TaskViewMode.MODAL"
              ref="sidebarRef"
              :task-detail="taskDetail"
              @close="emit('close')"
              @click-dependency="taskDependencyListRef?.showCreateable()"
              @click-subtasks="subtasksTable?.showCreateSubtask()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { pick, map, cloneDeep } from 'lodash-es'
import TaskExternalAttachment from '#task/components/TaskExternalAttachment.vue'
import type {
  BaseTask,
  TaskDetail,
  TaskCustomField,
  CurrentTask,
  TaskItem,
} from '#task/types'
import { TaskLevel, TaskViewMode } from '#task/constant'
import type { Label } from '#label/types'
import { ShortcutBusKey, ShortcutKeyEvent } from '#core/shortcut_keys_constants'

const props = defineProps({
  task: {
    type: Object as PropType<Pick<BaseTask, 'id' | 'name' | 'handle'>>,
    required: true,
  },
})
const emit = defineEmits<{
  (e: 'loaded', task: TaskDetail): void
  (e: 'close'): void
}>()

const toast = useToast()
const route = useRoute()
const { copy } = useClipboard()
const { text: textSelected } = useTextSelection()
const { currentWorkspace, setCurrentTask, currentTask } = useWorkspaceSharedState()
const { updateTask, onDropAttachment, toggleLabels, updateTaskCustomField } =
  useUpdateTask()
const { copyToClipboard } = useCopyClipboard()
const { auth } = storeToRefs(useAuthStore())
const { can } = useBoardAbility()

const {
  load: loadTask,
  start,
  onResult,
} = useTaskDetailLazyQuery(props.task.id)
const { archiveTask } = useArchiveTask()

const taskDetail = ref<TaskDetail>(props.task as TaskDetail)
const loading = ref(true)
const taskDetailRef = ref()
const taskDetailDropZoneRef = ref<HTMLDivElement>()
const titleRef = ref()
const descriptionRef = ref()
const subtasksTable = ref()
const statusPopoverRef = ref()
const taskMovePopoverRef = ref()
const assigneePopoverRef = ref()
const dueDatePopoverRef = ref()
const addLabelPopoverRef = ref()
const sidebarRef = ref()
const taskDependencyListRef = ref()

const state = reactive<Partial<TaskDetail>>({
  name: '',
  description: '',
})

const filteredLabels = computed(() =>
  taskDetail.value.labels?.filter((label) => label.id)
)

const dateTimeText = computed(() => {
  switch (true) {
    case !!taskDetail.value.startDate && !!taskDetail.value.dueDate:
      return 'Dates'
    case !!taskDetail.value.startDate:
      return 'Start date'
    case !!taskDetail.value.dueDate:
      return 'Due date'
    default:
      return 'Dates'
  }
})

const cancelUpdateTitle = (event: KeyboardEvent) => {
  state.name = taskDetail.value['name']
  const target = event.target as HTMLElement
  target.blur()
}

const onUpdateTaskProperty = async (field: keyof TaskDetail) => {
  if (field === 'name' && !state.name?.trim()) {
    state.name = taskDetail.value[field]
    return
  }

  if (state[field] !== taskDetail.value[field]) {
    await updateTask(taskDetail.value.id, pick(state, ['name', 'description']))
  }
}

const onToggleLabels = (label: Label) => toggleLabels({ taskIds: [props.task.id], labelIds: [label.id], detachLabelIds: [] })

const onUpdateTaskCustomField = (customField: TaskCustomField) => {
  updateTaskCustomField({
    fieldId: customField.field.id,
    taskIds: [taskDetail.value.id],
    value: customField.value,
  })
}

const onArchiveTask = () => {
  archiveTask(taskDetail.value as unknown as TaskItem)
}

const onClearTaskDueDate = () => {
  updateTask(taskDetail.value.id, {
    startDate: null as unknown as string,
    dueDate: null as unknown as string,
  })
}

const { isOverDropZone, } = useDropZone(taskDetailDropZoneRef, {
  onDrop: async (files, event) => {
    if (descriptionRef.value?.isEditorFocused()) {
      return
    }

    onDropAttachment({
      files,
      event,
      taskId: taskDetail.value.id,
    })
  },
  dataTypes: (types) => {
    return types.length > 0 && !descriptionRef.value?.isEditorFocused()
  },
})

onResult((result) => {
  if (result.data?.task) {
    extend(state, result.data.task)

    taskDetail.value = cloneDeep(result.data.task as TaskDetail)
    loading.value = false

    emit('loaded', taskDetail.value)
  }
})

watch(
  () => props.task.id,
  (taskId) => {
    start()
    loadTask(null, {
      id: taskId,
    })
  },
  {
    immediate: true,
  }
)

watch(
  [() => taskDetail.value?.name, () => taskDetail.value?.description],
  ([name, description]) => {
    extend(state, { name, description })
    if (!descriptionRef.value?.isEditorFocused()) {
      descriptionRef.value?.setContent(description)
    }
  }
)
const onBack = (event: PopStateEvent) => {
  event.preventDefault()
  // If user go back but the current route is /b/:handle, then close the task detail
  if (/\/b\/[^/]+/.test(window.location.pathname)) {
    setCurrentTask({} as CurrentTask)
  }
}

const scrollToComment = () => {
  const hash = window.location.hash
  if (!hash) {
    return
  }

  const element = document.querySelector(hash)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}

const shortcutBusListener = (message: ShortcutKeyEvent) => {
  const scroller = document.getElementById('task-detail-scroller')
  switch (message) {
    case ShortcutKeyEvent.TOGGLE_TASK_LABELS:
      scroller?.scrollTo(0, 0)
      sidebarRef.value?.openLabelPopover()
      break

    case ShortcutKeyEvent.ADD_MODULE:
      if (taskDetail.value.level === TaskLevel.TASK) {
        scroller?.scrollTo(0, 0)
        sidebarRef.value?.openTaskModulePopover()
      }

      break

    case ShortcutKeyEvent.TOGGLE_MEMBER_MODAL:
      scroller?.scrollTo(0, 0)
      sidebarRef.value?.openAssigneePopover()
      break

    case ShortcutKeyEvent.OPEN_CARD_DUE_DATE:
      scroller?.scrollTo(0, 0)
      sidebarRef.value?.openDatesPopover()
      break
    case ShortcutKeyEvent.ARCHIVE_CARD:
      onArchiveTask()
      break
    case ShortcutKeyEvent.ASSIGN_ME_TO_TASK:
      if (taskDetail.value.assignee?.id === auth.value.id) {
        updateTask(taskDetail.value.id, { assignee: null })
        return
      }

      updateTask(taskDetail.value.id, { assignee: auth.value.id })
      break
    case ShortcutKeyEvent.ADD_TASK_DEPENDENCIES:
      scroller?.scrollTo(0, 0)
      taskDependencyListRef.value?.showCreateable()
      break
    case ShortcutKeyEvent.EDIT_CART_TITLE:
      nextTick(() => {
        const textarea = titleRef.value?.$el.querySelector('textarea')
        textarea?.focus()
        textarea?.select()
      })
      break
    case ShortcutKeyEvent.EDIT_CART_DESCRIPTION:
      descriptionRef.value?.focus()
      break
  }
}

const shortcutBus = useEventBus<ShortcutKeyEvent>(ShortcutBusKey.TASK)

onMounted(() => {
  shortcutBus.on(shortcutBusListener)
  window.onhashchange = scrollToComment
  window.addEventListener('popstate', onBack)
  setTimeout(() => scrollToComment(), 2000)
})

onUnmounted(() => {
  shortcutBus.off(shortcutBusListener)
  window.removeEventListener('popstate', onBack)
  window.onhashchange = null
})

defineShortcuts({
  meta_c: {
    handler: () => {
      if (textSelected.value) {
        return copy(textSelected.value)
      }

      const taskLink = window.location.href
      const isCoppied = copyToClipboard(taskLink)
      if (isCoppied) {
        toast.add({
          icon: 'i-heroicons-clipboard',
          title: 'Task copied to clipboard',
          color: 'green',
        })
      }
    },
  },
})
</script>
