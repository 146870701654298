import type { ShortcutKey } from '#core/types'

export enum ShortcutBusKey {
  KANBAN = 'kanban-shortcut',
  KANBAN_TASK = 'kanban-task-shortcut',
  LISTVIEW = 'listview-shortcut',
  LISTVIEW_TASK = 'listview-task-shortcut',
  LISTVIEW_GROUP = 'listview-group-shortcut',
  SECTION = 'section-shortcut',
  TASK = 'task-shortcut',
  FILTER = 'filter-shortcut',
  WORKSPACE = 'workspace-shortcut',
}

export enum ShortcutCategory {
  GENERAL = 'General shortcuts',
  LIST = 'List interactions',
  CARD = 'Card actions'
}

export enum ShortcutKeyEvent {
  PASTE_CLIPBOARD = 'paste_clipboard',
  SCROLL_TO_ITEM = 'scroll_to_item',
  ADD_NEW_CARD = 'add_new_card',
  OPEN_CARD = 'open_card',
  OPEN_CARD_DUE_DATE = 'open_card_due_date',
  ARCHIVE_CARD = 'archive_card',
  OPEN_FILTER = 'open_filter',
  TOGGLE_FILTER_ONLY_ME = 'toggle_filter_only_me',
  TOGGLE_BOARD_SETTINGS = 'toggle_board_settings',
  TOGGLE_EXPAND_SIDEBAR = 'toggle_expand_sidebar',
  TOGGLE_BOARD_MENU = 'toggle_board_menu',
  CLEAR_ALL_FILTER = 'clear_all_filter',
  EDIT_CART_TITLE = 'edit_card_title',
  EDIT_CART_DESCRIPTION = 'edit_card_description',
  TOGGLE_TASK_QUICK_ACTIONS = 'toggle_task_quick_actions',
  TOGGLE_TASK_LABELS = 'toggle_task_labels',
  ADD_TASK_DEPENDENCIES = 'add_task_dependencies',
  ASSIGN_ME_TO_TASK = 'assign_me_to_task',
  MOVE_CARD_TO_RIGHT_SECTION = 'move_card_to_right_section',
  MOVE_CARD_TO_LEFT_SECTION = 'move_card_to_left_section',
  NAVIGATE_DOWN = 'navigate_down',
  NAVIGATE_UP = 'navigate_up',
  NAVIGATE_RIGHT = 'navigate_right',
  NAVIGATE_LEFT = 'navigate_left',
  TOGGLE_MEMBER_MODAL = 'toggle_member_modal',
  ADD_MODULE = 'add_module',
  EDIT_CELL_LISTVIEW = 'edit_cell_listview',
  DELETE_CELL_VALUE = 'delete_cell_value',
  NAVIGATE_BOARD_VIEW = 'navigate_board_view',
  NAVIGATE_LIST_VIEW = 'navigate_list_view',
  NAVIGATE_TIMELINE_VIEW = 'navigate_timeline_view',
}

export const ShortcutKeys: ShortcutKey[] = [
  {
    description: {
      name: 'Create a new task',
      shortcuts: ['N']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Open selected card',
      shortcuts: ['Enter']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Open the Due Date menu for a card',
      shortcuts: ['D']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Archive a card',
      shortcuts: ['C']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Open the Filter menu',
      shortcuts: ['F']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Show only your tasks',
      shortcuts: ['Q']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Open/Close board settings',
      shortcuts: ['R']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Collapse/Expand sidebar',
      shortcuts: ['[']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Open/Close board menu',
      shortcuts: [']']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Clear all filter',
      shortcuts: ['X']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Navigate to the previous card in the section',
      shortcuts: ['↑'],
      alternative: ['K']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Navigate to the next card in the section',
      shortcuts: ['↓'],
      alternative: ['J']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Navigate to the previous/next section',
      shortcuts: ['←', '→']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Move card to the left section',
      shortcuts: [','],
      alternative: ['<']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Move card to the right section',
      shortcuts: ['.'],
      alternative: ['>']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Edit card title',
      shortcuts: ['T']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Edit card description',
      shortcuts: ['Shift', 'T']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Close any open card, menu, or popup',
      shortcuts: ['Esc']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Add/Remove members',
      shortcuts: ['A']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Copy a card',
      shortcuts: ['meta', 'C']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Create a copied card or add an attachment',
      shortcuts: ['meta', 'V']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Open the Quick Action’s card menu',
      shortcuts: ['E']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Open shortcut help',
      shortcuts: ['meta', '.']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    description: {
      name: 'Open the Labels menu',
      shortcuts: ['L']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Add task dependencies',
      shortcuts: ['P']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Add module',
      shortcuts: ['S']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Assign me to task/Leave task',
      shortcuts: ['Space'],
      alternative: ['I']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Select a range of tasks upwards',
      shortcuts: ['Shift', '↑']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Select a range of tasks downwards',
      shortcuts: ['Shift', '↓']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Select range of tasks',
      shortcuts: ['Shift', 'Click']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Select multiple individual tasks',
      shortcuts: ['meta', 'Click']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Move focus cell',
      shortcuts: ['←', '→', '↑', '↓']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Open task/Edit cell',
      shortcuts: ['Enter']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Delete cell value',
      shortcuts: ['Backspace']
    },
    category: ShortcutCategory.LIST
  },
  // {
  //   description: {
  //     name: 'Navigate to board view',
  //     shortcuts: ['Shift', '1']
  //   },
  //   category: ShortcutCategory.GENERAL
  // },
  // {
  //   description: {
  //     name: 'Navigate to list view',
  //     shortcuts: ['Shift', '2']
  //   },
  //   category: ShortcutCategory.GENERAL
  // },
  // {
  //   description: {
  //     name: 'Navigate to timeline view',
  //     shortcuts: ['Shift', '3']
  //   },
  //   category: ShortcutCategory.GENERAL
  // }
]
