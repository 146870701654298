import { FIELD_FRAGMENT } from '#field/fragment'

export const TASK_TYPE_FRAGMENT = gql`
  ${FIELD_FRAGMENT}
  fragment TaskType on TaskTypeType {
    id
    name
    description
    icon
    background
    level
    settingsPackId
    fields {
      ...Field
    }
  }
`

export const TASK_TYPE_WITH_FIELDS_FRAGMENT = gql`
  ${FIELD_FRAGMENT}
  fragment TaskTypesWithFields on TaskTypeType {
    id
    level
    fieldsWithPosition {
      id
      field {
        ...Field
      }
    }
  }
`
