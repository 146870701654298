import { capitalize, cloneDeep } from 'lodash-es'
import {
  ACCESS_TOKEN_KEY,
  AUTH_STORAGE_KEY,
  MESSAGE_CODE,
  SERVER_ERROR_MESSAGE,
} from '#auth/constant'
import {
  CURRENT_USER_QUERY,
  LOGIN_MUTATION,
  LOGOUT_MUTATION,
  SIGNUP_MUTATION,
  COMPLETE_TUTORIAL,
  SET_LAST_ACTIVE_WORKSPACE_MUTATION,
} from '#auth/schema'
import type {
  AuthState,
  CurrentUser,
  LoginParams,
  SignUpParams,
} from '#auth/types'
import { handleSingleGraphQLError } from '#core/utils/errors'

export const useAuthStore = defineStore('auth', () => {
  const toast = useToast()
  const { result, load, refetch, onResult, onError } =
    useLazyQuery<CurrentUser>(CURRENT_USER_QUERY)
  const accessToken = useCookie(ACCESS_TOKEN_KEY, {
    maxAge: 60 * 60 * 24 * 30,
  })

  const state = ref<AuthState>({
    token: null,
    isLoggedIn: false,
    doNotRedirectAfterLogout: false,
    user: {
      id: '',
      email: '',
      fullName: '',
      photo: '',
      completedTutorial: false,
      emailVerified: false,
    },
  })

  onResult(() => {
    if (result.value) {
      state.value.isLoggedIn = true
      state.value.user = cloneDeep(result.value.currentUser)
    }
  })
  onError((err) => {
    if (err.message === SERVER_ERROR_MESSAGE) {
      return
    }

    state.value.isLoggedIn = false
    accessToken.value = null
  })

  const login = async (
    params: LoginParams
  ): Promise<{ success: boolean; message: string }> => {
    state.value.doNotRedirectAfterLogout = false
    const connectSocialAccountToken = sessionStorage.getItem(
      AUTH_STORAGE_KEY.CONNECT_SOCIAL_ACCOUNT_TOKEN
    )

    if (connectSocialAccountToken) {
      params = {
        ...params,
        connectSocialAccountToken,
      }
    }

    const { mutate, error } = useMutation(LOGIN_MUTATION, {
      variables: params,
      errorPolicy: 'none',
      throws: 'never',
    })
    const response = await mutate()
    if (error.value) {
      return {
        success: false,
        message: error.value?.message as string,
      }
    }

    const { tokenAuth } = response!.data
    await setToken(tokenAuth.token)
    if (tokenAuth.messageCode === MESSAGE_CODE.CONNECT_SOCIAL_ACCOUNT_SUCCESS) {
      toast.add({
        title: 'Connected successful',
        description: `Successfully connected with ${
          tokenAuth.provider ? capitalize(tokenAuth.provider) : 'social'
        } account`,
      })
      sessionStorage.removeItem(AUTH_STORAGE_KEY.CONNECT_SOCIAL_ACCOUNT_TOKEN)
    }

    if (!(await load())) {
      await refetch()
    }

    return {
      success: true,
      message: 'Logged in successfully',
    }
  }

  const signup = async (
    params: SignUpParams
  ): Promise<{
    success: boolean
    message: string
    workspaceHandle: string
  }> => {
    const { mutate, error } = useMutation(SIGNUP_MUTATION, {
      variables: params,
      errorPolicy: 'none',
      throws: 'never',
    })
    const data = await mutate()
    if (error.value) {
      return {
        success: false,
        message: error.value?.message as string,
        workspaceHandle: '',
      }
    }

    return {
      success: true,
      message: 'Created account successfully',
      workspaceHandle: data?.data.createUser.workspace.handle,
    }
  }

  const completeTutorial = async () => {
    const { mutate } = useMutation(COMPLETE_TUTORIAL)
    const data = await mutate()
    extend(state.value.user, {
      completedTutorial: data?.data.completeTutorial.success,
    })
  }

  const logout = async (doNotRedirectAfterLogout = false) => {
    const { mutate } = useMutation(LOGOUT_MUTATION, {
      throws: 'never',
    })
    await mutate()

    state.value.doNotRedirectAfterLogout = doNotRedirectAfterLogout
    accessToken.value = null
    state.value.isLoggedIn = false
  }

  const setToken = async (token: string) => {
    accessToken.value = token
    state.value.token = token
  }

  const setLastActiveWorkspace = async (workspaceId: string) => {
    const { mutate } = useMutation(SET_LAST_ACTIVE_WORKSPACE_MUTATION, {
      variables: { workspaceId },
    })
    try {
      await mutate()
    } catch (error) {
      handleSingleGraphQLError(
        error,
        true,
        'Failed to set last active workspace'
      )
    }
  }

  return {
    accessToken: computed(() => accessToken.value),
    auth: computed(() => state.value.user),
    doNotRedirectAfterLogout: computed(() => state.value.doNotRedirectAfterLogout),
    isLoggedIn: computed(() => state.value.isLoggedIn),
    login,
    signup,
    fetchCurrentUser: load,
    completeTutorial,
    logout,
    setLastActiveWorkspace,
    setToken,
  }
})
