import type { ActionPaginationType, BoardActionFilterInput, TaskActionFilterInput } from '#action/types'
import { TASK_ACTION_QUERY, ADD_UPDATE_COMMENT_MUTATION, DELETE_COMMENT_MUTATION, TOGGLE_COMMENT_REACTION_MUTATION, BOARD_ACTION_QUERY } from '#action/schema'

// Lazy query for TASK_ACTION_QUERY
export const useLazyTaskActionsQuery = (filter: TaskActionFilterInput) => {
  return useLazyQuery<{ taskActions: ActionPaginationType }>(TASK_ACTION_QUERY, { filter }, { debounce: 500 })
}

// Lazy query for TASK_ACTION_QUERY
export const useLazyBoardActionsQuery = (filter: BoardActionFilterInput) => {
  return useLazyQuery<{ boardActions: ActionPaginationType }>(BOARD_ACTION_QUERY, { filter }, { debounce: 500, fetchPolicy: 'network-only' })
}

// Mutation for ADD_UPDATE_COMMENT_MUTATION
export const useAddUpdateCommentMutation = () => {
  return useMutation(ADD_UPDATE_COMMENT_MUTATION)
}

// Mutation for DELETE_COMMENT_MUTATION
export const useDeleteCommentMutation = (commentId: string) => {
  return useMutation(DELETE_COMMENT_MUTATION, {
    variables: { commentId },
    update: (cache, { data }) => {
      if (data?.deleteComment.actionId) {
        cache.evict({ id: `ActionType:${data?.deleteComment.actionId}` })
      }
    },
  })
}

// Mutation for TOGGLE_COMMENT_REACTION_MUTATION
export const useToggleCommentReactionMutation = () => {
  return useMutation(TOGGLE_COMMENT_REACTION_MUTATION)
}
