<template>
  <EditorContent ref="editor" v-bind="$attrs" />
</template>

<script lang="ts" setup>
import { provideEditorContext } from '#core/editor_context'

provideEditorContext()
const editor = ref()

defineExpose({
  editor,
})
</script>
