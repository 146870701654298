<template>
  <div class="w-full">
    <DefineBlockTemplate v-slot="{ dependency, btnAttrs, showHint = true }">
      <div
        :class="{
          'text-orange-600': isBlocking(dependency.fromTaskId),
          'text-yellow-600': !isBlocking(dependency.fromTaskId),
        }"
      >
        <UButton
          color="white"
          size="sm"
          trailing-icon="i-heroicons-chevron-down-20-solid"
          :leading-icon="
            isBlocking(dependency.fromTaskId)
              ? 'i-heroicons-x-circle'
              : 'i-heroicons-minus-circle'
          "
          class="text-current hover:!text-current"
          v-bind="btnAttrs"
        >
          <span class="text-xs">
            {{ isBlocking(dependency.fromTaskId) ? 'Blocking' : 'Blocked by' }}
            <template v-if="showHint">
              :
              {{ getTaskDependencyHint(dependency.type, true) }}
            </template>
          </span>
        </UButton>
      </div>
    </DefineBlockTemplate>
    <DefineTemplate v-slot="{ dependency, onChangeType }">
      <UPopover
        :ui="{ width: 'w-fit' }"
        :popper="{
          placement: 'bottom-start',
          strategy: 'fixed',
        }"
        v-bind="$attrs"
      >
        <template #panel="{ close }">
          <div class="flex items-center justify-between px-4 pt-3 pb-2">
            <div class="flex items-center gap-1">
              <p class="font-semibold text-sm text-gray-900">Dependency type</p>
            </div>
            <UButton
              size="xs"
              icon="i-heroicons-x-mark"
              color="gray"
              variant="ghost"
              @click="close"
            />
          </div>
          <div class="pt-0 p-4 flex items-center justify-between gap-2">
            <UDropdown
              class="w-full"
              :items="[blockTypeItems]"
              :popper="{ placement: 'bottom-start' }"
              :ui="{
                item: {
                  padding: 'p-0',
                },
              }"
            >
              <template #item="{ item }">
                <div
                  class="w-full flex justify-between px-1.5 py-1.5"
                  @click.stop="item?.click(dependency)"
                >
                  <div
                    :class="{
                      'text-orange-600':
                        item.value === TaskDependencyBlockType.BLOCKING,
                      'text-yellow-600':
                        item.value === TaskDependencyBlockType.BLOCKED_BY,
                    }"
                    class="flex items-center gap-1"
                  >
                    <Icon
                      :name="
                        item.value === TaskDependencyBlockType.BLOCKING
                          ? 'i-heroicons-x-circle'
                          : 'i-heroicons-minus-circle'
                      "
                      size="18"
                    />
                    <span class="truncate text-sm space-x-1">
                      {{ item.label }}
                    </span>
                  </div>
                  <UIcon
                    v-if="item.value === dependency.blockType"
                    name="i-heroicons-check"
                    class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto"
                  />
                </div>
              </template>
              <ReuseBlockTemplate :dependency="dependency" :show-hint="false" />
            </UDropdown>
            <TaskDependencyDropdown
              class="w-full min-w-40"
              :current-type="dependency.type"
              :removeable="false"
              @change-type="onChangeType?.($event)"
            >
              <UButton
                color="white"
                class="text-xs w-full"
                trailing-icon="i-heroicons-chevron-down-20-solid"
              >
                {{ getTaskDependencyHint(dependency.type) }} -
                {{ getTaskDependencyHint(dependency.type, true) }}
              </UButton>
            </TaskDependencyDropdown>
          </div>
        </template>
        <ReuseBlockTemplate
          :dependency="dependency"
          :btn-attrs="{
            variant: 'ghost',
            color: 'gray',
          }"
        />
      </UPopover>
    </DefineTemplate>
    <div
      v-for="{
        id,
        type,
        fromTaskId,
        toTaskId,
        blockType,
        relatedTask,
      } in formattedDependencies"
      :key="id"
      class="flex items-center gap-1 text-xs group w-full"
    >
      <div class="flex-initial">
        <ReuseTemplate
          :dependency="{ id, type, fromTaskId, toTaskId, blockType }"
          :on-change-type="
            (type: TaskDependencyType) => {
              updateDependency(id, {
                type,
              })
            }
          "
        />
      </div>
      <UButton
        size="sm"
        color="gray"
        variant="ghost"
        class="flex items-center gap-1 truncate relative flex-1 min-w-0"
        @click="onOpenTask(relatedTask)"
      >
        <Tooltip
          :text="relatedTask.name"
          :compare-dimenssions="['width', 'height']"
        >
          <template #default="{ getTextRef }">
            <span
              :ref="getTextRef"
              class="text-gray-900 truncate max-w-32 cursor-pointer"
            >
              {{ relatedTask.name }}
            </span>
          </template>
        </Tooltip>
        <div
          v-if="relatedTask.startDate || relatedTask.dueDate"
          class="flex items-center gap-1 text-gray-500"
        >
          <span>-</span>
          <TaskDatesFormat
            :start="relatedTask.startDate"
            :end="relatedTask.dueDate"
          />
        </div>
      </UButton>
      <div class="group-hover:block hidden h-full flex-initial">
        <UTooltip
          text="Remove dependency"
          :popper="{ placement: 'top', arrow: true }"
        >
          <UButton
            icon="i-heroicons-x-mark"
            variant="ghost"
            color="gray"
            size="xs"
            @click="removeDependency(id)"
          />
        </UTooltip>
        <UTooltip
          text="Create dependency"
          :popper="{ placement: 'top', arrow: true }"
        >
          <UButton
            icon="i-heroicons-plus-small"
            variant="ghost"
            color="gray"
            size="xs"
            @click="createableVisible = true"
          />
        </UTooltip>
      </div>
    </div>
  </div>
  <div v-if="createableVisible" class="flex items-center w-full gap-1">
    <ReuseTemplate
      :dependency="createableForm"
      :on-change-type="(type: TaskDependencyType) => (createableForm.type = type)"
    />
    <TaskListSelectPopover
      heading="Select task"
      class="w-full flex-1"
      :board-id="boardId"
      :accept-levels="[TaskLevel.MODULE, TaskLevel.SUBTASK, TaskLevel.TASK]"
      :filter="({ id }) => !ignoreTaskIds.includes(id)"
      @change="onCreateTaskDependency"
    >
      <UButton
        color="gray"
        class="w-full justify-between"
        size="sm"
        trailing-icon="i-heroicons-chevron-down"
      >
        Select task
      </UButton>
    </TaskListSelectPopover>
    <UButton
      icon="i-heroicons-x-mark"
      variant="ghost"
      color="gray"
      size="xs"
      @click="createableVisible = false"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  TaskDependencyBlockType,
  TaskDependencyType,
  TaskLevel,
} from '#task/constant'
import type {
  ExtraTaskDependency,
  TaskDependencyDetail,
  TaskItem,
} from '#task/types'

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
const [DefineBlockTemplate, ReuseBlockTemplate] = createReusableTemplate()

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
  dependencies: {
    type: Array as PropType<TaskDependencyDetail[]>,
    required: true,
  },
})

const blockTypeItems = [
  {
    label: 'Blocking',
    value: TaskDependencyBlockType.BLOCKING,
    click: (dependency: ExtraTaskDependency) => {
      onChangeBlockType(dependency, TaskDependencyBlockType.BLOCKING)
    },
  },
  {
    label: 'Blocked by',
    value: TaskDependencyBlockType.BLOCKED_BY,
    click: (dependency: ExtraTaskDependency) => {
      onChangeBlockType(dependency, TaskDependencyBlockType.BLOCKED_BY)
    },
  },
]

const { currentTask, setCurrentTask } = useWorkspaceSharedState()
const { createDependency, updateDependency, removeDependency } =
  useTaskDependency()

const createableVisible = ref(false)
const createableForm = reactive({
  type: TaskDependencyType.START_TO_START,
  fromTaskId: props.taskId,
  toTaskId: '',
  blockType: TaskDependencyBlockType.BLOCKING,
})

const formattedDependencies = computed(() => {
  return props.dependencies.map(({ fromTask, toTask, ...dependency }) => {
    const relatedTask = fromTask.id === props.taskId ? toTask : fromTask
    const blockType =
      fromTask.id === props.taskId
        ? TaskDependencyBlockType.BLOCKING
        : TaskDependencyBlockType.BLOCKED_BY
    return {
      ...dependency,
      fromTaskId: fromTask.id,
      toTaskId: toTask.id,
      relatedTask,
      blockType,
    }
  })
})

const ignoreTaskIds = computed(() => {
  const relatedTaskIds = formattedDependencies.value.map(
    ({ relatedTask }) => relatedTask.id
  )
  relatedTaskIds.push(props.taskId)
  return relatedTaskIds
})

const onOpenTask = (task: TaskDependencyDetail['fromTask']) => {
  const currentView = getBoardViewByUrl()
  updateTaskUrl(task, currentView && `/${currentView}`)
  setCurrentTask({
    ...task,
    viewMode: currentTask.value.viewMode,
  })
}

const onCreateTaskDependency = (task: TaskItem) => {
  const { blockType, type } = createableForm
  const isBlocking = blockType === TaskDependencyBlockType.BLOCKING
  const fromTaskId = isBlocking ? props.taskId : task.id
  const toTaskId = isBlocking ? task.id : props.taskId
  createDependency(props.boardId, {
    fromTaskId,
    toTaskId,
    type: type,
  })
  createableVisible.value = false
}

const onChangeBlockType = (
  dependency: ExtraTaskDependency,
  blockType: TaskDependencyBlockType
) => {
  if (dependency.blockType !== blockType) {
    /**
     * If dependency has id, update the dependency, else update createable form
     */
    if (dependency.id) {
      return updateDependency(dependency.id, {
        swap: true,
        type: dependency.type,
      })
    }

    const isBlocking = blockType === TaskDependencyBlockType.BLOCKING
    extend(createableForm, {
      blockType,
      fromTaskId: isBlocking ? props.taskId : '',
      toTaskId: isBlocking ? '' : props.taskId,
    })
  }
}

const isBlocking = (fromTaskId: string) => {
  return fromTaskId === props.taskId
}

defineExpose({
  showCreateable() {
    createableVisible.value = true
  },
})
</script>
