import { STATUS_FRAGMENT } from '#status/fragment'

export const STATUSES_QUERY = gql`
  ${STATUS_FRAGMENT}
  query StatusesQuery($parentId: ID!, $source: String!, $loadFull: Boolean) {
    statuses(id: $parentId, source: $source, loadFull: $loadFull) {
      ...Status
      settingsPack {
        id
        name
      }
    }
  }
`

export const ADD_UPDATE_STATUS_MUTATION = gql`
  ${STATUS_FRAGMENT}
  mutation AddUpdateStatusMutation($input: AddUpdateStatusInput!) {
    addUpdateStatus(input: $input) {
      status {
        ...Status
      }
    }
  }
`

export const DELETE_STATUS_MUTATION = gql`
  mutation DeleteStatusMutation($id: ID!, $replaceToId: ID) {
    deleteStatus(id: $id, replaceToId: $replaceToId) {
      success
    }
  }
`
