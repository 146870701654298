<template>
  <DeferredPopover
    v-model:open="open"
    :ui="{
      width: 'w-[280px]',
      wrapper: 'h-full',
      trigger: 'h-full flex items-center',
    }"
    v-bind="$attrs"
  >
    <template #content>
      <div class="flex items-center justify-between px-3 py-2">
        <p class="font-semibold text-sm text-gray-900">Link task to module</p>
        <UButton
          size="xs"
          icon="i-heroicons-x-mark"
          color="gray"
          variant="ghost"
          @click="open = false"
        />
      </div>
      <div v-if="!tasks.length" class="mb-4">
        <p class="text-sm text-gray-700 px-4">
          No task to link this module. Please create task first.
        </p>
      </div>
      <div v-else class="pl-2">
        <div class="pl-2 pr-4">
          <UInput
            v-model="search"
            icon="i-heroicons-magnifying-glass"
            size="sm"
            autofocus
            placeholder="Find a tasks"
          />
        </div>
        <div
          class="mt-3 pb-2 max-h-[18.25rem] overflow-auto pr-1 scroll-stable minimal-scrollbar space-y-1"
        >
          <div
            v-for="(task, index) in filterTasks"
            ref="optionRefs"
            :key="task.id"
            :class="[
              'flex group/item items-center text-gray-900 justify-between px-2 py-1.5 cursor-pointer rounded-md hover:bg-gray-100 ',
              focusIndex === index && 'bg-gray-100',
            ]"
            @mouseenter="focusIndex = index"
            @mouseleave="focusIndex = -1"
            @click="onSelect(task)"
          >
            <div class="flex items-center gap-1.5 truncate">
              <TaskTypeIcon
                size="sm"
                :background="task.type.background"
                :icon="task.type.icon"
              />
              <span class="text-sm text-gray-400"> #{{ task.number }} </span>
              <span
                class="text-sm text-gray-700 truncate"
                data-test="popover-module-name"
              >
                {{ task.name }}
              </span>
            </div>
            <UButton
              size="2xs"
              color="gray"
              variant="solid"
              class="group-hover/item:opacity-100 opacity-0"
              label="Add"
            />
          </div>
        </div>
        <slot name="footer" />
      </div>
    </template>

    <UButton
      variant="ghost"
      size="xs"
      color="gray"
      icon="heroicons:plus-small"
      data-test="add-subtask-btn"
      :disabled="!filterTasks.length"
    >
      Link task to module
    </UButton>
  </DeferredPopover>
</template>

<script lang="ts" setup>
import { TaskLevel } from '#task/constant'
import type { TaskItem } from '#task/types'

const props = defineProps({
  boardId: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectedTaskIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  parentId: {
    type: String,
    required: true,
  },
})

const search = ref('')
const open = ref(false)
const { tasks: allTasks } = useBoardTasksLoader()
const { updateTask } = useUpdateTask()
const toast = useToast()

const tasks = computed(() => {
  return allTasks.value
})

const filterTasks = computed(() => {
  return tasks.value.filter((task) =>
    task.name.toLowerCase().includes(search.value.toLowerCase()) &&
    task.level === TaskLevel.TASK &&
    !props.selectedTaskIds.includes(task.id)
  )
})

const onSelect = async (task: TaskItem | null) => {
  if (task) {
    await updateTask(task.id, {
      parent: props.parentId,
    })

    toast.add({
      title: 'Task linked to module',
    })
  }

  if (filterTasks.value.length === 0) {
    open.value = false
  }
}

const { focusIndex, optionRefs } = useDropdownShortcutKey({
  whenever: open,
  options: filterTasks,
  onEscape: () => {
    open.value = false
  },
  onEnter: (option: typeof filterTasks.value[number]) => {
    onSelect(option)
  },
})

watch(
  () => open.value,
  (isOpen) => {
    if (isOpen) {
      search.value = ''
      focusIndex.value = -1
    }
  }
)
</script>
