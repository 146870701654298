export enum STATUS_TYPE {
  NOT_STARTED = 0,
  ACTIVE = 1,
  DONE = 2,
}

export const STATUS_TYPE_CHOICES = [
  {
    value: STATUS_TYPE.NOT_STARTED,
    text: 'Not Started',
  },
  {
    value: STATUS_TYPE.ACTIVE,
    text: 'Active',
  },
  {
    value: STATUS_TYPE.DONE,
    text: 'Done',
  },
]

export const STATUS_TYPE_CLASS = {
  [STATUS_TYPE.NOT_STARTED]: {
    text: 'text-gray-700',
    bg: 'bg-gray-50',
    border: 'border-gray-200 hover:border-gray-400',
  },
  [STATUS_TYPE.ACTIVE]: {
    text: 'text-yellow-600',
    bg: 'bg-yellow-50',
    border: 'border-yellow-600/25 hover:border-yellow-600',
  },
  [STATUS_TYPE.DONE]: {
    text: 'text-primary-600',
    bg: 'bg-primary-50',
    border: 'border-primary-600/25 hover:border-primary-600',
  },
}

export const STATUS_TYPE_NAME = 'StatusType'
