<template>
  <div class="flex items-center mb-4 gap-1 -translate-x-1 z-10 relative" data-test="task-details-header">
    <!-- Parent Task -->
    <div
      v-if="taskDetail.parent"
      class="flex items-center"
      @click="onOpenParentTask"
    >
      <Tooltip :text="taskDetail.parent.name">
        <template #default="{ getTextRef }">
          <UButton
            v-if="taskDetail.level === TaskLevel.TASK"
            color="white"
            variant="ghost"
            size="2xs"
            class="items-center flex hover:bg-gray-100 py-1 px-1.5"
            data-test="task-parent"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
          >
            <template #leading>
              <TaskTypeIcon
                transparent
                icon-class="text-gray-700"
                :size-wrapper="false"
                :icon="taskDetail.parent.type?.icon"
              />
            </template>
            <span
              :ref="getTextRef"
              class="text-xs font-medium line-clamp-1 break-all max-w-72 leading-4"
            >
              {{ taskDetail.parent.name }}
            </span>
          </UButton>
          <UButton
            v-else
            color="white"
            size="2xs"
            variant="ghost"
            class="hover:bg-gray-100 py-1 px-1.5"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
          >
            <template #leading>
              <TaskTypeIcon
                transparent
                icon-class="text-gray-700"
                :size-wrapper="false"
                :icon="taskDetail.parent?.type?.icon"
              />
            </template>
            <span
              :ref="getTextRef"
              class="text-xs font-medium line-clamp-1 break-all max-w-72 leading-4"
            >
              {{ taskDetail.parent.name }}
            </span>
          </UButton>
        </template>
      </Tooltip>
      <span class="text-gray-400 ml-1">/</span>
    </div>
    <!-- Current Task -->
    <TaskTypeSelectPopover
      v-if="taskDetail?.type"
      source="board"
      :parent-id="taskDetail.boardId"
      class="flex items-center"
      :selected-task-type-id="taskDetail.typeId"
      :accept-levels="[taskDetail.level]"
      disable-setting
      @change="onChangeTaskType($event.id)"
    >
      <template #default="{ open, isOpen }">
        <UButton
          size="2xs"
          color="white"
          variant="ghost"
          class="hover:bg-gray-100 flex items-center p-0 max-h-6 group gap-x-0"
          :disabled="!can('dashboard.data.managing_tasks_sections')"
          @click.prevent.stop="
            taskDetail.level === TaskLevel.TASK && open()
          "
        >
          <Tooltip
            :text="`${taskDetail.type.name}: Change task type`"
            :prevent="taskDetail.type.level !== TaskLevel.TASK || isOpen"
          >
            <div
              :class="[
                'flex items-center gap-1 py-1 px-1.5  rounded-l-md',
                {
                  'hover:bg-gray-200':
                    taskDetail.type.level === TaskLevel.TASK,
                },
              ]"
            >
              <div class="relative">
                <div
                  :class="[
                    'visible',
                    {
                      'group-hover:invisible':
                        taskDetail.type.level === TaskLevel.TASK,
                    },
                  ]"
                >
                  <TaskTypeIcon
                    transparent
                    icon-class="text-gray-700"
                    :size-wrapper="false"
                    :icon="taskDetail.type.icon"
                  />
                </div>
                <div
                  v-if="taskDetail.type.level === TaskLevel.TASK"
                  class="h-full flex items-center absolute inset-0 invisible group-hover:visible"
                >
                  <UIcon
                    name="i-heroicons-chevron-down-20-solid"
                    :size="16"
                    class="absolute"
                  />
                </div>
              </div>
              <div class="text-xs font-medium leading-4">
                <span data-test="task-type">
                  {{ taskDetail.type.name }}
                </span>
                #{{ taskDetail.number }}
              </div>
            </div>
          </Tooltip>
          <template #trailing>
            <UTooltip
              v-if="taskDetail.number"
              text="Copy ID number"
              :popper="{ placement: 'top', arrow: true }"
              class="h-full"
            >
              <UButton
                size="xs"
                class="px-1 py-0 flex items-center rounded-l-none hover:bg-gray-200 bg-gray-100 h-full group-hover:visible invisible gap-x-1"
                variant="ghost"
                color="white"
                @click.prevent.stop="copy(taskDetail.number.toString())"
              >
                <Icon name="i-heroicons-document-duplicate" :size="16" />
                <span v-if="copied" class="text-xs font-medium">
                  Copied!
                </span>
              </UButton>
            </UTooltip>
          </template>
        </UButton>
      </template>
    </TaskTypeSelectPopover>
  </div>
</template>

<script lang="ts" setup>
import type {
  TaskDetail,
} from '#task/types'
import { TaskLevel } from '#task/constant'

const props = defineProps({
  taskDetail: {
    type: Object as PropType<TaskDetail>,
    required: true,
  },
})

const { copy, copied } = useClipboard()
const { setCurrentTask } = useWorkspaceSharedState()
const { updateTask } = useUpdateTask()
const { can } = useBoardAbility()

const onChangeTaskType = async (type: string) => {
  await updateTask(props.taskDetail.id, { type })
}

const onOpenParentTask = () => {
  if (!props.taskDetail.parent) {
    return
  }

  const prefix = /^(.*)\/t\/.+$/.exec(window.location.pathname)?.[1]

  updateTaskUrl(props.taskDetail.parent, prefix)
  setCurrentTask(props.taskDetail.parent)
}
</script>
