<template>
  <DeferredPopover
    ref="popoverRef"
    :ui="{ width: 'w-[280px]', trigger: `flex ${disabled ? '!cursor-not-allowed' : ''}` }"
    @update:open="$emit('update:open', $event)"
  >
    <template #content>
      <PopoverContentLabelList
        source="board"
        heading="Add labels"
        :selected-ids="selectedIds"
        :parent-id="parentId"
        :task-id="taskId"
        @close="popoverRef.close()"
        @select="$emit('select', $event)"
        @remove="$emit('remove', $event)"
        @click.prevent.stop
      />
    </template>
    <slot />
  </DeferredPopover>
</template>

<script lang="ts" setup>
defineProps({
  selectedIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  parentId: {
    type: String,
    required: true,
  },
  taskId: {
    type: String,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
  heading: {
    type: String,
    default: '',
  },
  editMultiple: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['select', 'remove', 'update:open'])

const popoverRef = ref()
provide('popoverRef', popoverRef)

defineExpose({
  open: () => {
    popoverRef.value?.open()
  }
})
</script>
