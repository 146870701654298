import type {
  Cache,
  OperationVariables,
  UseFragmentResult,
  UseFragmentOptions,
} from '@apollo/client'
import { isEqual } from 'lodash-es'

export const useRootQuery = () => {
  const { client } = useApolloClient()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFieldRefs = <T = any>(
    fieldName: string,
    args: Record<string, unknown>
  ): T | undefined => {
    const fieldWithArgs = getRootQueryFieldRefs(fieldName, args)
    return client.cache.extract().ROOT_QUERY[fieldWithArgs] as T
  }

  return {
    getFieldRefs,
  }
}

export const useExtractItem = (id: string, typeName: string) => {
  const { client } = useApolloClient()

  const cacheId = client.cache.identify({
    id,
    __typename: typeName,
  })
  if (!cacheId) {
    return
  }

  const item = client.cache.extract()?.[cacheId]
  if (!item) {
    return
  }

  return item
}

export const useWatchFragment = <TData = unknown, TVars = OperationVariables>(
  options: Omit<UseFragmentOptions<TData, TVars>, 'from'> & {
    id: UseFragmentOptions<TData, TVars>['from']
    onUpdate?: (snapshot: UseFragmentResult<TData>) => void
  }
): { snapshot: Ref<UseFragmentResult<TData>>; unsubscribe?: () => void } => {
  const { client } = useApolloClient()

  const {
    id,
    fragment,
    fragmentName,
    optimistic = true,
    onUpdate,
    ...rest
  } = options
  const diffOptions: Cache.DiffOptions<TData, TVars> = {
    ...rest,
    returnPartialData: true,
    id: typeof id === 'string' ? id : client.cache.identify(id),
    query: client.cache['getFragmentDoc'](fragment, fragmentName),
    optimistic,
  }

  let latestDiff = client.cache.diff<TData>(diffOptions)
  const resultRef = ref<UseFragmentResult<TData>>()

  const snapshot = computed(() => {
    const latestDiffToResult = diffToResult(latestDiff)
    return resultRef.value && isEqual(resultRef.value, latestDiffToResult)
      ? resultRef.value
      : (resultRef.value = latestDiffToResult)
  })

  const diffToResult = (
    diff: Cache.DiffResult<TData>
  ): UseFragmentResult<TData> => {
    const result = {
      data: diff.result,
      complete: !!diff.complete,
    } as UseFragmentResult<TData>

    return result
  }

  const unsubscribe = client.cache.watch({
    ...diffOptions,
    immediate: true,
    callback: (diff) => {
      if (diff && !isEqual(diff, latestDiff)) {
        if (!diff.complete && latestDiff.complete) {
          resultRef.value = diffToResult(latestDiff)
          return onUpdate?.(snapshot.value)
        }

        latestDiff = diff
        resultRef.value = diffToResult(diff)
        onUpdate?.(snapshot.value)
      }
    },
  })

  tryOnUnmounted(() => {
    unsubscribe()
  })

  return {
    snapshot,
    unsubscribe,
  }
}

interface UseWatchFragmentsResult<TData, TVars> {
  snapshots: Ref<UseFragmentResult<TData>>[];
  unsubscribeAll: () => void;
}

export const useWatchFragments = <TData = unknown, TVars = OperationVariables>(
  fragments: Parameters<typeof useWatchFragment<TData, TVars>>[0][]
): UseWatchFragmentsResult<TData, TVars> => {
  const unsubscribes: (() => void)[] = []
  const snapshots: Ref<UseFragmentResult<TData>>[] = []

  fragments.forEach((fragmentOption) => {
    const { snapshot, unsubscribe } = useWatchFragment<TData, TVars>(fragmentOption)
    unsubscribes.push(unsubscribe || (() => {}))
    snapshots.push(snapshot)
  })

  const unsubscribeAll = () => {
    unsubscribes.forEach((unsubscribe) => unsubscribe())
  }

  tryOnUnmounted(() => {
    unsubscribeAll()
  })

  return {
    snapshots,
    unsubscribeAll,
  }
}
