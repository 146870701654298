import { ShortcutBusKey, ShortcutKeyEvent } from '#core/shortcut_keys_constants'
import { getCurrentView, emitEventBus } from '#core/utils/helper'
import type { TaskItemLoader } from '#task/types'
import { TaskLevel } from '#task/constant'
import type { ShortcutsConfig } from '#ui/composables/defineShortcuts.js'

export const useShortcutKeyHandler = () => {
  const { activeSectionIndex, activeTaskPosition, isActiveKanbanTask } =
    useKanbanSharedState()
  const { activeGroupIndex } = useListViewSharedState()
  const { currentFocusTaskId, currentFocusColumnKey } =
    useSpreadsheetInteractions()

  const { toggleBoardSettingsSlideover } = useBoardSharedState()
  const { currentTask, setCurrentTask } = useWorkspaceSharedState()

  const isKanbanView = () => getCurrentView() === 'b-handle'
  const isListView = () => getCurrentView() === 'b-handle-list'
  const isKanbanTaskDetail = () => getCurrentView() === 't-handle'
  const isListViewTaskDetail = () => getCurrentView() === 't-handle-list'
  const isOpenTaskDetail = () => isKanbanTaskDetail() || isListViewTaskDetail()

  const { tasks: allTasks } = useBoardTasksLoader()
  const tasks = computed<TaskItemLoader[]>(() => {
    return allTasks.value.filter(
      (task) => !task.parentClosed
    ) as TaskItemLoader[]
  })
  const { filteredTasks } = useApplyTaskFilter({
    tasks,
  })

  const goToTaskDetail = (direction: string) => {
    let currentTaskDetail = filteredTasks.value.find(
      (task) => task.id === currentTask.value?.id
    )
    if (!currentTaskDetail) {
      return
    }

    // If current task is a subtask, find the parent task
    if (currentTaskDetail.level === TaskLevel.SUBTASK) {
      currentTaskDetail = filteredTasks.value.find(
        (task) => task.id === currentTaskDetail?.parent?.id
      )
    }

    if (!currentTaskDetail) {
      return
    }

    const tasksBySection = filteredTasks.value.filter(
      (task) => task.sectionId === currentTaskDetail.sectionId
    )
    const taskIndex = tasksBySection.findIndex(
      (task) => task.id === currentTask.value?.id
    )
    if (taskIndex === -1) {
      return
    }

    // Find the target task, ignoring subtasks
    let targetIndex = direction === 'down' ? taskIndex + 1 : taskIndex - 1
    while (
      tasksBySection[targetIndex] &&
      tasksBySection[targetIndex]?.level === TaskLevel.SUBTASK
    ) {
      targetIndex += direction === 'down' ? 1 : -1
    }

    if (!tasksBySection[targetIndex]) {
      return
    }

    setCurrentTask(tasksBySection[targetIndex], true)
  }

  const kanbanTaskBusKey = computed(() =>
    [
      ShortcutBusKey.KANBAN_TASK,
      activeTaskPosition.value.column,
      activeTaskPosition.value.row,
    ].join('-')
  )

  const sectionBusKey = computed(() => {
    let sectionIndex = activeSectionIndex.value
    if (isActiveKanbanTask() && activeTaskPosition.value.column !== -1) {
      sectionIndex = activeTaskPosition.value.column
    }

    return [ShortcutBusKey.SECTION, sectionIndex].join('-')
  })

  const listViewGroupBusKey = computed(() =>
    [ShortcutBusKey.LISTVIEW_GROUP, activeGroupIndex.value].join('-')
  )

  const listViewCellBusKey = computed(() =>
    [
      ShortcutBusKey.LISTVIEW_TASK,
      currentFocusTaskId.value,
      currentFocusColumnKey.value,
    ].join('-')
  )

  const handleEmitListViewCellEvent = (event: string) => {
    // If no task is focused or hovered, return
    if (!currentFocusTaskId.value) {
      return
    }

    emitEventBus(listViewCellBusKey.value, event)
  }

  defineShortcuts({
    // VIEW: [KANBAN]
    // Create new task at kanban view and list view
    // Get current mouse position to find the section to create new task
    'n': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(sectionBusKey.value, ShortcutKeyEvent.ADD_NEW_CARD)
            break

          case isListView():
            emitEventBus(
              listViewGroupBusKey.value,
              ShortcutKeyEvent.ADD_NEW_CARD
            )
            break
        }
      },
    },
    // VIEW: [KANBAN]
    // Open task detail at kanban view
    'enter': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(kanbanTaskBusKey.value, ShortcutKeyEvent.OPEN_CARD)
            break

          case isListView():
            handleEmitListViewCellEvent(ShortcutKeyEvent.EDIT_CELL_LISTVIEW)
            break
        }
      },
    },
    // VIEW: [LIST_VIEW]
    // Delete cell value at list view
    'backspace': {
      whenever: [() => isListView()],
      usingInput: false,
      handler: () => {
        handleEmitListViewCellEvent(ShortcutKeyEvent.DELETE_CELL_VALUE)
        return
      },
    },
    // VIEW: [KANBAN, TASK DETAIL]
    // Open card due date at kanban view and task detail
    'd': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(
              kanbanTaskBusKey.value,
              ShortcutKeyEvent.OPEN_CARD_DUE_DATE
            )
            break

          case isOpenTaskDetail():
            emitEventBus(
              ShortcutBusKey.TASK,
              ShortcutKeyEvent.OPEN_CARD_DUE_DATE
            )
            break
        }
      },
    },
    // VIEW: [KANBAN, TASK DETAIL]
    // Archive task at kanban view and task detail
    'c': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(kanbanTaskBusKey.value, ShortcutKeyEvent.ARCHIVE_CARD)
            break

          case isOpenTaskDetail():
            emitEventBus(ShortcutBusKey.TASK, ShortcutKeyEvent.ARCHIVE_CARD)
            break
        }
      },
    },
    // VIEW: [KANBAN, TASK DETAIL]
    // Open assignee popover at kanban view and task detail
    'a': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(
              kanbanTaskBusKey.value,
              ShortcutKeyEvent.TOGGLE_MEMBER_MODAL
            )
            break

          case isOpenTaskDetail():
            emitEventBus(
              ShortcutBusKey.TASK,
              ShortcutKeyEvent.TOGGLE_MEMBER_MODAL
            )
            break
        }
      },
    },
    // VIEW: [KANBAN, TASK DETAIL]
    // Open label popover at kanban view and task detail
    'l': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(
              kanbanTaskBusKey.value,
              ShortcutKeyEvent.TOGGLE_TASK_LABELS
            )
            break

          case isOpenTaskDetail():
            emitEventBus(
              ShortcutBusKey.TASK,
              ShortcutKeyEvent.TOGGLE_TASK_LABELS
            )
            break
        }
      },
    },
    // VIEW: [KANBAN, TASK DETAIL]
    // Open Set Module popover at kanban view and task detail
    's': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(kanbanTaskBusKey.value, ShortcutKeyEvent.ADD_MODULE)
            break

          case isOpenTaskDetail():
            emitEventBus(ShortcutBusKey.TASK, ShortcutKeyEvent.ADD_MODULE)
            break
        }
      },
    },
    // VIEW: [TASK DETAIL]
    // Open set dependency popover at task detail
    'p': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isOpenTaskDetail():
            emitEventBus(
              ShortcutBusKey.TASK,
              ShortcutKeyEvent.ADD_TASK_DEPENDENCIES
            )
            break
        }
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // Open filter, except on task detail
    'f': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(ShortcutBusKey.FILTER, ShortcutKeyEvent.OPEN_FILTER)
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // Clear filter, except on task detail
    'x': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(ShortcutBusKey.FILTER, ShortcutKeyEvent.CLEAR_ALL_FILTER)
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // Toggle filter only me, except on task detail
    'q': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.FILTER,
          ShortcutKeyEvent.TOGGLE_FILTER_ONLY_ME
        )
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // Open/Close board settings, except on task detail
    'r': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.WORKSPACE,
          ShortcutKeyEvent.TOGGLE_BOARD_SETTINGS
        )
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // Open/Close Board menu, except on task detail
    ']': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        toggleBoardSettingsSlideover()
      },
    },
    // Case use TALEX keyboard
    'ư': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        toggleBoardSettingsSlideover()
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // Open/Close sidebar, except on task detail
    '[': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.WORKSPACE,
          ShortcutKeyEvent.TOGGLE_EXPAND_SIDEBAR
        )
      },
    },
    // Case use TALEX keyboard
    'ơ': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.WORKSPACE,
          ShortcutKeyEvent.TOGGLE_EXPAND_SIDEBAR
        )
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // [Shift + 1] Navigate to kanban view
    '!': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.WORKSPACE,
          ShortcutKeyEvent.NAVIGATE_BOARD_VIEW
        )
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // [Shift + 2] Navigate to list view
    '@': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.WORKSPACE,
          ShortcutKeyEvent.NAVIGATE_LIST_VIEW
        )
      },
    },
    // VIEW: [ALL]
    // EXCEPT: [TASK DETAIL]
    // [Shift + 3] Navigate to timeline view
    '#': {
      whenever: [() => !isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.WORKSPACE,
          ShortcutKeyEvent.NAVIGATE_TIMELINE_VIEW
        )
      },
    },
    // VIEW: [KANBAN, LIST_VIEW]
    // Arrow key: Right
    'arrowright': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(ShortcutBusKey.KANBAN, ShortcutKeyEvent.NAVIGATE_RIGHT)
            break

          case isListView():
            emitEventBus(
              ShortcutBusKey.LISTVIEW,
              ShortcutKeyEvent.NAVIGATE_RIGHT
            )
            break

          case isOpenTaskDetail():
            goToTaskDetail('down')
        }
      },
    },
    // VIEW: [KANBAN, LIST_VIEW]
    // Arrow key: Left
    'arrowleft': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(ShortcutBusKey.KANBAN, ShortcutKeyEvent.NAVIGATE_LEFT)
            break

          case isListView():
            emitEventBus(
              ShortcutBusKey.LISTVIEW,
              ShortcutKeyEvent.NAVIGATE_LEFT
            )
            break

          case isOpenTaskDetail():
            goToTaskDetail('up')
        }
      },
    },
    // VIEW: [KANBAN, LIST_VIEW]
    // Arrow key: Down
    'arrowdown': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(ShortcutBusKey.KANBAN, ShortcutKeyEvent.NAVIGATE_DOWN)
            break
        }
      },
    },
    'j': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(ShortcutBusKey.KANBAN, ShortcutKeyEvent.NAVIGATE_DOWN)
            break
          case isOpenTaskDetail():
            goToTaskDetail('down')
        }
      },
    },
    // VIEW: [KANBAN, LIST_VIEW]
    // Arrow key: Up
    'arrowup': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(ShortcutBusKey.KANBAN, ShortcutKeyEvent.NAVIGATE_UP)
            break
        }
      },
    },
    'k': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(ShortcutBusKey.KANBAN, ShortcutKeyEvent.NAVIGATE_UP)
            break
          case isOpenTaskDetail():
            goToTaskDetail('up')
        }
      },
    },
    // VIEW: [KANBAN]
    // Move task to right section
    '.': {
      whenever: [() => isKanbanView()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.KANBAN,
          ShortcutKeyEvent.MOVE_CARD_TO_RIGHT_SECTION
        )
      },
    },
    '>': {
      whenever: [() => isKanbanView()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.KANBAN,
          ShortcutKeyEvent.MOVE_CARD_TO_RIGHT_SECTION
        )
      },
    },
    // VIEW: [KANBAN]
    // Move task to left section
    ',': {
      whenever: [() => isKanbanView()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.KANBAN,
          ShortcutKeyEvent.MOVE_CARD_TO_LEFT_SECTION
        )
      },
    },
    '<': {
      whenever: [() => isKanbanView()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.KANBAN,
          ShortcutKeyEvent.MOVE_CARD_TO_LEFT_SECTION
        )
      },
    },
    // VIEW: [TASK DETAIL]
    // Edit card title
    't': {
      whenever: [() => isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(ShortcutBusKey.TASK, ShortcutKeyEvent.EDIT_CART_TITLE)
      },
    },
    // VIEW: [TASK DETAIL]
    // Edit card description
    'shift_t': {
      whenever: [() => isOpenTaskDetail()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          ShortcutBusKey.TASK,
          ShortcutKeyEvent.EDIT_CART_DESCRIPTION
        )
      },
    },
    // VIEW: [KANBAN]
    // Toggle quick menu edit task
    'e': {
      whenever: [() => isKanbanView()],
      usingInput: false,
      handler: () => {
        emitEventBus(
          kanbanTaskBusKey.value,
          ShortcutKeyEvent.TOGGLE_TASK_QUICK_ACTIONS
        )
      },
    },
    // VIEW: [ALL]
    // Open help shortcut modal
    'meta_.': {
      usingInput: false,
      handler: () => {
        const { emit } = useEventBus('toggle-shortcut-key-board')
        emit()
      },
    },
    // View: [KANBAN, TASK DETAIL]
    // Assign task to me
    ' ': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(
              kanbanTaskBusKey.value,
              ShortcutKeyEvent.ASSIGN_ME_TO_TASK
            )
            break

          case isOpenTaskDetail():
            emitEventBus(
              ShortcutBusKey.TASK,
              ShortcutKeyEvent.ASSIGN_ME_TO_TASK
            )
            break
        }
      },
    },
    'i': {
      usingInput: false,
      handler: () => {
        switch (true) {
          case isKanbanView():
            emitEventBus(
              kanbanTaskBusKey.value,
              ShortcutKeyEvent.ASSIGN_ME_TO_TASK
            )
            break

          case isOpenTaskDetail():
            emitEventBus(
              ShortcutBusKey.TASK,
              ShortcutKeyEvent.ASSIGN_ME_TO_TASK
            )
            break
        }
      },
    },
  })
}

export function useDropdownShortcutKey<T>(props: {
  whenever: Ref<boolean>
  options: Ref<T[]>
  onEscape?: () => void
  onEnter: (option: T) => void
}) {
  const { whenever, options, onEscape, onEnter } = props
  const focusIndex = ref(-1)
  const optionRefs = ref<HTMLElement[]>([])

  const scrollToView = (index: number) => {
    if (optionRefs.value[index]) {
      optionRefs.value[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      })
    }
  }

  const shortcuts = computed(() => {
    const shortcutConfig: ShortcutsConfig = {
      arrowup: {
        whenever: [whenever],
        usingInput: true,
        handler: () => {
          if (focusIndex.value > 0) {
            focusIndex.value--
          } else {
            focusIndex.value = options.value.length - 1
          }

          scrollToView(focusIndex.value)
        },
      },
      arrowdown: {
        whenever: [whenever],
        usingInput: true,
        handler: () => {
          if (focusIndex.value < options.value.length - 1) {
            focusIndex.value++
          } else {
            focusIndex.value = 0
          }

          scrollToView(focusIndex.value)
        },
      },
      enter: {
        whenever: [whenever],
        usingInput: true,
        handler: () => {
          if (focusIndex.value >= 0 && options.value[focusIndex.value]) {
            onEnter(options.value[focusIndex.value])
          }
        },
      },
    }

    if (onEscape) {
      shortcutConfig.escape = {
        whenever: [whenever],
        handler: () => {
          onEscape()
        },
      }
    }

    return shortcutConfig
  })

  defineShortcuts(shortcuts.value)

  return {
    focusIndex,
    optionRefs,
  }
}
