import Image from '@tiptap/extension-image'
import type { NodeConfig } from '@tiptap/vue-3'
import { Plugin } from 'prosemirror-state'
import tippy, { type Content } from 'tippy.js'
import { VueRenderer } from '@tiptap/vue-3'
import ToolbarImageVue from '#core/components/editor/extensions/ToolbarImage.vue'
import ImagePreviewVue from '#core/components/editor/extensions/ImagePreview.vue'
import type { ImageOptions } from '#core/types/packages/tiptap'

const MIN_WIDTH = 100
const ResizeImageExtension = (options: { maxWidth: number }): Partial<NodeConfig<ImageOptions, unknown>> | undefined => {
  const { maxWidth } = options

  return {
    addAttributes() {
      return {
        'src': {
          default: null
        },
        'alt': {
          default: null
        },
        'style': {
          default: 'width: 100%; height: auto; cursor: pointer;',
          parseHTML: (element) => {
            const width = element.getAttribute('width')
            return width
              ? `width: ${width}px; height: auto; cursor: pointer;`
              : `${element.style.cssText}`
          }
        },
        'title': {
          default: null
        },
        'loading': {
          default: null
        },
        'srcset': {
          default: null
        },
        'sizes': {
          default: null
        },
        'crossorigin': {
          default: null
        },
        'usemap': {
          default: null
        },
        'ismap': {
          default: null
        },
        'width': {
          default: null
        },
        'height': {
          default: null
        },
        'referrerpolicy': {
          default: null
        },
        'longdesc': {
          default: null
        },
        'decoding': {
          default: null
        },
        'class': {
          default: null
        },
        'id': {
          default: null
        },
        'name': {
          default: null
        },
        'draggable': {
          default: true
        },
        'tabindex': {
          default: null
        },
        'aria-label': {
          default: null
        },
        'aria-labelledby': {
          default: null
        },
        'aria-describedby': {
          default: null
        }
      }
    },
    addNodeView() {
      return ({ node, editor, getPos }) => {
        const {
          view,
          options: { editable }
        } = editor

        const { style } = node.attrs
        const $wrapper = document.createElement('div')
        const $container = document.createElement('div')
        const $img = document.createElement('img')

        const dispatchNodeView = () => {
          if (typeof getPos === 'function') {
            const newAttrs = {
              ...node.attrs,
              style: `${$img.style.cssText}`
            }
            view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs))
          }
        }

        const paintPositionController = () => {
          const $postionController = document.createElement('div')
          $postionController.setAttribute(
            'style',
            'position: absolute; top: 0%; right: 0; width: 100%; z-index: 999;'
          )
          const menu = tippy($postionController, {
            content: new VueRenderer(ToolbarImageVue, {
              props: {
                onAlignLeft: () => {
                  $img.setAttribute(
                    'style',
                    `${$img.style.cssText} margin: 0 auto 0 0;`
                  )
                  dispatchNodeView()
                },
                onAlignCenter: () => {
                  $img.setAttribute('style', `${$img.style.cssText} margin: 0 auto;`)
                  dispatchNodeView()
                },
                onAlignRight: () => {
                  $img.setAttribute(
                    'style',
                    `${$img.style.cssText} margin: 0 0 0 auto;`
                  )
                  dispatchNodeView()
                },
                editor,
                destroy: () => {
                  menu.destroy()
                },
                imageUrl: node.attrs.src
              },
              editor
            }).element as Content,
            trigger: 'manual',
            interactive: true,
            placement: 'top',
            appendTo: document.body,
            delay: 200,
            duration: 150
          })

          $container.appendChild($postionController)

          menu.show()
        }

        $wrapper.setAttribute('style', `display: flex; margin: 4px 0;`)
        $wrapper.appendChild($container)

        $container.setAttribute('style', `${style}`)
        $container.appendChild($img)

        Object.entries(node.attrs).forEach(([key, value]) => {
          if (value === undefined || value === null) return

          $img.setAttribute(key, value)
        })

        if (!editable) return { dom: $img }

        const isMobile = document.documentElement.clientWidth < 768
        const dotPosition = isMobile ? '-8px' : '-4px'
        const dotsPosition = [
          `top: ${dotPosition}; left: ${dotPosition}; cursor: nwse-resize;`,
          `top: ${dotPosition}; right: ${dotPosition}; cursor: nesw-resize;`,
          `bottom: ${dotPosition}; left: ${dotPosition}; cursor: nesw-resize;`,
          `bottom: ${dotPosition}; right: ${dotPosition}; cursor: nwse-resize;`
        ]

        let isResizing = false
        let startX: number, startWidth: number

        $container.addEventListener('click', (_: MouseEvent) => {
          // remove remaining dots and position controller
          const isMobile = document.documentElement.clientWidth < 768
          isMobile
          && (
            document.querySelector('.ProseMirror-focused') as HTMLElement
          )?.blur()

          if ($container.childElementCount > 3) {
            for (let i = 0; i < 5; i++) {
              $container.removeChild($container.lastChild as Node)
            }
          }

          // menu.show()
          paintPositionController()

          $container.setAttribute(
            'style',
            `position: relative; outline: 2px solid #00C16A; ${style} cursor: pointer;`
          )

          Array.from({ length: 4 }, (_, index) => {
            const $dot = document.createElement('div')
            $dot.setAttribute(
              'style',
              `position: absolute; width: ${isMobile ? 16 : 9}px; height: ${isMobile ? 16 : 9
              }px; border: 1.5px solid #00C16A; border-radius: 50%; ${dotsPosition[index]
              }; background-color: #fff;`
            )

            $dot.addEventListener('mousedown', (e) => {
              e.preventDefault()
              isResizing = true
              startX = e.clientX
              startWidth = $container.offsetWidth

              const onMouseMove = (e: MouseEvent) => {
                if (!isResizing) return

                const deltaX = index % 2 === 0
                  ? -(e.clientX - startX)
                  : e.clientX - startX

                const newWidth = Math.max(MIN_WIDTH, Math.min(maxWidth, startWidth + deltaX))

                $container.style.width = newWidth + 'px'
                $img.style.width = newWidth + 'px'
              }

              const onMouseUp = () => {
                if (isResizing) {
                  isResizing = false
                }

                dispatchNodeView()

                document.removeEventListener('mousemove', onMouseMove)
                document.removeEventListener('mouseup', onMouseUp)
              }

              document.addEventListener('mousemove', onMouseMove)
              document.addEventListener('mouseup', onMouseUp)
            })

            $dot.addEventListener(
              'touchstart',
              (e) => {
                e.cancelable && e.preventDefault()
                isResizing = true
                startX = e.touches[0].clientX
                startWidth = $container.offsetWidth

                const onTouchMove = (e: TouchEvent) => {
                  if (!isResizing) return

                  const deltaX = index % 2 === 0
                    ? -(e.touches[0].clientX - startX)
                    : e.touches[0].clientX - startX

                  const newWidth = Math.max(MIN_WIDTH, Math.min(maxWidth, startWidth + deltaX))

                  $container.style.width = newWidth + 'px'
                  $img.style.width = newWidth + 'px'
                }

                const onTouchEnd = () => {
                  if (isResizing) {
                    isResizing = false
                  }

                  dispatchNodeView()

                  document.removeEventListener('touchmove', onTouchMove)
                  document.removeEventListener('touchend', onTouchEnd)
                }

                document.addEventListener('touchmove', onTouchMove)
                document.addEventListener('touchend', onTouchEnd)
              },
              { passive: false }
            )
            $container.appendChild($dot)
          })
        })

        // Set on double click to show image preview
        $img.addEventListener('dblclick', () => {
          const imagePreviewInstance = tippy($container, {
            content: new VueRenderer(ImagePreviewVue, {
              props: {
                imageUrl: node.attrs.src,
                destroy: () => {
                  imagePreviewInstance.hide()
                }
              },
              editor
            }).element as Content,
            trigger: 'manual',
            interactive: true,
            placement: 'top',
            appendTo: document.body,
            getReferenceClientRect: () => {
              return {
                width: window.innerWidth,
                height: window.innerHeight,
                top: window.innerHeight,
                left: -window.innerWidth
              } as DOMRect
            },
            offset: [0, 0],
            delay: 200,
            duration: 300
          })

          imagePreviewInstance.show()
        })

        onClickOutside($container, (event) => {
          const shouldCloseTask = (event.target as HTMLElement).closest('[data-prevent-close-editor]') === null
          if (!shouldCloseTask) {
            return
          }

          const containerStyle = $container.getAttribute('style')
          const newStyle = containerStyle?.replace(
            'outline: 2px solid #00C16A;',
            ''
          )
          $container.setAttribute('style', newStyle as string)

          if ($container.childElementCount > 3) {
            for (let i = 0; i < 5; i++) {
              $container.removeChild($container.lastChild as Node)
            }
          }
        })

        return {
          dom: $wrapper
        }
      }
    }
  }
}

const createOnPasteImagePlugin = (options: ImageOptions) => {
  const { onUploadFiles } = options

  return new Plugin({
    props: {
      handleDOMEvents: {
        drop(view, event) {
          const hasFiles
            = event.dataTransfer
            && event.dataTransfer.files
            && event.dataTransfer.files.length

          if (!hasFiles) {
            return
          }

          const images = Array.from(event.dataTransfer.files).filter(file =>
            /image/i.test(file.type)
          )

          if (images.length === 0) {
            return
          }

          event.preventDefault()

          const { schema } = view.state
          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY
          })

          onUploadFiles(images, (urls: string[]) => {
            urls.forEach((url) => {
              const node = schema.nodes.image.create({
                src: url
              })
              const transaction = view.state.tr.insert(
                coordinates?.pos ?? 0,
                node
              )
              view.dispatch(transaction)
            })
          }, event)
        },
        paste(view, event) {
          const hasFiles
            = event.clipboardData
            && event.clipboardData.files
            && event.clipboardData.files.length

          if (!hasFiles) {
            return
          }

          const images = Array.from(
            event.clipboardData.files
          ).filter(file => /image/i.test(file.type))

          if (images.length === 0) {
            return
          }

          event.preventDefault()

          const { schema } = view.state

          onUploadFiles(images, (urls: string[]) => {
            urls.forEach((url) => {
              const node = schema.nodes.image.create({
                src: url
              })
              const transaction = view.state.tr.replaceSelectionWith(node)
              view.dispatch(transaction)
            })
          })
        }
      }
    }
  })
}

const createImageExtension = (options: ImageOptions) => {
  return Image.extend({
    addProseMirrorPlugins() {
      return [
        createOnPasteImagePlugin(options)
      ]
    }
  })
    .extend(ResizeImageExtension({ maxWidth: options.maxWidth ?? 520 }))
}

export default createImageExtension
