<template>
  <div
    ref="formRef"
    class=" min-w-72 w-72 z-50 bg-white rounded-md shadow-xl p-3 border border-gray-200"
    data-prevent-close-editor
    data-prevent-close-task
  >
    <UForm
      :state="state"
      :schema="schema"
      @submit="handleSubmit"
      @error="handleError"
    >
      <div class="flex flex-col gap-1">
        <div class="text-xs text-gray-700">URL</div>
        <UInput
          v-model="state.href"
          type="url"
          autofocus
          placeholder="https://example.com"
        />
        <div v-if="errors.href" class="text-red-500 text-xs mt-1">
          {{ errors.href }}
        </div>
      </div>
      <div class="flex flex-col gap-1 mt-4">
        <div class="text-xs text-gray-700">Link title</div>
        <UInput
          v-model="state.text"
          label="Text"
          placeholder="Link title"
        />
        <div v-if="errors.text" class="text-red-500 text-xs mt-1">
          {{ errors.text }}
        </div>
      </div>
      <div class="flex justify-end gap-2 mt-4">
        <UButton variant="soft" color="gray" @click="handleCancel">
          Cancel
        </UButton>
        <UButton type="submit">Save</UButton>
      </div>
    </UForm>
  </div>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type { Editor } from '@tiptap/vue-3'
import type { UFormError } from '#core/types'
import { useEditorContext } from '#core/editor_context'

const props = defineProps<{
  editor: Editor
}>()

const {
  linkOption,
  isShowLinkEditForm,
  resetLinkEditForm,
  setIsShowLinkEditForm,
} = useEditorContext()

const errors = reactive({
  href: '',
  text: '',
})
const state = reactive({
  href: linkOption.value.href,
  text: linkOption.value.text,
})

const formRef = ref<HTMLDivElement>()
const schema = z.object({
  href: z
    .string()
    .transform((val) => val.trim())
    .pipe(
      z
        .string()
        .url({ message: 'Please enter a valid URL' })
    ),
  text: z.string().min(1, { message: 'Please enter a text' }),
})

onClickOutside(formRef, () => {
  resetLinkEditForm()
  setIsShowLinkEditForm(false)
})

const handleSubmit = () => {
  const {
    range: { from, to },
  } = linkOption.value

  props.editor
    .chain()
    .focus(from)
    .command(({ tr, dispatch }) => {
      if (dispatch) {
        tr.replaceWith(from, to, props.editor.schema.text(state.text))

        tr.addMark(
          from,
          from + state.text.length,
          props.editor.schema.marks.link.create({
            href: state.href,
            target: '_blank',
            class: 'underline text-primary-500 underline-offset-2',
          })
        )
      }

      return true
    })
    .run()

  setIsShowLinkEditForm(false)
}

const handleError = (formError: UFormError) => {
  if (!state.href.trim()) {
    const {
      range: { from, to },
    } = linkOption.value
    props.editor
      .chain()
      .focus(from)
      .command(({ tr }) => {
        tr.removeMark(from, to, props.editor.schema.marks.link)
        tr.removeMark(from, to, props.editor.schema.marks.style)
        return true
      })
      .run()
    setIsShowLinkEditForm(false)
  }

  if (!state.text.trim()) {
    setIsShowLinkEditForm(false)
  }

  resetErrors()

  formError.errors.forEach((error) => {
    if (error.path in errors) {
      errors[error.path as keyof typeof errors] = error.message
    }
  })
}

const resetErrors = () => {
  errors.href = ''
  errors.text = ''
}

const handleCancel = () => {
  resetLinkEditForm()
  setIsShowLinkEditForm(false)
}

watch(isShowLinkEditForm, (isShow) => {
  if (isShow) {
    resetErrors()
    const { href, text } = linkOption.value
    state.href = href
    state.text = text
  }
})
</script>
