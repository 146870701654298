<template>
  <BubbleMenu
    ref="linkMenuRef"
    plugin-key="bubbleMenuText"
    :editor="editor"
    class="flex items-center p-1.5 bg-white border border-gray-200 w-fit rounded pl-0 relative shadow-xl"
    :tippy-options="{ duration: 0 }"
    :update-delay="100"
    :should-show="showBubbleMenuText"
    @mousedown.prevent
  >
    <div class="flex items-center gap-2 divide-x divide-gray-200">
      <div
        v-for="toolbarList in toolbarGroups"
        :key="toolbarList[0].title"
        class="flex gap-1 pl-2"
      >
        <UTooltip
          v-for="toolbar in toolbarList"
          :key="toolbar.icon"
          :popper="{ placement: 'top', arrow: true }"
          :text="toolbar.title"
          :shortcuts="toolbar.shortCuts"
        >
          <UButton
            variant="ghost"
            size="2xs"
            color="gray"
            :class="[
              'flex items-center p-1',
              editor.isActive(
                toolbar.active.name,
                toolbar.active.attrs
              ) ? '!bg-primary-500 !text-white' : 'hover:bg-gray-100',
            ]"
            @click="toolbar.click()"
          >
            <Suspense :timeout="1000">
              <Icon v-if="toolbar.icon" :name="toolbar.icon" class="size-4" />
            </Suspense>
          </UButton>
        </UTooltip>
      </div>
    </div>
  </BubbleMenu>
</template>

<script lang="ts" setup>
import type { Editor } from '@tiptap/core'
import { BubbleMenu } from '@tiptap/vue-3'
import { useEditorContext } from '#core/editor_context'
import type { ToolbarItem } from '#core/types/packages/tiptap'

const linkMenuRef = ref(null)
const { showLinkEditForm, isShowLinkEditForm } = useEditorContext()

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
})

const popoverLinkOpen = ref(false)

const toolbarGroups: ToolbarItem[][] = [
  [
    {
      key: 'bold',
      title: 'Bold',
      icon: 'tabler:bold',
      shortCuts: ['⌘', 'B'],
      active: { name: 'bold' },
      click: () => {
        props.editor.chain().focus().toggleBold().run()
      },
    },
    {
      key: 'italic',
      title: 'Italic',
      icon: 'tabler:italic',
      shortCuts: ['⌘', 'I'],
      active: { name: 'italic' },
      click: () => {
        props.editor.chain().focus().toggleItalic().run()
      },
    },
    {
      key: 'strikethrough',
      title: 'Strikethrough',
      icon: 'tabler:strikethrough',
      shortCuts: ['⌘', '⇧', 'S'],
      active: { name: 'strike' },
      click: () => {
        props.editor.chain().focus().toggleStrike().run()
      },
    },
    {
      key: 'code',
      title: 'Code',
      icon: 'tabler:code',
      shortCuts: ['⌘', '⌥', 'M'],
      active: { name: 'code' },
      click: () => {
        props.editor.chain().focus().toggleCode().run()
      },
    },
  ],
  [
    {
      key: 'paragraph',
      title: 'Paragraph',
      icon: 'tabler:letter-t',
      shortCuts: ['⌘', '⌥', '0'],
      active: { name: 'paragraph' },
      click: () => {
        props.editor.chain().focus().setParagraph().run()
      },
    },
    {
      key: 'h1',
      title: 'H1',
      icon: 'tabler:h-1',
      shortCuts: ['⌘', '⌥', '1'],
      active: { name: 'heading', attrs: { level: 1 } },
      click: () => {
        props.editor.chain().focus().toggleHeading({ level: 1 }).run()
      },
    },
    {
      key: 'h2',
      title: 'H2',
      icon: 'tabler:h-2',
      shortCuts: ['⌘', '⌥', '2'],
      active: { name: 'heading', attrs: { level: 2 } },
      click: () => {
        props.editor.chain().focus().toggleHeading({ level: 2 }).run()
      },
    },
    {
      key: 'h3',
      title: 'H3',
      icon: 'tabler:h-3',
      shortCuts: ['⌘', '⌥', '3'],
      active: { name: 'heading', attrs: { level: 3 } },
      click: () => {
        props.editor.chain().focus().toggleHeading({ level: 3 }).run()
      },
    },
  ],
  [
    {
      key: 'bulletList',
      title: 'Bullet list',
      icon: 'tabler:list',
      shortCuts: ['⌘', '⇧', 'B'],
      active: { name: 'bulletList' },
      click: () => {
        props.editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()
      },
    },
    {
      key: 'orderedList',
      title: 'Number list',
      icon: 'tabler:list-numbers',
      shortCuts: ['⌘', '⇧', 'O'],
      active: { name: 'orderedList' },
      click: () => {
        props.editor.chain().focus().toggleOrderedList().run()
      },
    },
    {
      key: 'link',
      title: 'Link',
      icon: 'heroicons-link',
      active: { name: 'link' },
      disabled: true,
      click: () => {
        handleLinkClick()
      },
    },
  ],
]

const showBubbleMenuText = ({
  editor,
  from,
  to,
}: {
  editor: Editor
  from: number
  to: number
}) => {
  if (to - from > 0) {
    popoverLinkOpen.value = false
    return editor.isEditable && !isShowLinkEditForm.value && !editor.isActive('image')
  }

  return false
}

const handleLinkClick = () => {
  const { from, to } = props.editor.view.state.selection
  const position = props.editor.view.coordsAtPos(from)
  const selectedText = props.editor.view.state.doc.textBetween(from, to)
  const linkAttrs = props.editor.getAttributes('link')

  showLinkEditForm({
    position,
    range: { from, to },
    href: linkAttrs.href || '',
    text: selectedText || linkAttrs.text || '',
  })
  props.editor
    .chain()
    .setTextSelection({ from, to })
    .focus()
    .run()
}
</script>
