<template>
  <div class="relative items-center flex">
    <UDropdown
      mode="click"
      :open="openQuickActions"
      :items="actionItems"
      :popper="{ placement: 'bottom-start' }"
      :ui="{ item: { padding: 'p-0' } }"
      :disabled="disabled"
      class="z-30"
      @update:open="onUpdateOpenQuickAction"
    >
      <template #item="{ item: action }">
        <UButton
          size="2xs"
          class="w-full px-2 py-1.5"
          color="gray"
          variant="ghost"
          :data-test="`more-actions-${action?.key || ''}`"
          :disabled="disabled"
          @click.prevent.stop="onActionClick(action)"
        >
          <template #leading>
            <Icon :name="action.icon" :size="20" class="text-gray-400" />
          </template>
          <span class="text-sm text-gray-700 font-normal">
            {{ action.label }}
          </span>
        </UButton>
      </template>

      <UTooltip
        text="More settings"
        :shortcuts="buttonProps?.shortcuts"
        :popper="{ placement: 'top', arrow: true }"
        :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
        class="z-20"
        v-bind="tooltipProps"
      >
        <UButton
          ref="buttonRef"
          color="gray"
          variant="ghost"
          size="2xs"
          icon="i-heroicons-ellipsis-horizontal"
          :class="[
            buttonProps?.class,
            isShowMoreActionButton ? activeClass : inActiveClass,
          ]"
          v-bind="buttonProps"
          :disabled="disabled"
          @click.prevent.exact.stop="onShowDropdown"
        />
      </UTooltip>
    </UDropdown>
    <slot :on-update-open="onUpdatePopoverOpen" />
  </div>
</template>

<script setup lang="ts">
import type { DropdownItem, Button, Tooltip } from '#ui/types'

const props = defineProps({
  actionItems: {
    type: Object as PropType<DropdownItem[][]>,
    required: true,
  },
  tooltipProps: {
    type: Object as PropType<Tooltip>,
  },
  buttonProps: {
    type: Object as PropType<
      Button & {
        class?:
          | string
          | Array<string | Record<string, boolean>>
          | Record<string, boolean>
        text?: string
        popper?: {
          placement?: string
          arrow?: boolean
        }
        shortcuts?: string[]
      }
    >,
  },
  activeClass: {
    type: String,
  },
  inActiveClass: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const openQuickActions = ref(false)
const isShowMoreActionButton = ref(false)
const buttonRef = ref()

const emit = defineEmits(['update:open', 'update:openQuickAction'])

const onShowDropdown = () => {
  openQuickActions.value = !openQuickActions.value
  isShowMoreActionButton.value = openQuickActions.value
}

const onUpdateOpenQuickAction = (isOpen: boolean) => {
  if (!isOpen && openQuickActions.value) {
    isShowMoreActionButton.value = false
  }

  openQuickActions.value = isOpen
}

const onActionClick = (action: DropdownItem) => {
  if (props.disabled) {
    return
  }

  action?.click?.(() => {
    isShowMoreActionButton.value = false
  })
  openQuickActions.value = false
}

const onUpdatePopoverOpen = (isOpen: boolean) => {
  emit('update:openQuickAction', isOpen)
  if (!isOpen) {
    isShowMoreActionButton.value = false
  }
}

watch(
  () => isShowMoreActionButton.value,
  (value) => {
    emit('update:open', value)
  }
)

defineExpose({
  open: () => {
    buttonRef.value?.$el.click()
  },
  close: () => {
    openQuickActions.value = false
    isShowMoreActionButton.value = false
  },
})
</script>
