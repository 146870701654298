type IdleTask = () => void | Promise<void>

interface IdleDeadline {
  timeRemaining: () => number
  didTimeout: boolean
}

export class IdleTaskQueue {
  private queue: IdleTask[] = []
  private isRunning: boolean = false

  /**
   * Add a task to the queue.
   * @param task - The task to add. Should return void or a Promise<void>.
   */
  addTask(task: IdleTask): void {
    this.queue.push(task)
    this.runQueue()
  }

  /**
   * Process tasks in the queue during browser idle time.
   */
  private runQueue(): void {
    if (this.isRunning || this.queue.length === 0) return

    this.isRunning = true

    const processNextTask = (deadline: IdleDeadline) => {
      while (this.queue.length > 0 && deadline.timeRemaining() > 0) {
        const task = this.queue.shift()!
        try {
          const result = task()
          if (result instanceof Promise) {
            result.catch((err) =>
              logger.error('Error executing async task:', err)
            )
          }
        } catch (err) {
          logger.error('Error executing task:', err)
        }
      }

      if (this.queue.length > 0) {
        requestIdleCallback(processNextTask)
      } else {
        this.isRunning = false
      }
    }

    requestIdleCallback(processNextTask)
  }
}

export const Queue = new IdleTaskQueue()
