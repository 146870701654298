export default defineNuxtPlugin((/** nuxtApp **/) => {
  // const { searchParams } = useRequestURL()
  const isDebug = true

  // if (!import.meta.dev) {
  //   nuxtApp.vueApp.config.warnHandler = (msg, vm, trace) => {
  //     logger.warn(msg, vm, trace)
  //   }

  //   nuxtApp.vueApp.config.errorHandler = (err, vm, info) => {
  //     logger.error(err, vm, info)
  //   }
  // }

  return {
    provide: {
      isDebug,
    },
  }
})
