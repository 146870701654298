<template>
  <div
    :class="[
      'transition-all duration-100 ease-in-out mt-2 has-[#editor-menu-bar]:ring-2 ring-primary-500 ring-0 rounded-md px-3 pt-3 -ml-3',
      !showMask
        ? 'max-h-[unset]'
        : 'max-h-[500px] mask-description relative',
    ]"
  >
    <div
      v-if="showMask"
      class="absolute inset-0 z-20 cursor-pointer"
      data-test="mask-description"
      @click="toggleDescriptionVisibility"
    />
    <Editor
      ref="descriptionRef"
      v-model:content="description"
      data-test="description"
      :additional-extensions="additionalExtensions"
      :disabled="disabled"
      :image-option="{
        onUploadFiles: onUploadFiles,
        maxWidth: 520,
      }"
      @blur="onChangeDescription"
      @created="checkExpandDescription"
    />
  </div>
  <UButton
    v-if="showToggleButton"
    variant="soft"
    color="gray"
    :icon="
      isExpanded
        ? 'i-heroicons-chevron-up'
        : 'i-heroicons-chevron-down'
    "
    class="w-full flex justify-center mt-2 text-sm"
    data-test="toggle-description"
    @click="toggleDescriptionVisibility"
  >
    {{ isExpanded ? 'Show less' : 'Show more' }}
  </UButton>
</template>

<script lang="ts" setup>
import { TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION } from '#task/schema'
import SlashCommandExtension, { COMMAND_LIST } from '#core/packages/tiptap/slash-command'
import type {
  TaskDetail,
} from '#task/types'
import type { FileChangeParams } from '#core/types/packages/tiptap'
import { AttachmentType } from '#attachment/constant'
import Mention from '#core/packages/tiptap/mention'

const props = defineProps<{
  disabled: boolean
  taskDetail: TaskDetail
}>()

const { createAttachment, onDropAttachment, processAddAttachment } = useUpdateTask()
const { upload } = usePresignedUpload()
const { boardData } = useBoardSharedState()
const toast = useToast()
const description = defineModel<string>('content')

const descriptionRef = ref()

const isExpanded = ref<null | boolean>(null)

const emit = defineEmits<{
  (e: 'change', description: string): void
}>()

const editorRef = computed(() => descriptionRef.value?.editor || {})
const isEditorFocus = computed(() => editorRef.value?.isFocused?.())
const isOversized = ref(false)
const showToggleButton = computed(() => {
  if (isEditorFocus.value) {
    return false
  }

  return isOversized.value
})

const showMask = computed(() => {
  if (isEditorFocus.value) {
    return false
  }

  return isOversized.value ? !isExpanded.value : false
})

watch(
  () => isOversized.value,
  (value) => {
    // Auto expand if not oversized
    if (!value && isExpanded.value === null) {
      isExpanded.value = true
    }
  }
)

const additionalExtensions = computed(() => [
  Mention(boardData.value.users),
  SlashCommandExtension(COMMAND_LIST, { onFileChange })
])

const toggleDescriptionVisibility = () => {
  isExpanded.value = !isExpanded.value
}

const onUploadFiles = async (images: File[], dispatch: (urls: string[]) => void, event?: DragEvent) => {
  if (event) {
    // On drag and drop case
    onDropAttachment({
      files: images,
      event,
      taskId: props.taskDetail.id,
      callback: (attachments) => {
        dispatch(attachments.map(item => item.url))
      },
    })
  } else {
    // On paste case
    const attachments = await processAddAttachment(images, '', props.taskDetail.id)
    dispatch(attachments.map(item => item.url))
  }
}

const onFileChange = async (params: FileChangeParams) => {
  const { id } = props.taskDetail
  const data = await upload(
    TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION,
    {
      taskId: id,
    },
    [params.file]
  )
  if (!data.success) {
    return toast.add({
      color: 'red',
      title: data.message,
    })
  }

  const response = await createAttachment(id, {
    attachments: data.presignedUrls,
    type: AttachmentType.FILE,
    name: '',
  })

  if (response && response.length) {
    params.callback?.(response[0].url)
  }
}

const onChangeDescription = () => {
  emit('change', description.value ?? '')
  checkExpandDescription()
}

const checkExpandDescription = () => {
  nextTick(() => {
    // Check if the description is oversized
    isOversized.value = editorRef.value?.getHeight?.() > 500

    editorRef.value?.setEditable(true)
  })
}

defineExpose({
  setContent: (value: string) => {
    nextTick(() => {
      editorRef.value?.setContent(value)
    })
  },
  isEditorFocused: () => {
    return editorRef.value?.isFocused()
  },
  focus: () => {
    return editorRef.value?.focus()
  }
})
</script>

<style scoped lang="scss">
.mask-description {
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    to bottom,
    black 0,
    black calc(100% - 100px),
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    black 0,
    black calc(100% - 100px),
    transparent 100%
  );
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
</style>
