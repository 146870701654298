<template>
  <Icon v-if="type != null" :name="STATUS_ICONS[type as STATUS_TYPE]" v-bind="$attrs" />
</template>

<script lang="ts" setup>
import { STATUS_TYPE } from '#status/constant'

defineProps({
  type: {
    type: Number as PropType<STATUS_TYPE>,
  },
})

const STATUS_ICONS: Record<STATUS_TYPE, string> = {
  [STATUS_TYPE.NOT_STARTED]: 'leanbase:status-not-started',
  [STATUS_TYPE.ACTIVE]: 'leanbase:status-active',
  [STATUS_TYPE.DONE]: 'leanbase:status-done',
}
</script>
