import { isEmptyTaskId } from '#task/utils/ultitity'
import { SpreadsheetAxis } from '#task/constant'
import type { SpreadsheetAxisType } from '#task/types'
import { ShortcutKeyEvent, ShortcutBusKey } from '#core/shortcut_keys_constants'

const ignoreShortcuts = () => {
  const activeElement = document.activeElement as HTMLElement
  const inputs = ['input', 'select', 'textarea']

  if (
    activeElement &&
    inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1
  ) {
    return true
  }

  return window.location.pathname.startsWith('/list/t/')
}

export const $useSpreadsheetInteractions = () => {
  const { scrollToItemId } = useListViewSharedState()
  // Focus
  const currentFocusTaskId = ref<string | null>(null)
  const currentFocusColumnKey = ref<string | null>(null)

  // Shift Select
  const currentShiftSelectTaskId = ref<string | null>(null)
  watch(currentFocusTaskId, () => {
    currentShiftSelectTaskId.value = null
  })

  // Hover
  const currentHoverTaskId = ref<string | null>(null)
  const currentHoverColumnKey = ref<string | null>(null)

  // Composable
  const shortcutBus = useEventBus<ShortcutKeyEvent>(ShortcutBusKey.LISTVIEW)
  const {
    taskItems,
    columns,
    isItemChecked,
    getRelativeTask,
    getRelativeColumn,
    setSelectState,
  } = useListViewSharedState()

  const resetCellFocusState = () => {
    currentFocusTaskId.value = null
    currentFocusColumnKey.value = null
  }

  const resetCellHoverState = () => {
    currentHoverTaskId.value = null
    currentHoverColumnKey.value = null
  }

  const currentSelectedRow = ref<number | null>(null)

  const isFocusCell = computed(() => {
    return currentFocusTaskId.value !== null
  })

  // Check if the current cell is focused or selected
  const isCurrentFocusCell = (taskId: string, columnKey: string) => {
    return (
      currentFocusTaskId.value === taskId &&
      currentFocusColumnKey.value === columnKey
    )
  }

  const getIsFocusCurrentRowRef = (taskId: string) => {
    return computed(() => currentFocusTaskId.value === taskId)
  }

  const getIsHightlightCurrentRowRef = (taskId: string) => {
    return computed(() => {
      const isCurrentFocus = currentFocusTaskId.value === taskId
      if (isCurrentFocus) {
        return true
      }

      // Check if the current cell is selected
      if (isItemChecked(taskId)) {
        return true
      }

      return false
    })
  }

  const getIsHightlightCurrentCellRef = (taskId: string, columnKey: string) => {
    return computed(() => {
      const isCurrentFocus = isCurrentFocusCell(taskId, columnKey)
      if (isCurrentFocus) {
        return true
      }

      // Check if the current cell is selected
      if (isItemChecked(taskId)) {
        return true
      }

      return false
    })
  }

  const setHoverCell = (taskId: string | null, columnKey: string | null) => {
    if (taskId && !isEmptyTaskId(taskId)) {
      currentHoverTaskId.value = taskId
    }

    if (columnKey) {
      currentHoverColumnKey.value = columnKey
    }
  }

  const setFocusCell = (taskId: string | null, columnKey: string | null) => {
    if (taskId && !isEmptyTaskId(taskId)) {
      currentFocusTaskId.value = taskId
    }

    if (columnKey) {
      currentFocusColumnKey.value = columnKey
    }
  }

  const getTaskIdsInRange = (fromTaskId: string, toTaskId: string) => {
    const fromIndex = taskItems.value.findIndex(
      (task) => task.id === fromTaskId
    )
    const toIndex = taskItems.value.findIndex((task) => task.id === toTaskId)
    if (fromIndex === -1 || toIndex === -1) {
      return []
    }

    const _from = Math.min(fromIndex, toIndex)
    const _to = Math.max(fromIndex, toIndex)

    return taskItems.value.slice(_from, _to + 1).map((task) => task.id)
  }

  const selectMultipleTasks = (fromTaskId: string, toTaskId: string) => {
    const taskIds = getTaskIdsInRange(fromTaskId, toTaskId)

    taskIds.forEach((taskId) => {
      setSelectState(taskId, true)
    })
  }

  const getCurrentFocusTaskId = () => {
    if (currentFocusTaskId.value) {
      return currentFocusTaskId.value
    }

    return taskItems.value[0]?.id || null
  }

  const getCurrentShiftFocusTaskId = () => {
    if (currentShiftSelectTaskId.value) {
      return currentShiftSelectTaskId.value
    }

    return getCurrentFocusTaskId()
  }

  const getCurrentFocusColumnKey = () => {
    if (currentFocusColumnKey.value) {
      return currentFocusColumnKey.value
    }

    return columns.value[0]?.key || null
  }

  const moveFocus = (axis: SpreadsheetAxisType, change: number) => {
    if (axis === SpreadsheetAxis.ROW) {
      const _currentFocusTaskId = getCurrentFocusTaskId()
      if (!_currentFocusTaskId) {
        return
      }

      currentFocusTaskId.value = getRelativeTask(_currentFocusTaskId, change).id
      scrollToItemId(currentFocusTaskId.value)
    } else {
      // Column
      const _currentFocusColumnKey = getCurrentFocusColumnKey()
      if (!_currentFocusColumnKey) {
        return
      }

      currentFocusColumnKey.value = getRelativeColumn(
        _currentFocusColumnKey,
        change
      ).key
    }
  }

  const moveShiftFocus = (change: number) => {
    if (!currentShiftSelectTaskId.value) {
      currentShiftSelectTaskId.value = getCurrentFocusTaskId()
    }

    if (!currentShiftSelectTaskId.value) {
      return
    }

    currentShiftSelectTaskId.value = getRelativeTask(
      currentShiftSelectTaskId.value,
      change
    ).id
  }

  const shortcutBusListener = (message: ShortcutKeyEvent) => {
    switch (message) {
      case ShortcutKeyEvent.NAVIGATE_RIGHT:
        moveFocus(SpreadsheetAxis.COLUMN, 1)
        break
      case ShortcutKeyEvent.NAVIGATE_LEFT:
        moveFocus(SpreadsheetAxis.COLUMN, -1)
        break
    }
  }

  const shiftSelectTask = (targetTaskId: string) => {
    // Handle shift key select
    const currentFocusTaskId = getCurrentFocusTaskId()
    const currentShiftFocusTaskId = getCurrentShiftFocusTaskId()

    // If there is no focus task, do nothing
    if (!currentFocusTaskId || !currentShiftFocusTaskId) {
      return
    }

    // Get task ids in shift select range
    const currentShiftSelectTaskIds = getTaskIdsInRange(
      currentFocusTaskId,
      currentShiftFocusTaskId
    )

    // Change shift select target
    currentShiftSelectTaskId.value = targetTaskId

    // Get new task ids in shift select range
    const newShiftSelectTaskIds = getTaskIdsInRange(
      currentFocusTaskId,
      currentShiftSelectTaskId.value
    )

    // Exclude task ids
    const excludeTaskIds = currentShiftSelectTaskIds.filter(
      (taskId) => !newShiftSelectTaskIds.includes(taskId)
    )

    // Set select state
    excludeTaskIds.forEach((taskId) => {
      setSelectState(taskId, false)
    })
    newShiftSelectTaskIds.forEach((taskId) => {
      setSelectState(taskId, true)
    })
  }

  const metaClickTask = (taskId: string) => {
    if (isItemChecked(taskId)) {
      setSelectState(taskId, false)
    } else {
      setSelectState(taskId, true)
    }

    setFocusCell(taskId, null)
  }

  const handleUpDownShiftArrow = (event: KeyboardEvent) => {
    if (ignoreShortcuts() || !event.key.startsWith('Arrow')) {
      return
    }

    // Move up and down without shift key
    if (!event.shiftKey) {
      if (event.key === 'ArrowUp') {
        moveFocus(SpreadsheetAxis.ROW, -1)
      } else if (event.key === 'ArrowDown') {
        moveFocus(SpreadsheetAxis.ROW, 1)
      }

      return
    }

    // Handle shift key select
    const currentFocusTaskId = getCurrentFocusTaskId()
    const currentShiftFocusTaskId = getCurrentShiftFocusTaskId()

    // If there is no focus task, do nothing
    if (!currentFocusTaskId || !currentShiftFocusTaskId) {
      return
    }

    // Get task ids in shift select range
    const currentShiftSelectTaskIds = getTaskIdsInRange(
      currentFocusTaskId,
      currentShiftFocusTaskId
    )

    // Move shift focus
    if (event.key === 'ArrowUp') {
      moveShiftFocus(-1)
    } else if (event.key === 'ArrowDown') {
      moveShiftFocus(1)
    }

    // Get new task ids in shift select range
    const newShiftFocusTaskId = getCurrentShiftFocusTaskId()
    if (!newShiftFocusTaskId) {
      return
    }

    const newShiftSelectTaskIds = getTaskIdsInRange(
      currentFocusTaskId,
      newShiftFocusTaskId
    )

    // Exclude task ids
    const excludeTaskIds = currentShiftSelectTaskIds.filter(
      (taskId) => !newShiftSelectTaskIds.includes(taskId)
    )

    // Set select state
    excludeTaskIds.forEach((taskId) => {
      setSelectState(taskId, false)
    })
    newShiftSelectTaskIds.forEach((taskId) => {
      setSelectState(taskId, true)
    })
  }

  // Register cell move shortcut keys
  const registerCellMove = () => {
    tryOnMounted(() => {
      window.addEventListener('keydown', handleUpDownShiftArrow)
      shortcutBus.on(shortcutBusListener)
    })

    tryOnBeforeUnmount(() => {
      window.removeEventListener('keydown', handleUpDownShiftArrow)
      shortcutBus.off(shortcutBusListener)
    })
  }

  return {
    // Spreadsheet cell navigate
    // Refs
    currentFocusTaskId,
    currentFocusColumnKey,
    currentHoverTaskId,
    currentHoverColumnKey,

    // Functions
    metaClickTask,
    shiftSelectTask,
    moveFocus,
    resetCellHoverState,
    setHoverCell,
    setFocusCell,
    getIsFocusCurrentRowRef,
    getIsHightlightCurrentCellRef,
    getIsHightlightCurrentRowRef,
    selectMultipleTasks,
    resetCellFocusState,
    currentSelectedRow,
    isFocusCell,
    registerCellMove,
  }
}

export const useSpreadsheetInteractions = createSharedComposable(
  $useSpreadsheetInteractions
)
