import { has } from 'lodash-es'
import type { Reference } from '@apollo/client'
import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { TaskFieldsSync, TaskItem, TaskLabelSync, TaskSyncPayload } from '#task/types'
import { CUSTOM_FIELD_FRAGMENT, FIELD_FRAGMENT } from '#field/fragment'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { TASK_TYPE_NAME } from '#task/constant'
import { TASK_SYNC_FRAGMENT } from '#task/loader_fragment'

class TaskListCache extends ModelListCache<TaskItem> {
  constructor() {
    super(TASK_TYPE_NAME)
  }

  override getBoardCacheKeys(): BOARD_CACHE[] {
    return [BOARD_CACHE.TASK]
  }
}

/**
 * Task sync composable
 */
export const useTaskSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new TaskListCache()
  const { getTaskIdentifier, getSyncTaskFields } = useTaskDataConvert()

  const onAdd = async (payload: TaskSyncPayload) => {
    client.cache.writeFragment({
      data: payload,
      fragment: TASK_SYNC_FRAGMENT,
      fragmentName: 'TaskSync',
    })

    // Add the new item to the list cache
    listCache.add(currentBoard.value.id, payload)
  }

  const onUpdate = (payload: TaskSyncPayload) => {
    // If task is reopened, treat it like a new section
    if (has(payload, 'closed') && payload.closed === false) {
      onAdd(payload)
      return
    }

    client.cache.modify({
      id: getTaskIdentifier(payload.id),
      fields: getSyncTaskFields(payload),
    })
  }

  const onDelete = (payload: TaskSyncPayload) => {
    client.cache.evict({
      id: getTaskIdentifier(payload.id),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: TaskSyncPayload) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}

/**
 * Task label sync composable
 */
export const useTaskLabelSync = () => {
  const { client } = useApolloClient()
  const { getTaskIdentifier, getSyncTaskFields, getTaskObject } = useTaskDataConvert()

  const onAdd = (payload: TaskLabelSync) => {
    const taskIdentifier = getTaskIdentifier(payload.taskId)
    const task = getTaskObject(taskIdentifier)
    if (!task) {
      return logger.log('Task not found')
    }

    const currentLabels = new Set(task.labelIds || [])
    currentLabels.add(payload.labelId)
    const newLabels = Array.from(currentLabels)
    const updateFields = getSyncTaskFields({ labelIds: newLabels })
    client.cache.modify({
      id: getTaskIdentifier(payload.taskId),
      fields: updateFields
    })
  }

  const onDelete = (payload: TaskLabelSync) => {
    const taskIdentifier = getTaskIdentifier(payload.taskId)
    const task = getTaskObject(taskIdentifier)
    if (!task) {
      return logger.log('Task not found')
    }

    const currentLabels = new Set(task.labelIds || [])
    currentLabels.delete(payload.labelId)
    const newLabels = Array.from(currentLabels)
    const updateFields = getSyncTaskFields({ labelIds: newLabels })
    client.cache.modify({
      id: taskIdentifier,
      fields: updateFields
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: TaskLabelSync) => void
        >([
          ['ADD', onAdd],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model as unknown as TaskLabelSync)
  }

  return {
    sync,
  }
}

/**
 * Task fields sync composable
 */
export const useTaskFieldsSync = () => {
  const { client } = useApolloClient()
  const { getTaskIdentifier } = useTaskDataConvert()

  const onAdd = (payload: TaskFieldsSync) => {
    client.cache.modify({
      id: getTaskIdentifier(payload.taskId),
      fields: {
        taskFields: (existingFields = [], { readField }) => {
          const isExisting = existingFields.some(
            (ref: Reference) => readField('id', ref) === payload.id
          )
          const field = client.readFragment({
            fragment: FIELD_FRAGMENT,
            id: client.cache.identify({
              id: payload.fieldId,
              __typename: 'FieldType',
            }),
          })
          if (isExisting || !field) {
            return existingFields
          }

          const newFieldRef = client.cache.writeFragment({
            data: {
              id: payload.id,
              value: payload.value,
              fieldId: payload.fieldId,
              field,
              __typename: 'TaskFieldType',
            },
            fragment: CUSTOM_FIELD_FRAGMENT,
            fragmentName: 'CustomField',
          })
          return [...existingFields, newFieldRef]
        },
      },
    })
  }

  const onUpdate = (payload: TaskFieldsSync) => {
    client.cache.modify({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'TaskFieldType',
      }),
      fields: {
        value: () => payload.value,
      },
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: TaskFieldsSync) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model as unknown as TaskFieldsSync)
  }

  return {
    sync,
  }
}
