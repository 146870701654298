<template>
  <div class="px-4 py-3 w-[26rem]">
    <div class="text-sm font-semibold text-gray-900">{{ title }}</div>
    <div class="mt-3 text-sm break-word">
      <slot></slot>
    </div>
    <div class="flex justify-end mt-3 gap-2">
      <UButton
        color="gray"
        variant="soft"
        @click="close"
      >
        Cancel
      </UButton>
      <UButton
        v-if="confirmButtonText"
        :class="`bg-${confirmButtonColor}-500 text-white`"
        :loading="closeLoading"
        data-test="popover-button-confirm"
        @click="emit('confirm')"
      >
        {{ confirmButtonText }}
      </UButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['confirm'])
defineProps({
  title: {
    type: String,
    default: '',
  },
  confirmButtonText: {
    type: String,
    default: '',
  },
  confirmButtonColor: {
    type: String,
    default: 'red',
  },
  closeLoading: {
    type: Boolean,
    default: false,
  },
  close: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Function as PropType<(...args: any[]) => void>,
    default: () => {},
  },
})
</script>
