<template>
  <div class="mt-6 relative" @scroll="handleScroll">
    <Icon
      name="heroicons:list-bullet-20-solid"
      class="absolute my-1.5 -left-9"
      :size="20"
    />
    <div class="flex justify-between items-center gap-4 py-1">
      <p class="text-gray-900 font-semibold flex-grow">Activity</p>
      <ULink
        v-for="(item, index) in ['All', 'Comments', 'Activities']"
        :key="index"
        :class="[
          'text-sm font-medium',
          { 'text-primary': filter.actionType === item },
        ]"
        @click="selectTab(item)"
      >
        {{ item }}
      </ULink>
    </div>
    <div class="mt-3 flex flex-col -ml-11">
      <div class="flex gap-3 py-2">
        <Avatar
          :id="auth.id"
          :src="auth.photo"
          :name="auth.fullName"
          size="sm"
        />
        <div class="flex-1">
          <div
            v-if="!editorVisible || loadingUpdateComment"
            class="border rounded-md px-3 py-2 cursor-pointer"
            @click="openEditor()"
          >
            <input
              type="text"
              placeholder="Add a comment"
              class="w-full border-none outline-none text-sm"
              readonly
              data-test="add-comment-input"
            />
          </div>
          <Editor
            v-else
            ref="editorRef"
            v-model:content="commentContent"
            v-model:is-empty="isEditorEmpty"
            class="rounded-md px-3.5 pt-2.5 text-sm border-primary-500 border-2"
            placeholder="Add a comment, '/' for commands…"
            data-test="comment-input"

            :additional-extensions="additonalExtensions"
            @cancel="cancelComment()"
          />
          <UButton
            v-if="editorVisible && !loadingUpdateComment"
            color="primary"
            class="mt-2 mr-2"
            :disabled="isEditorEmpty"
            @click="saveComment()"
            @keyup.enter="saveComment()"
          >
            Send
          </UButton>
          <UButton
            v-if="editorVisible && !loadingUpdateComment"
            color="gray"
            variant="soft"
            class="mt-2"
            @click="cancelComment()"
            @keyup.enter="cancelComment()"
          >
            Cancel
          </UButton>
        </div>
      </div>
      <div v-if="loadingUpdateComment" class="flex py-2 -ml-5 pl-5">
        <div class="mr-3">
          <Avatar
            :id="auth.id"
            :src="auth.photo"
            :name="auth.fullName"
            size="sm"
          />
        </div>
        <div class="flex-1 text-sm">
          <div
            class="flex justify-start items-center mb-2 text-gray-500 gap-1 flex-wrap w-full"
          >
            <span class="font-semibold text-gray-900">{{
              auth.fullName
            }}</span>
          </div>
          <div
            class="px-3 py-2 rounded-lg border w-full bg-white break-all ProseMirror"
            v-html="commentContent"
          ></div>
          <div class="flex items-center gap-1 flex-wrap py-1">
            <UButton variant="ghost" color="gray" :loading="true" size="xs">Sending</UButton>
          </div>
        </div>
      </div>
      <template v-for="action in actions || []">
        <TaskActivityComment
          v-if="action.type === 'CREATE_COMMENT'"
          :key="action.comment.id"
          v-model:editing-item-id="edittingItemId"
          :action="action"
          :is-active="activeCommentId === action.comment.id"
          :board-members="boardData.users || []"
          @edit="editorVisible = false"
          @reply="replyComment"
          @click-time="onClickTime"
        />
        <TaskActivityLog v-else :key="action.id" :action="action" type="task" />
      </template>
    </div>

    <UButton
      v-if="result?.taskActions.nextCursor"
      variant="ghost"
      color="gray"
      :loading="loading"
      class="mt-2"
      @click="loadMore"
    >
      Show more
    </UButton>
  </div>
</template>

<script lang="ts" setup>
import type { AnyExtension } from '@tiptap/vue-3'
import * as _ from 'lodash-es'
import type {
  ActionPaginationType,
  TaskActionFilterInput,
} from '#action/types'
import Mention from '#core/packages/tiptap/mention'
import SlashCommandExtension, { COMMAND_LIST } from '#core/packages/tiptap/slash-command'

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
})

const { auth } = useAuthStore()

const filter = reactive<TaskActionFilterInput>({
  taskId: props.taskId,
  actionType: 'All',
  limit: 10,
  nextCursor: undefined,
})

const { boardData } = useBoardSharedState()
const { result, load, refetch, loading, fetchMore } =
  useLazyTaskActionsQuery(filter)
const { mutate, loading: loadingUpdateComment } = useAddUpdateCommentMutation()

const editorRef = ref()
const isEditorEmpty = ref(true)
const editorVisible = ref(false)
const commentContent = ref('<p></p>')
const additonalExtensions = ref<AnyExtension[]>([])
const edittingItemId = ref('')
const activeCommentId = ref('')
const actions = computed(() => result.value?.taskActions?.items?.filter((action) => {
  if (filter.actionType === 'Comments' && action.type !== 'CREATE_COMMENT') {
    return false
  }

  return true
}))

const openEditor = () => {
  editorVisible.value = true
  edittingItemId.value = ''
  editorRef.value?.editor.focus()
  editorRef.value?.editor.setEditable(true)
}

const saveComment = async () => {
  await mutate({
    input: {
      task: props.taskId,
      content: commentContent.value,
    },
  })
  commentContent.value = '<p></p>'
  editorVisible.value = false
  await refetch()
}

const cancelComment = () => {
  commentContent.value = '<p></p>'
  editorVisible.value = false
}

const replyComment = (fullName: string) => {
  commentContent.value = `@${fullName} `
  editorVisible.value = true
}

const selectTab = (tab: string) => {
  filter.actionType = tab
  filter.nextCursor = undefined
  refetch({ filter })
}

const handleScroll = (event: Event) => {
  const target = event.target as HTMLElement
  if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
    refetch({ filter })
  }
}

const loadMore = () => {
  if (result?.value?.taskActions.nextCursor) {
    fetchMore({
      variables: {
        filter: {
          ...filter,
          nextCursor: result.value.taskActions.nextCursor,
        },
      },
      updateQuery,
    })
  }
}

const updateQuery = (
  previousResult: { taskActions: ActionPaginationType },
  options: { fetchMoreResult?: { taskActions: ActionPaginationType; } | undefined },
) => {
  if (!options.fetchMoreResult) {
    return previousResult
  }

  const previousActions = previousResult.taskActions
  const fetchMoreActions = options.fetchMoreResult.taskActions

  options.fetchMoreResult.taskActions.items = [...previousActions.items, ...fetchMoreActions.items]

  return { ...options.fetchMoreResult }
}

const onClickTime = () => {
  getActiveComment()
}

const getActiveComment = () => {
  const hashMatch = window.location.hash.match(/#comment-(.*)/)

  if (hashMatch) {
    const commentId = hashMatch[1]
    activeCommentId.value = commentId
  }
}

onMounted(async () => {
  await load()
  window.onhashchange = getActiveComment
  getActiveComment()
  additonalExtensions.value = [
    SlashCommandExtension(
      COMMAND_LIST.filter(item => !['Table', 'Heading 1', 'Heading 2', 'Heading 3', 'Quote', 'Image'].includes(item.title))
    ),
    Mention(boardData.value.users)
  ]
})

onUnmounted(() => {
  window.onhashchange = null
})
</script>

<style scoped>
.mention-link {
  color: #3b82f6; /* Tailwind CSS primary color */
  text-decoration: none;
}
.mention-link:hover {
  text-decoration: underline;
}
</style>
