import type { Editor, Range } from '@tiptap/core'
import { VueRenderer } from '@tiptap/vue-3'
import { Plugin } from 'prosemirror-state'
import tippy, { type Instance, type Content } from 'tippy.js'
import type { EditorView } from '@tiptap/pm/view'
import Link from '@tiptap/extension-link'
import LinkPreview from '#core/components/editor/extensions/LinkPreview.vue'
import type {
  TiptapCoordination,
  LinkPreviewOption,
} from '#core/types/packages/tiptap'

class LinkPlugin {
  private previewLink: Instance | null = null
  private range: Range
  private rect: TiptapCoordination | null = null
  private options: LinkPreviewOption
  private editor: Editor

  constructor({
    editor,
    options,
  }: {
    editor: Editor
    options: LinkPreviewOption
  }) {
    this.range = { from: 0, to: 0 }
    this.editor = editor
    this.options = options
  }

  private makePreviewLinkProps(href: string, text: string) {
    return {
      props: {
        href,
        text,
        editor: this.editor,
        range: this.range,
        rect: this.rect,
        position: this.rect,
        hideTooltip: this.destroy,
      },
      editor: this.editor,
    }
  }

  private createPreviewLink(
    element: HTMLElement,
    href: string,
    text: string
  ): Instance {
    return tippy(element, {
      content: new VueRenderer(
        LinkPreview,
        this.makePreviewLinkProps(href, text)
      ).element as Content,
      trigger: 'manual',
      interactive: true,
      placement: 'bottom',
      appendTo: document.body,
      delay: 200,
      duration: 200,
    })
  }

  private showPreviewLink(
    element: HTMLAnchorElement,
    href: string,
    text: string
  ): void {
    if (!this.previewLink) {
      this.previewLink = this.createPreviewLink(element, href, text)
    } else {
      this.previewLink.setProps({
        getReferenceClientRect: () => element.getBoundingClientRect(),
        content: new VueRenderer(
          LinkPreview,
          this.makePreviewLinkProps(href, text)
        ).element as Content,
      })
    }

    this.previewLink.show()
  }

  private destroy = (): void => {
    if (this.previewLink) {
      this.previewLink.destroy()
      this.previewLink = null
    }
  }

  private handleMouseOver = (view: EditorView, event: MouseEvent): boolean => {
    const target = event.target as HTMLElement
    const link = target.closest('a') as HTMLAnchorElement
    const href = link?.href

    if (!href || this.options.disabled?.()) {
      return false
    }

    const pos = view.posAtDOM(target, 0)
    this.rect = view.coordsAtPos(pos)

    const $pos = view.state.doc.resolve(pos)
    const node = $pos.node()

    const length = target.innerText.length
    const end = pos + node?.nodeSize - 1

    const to = Math.min(pos + length, end)
    const from = pos

    this.range = { from, to }
    this.showPreviewLink(link, href, target.innerText)
    return true
  }

  public create(): Plugin {
    return new Plugin({
      view: () => ({
        destroy: () => {
          this.destroy()
        },
      }),
      props: {
        handleDOMEvents: {
          mouseover: this.handleMouseOver,
          mouseout: (_, event: MouseEvent) => {
            this.previewLink?.hideWithInteractivity(event)
            return false
          },
        },
      },
    })
  }
}

export function createLinkExtension(options: LinkPreviewOption) {
  return Link.configure({
    openOnClick: true,
    autolink: true,
    linkOnPaste: true,
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
      class:
        'text-primary-500 text-sm hover:cursor-pointer underline',
    },
  }).extend({
    addProseMirrorPlugins() {
      return [
        new LinkPlugin({
          editor: this.editor,
          options,
        }).create(),
        ...(this.parent?.() || []),
        new Plugin({
          props: {
            handleKeyDown: (_: EditorView, event: KeyboardEvent) => {
              const { selection } = this.editor.state

              if (event.key === 'Escape' && selection.empty !== true) {
                this.editor.commands.focus(selection.to, { scrollIntoView: false })
              }

              return false
            },
          },
        }),
      ]
    },
  })
}
