import type { BaseTask, TaskCustomField } from '#task/types'
import { FieldType } from '#field/constant'
import { TASK_EMPTY_ID, TaskDependencyType } from '#task/constant'

export const isFieldValueEmpty = (field: TaskCustomField) => {
  if (field.field?.type === FieldType.CHECKBOX) {
    return field.value === '[]' || !field.value
  }

  return !field.value
}

export const getTaskDependencyType = (fromEdge: string, toEdge: string) => {
  if (fromEdge === 'left' && toEdge === 'left') {
    return TaskDependencyType.START_TO_START
  }

  if (fromEdge === 'right' && toEdge === 'right') {
    return TaskDependencyType.FINISH_TO_FINISH
  }

  if (fromEdge === 'left' && toEdge === 'right') {
    return TaskDependencyType.START_TO_FINISH
  }

  return TaskDependencyType.FINISH_TO_START
}

export const getTaskDependencyHint = (
  type: TaskDependencyType,
  isAcronym = false
) => {
  switch (type) {
    case TaskDependencyType.START_TO_START:
      return isAcronym ? 'SS' : 'Start to start'
    case TaskDependencyType.START_TO_FINISH:
      return isAcronym ? 'SF' : 'Start to finish'
    case TaskDependencyType.FINISH_TO_START:
      return isAcronym ? 'FS' : 'Finish to start'
    case TaskDependencyType.FINISH_TO_FINISH:
      return isAcronym ? 'FF' : 'Finish to finish'
  }
}

export const makeTaskUrl = (task: Pick<BaseTask, 'id' | 'handle' | 'name'>) => {
  if (isOptimisticId(task.id)) {
    return `#`
  }

  const { handle, name } = task
  // Remove special characters
  const nameWithoutSpecialCharacters = name.replace(
    /[!@#$%^&*()\-_=+\\|[\]{};:/?.>,]/g,
    ''
  ).toLowerCase()

  // Remove accents
  const nameWithoutAccents = nameWithoutSpecialCharacters
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')

  // Remove emoji ✅🙃
  const nameWithoutEmoji = nameWithoutAccents.replace(
    /[\u{1F600}-\u{1F6FF}]|[^\w.,\s]/gu,
    ''
  )

  // Replace spaces with - characters
  const nameWithHyphens = nameWithoutEmoji.trim().replace(/\s+/g, '-')

  return `/t/${handle}/${nameWithHyphens}`
}

export const updateTaskUrl = (task: Pick<BaseTask, 'id' | 'handle' | 'name'>, prefix: string = '', hash: string = '') => {
  if (isOptimisticId(task.id)) {
    return
  }

  const taskUrl = makeTaskUrl(task)
  window.history.pushState(null, '', `${prefix}${taskUrl}${hash?.length ? `#${hash}` : ''}`)
}

export const getTaskHandleFromUrl = (url: string) => {
  const regex = /\/t\/([a-zA-Z0-9]+)/
  const match = url.match(regex)
  return match ? match[1] : null
}

export const isEmptyTaskId = (taskId: string) => {
  return taskId === TASK_EMPTY_ID
}
