export const BASE_USER_FRAGMENT = gql`
  fragment BaseUser on UserType {
    id
    fullName
    email
    emailVerified
    photo
    completedTutorial
  }
`

export const MIN_USER_FRAGMENT = gql`
  fragment MinUser on UserType {
    id
    fullName
    email
    photo
  }
`

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfile on UserType {
    fullName
    photo
    pronouns
    phone
    jobTitle
    department
    bio
  }
`

export const ROLE_FRAGMENT = gql`
  fragment Role on RoleType {
    id
    name
    type
    level
    baseLevel
    allowedPermissions
    disallowedPermissions
  }
`

export const INVITE_ENTRY_FRAGMENT = gql`
  fragment InviteEntry on InviteEntryOutput {
    code
    email
    workspaceMemberId
    userId
    error
  }
`
