import { ATTACHMENT_TYPE_NAME } from '#attachment/constant'
import type { Attachment } from '#attachment/types'
import { getIdentifier } from '#core/utils/apollo'

export const useAttachmentDataConvert = () => {
  const getAttachmentIdentifier = (id: string) => getIdentifier(id, ATTACHMENT_TYPE_NAME)
  const fields = ['name', 'mimeType', 'type', 'url', 'created']

  const getOptimisticAttachment = (id: string | null, payload: Partial<Attachment>, forFields = false): Partial<Attachment> & { __typename?: string } => {
    const defaults = { boards: [], taskTypes: [], statuses: [], labels: [] }
    const attachment = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? attachment : { ...attachment, __typename: ATTACHMENT_TYPE_NAME }
  }

  const getOptimisticAttachmentFields = (payload: Partial<Attachment>) => {
    const attachment = getOptimisticAttachment(null, payload, true)
    return convertObjectToFields(attachment)
  }

  return {
    getAttachmentIdentifier,
    getOptimisticAttachment,
    getOptimisticAttachmentFields,
  }
}
