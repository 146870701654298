import { pick } from 'lodash-es'
import type { PresignedUploadResponse } from '#core/types'

export const usePresignedUpload = () => {
  const upload = <Variable extends object>(
    mutation: unknown,
    variables: Variable,
    rawFiles: File[] | FileList
  ): Promise<
    | { success: false; message: string }
    | { success: true; presignedUrls: string[] }
  > => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const files = Array.from(rawFiles as unknown as File[])
      const isTooLarge = files.some((file) => file.size > 1024 * 1024 * 100)
      if (isTooLarge) {
        return resolve({ success: false, message: 'File is too large' })
      }

      const { mutate, error } = useMutation<PresignedUploadResponse>(
        // @ts-expect-error Mutation type is unknown
        mutation,
        {
          variables: {
            ...variables,
            files: files.map((file) => pick(file, ['name', 'size', 'type'])),
          },
        }
      )
      const data = await mutate()
      if (error.value) {
        return resolve({
          success: false,
          message: 'Failed to get presigned urls',
        })
      }

      if (data?.data?.presigned?.urls) {
        const presignedUrls = data.data.presigned.urls
        const isPresignedUrlsValid = presignedUrls.every(
          (url) => typeof url === 'string'
        )
        if (!isPresignedUrlsValid) {
          return resolve({ success: false, message: 'Something went wrong' })
        }

        try {
          const uploadPromises = files.map((file, index) => {
            const presignedUrl = presignedUrls[index] as string
            return fetch(presignedUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': file.type,
              },
            })
          })
          await Promise.all(uploadPromises)
          resolve({
            success: true,
            presignedUrls: presignedUrls,
          })
        } catch (error) {
          resolve({ success: false, message: 'Something went wrong' })
        }
      }
    })
  }

  return {
    upload,
  }
}
