import { get } from 'lodash-es'
import {
  BackgroundTypes,
  BackgroundImageQuality,
  GroupBy,
} from '#board/constant'
import { type BaseBoard } from '#board/types'
import type { TaskItem } from '#task/types'
import type { Field } from '#field/types'
import { TaskLevel } from '#task/constant'
import { MIN_COLUMN_RESIZER_WIDTH } from '#core/constant'

export const getBackgroundColorURL = (
  color: string,
  quality: BackgroundImageQuality = BackgroundImageQuality.RAW
): string => {
  if (color === 'default') {
    if (quality === BackgroundImageQuality.FULL) {
      return `/assets/background-presets/default.svg`
    } else if (quality === BackgroundImageQuality.THUMB) {
      return `/assets/background-presets/default-thumb.svg`
    } else {
      return `/assets/background-presets/default-wave.svg`
    }
  }

  return `/assets/background-presets/gradient-${color}.svg`
}

export const getBackgroundUrl = (
  board: BaseBoard,
  quality: BackgroundImageQuality = BackgroundImageQuality.RAW
): string => {
  switch (board.backgroundType) {
    case BackgroundTypes.PHOTO:
      return getUnsplashImageQuery(board.backgroundPhoto, quality)
    case BackgroundTypes.CUSTOM:
      return board.backgroundPhoto
    case BackgroundTypes.COLOR:
    default:
      return getBackgroundColorURL(board.backgroundColor, quality)
  }
}

export const getUnsplashImageQuery = (
  rawUrl: string,
  quality: BackgroundImageQuality
): string => {
  switch (quality) {
    case BackgroundImageQuality.FULL:
      return `${rawUrl}&crop=entropy&cs=srgb&q=85&w=2560`
    case BackgroundImageQuality.REGULAR:
      return `${rawUrl}&crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080`
    case BackgroundImageQuality.SMALL:
      return `${rawUrl}&crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=400`
    case BackgroundImageQuality.THUMB:
      return `${rawUrl}&crop=entropy&cs=tinysrgb&fit=max&fm=jpg&w=200`
    case BackgroundImageQuality.RAW:
    default:
      return rawUrl
  }
}

export const getCalcResult = (input: number[], expression: string) => {
  if (!input.length) return ''

  const sum = () => input.reduce((a, b) => a + b, 0)
  switch (expression) {
    case 'SUM':
      return sum()
    case 'AVERAGE':
      return (sum() / input.length).toFixed(2)
    case 'COUNT':
      return input.length
    case 'MIN':
      return Math.min(...input)
    case 'MAX':
      return Math.max(...input)
    default:
      return ''
  }
}

export const getNumberFieldValue = (
  listItems: TaskItem[],
  key: string,
  getChildren = false
) => {
  const fieldValues: string[] = []
  const { boardData } = useBoardSharedState()
  const taskTypes = boardData.value.taskTypes || []

  const collectFieldValues = (tasks: TaskItem[]) => {
    for (const task of tasks) {
      let value = task.taskFields?.find(
        (taskField) => taskField.field.id === key
      )?.value

      if (value === undefined) {
        const { boardData } = useBoardSharedState()
        const taskType = taskTypes.find(
          (taskType) => taskType.id === task.typeId
        )
        const fieldType = taskType?.fields?.find((field) => field.id === key)
        const fields = (boardData.value as { fields?: Field[] })?.fields || []
        value = fields.find(
          (field) => field.id === key && fieldType?.id === key
        )?.default
      }

      if (value) {
        fieldValues.push(value)
      }

      if (getChildren && task.subtasksCount) {
        const { tasks: boardTasks } = useBoardTasksLoader()
        const { groupBy } = useBoardSharedState()
        if (groupBy.value === GroupBy.SECTION) {
          if (task.level === TaskLevel.TASK) {
            const subtasks = boardTasks.value.filter((subtask) => {
              return subtask.parentId === task.id
            })
            collectFieldValues(subtasks)
          }
        } else {
          const subtasks = boardTasks.value.filter((subtask) => {
            return (
              subtask.parentId === task.id && subtask.level !== TaskLevel.MODULE
            )
          })
          collectFieldValues(subtasks)
        }
      }
    }
  }

  collectFieldValues(listItems)
  return fieldValues
}

export const getBoardViewByUrl = (url = location.pathname) => {
  const reg =
    /\/(?<type1>list|timeline)\/t\/(.*)|\/b\/(.*)\/(?<type2>list|timeline)/
  const result = url.match(reg)
  return result?.groups?.type1 || result?.groups?.type2 || ''
}

export const getTableColumnWidth = (
  tableColumnWidth: Record<string, number>,
  key: string,
  defaultValue = MIN_COLUMN_RESIZER_WIDTH
) => {
  return get(tableColumnWidth, key, defaultValue)
}
