export const BASE_WORKSPACE_FRAGMENT = gql`
  fragment BaseWorkspace on WorkspaceType {
    id
    name
    handle
    logo
    active
  }
`

export const WORKSPACE_SETTINGS_FRAGMENT = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  fragment WorkspaceSettings on WorkspaceType {
    ...BaseWorkspace
    enableInvitationLink
    invitationToken
    membershipRestrictions
    membershipRestrictionsDomains
  }
`
