import { has, includes } from 'lodash-es'
import { safeParseStringToJSON, uuid } from '#core/utils/helper'

export const getIdFromRef = (ref: string) => {
  return ref.split(':')[1]
}

export const extractStoreFieldName = <T extends Record<string, unknown>>(
  storeFieldName: string
): Partial<T> => {
  const reg = /{.*}/
  const [args] = storeFieldName.match(reg) || []
  if (args) {
    return safeParseStringToJSON(args, {}) as T
  }

  return {} as T
}

export const getRootQueryFieldRefs = (
  fieldName: string,
  args: Record<string, unknown>
) => {
  return `${fieldName}(${JSON.stringify(args)})`
}

export const getIdentifier = (id: string, typeName: string) => typeName + ':' + id

export const getRef = (id: string, __typename: string) => {
  return { __ref: getIdentifier(id, __typename) }
}

export const generateOptimisticId = () => {
  return 'opt-' + uuid()
}

export const isOptimisticId = (id: string) => {
  return id.startsWith('opt-')
}

/**
 * Generate optimistic object for any model
 */
export const getOptimisticObject = (
  id: string | null,
  payload: Record<string, unknown>,
  fields: string[],
  defaults: Record<string, unknown>,
  forFields = false
): Partial<Record<string, unknown>> & { __typename?: string } => {
  let create = isOptimisticId(id || '')
  if (!id && !forFields) {
    id = generateOptimisticId()
    create = true
  }

  const optimisticObject: Partial<Record<string, unknown>> & { __typename?: string } = fields.reduce((acc, field) => {
    const check_field = field.endsWith('Id') ? field.slice(0, -2) : field
    if (has(payload, check_field)) {
      let value = payload[check_field]
      if (!includes(['string', 'number', 'undefined'], typeof value) && value !== null) {
        value = String(value)
      }

      acc[field] = value
    } else if (create) {
      acc[field] = has(defaults, field) ? defaults[field] : null
    }

    return acc
  }, {} as Partial<Record<string, unknown>>)

  return forFields ? optimisticObject : { ...optimisticObject, id }
}

export const convertObjectToFields = (obj: Record<string, unknown>) => {
  return Object.entries(obj).reduce((acc, curr) => {
    const [key, value] = curr
    acc[key] = () => value
    return acc
  }, {} as Record<string, () => unknown>)
}

export const createFieldModifiers = <T extends Record<string, unknown>>(
  fields: string[],
  payload: T
): Record<string, () => unknown> => {
  return fields.reduce((acc, field) => {
    acc[field] = () => payload[field]
    return acc
  }, {} as Record<string, () => unknown>)
}
