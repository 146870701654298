export const AUTOMATION_ACTION_FRAGMENT = gql`
  fragment AutomationAction on AutomationActionType {
    id
    type
    operator
    targetData
    targetValue
    label
    icon
    index
  }
`
export const AUTOMATION_CONDITION_FRAGMENT = gql`
  fragment AutomationCondition on AutomationConditionType {
    id
    type
    operator
    targetData
    targetValue
    label
    icon
  }
`
export const AUTOMATION_TRIGGER_FRAGMENT = gql`
  fragment AutomationTrigger on AutomationTriggerType {
    id
    type
    operator
    targetData
    targetValue
    label
    icon
  }
`

export const AUTOMATION_FRAGMENT = gql`
  ${AUTOMATION_ACTION_FRAGMENT}
  ${AUTOMATION_CONDITION_FRAGMENT}
  ${AUTOMATION_TRIGGER_FRAGMENT}
  fragment Automation on AutomationRuleType {
    id
    name
    type
    isActive
    actions {
      ...AutomationAction
    }
    condition {
      ...AutomationCondition
    }
    trigger {
      ...AutomationTrigger
    }
    taskTypes
  }
`
