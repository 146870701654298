import { LazyDeleteTaskAndSectionModal } from '#components'
import { ArchiveModalType, TaskLevel } from '#task/constant'
import type { TaskItem } from '#task/types'

export const useArchiveTask = () => {
  const modal = useModal()
  const { updateTask, updateTasks } = useUpdateTask()
  const toast = useToast()
  const { preventCloseState } = useModalManager()

  watch(modal.isOpen, (isOpen) => {
    if (!isOpen) {
      preventCloseState.value.delete('task-detail')
    } else {
      preventCloseState.value.add('task-detail')
    }
  })

  const archiveTask = async (task: TaskItem,) => {
    if (task.level === TaskLevel.MODULE && task.subtasksCount) {
      modal.open(LazyDeleteTaskAndSectionModal, {
        module: task,
        type: ArchiveModalType.MODULE,
        onCancel: () => {
          modal.close()
        }
      })
      return
    }

    await updateTask(task.id, { closed: true })
    const actions = [
      {
        label: 'Undo',
        click: () => {
          updateTask(task.id, { closed: false })
        },
      },
    ]
    toast.add({
      icon: 'i-heroicons-check-circle',
      color: 'green',
      title: 'Task archived',
      actions,
    })
  }

  const archiveTasks = async (tasks: TaskItem[]) => {
    const modules = tasks.filter((task) => task.level === TaskLevel.MODULE && task.subtasksCount)
    if (modules.length) {
      modal.open(LazyDeleteTaskAndSectionModal, {
        archiveTasks: tasks,
        modules: modules,
        type: ArchiveModalType.MODULES,
        onCancel: () => {
          modal.close()
        },
      })
      return
    }

    await updateTasks(tasks.map((task) => task.id), { closed: true })

    const actions = [
      {
        label: 'Undo',
        click: () => {
          updateTasks(tasks.map((task) => task.id), { closed: false })
        },
      },
    ]
    toast.add({
      icon: 'i-heroicons-check-circle',
      color: 'green',
      title: 'Task archived',
      actions,
    })
  }

  return { archiveTask, archiveTasks }
}
