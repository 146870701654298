export enum LogTypeEnum {
  CREATE_TASK = 'CREATE_TASK',
  UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION',
  DELETE_TASK = 'DELETE_TASK',
  UNASSIGN_TASK = 'UNASSIGN_TASK',
  ASSIGN_TASK = 'ASSIGN_TASK',
  SET_TASK_STATUS = 'SET_TASK_STATUS',
  COPY_TASK = 'COPY_TASK',
  MOVE_TO_BOARD = 'MOVE_TO_BOARD',
  MOVE_TO_SECTION = 'MOVE_TO_SECTION',
  ARCHIVE_TASK = 'ARCHIVE_TASK',
  UNARCHIVE_TASK = 'UNARCHIVE_TASK',

  ADD_SUB_TASK = 'ADD_SUB_TASK',

  CREATE_SECTION = 'CREATE_SECTION',
  UPDATE_SECTION_NAME = 'UPDATE_SECTION_NAME',
  ARCHIVE_SECTION = 'ARCHIVE_SECTION',
  UNARCHIVE_SECTION = 'UNARCHIVE_SECTION',

  CREATE_BOARD = 'CREATE_BOARD',
  UPDATE_BOARD_NAME = 'UPDATE_BOARD_NAME',
  JOIN_BOARD = 'JOIN_BOARD',
  LEAVE_BOARD = 'LEAVE_BOARD',
  DELETE_BOARD_MEMBER = 'DELETE_BOARD_MEMBER',

  ADD_ATTACHMENT_TO_TASK = 'ADD_ATTACHMENT_TO_TASK',

  SET_DUE_DATE = 'SET_DUE_DATE',
  UPDATE_DUE_DATE = 'UPDATE_DUE_DATE',
  SET_MODULE = 'SET_MODULE',
  CHANGE_TASK_TYPE = 'CHANGE_TASK_TYPE',

  // TODO: Use this log type after implementing the Sprint feature
  // CREATE_SPRINT = 'CREATE_SPRINT',
  // UPDATE_SPRINT = 'UPDATE_SPRINT',
  // DELETE_SPRINT = 'DELETE_SPRINT',
}
