<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1359_64583)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0005 -0.000488281H0V16H16.0005V-0.000488281ZM11.7882 5.8207C12.0574 5.56948 12.0719 5.14762 11.8207 4.87845C11.5695 4.60929 11.1476 4.59474 10.8785 4.84596L6.33333 9.08808L5.12155 7.95707C4.85238 7.70585 4.43052 7.7204 4.1793 7.98957C3.92807 8.25873 3.94262 8.68059 4.21179 8.93182L5.87846 10.4874C6.13459 10.7264 6.53207 10.7264 6.78821 10.4874L11.7882 5.8207Z"
        fill="#3B82F6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1359_64583">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
