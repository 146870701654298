<template>
  <div class="px-4 py-3" data-test="date-picker-popover" @click.prevent.stop.exact>
    <div class="grid grid-cols-2 gap-2">
      <div class="w-32 max-h-8 flex flex-col-reverse gap-1 items-start justify-end">
        <div v-if="showStartDateInput" class="relative">
          <UInput
            ref="startDateInputRef"
            v-model="formStateTemp.startDate"
            placeholder="YYYY/M/D"
            class="w-full"
            autofocus
            data-test="start-date-input"
            @blur="onChangeStartDate"
            @focus="focusInputType = 'startDate'"
            @keydown.enter.prevent.stop="$event.target?.blur()"
          />
          <UButton
            size="2xs"
            color="gray"
            variant="ghost"
            icon="i-heroicons-x-mark"
            class="absolute right-1 top-1/2 -translate-y-1/2"
            data-test="clear-start-date"
            @click="onClearStartDate"
          />
        </div>
        <UButton
          v-else
          icon="i-heroicons-plus-small"
          class="w-full"
          color="gray"
          variant="ghost"
          size="sm"
          data-test="add-start-date-btn"
          @click="onAddStartDate"
        >
          Start date
        </UButton>

        <span class="text-xs font-medium text-gray-700">
          Start date
        </span>
      </div>
      <div class="w-32 flex flex-col-reverse gap-1">
        <div class="relative">
          <UInput
            ref="dueDateInputRef"
            v-model="formStateTemp.dueDate"
            autofocus
            placeholder="YYYY/M/D"
            class="w-full"
            data-test="due-date-input"
            @blur="onChangeDueDate"
            @focus="focusInputType = 'dueDate'"
            @keydown.enter.prevent.stop="$event.target?.blur()"
          />
          <UButton
            size="2xs"
            color="gray"
            variant="ghost"
            icon="i-heroicons-x-mark"
            class="absolute right-1 top-1/2 -translate-y-1/2"
            @click="onClearDueDate"
          />
        </div>
        <span class="text-xs font-medium text-black">
          Due date
        </span>
      </div>

      <!-- Time section -->
      <template v-if="showTimeInput">
        <div class="w-32 relative">
          <template v-if="showStartTimeInput">
            <InputMenu
              ref="startTimeRef"
              :value="formState.startTime"
              placeholder="Start time"
              :options="TimePickPresets"
              @change="onChangeStartTime"
              @clear="clear('startTime')"
            />
          </template>
          <UButton
            v-else
            icon="i-heroicons-plus-small"
            class="w-full"
            color="gray"
            variant="ghost"
            size="sm"
            @click="onAddStartTime"
          >
            Start time
          </UButton>
        </div>
        <div class="w-32 relative">
          <InputMenu
            ref="dueTimeRef"
            :value="formState.dueTime"
            placeholder="Due time"
            :options="TimePickPresets"
            @change="onChangeDueTime"
            @clear="clear('dueTime')"
          />
        </div>
      </template>
    </div>
    <div class="flex justify-center mt-4">
      <DatePicker
        ref="datePickerRef"
        :model-value="rangeState"
        :is-range="showStartDateInput"
        @update:model-value="(value) => (rangeState = value)"
        @dayclick="onDayClick"
      />
    </div>
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <UTooltip
          text="Add time"
          :popper="{
            placement: 'top',
          }"
        >
          <UButton
            :color="showTimeInput ? 'primary' : 'gray'"
            variant="ghost"
            size="sm"
            icon="i-heroicons-clock"
            @click="onToggleTimeInput"
          >
            Add time
          </UButton>
        </UTooltip>
      </div>

      <div class="flex items-center gap-2">
        <UButton
          color="gray" variant="ghost" size="sm" data-test="clear-button"
          @click="onClearAll"
        >
          Clear
        </UButton>
        <UButton
          color="primary" size="sm"
          data-test="save-button"
          @click="onApply"
        >
          Save
        </UButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type {
  DatePickerDate,
  DatePickerRangeObject,
} from 'v-calendar/dist/types/src/use/datePicker.js'
import { isAfter } from 'date-fns'
import type { CalendarDay } from 'v-calendar/dist/types/src/utils/page.js'
import { TimePickPresets } from '#core/constant'

const props = defineProps({
  startDate: {
    type: String,
  },
  dueDate: {
    type: String,
  },
  close: {
    type: Function,
  },
})

const emit = defineEmits(['change', 'close'])

const dateSchema = z.coerce.date()

const defaultDate = () => {
  const { startDate, dueDate } = props

  return {
    startDate: startDate ? dateFormat(startDate) : '',
    dueDate: dueDate ? dateFormat(dueDate) : '',
    startTime: timeFormat(startDate, (time: string) => dateFormat(time, 'HH:mm')),
    dueTime: timeFormat(dueDate, (time: string) => dateFormat(time, 'HH:mm')),
  }
}

const lastClear = ref(Date.now())
const showStartDateInput = ref(!!props.startDate)
const showTimeInput = ref(false)
const showStartTimeInput = ref(false)
const formState = reactive(defaultDate())
const startTimeRef = ref()
const dueTimeRef = ref()
const datePickerRef = ref()
const focusInputType = ref<'startDate' | 'dueDate' | null>(null)
const dueDateInputRef = ref()
const startDateInputRef = ref()
let preventSetDate = false

const formStateTemp = computed({
  set({ startDate, dueDate }) {
    setDate('startDate', startDate)
    setDate('dueDate', dueDate)
  },
  get() {
    return {
      startDate: formState.startDate,
      dueDate: formState.dueDate,
    }
  },
})

const rangeState = computed<DatePickerDate | DatePickerRangeObject>({
  set(value: DatePickerDate | DatePickerRangeObject) {
    if (value) {
      //  With Range
      if (Object.prototype.hasOwnProperty.call(value, 'start')) {
        const { start, end } = value as DatePickerRangeObject
        const isRecentClear = Date.now() - lastClear.value < 100
        const shouldUpdateStart =
          start && (!isRecentClear || formState.startDate)
        const shouldUpdateEnd = end && (!isRecentClear || formState.dueDate)
        return extend(formState, {
          startDate: shouldUpdateStart ? dateFormat(start as string) : '',
          dueDate: shouldUpdateEnd ? dateFormat(end as string) : '',
        })
      }

      //  Without Range
      const partialValue = dateFormat(value as string)
      if (!formState.startDate && !formState.dueDate) {
        if (showStartDateInput.value) {
          datePickerRef.value?.setDragEnd(partialValue)
          return extend(formState, {
            startDate: partialValue,
            dueDate: '',
          })
        }

        return extend(formState, {
          startDate: '',
          dueDate: partialValue,
        })
      }

      if (formState.startDate) {
        formState.startDate = partialValue
      }

      if (formState.dueDate) {
        formState.dueDate = partialValue
      }
    }
  },
  get() {
    const { startDate, dueDate } = formState
    if (showStartDateInput.value) {
      return {
        start: startDate,
        end: dueDate,
      }
    }

    return startDate || dueDate || null
  },
})

const isAfterDay = (day: CalendarDay, key: 'startDate' | 'dueDate') => {
  return isAfter(day.date, new Date(formState[key]))
}

const inputElement = (ref: Ref<{ $el: HTMLElement }>): HTMLInputElement | null => {
  return ref.value?.$el?.querySelector('input')
}

const sortDateTime = () => {
  if (Object.values(formState).every((value) => value)) {
    const startDateTime = new Date(
      `${formState.startDate} ${formState.startTime}`
    )
    const dueDateTime = new Date(`${formState.dueDate} ${formState.dueTime}`)
    if (isAfter(startDateTime, dueDateTime)) {
      [formState.startTime, formState.dueTime] = [
        formState.dueTime,
        formState.startTime,
      ]
    }
  }
}

const setDate = (key: 'startDate' | 'dueDate', value: string) => {
  const parse = dateSchema.safeParse(value)
  const date = parse.success ? dateFormat(parse.data) : ''

  extend(formState, {
    [key]: date,
  })
}

const isTimeFormat = (value: string) => {
  return /^\d{2}:\d{2}$/.test(value)
}

const setTime = (key: 'startTime' | 'dueTime', value: string) => {
  const time = timeFormat(value)
  extend(formState, {
    [key]: time,
  })
}

const clearDatePickerState = () => {
  datePickerRef.value?.updateValue(null, {
    patch: 'date',
    formatInput: true,
    hidePopover: true,
    dragging: false,
  })
}

const onClearDate = (key: string) => {
  extend(formState, {
    [key]: '',
  })
  lastClear.value = Date.now()
  focusInputType.value = null
  if (!formState.startDate && !formState.dueDate) {
    clearDatePickerState()
  }
}

const clear = (key: string) => {
  extend(formState, {
    [key]: '',
  })
}

const onClearAll = () => {
  extend(formState, {
    startDate: '',
    dueDate: '',
    startTime: '',
    dueTime: '',
  })
  showTimeInput.value = false
  showStartTimeInput.value = false
  focusInputType.value = null
  clearDatePickerState()
}

const onClearDueDate = () => {
  onClearDate('dueDate')
  datePickerRef.value?.setDragEnd(formState.startDate)
}

const onClearStartDate = () => {
  onClearDate('startDate')
  datePickerRef.value?.setDragEnd(formState.dueDate)
}

const onToggleTimeInput = () => {
  showTimeInput.value = !showTimeInput.value
  if (showTimeInput.value) {
    nextTick(() => {
      dueTimeRef.value?.focus()
    })
  }
}

const onAddStartTime = () => {
  showStartTimeInput.value = true
  nextTick(() => {
    lastClear.value = Date.now()
    startTimeRef.value?.focus()
  })
}

const onAddStartDate = () => {
  showStartDateInput.value = true

  if (typeof rangeState.value === 'object' && rangeState.value) {
    const { end } = rangeState.value as DatePickerRangeObject

    if (formState.dueDate) {
      formState.startDate = formState.dueDate
    }

    datePickerRef.value?.setDragEnd(end)
  }
}

const handleStartDateFocus = (day: CalendarDay) => {
  if (!formState.dueDate) {
    formState.startDate = dateFormat(day.date)
    datePickerRef.value?.setDragEnd(day.date)
    inputElement(dueDateInputRef)?.focus()
    return
  }

  if (dateFormat(day.date) === formState.dueDate) {
    datePickerRef.value?.updateValue({
      start: day.date,
      end: day.date,
    }, {
      patch: 'date',
      formatInput: true,
      hidePopover: true,
      dragging: false,
    })
    inputElement(startDateInputRef)?.blur()
    return
  }

  if (isAfterDay(day, 'dueDate')) {
    formState.startDate = dateFormat(day.date)
    onClearDate('dueDate')
    datePickerRef.value?.setDragEnd(day.date)
    inputElement(dueDateInputRef)?.focus()
    return
  }

  formState.startDate = dateFormat(day.date)
  inputElement(startDateInputRef)?.blur()
}

const handleDueDateFocus = (day: CalendarDay) => {
  formState.dueDate = dateFormat(day.date)
  inputElement(dueDateInputRef)?.blur()
  if (dateFormat(day.date) === formState.startDate) {
    return
  }

  if (formState.startDate && !isAfterDay(day, 'startDate')) {
    onClearDate('startDate')
    datePickerRef.value?.setDragEnd(day.date)
    inputElement(startDateInputRef)?.focus()
  }
}

const handleExistingStartDate = (day: CalendarDay) => {
  if (formState.dueDate) {
    // Update appropriate date based on clicked day
    if (isAfterDay(day, 'startDate')) {
      formState.dueDate = dateFormat(day.date)
    } else {
      formState.startDate = dateFormat(day.date)
    }

    return
  }

  // No due date yet, set it
  formState.dueDate = dateFormat(day.date)
  sortDateTime()
}

const handleNoStartDate = (day: CalendarDay) => {
  if (!formState.dueDate) {
    // Set both dates to same day initially
    formState.dueDate = dateFormat(day.date)
    formState.startDate = dateFormat(day.date)
    inputElement(dueDateInputRef)?.focus()
    return
  }

  // Has due date, set start date
  formState.startDate = dateFormat(day.date)
  sortDateTime()
}

const onDayClick = (day: CalendarDay, _: MouseEvent, preventDefault: (prevent: boolean) => void) => {
  if (!showStartDateInput.value) return

  preventSetDate = true

  // Handle focused input cases
  if (focusInputType.value) {
    preventDefault(true)

    if (focusInputType.value === 'startDate') {
      handleStartDateFocus(day)
      return
    }

    handleDueDateFocus(day)
    return
  }

  // Handle non-focused input cases
  if (formState.startDate) {
    preventDefault(true)
    handleExistingStartDate(day)
    return
  }

  handleNoStartDate(day)
  focusInputType.value = null
}

const onChangeDueDate = (event: Event) => {
  focusInputType.value = null
  if (preventSetDate) {
    preventSetDate = false
    return
  }

  focusInputType.value = null

  setDate('dueDate', (event.target as HTMLInputElement).value)
  datePickerRef.value?.moveToValue('end')
  sortDateTime()
}

const onChangeStartDate = (event: Event) => {
  focusInputType.value = null
  if (preventSetDate) {
    preventSetDate = false
    return
  }

  setDate('startDate', (event.target as HTMLInputElement).value)
  datePickerRef.value?.moveToValue('start')
  sortDateTime()
}

const onChangeStartTime = (startTime: string) => {
  if (!isTimeFormat(startTime)) {
    return
  }

  // Check if start date is empty, set it to today's date
  if (startTime && !formState.startDate) {
    showStartDateInput.value = true
    formState.startDate = dateFormat(new Date())
  }

  setTime('startTime', startTime)
}

const onChangeDueTime = (dueTime: string) => {
  if (!isTimeFormat(dueTime)) {
    return
  }

  // Check if due date is empty, set it to today's date
  if (dueTime && !formState.dueDate) {
    formState.dueDate = dateFormat(new Date())
  }

  setTime('dueTime', dueTime)
}

const changeValue = () => {
  const { startDate, dueDate, startTime, dueTime } = formState
  const startDateTime = startDate ? safariSafeDate(startDate, startTime) : null
  const dueDateTime = dueDate ? safariSafeDate(dueDate, dueTime) : null
  const emitDates: Record<string, string | null> = {}
  if (props.startDate != startDateTime) {
    emitDates.startDate = startDateTime || null
  }

  if (props.dueDate != dueDateTime) {
    emitDates.dueDate = dueDateTime || null
  }

  if (startDate && !dueDate) {
    emitDates.dueDate = emitDates.startDate
  }

  emit('change', emitDates)
}

const onApply = () => {
  changeValue()
  emit('close')
}

defineExpose({
  change: changeValue,
})

onMounted(() => {
  extend(formState, defaultDate())
  const isEmpty = (value: string) => !value || value === '00:00'
  showStartDateInput.value = !!props.startDate

  showStartTimeInput.value = !isEmpty(formState.startTime)
  showTimeInput.value = showStartTimeInput.value || !isEmpty(formState.dueTime)

  if (!formState.dueDate && formState.startDate) {
    datePickerRef.value?.setDragEnd(formState.startDate)
  }
})
</script>
