<template>
  <Transition
    enter-active-class="transition ease-out duration-150"
    enter-from-class="translate-x-[632px]"
    enter-to-class="opacity-100 translate-x-0"
    leave-active-class="transition ease-in duration-150"
    leave-from-class="opacity-100 translate-x-0"
    leave-to-class="translate-x-[632px]"
  >
    <div
      v-if="visible"
      ref="containerRef"
      class="fixed right-0 top-0 bottom-0 z-50 flex items-center justify-end bg-black bg-opacity-50 overflow-hidden"
      data-test="task-detail-sidebar"
    >
      <!-- Background overlay -->
      <div
        class="w-[632px] min-w-[632px] max-w-[632px] h-screen bg-white "
      >
        <!-- Slideover container -->
        <UCard
          v-if="currentTask.id"
          id="task-detail-scroller"
          class="ring-0 shadow-none overflow-y-auto overflow-x-hidden max-h-screen scroll-stable minimal-scrollbar border-l border-gray-200 dark:border-gray-800 rounded-none min-h-screen"
          :ui="{ body: { padding: 'p-0 sm:p-0' } }"
        >
          <TaskDetail
            :task="currentTask"
            @loaded="(task) => (taskDetail = task)"
            @close="onCloseTaskView"
          />
        </UCard>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import type { TaskDetail, CurrentTask } from '#task/types'

const { currentTask, currentBoard, setCurrentTask } = useWorkspaceSharedState()
const { preventCloseState, isPreventOpenTaskDetail } = useModalManager()
const { syncFilterToURL } = useSyncTaskFilterURL()

const taskDetail = ref<TaskDetail>()
const containerRef = ref<HTMLElement>()
const visible = ref(false)

onClickOutside(containerRef, (event) => {
  if (preventCloseState.value?.has('task-detail')) {
    return
  }

  const el = event.target as HTMLElement
  const shouldCloseTask = el.closest('[data-prevent-close-task]') === null

  if (shouldCloseTask) {
    visible.value = false
    onCloseTaskView()
  }
})

const onCloseTaskView = () => {
  if (preventCloseState.value?.has('task-detail')) {
    return
  }

  window.history.pushState(
    null,
    '',
    `/b/${taskDetail.value?.board.handle}/${getBoardViewByUrl()}`
  )
  syncFilterToURL()
  setCurrentTask({} as CurrentTask)
}

watch(
  () => [currentTask.value.id, isPreventOpenTaskDetail.value],
  ([taskId, isPreventOpen]) => {
    if (isPreventOpen) {
      visible.value = false
      return
    }

    visible.value = !!taskId
    if (visible.value) {
      useHead({
        title: () => `${currentTask.value.name} | ${currentBoard.value.name}`,
      })
    }
  },
  {
    immediate: true,
  }
)
defineShortcuts({
  escape: {
    whenever: [visible],
    handler: () => {
      visible.value = false
      onCloseTaskView()
    },
  },
})
</script>
