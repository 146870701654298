import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { LabelBase, LabelSyncPayload } from '#label/types'
import { LABEL_LOADER_FRAGMENT } from '#label/loader_fragment'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { LABEL_TYPE_NAME } from '#label/constant'

class LabelListCache extends ModelListCache<LabelBase> {
  constructor() {
    super(LABEL_TYPE_NAME)
  }

  override getBoardCacheKeys() {
    return [{
      key: BOARD_CACHE.DATA,
      subKey: 'labels',
    }]
  }
}

export const useLabelSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new LabelListCache()
  const { getLabelIdentifier, getSyncLabelFields } = useLabelDataConvert()

  const onAdd = async (payload: LabelSyncPayload) => {
    client.cache.writeFragment({
      data: payload,
      fragment: LABEL_LOADER_FRAGMENT,
      fragmentName: 'LabelLoader',
    })
    listCache.add(currentBoard.value.id, payload)
  }

  const onUpdate = (payload: LabelSyncPayload) => {
    client.cache.modify({
      id: getLabelIdentifier(payload.id),
      fields: getSyncLabelFields(payload),
    })
  }

  const onDelete = (payload: LabelSyncPayload) => {
    client.cache.evict({
      id: getLabelIdentifier(payload.id),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: LabelSyncPayload) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}
