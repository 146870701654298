import { has } from 'lodash-es'
import type { Reference } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import type {
  UpdateTaskCustomFieldParams,
  UpdateTaskAttachmentParams,
  TaskUpdatePayload,
  TaskCustomField,
} from '#task/types'
import {
  DELETE_TASK_ATTACHMENT_MUTATION,
  TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION,
} from '#task/schema'
import type { Attachment } from '#attachment/types'
import { FIELD_FRAGMENT } from '#field/fragment'
import { AttachmentType } from '#attachment/constant'
import type { DropTaskAttachment } from '#task-type/types'

export const useUpdateTask = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const {
    getTaskIdentifier,
    getOptimisticTaskFields,
    getSyncTaskFields,
    getTaskObject,
    getTaskLabels,
  } = useTaskDataConvert()

  const normalizeTask = (payload: TaskUpdatePayload) => {
    const params: Record<string, unknown> = { ...payload }

    if (payload.cover) {
      params.cover = encodeURI(payload.cover)
    }

    return params
  }

  const updateTask = async (id: string, payload: TaskUpdatePayload) => {
    const taskIdentifier = getTaskIdentifier(id)
    const task = getTaskObject(taskIdentifier)
    if (!task && !has(payload, 'closed')) {
      return logger.log('Task not found')
    }

    const params: Record<string, unknown> = normalizeTask(payload)

    const optimisticFields = getOptimisticTaskFields(params)
    client.cache.modify({
      id: taskIdentifier,
      fields: optimisticFields,
      optimistic: true,
    })

    const { mutate, error } = useAddUpdateTaskMutation({
      variables: {
        input: {
          id,
          ...params,
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateTask?.task) {
          const { listCache } = useTaskSync()
          listCache.update(currentBoard.value.id, data?.addUpdateTask.task)
        }
      },
    })
    const data = await mutate()
    if (error.value) {
      handleSingleGraphQLError(error.value, true)
      return []
    }

    return data?.data?.addUpdateTask
  }

  const updateTasks = async (taskIds: string[], payload: TaskUpdatePayload) => {
    const params: Record<string, unknown> = normalizeTask(payload)
    for (const id of taskIds) {
      const taskIdentifier = getTaskIdentifier(id)
      const task = getTaskObject(taskIdentifier)
      if (!task && !has(payload, 'closed')) {
        return logger.log('Task not found')
      }

      const optimisticFields = getOptimisticTaskFields(params)
      client.cache.modify({
        id: taskIdentifier,
        fields: optimisticFields,
        optimistic: true,
      })
    }

    const { mutate, error } = useAddUpdateTasksMutation({
      variables: {
        taskIds,
        fields: JSON.stringify(params),
      },
      update: (_, { data }) => {
        if (data?.updateTasks?.tasks) {
          const { listCache } = useTaskSync()
          const tasks = data.updateTasks.tasks
          for (const task of tasks) {
            listCache.update(currentBoard.value.id, task)
          }
        }
      },
    })
    const data = await mutate()
    if (error.value) {
      handleSingleGraphQLError(error.value, true)
      return []
    }

    return data?.data?.updateTasks
  }

  const toggleLabels = async ({ taskIds, detachLabelIds = [], labelIds = [] }: { taskIds: string[], detachLabelIds: string[], labelIds: string[] }) => {
    for (const taskId of taskIds) {
      const taskIdentifier = getTaskIdentifier(taskId)
      const task = getTaskObject(taskIdentifier)
      if (!task) {
        return logger.log('Task not found')
      }

      const newLabels = getTaskLabels(task.labelIds || [], labelIds || [], detachLabelIds || [])
      const optimisticFields = getSyncTaskFields({ labelIds: newLabels })
      client.cache.modify({
        id: taskIdentifier,
        fields: optimisticFields,
        optimistic: true,
      })
    }

    const { mutate } = useToggTaskLabelsMutation({
      variables: {
        taskIds,
        labelIds,
        detachLabelIds,
      },
    })
    await mutate()
  }

  const updateTaskCustomField = async (params: UpdateTaskCustomFieldParams) => {
    const optimisticTaskFields = params.taskIds.map((id) => {
      const taskIdentifier = getTaskIdentifier(id)
      const task = getTaskObject(taskIdentifier)
      if (!task) {
        return logger.log('Task not found')
      }

      const updateFields = cloneDeep(task.taskFields || []) as (TaskCustomField & { __typename: string })[]
      const isExisting = updateFields.find((field) => {
        return field.field.id === params.fieldId
      })
      if (!isExisting) {
        const field = client.readFragment({
          fragment: FIELD_FRAGMENT,
          id: client.cache.identify({
            id: params.fieldId,
            __typename: 'FieldType',
          }),
        })

        if (!field) {
          return logger.log('Field not found')
        }

        return {
          value: params.value,
          field,
          fieldId: params.fieldId,
          id: generateOptimisticId(),
          __typename: 'TaskFieldType',
        }
      } else {
        return {
          ...isExisting,
          value: params.value,
        }
      }
    })

    const { mutate } = useUpdateTaskCustomFieldMutation({
      variables: params,
      optimisticResponse: {
        updateTaskCustomField: {
          success: true,
          taskFields: optimisticTaskFields,
        }
      }
    })
    await mutate()
  }

  const createAttachment = async (taskId: string, params: UpdateTaskAttachmentParams) => {
    const taskIdentifier = getTaskIdentifier(taskId)
    const task = getTaskObject(taskIdentifier)
    if (!task) {
      return logger.log('Task not found')
    }

    const optimisticAttachments = params.attachments.map((attachment: string) => {
      return {
        id: generateOptimisticId(),
        name: params.name,
        type: params.type,
        mimeType: null,
        url: attachment,
        created: '',
        __typename: 'AttachmentType',
      }
    })

    const { mutate, error } = useCreateTaskAttachmentMutation({
      variables: {
        taskId,
        ...params,
      },
      optimisticResponse: {
        createTaskAttachment: {
          attachments: optimisticAttachments,
        }
      },
      update: (cache, { data }) => {
        if (data?.createTaskAttachment?.attachments) {
          cache.modify({
            id: taskIdentifier,
            fields: {
              attachments: (existingAttachments = [], { toReference }) => {
                return [...existingAttachments,
                  ...data.createTaskAttachment.attachments.map(
                    (attachment: Attachment) => toReference(attachment, true)
                  )
                ]
              },
            },
            optimistic: true,
          })
        }
      }
    })

    const data = await mutate()
    if (error.value) {
      handleSingleGraphQLError(error.value, true)
      return []
    }

    return data?.data?.createTaskAttachment.attachments
  }

  const updateTaskAttachment = async (attachmentId: string, name: string) => {
    const { client } = useApolloClient()
    const { getAttachmentIdentifier } = useAttachmentDataConvert()
    client.cache.modify({
      id: getAttachmentIdentifier(attachmentId),
      fields: {
        name: () => name,
      },
      optimistic: true,
    })

    const { mutate, error } = useUpdateTaskAttachmentMutation({
      variables: {
        attachmentId,
        name,
      },
    })
    const data = await mutate()
    if (error.value) {
      handleSingleGraphQLError(error.value, true)
      return
    }

    return data?.data?.updateTaskAttachment
  }

  const deleteAttachment = async (attachmentId: string, taskId: string) => {
    const { mutate, error } = useMutation<{
      deleteTaskAttachment?: { success: boolean }
    }>(DELETE_TASK_ATTACHMENT_MUTATION, {
      variables: {
        attachmentId,
      },
    })
    const data = await mutate()
    if (error.value || !data?.data?.deleteTaskAttachment?.success) {
      return
    }

    client.cache.modify({
      id: getTaskIdentifier(taskId),
      fields: {
        attachments: (existingAttachments = [], { readField }) => {
          return existingAttachments.filter(
            (attachmentRef: Reference) =>
              readField('id', attachmentRef) !== attachmentId
          )
        },
      },
      optimistic: true,
    })
  }

  const onDropAttachment = async ({ files, event, taskId, callback }: DropTaskAttachment) => {
    const draggedLink = event.dataTransfer?.getData('text/uri-list') || ''
    const attachments = await processAddAttachment(files, draggedLink, taskId)
    callback?.(attachments)
  }

  const processAddAttachment = async (files: File[] | null, link: string, taskId: string) => {
    const { createAttachment, updateTask } = useUpdateTask()
    const { upload } = usePresignedUpload()
    const toast = useToast()
    let isUpdated = false
    const taskAttachments: Attachment[] = []
    try {
      if (link && isUrl(link)) {
        await createAttachment(taskId, {
          attachments: [link],
          type: AttachmentType.LINK,
          name: ''
        })
        isUpdated = true
      }

      if (files?.length) {
        const data = await upload(
          TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION,
          { taskId },
          files
        )

        if (data.success) {
          const attachments = await createAttachment(taskId, {
            attachments: data.presignedUrls,
            type: AttachmentType.FILE,
            name: '',
          })

          if (attachments?.length) {
            taskAttachments.push(...attachments)
          }

          const { load: loadTask, result: taskDetail, stop } = useTaskDetailLazyQuery()
          await loadTask(null, { id: taskId })
          stop()

          // Set cover image if task doesn't have cover
          const coverAttachment = attachments?.find(attachment =>
            attachment.mimeType?.includes('image') && !taskDetail.value?.task?.cover
          )
          if (coverAttachment) {
            await updateTask(taskId, {
              cover: coverAttachment.url,
              coverBackgroundColor: await getImageAvgColorHex(coverAttachment.url),
            })
          }

          isUpdated = true
        }
      }

      if (isUpdated) {
        toast.add({
          color: 'green',
          title: 'Add attachment successfully'
        })
      }
    } catch {
      toast.add({
        color: 'red',
        title: 'Something went wrong'
      })
    }

    return taskAttachments
  }

  return {
    updateTask,
    updateTasks,
    toggleLabels,
    updateTaskCustomField,
    createAttachment,
    updateTaskAttachment,
    deleteAttachment,
    onDropAttachment,
    processAddAttachment,
  }
}
