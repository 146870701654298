import {
  isEqual
} from 'lodash-es'
import {
  TaskFilterCriteria
} from '#task/constant'
import type {
  TaskGlobalFilter
} from '#task/types'

const $useTaskFilterContext = () => {
  const defaultFilter = (): TaskGlobalFilter => ({
    [TaskFilterCriteria.SEARCH]: '',
    [TaskFilterCriteria.INCLUDE_MEMBER]: {
      condition: {
        all: false,
        no: false,
      },
      members: [],
    },
    [TaskFilterCriteria.EXCLUDE_MEMBER]: {
      members: [],
    },
    [TaskFilterCriteria.DUE_DATE]: [],
    [TaskFilterCriteria.ACTIVITY]: [],
    [TaskFilterCriteria.INCLUDE_LABEL]: {
      condition: {
        all: false,
        no: false,
      },
      labels: [],
    },
    [TaskFilterCriteria.EXCLUDE_LABEL]: {
      labels: [],
    },
    [TaskFilterCriteria.INCLUDE_STATUS]: {
      condition: {
        all: false,
      },
      statuses: [],
    },
    [TaskFilterCriteria.EXCLUDE_STATUS]: {
      statuses: [],
    },
    [TaskFilterCriteria.INCLUDE_MODULE]: {
      condition: {
        all: false,
        no: false,
      },
      tasks: [],
    },
    [TaskFilterCriteria.INCLUDE_TASK_TYPE]: {
      condition: {
        all: false,
      },
      taskTypes: [],
    },
    [TaskFilterCriteria.INCLUDE_FIELD]: {},
    [TaskFilterCriteria.EXCLUDE_FIELD]: {},
  })

  const globalFilter = reactive<TaskGlobalFilter>(defaultFilter())
  const filteredTasksCount = ref(0)
  const filterLoaded = ref<boolean>(false)
  /**
   * Because subtasks are not showed in board views, so we need to store it separately when filter
   */
  const filteredSubtaskIds = ref<Record<string, string[]>>({})

  const tasksCreatedByCurrentUser = ref<string[]>([])

  const filterCriteria = computed(() => {
    return Object.entries(defaultFilter()).reduce((acc, entry) => {
      const [key, value] = entry as [
        keyof TaskGlobalFilter,
        keyof TaskGlobalFilter[keyof TaskGlobalFilter]
      ]
      if (!isEqual(globalFilter[key], value)) {
        acc[key] = globalFilter[key]
      }

      return acc
    }, {} as Record<string, unknown>)
  })

  const filterCriteriaCount = computed(
    () => Object.keys(filterCriteria.value).length
  )

  const resetFilter = () => {
    extend(globalFilter, defaultFilter())
    tasksCreatedByCurrentUser.value = []
    filteredSubtaskIds.value = {}
  }

  watch(() => filterCriteriaCount.value, () => {
    tasksCreatedByCurrentUser.value = []
    if (!filterCriteriaCount.value) {
      filterLoaded.value = false
    }
  })

  const isHasFilter = computed(() => {
    return filterCriteriaCount.value > 0
  })

  return {
    globalFilter,
    isHasFilter,
    filterCriteria,
    filterCriteriaCount,
    filteredTasksCount,
    filteredSubtaskIds,
    filterLoaded,
    tasksCreatedByCurrentUser,
    defaultFilter,
    resetFilter,
  }
}

export const useTaskFilterContext = createSharedComposable(
  $useTaskFilterContext
)
