<template>
  <div class="pt-2 border-t border-gray-200 z-30" @mousedown.prevent>
    <div class="flex items-center gap-2 divide-x divide-gray-200 ">
      <div v-for="toolbarList in toolbarGroups" :key="toolbarList[0].key" class="flex gap-1 pl-2">
        <UTooltip
          v-for="toolbar in toolbarList" :key="toolbar.key"
          :popper="{ placement: 'top', arrow: true }" :text="toolbar.title"
          :shortcuts="toolbar.shortCuts"
          :prevent="!toolbar.title"
        >
          <UButton
            ref="buttonRef" variant="ghost" size="2xs" color="gray"
            :class="[
              'flex items-center p-1',
              toolbar.active.name && editor.isActive(
                toolbar.active.name,
                toolbar.active.attrs
              ) ? '!bg-primary-500 !text-white' : 'hover:bg-gray-100',
            ]"
            @click="toolbar.click()"
          >
            <Suspense :timeout="1000">
              <Icon v-if="toolbar.icon" :name="toolbar.icon" class="size-4" />
            </Suspense>
          </UButton>
        </UTooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Editor } from '@tiptap/core'
import tippy, { type Content } from 'tippy.js'
import { VueRenderer } from '@tiptap/vue-3'
import CommandList from './CommandList.vue'
import type { ToolbarItem } from '#core/types/packages/tiptap'
import { useEditorContext } from '#core/editor_context'
import { COMMAND_LIST } from '#core/packages/tiptap/slash-command'

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
})

const { showLinkEditForm } = useEditorContext()

const buttonRef = ref()

const onClickMore = () => {
  const pos = props.editor.view.state.selection.$anchor.pos

  const tools = tippy(buttonRef.value[0].$el as HTMLElement, {
    content: new VueRenderer(CommandList, {
      props: {
        items: COMMAND_LIST,
        range: {
          from: pos,
          to: pos
        },
        command: (commandProps: {
          editor: Editor,
          command: (options: { editor: Editor, range: { from: number, to: number } }) => void
        }) => {
          props.editor
            .chain()
            .insertContentAt(pos + 1, { type: 'paragraph' })
            .focus(pos + 1)
            .run()

          commandProps.command({
            editor: props.editor,
            range: {
              from: pos - 1,
              to: pos,
            },
          })

          tools.destroy()
        },
        editor: props.editor
      },
      editor: props.editor
    }).element as Content,
    showOnCreate: true,
    interactive: true,
    trigger: 'manual',
    placement: 'auto-end',
    appendTo: document.body,
    offset: [0, 0],
    delay: 200,
  })

  tools.show()
}

const toolbarGroups: ToolbarItem[][] = [
  [
    {
      key: 'more',
      title: '',
      icon: 'tabler:plus',
      shortCuts: ['⌘', 'M'],
      active: { name: '' },
      click: () => {
        onClickMore()
      },
    },
  ],
  [
    {
      key: 'bold',
      title: 'Bold',
      icon: 'tabler:bold',
      shortCuts: ['⌘', 'B'],
      active: { name: 'bold' },
      click: () => {
        props.editor.chain().focus().toggleBold().run()
      },
    },
    {
      key: 'italic',
      title: 'Italic',
      icon: 'tabler:italic',
      shortCuts: ['⌘', 'I'],
      active: { name: 'italic' },
      click: () => {
        props.editor.chain().focus().toggleItalic().run()
      },
    },
    {
      key: 'strikethrough',
      title: 'Strikethrough',
      icon: 'tabler:strikethrough',
      shortCuts: ['⌘', '⇧', 'S'],
      active: { name: 'strike' },
      click: () => {
        props.editor.chain().focus().toggleStrike().run()
      },
    },
    {
      key: 'code',
      title: 'Code',
      icon: 'tabler:code',
      shortCuts: ['⌘', '⌥', 'M'],
      active: { name: 'code' },
      click: () => {
        props.editor.chain().focus().toggleCode().run()
      },
    },
  ],
  [
    {
      key: 'bulletList',
      title: 'Bullet list',
      icon: 'tabler:list',
      shortCuts: ['⌘', '⇧', 'B'],
      active: { name: 'bulletList' },
      click: () => {
        props.editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()
      },
    },
    {
      key: 'orderedList',
      title: 'Number list',
      icon: 'tabler:list-numbers',
      shortCuts: ['⌘', '⇧', 'O'],
      active: { name: 'orderedList' },
      click: () => {
        props.editor.chain().focus().toggleOrderedList().run()
      },
    },
  ],
  [
    {
      key: 'link',
      title: 'Link',
      icon: 'heroicons-link',
      active: { name: 'link' },
      disabled: true,
      click: () => {
        handleLinkClick()
      },
    },
  ],
  [
    {
      key: 'file',
      title: 'File',
      icon: 'heroicons-paper-clip',
      active: { name: 'file' },
      disabled: true,
      click: () => {
        // TODO: open file menu
      },
    },
  ],
]

const handleLinkClick = () => {
  const { from, to } = props.editor.view.state.selection
  const position = props.editor.view.coordsAtPos(from)
  const selectedText = props.editor.view.state.doc.textBetween(from, to)
  const linkAttrs = props.editor.getAttributes('link')

  showLinkEditForm({
    position,
    range: { from, to },
    href: linkAttrs.href || '',
    text: selectedText || linkAttrs.text || '',
  })
  nextTick(() => {
    props.editor
      .chain()
      .setTextSelection({ from, to })
      .focus()
      .run()
  })
}
</script>
