/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

export const USER_LOADER_FRAGMENT = gql`
  fragment UserLoader on UserType {
    id
    fullName
    photo
    email
    emailVerified
  }
`
