import { RoleLevel } from '#auth/constant'
import type { WorkspaceMember } from '#auth/types'

export const useWorkspaceAbility = () => {
  const currentWorkspaceMember = useState < WorkspaceMember | null > (
    'currentWorkspaceMember',
    () => null
  )
  const checkedPermissions: Map<string, boolean> = new Map()

  const can = (permisson_required: string) => {
    if (checkedPermissions.get(permisson_required) === undefined) {
      const isAllowed = currentWorkspaceMember.value?.role.allowedPermissions.some((permission) =>
        permisson_required.startsWith(permission)
      ) || false
      checkedPermissions.set(permisson_required, isAllowed)
    }

    return checkedPermissions.get(permisson_required)
  }

  const setWorkspaceMember = (payload: WorkspaceMember) => {
    currentWorkspaceMember.value = payload
    checkedPermissions.clear()
  }

  const isRole = (role: string) => {
    if (!currentWorkspaceMember.value)
      return false

    return currentWorkspaceMember.value?.role.level === role
  }

  const isOwner = computed(() => isRole(RoleLevel.OWNER))

  return { can, isOwner, setWorkspaceMember }
}

export const useBoardAbility = () => {
  const { currentBoardMember } = useBoardMemberSharedState()
  const { can: canWorkspaceAccess } = useWorkspaceAbility()

  const currentBoardPermissions = computed<string[]>(() => currentBoardMember.value !== false ? currentBoardMember.value?.role.allowedPermissions || [] : [])

  const can = (permisson_required: string) => {
    if (currentBoardPermissions.value.some((permission) =>
      permisson_required.startsWith(permission)
    ))
      return true

    return canWorkspaceAccess('dashboard.boards.manage_boards')
  }

  const canAny = (permissions_required: string[]) => {
    if (currentBoardPermissions.value.some((permission) => permissions_required.some((p) => p.startsWith(permission))))
      return true

    return canWorkspaceAccess('dashboard.boards.manage_boards')
  }

  const canAccessAsMember = () => {
    return !!currentBoardMember.value || canWorkspaceAccess('dashboard.boards.manage_boards')
  }

  const isRole = (role: string) => {
    if (currentBoardMember.value === null || currentBoardMember.value === false)
      return false

    return currentBoardMember.value?.role.level === role || canWorkspaceAccess('dashboard.boards.manage_boards')
  }

  const isOwner = computed(() => isRole('OWNER') || canWorkspaceAccess('dashboard.boards.manage_boards'))

  const isAdmin = computed(() => isRole('ADMIN') || canWorkspaceAccess('dashboard.boards.manage_boards'))

  return { isAdmin, isOwner, can, canAny, canAccessAsMember }
}
