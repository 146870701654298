export const BOARD_MIN_FRAGMENT = gql`
  fragment Board on BoardType {
    id
    name
    handle
  }
`

export const BOARD_BASE_FRAGMENT = gql`
  fragment BaseBoard on BoardType {
    id
    name
    closed
    handle
    description
    visibility
    backgroundType
    backgroundColor
    backgroundPhoto
  }
`

export const BOARD_SETTINGS_FRAGMENT = gql`
  ${BOARD_BASE_FRAGMENT}
  fragment BoardSettings on BoardType {
    ...BaseBoard
    enableInvitationLink
    invitationToken
  }
`

export const BOARD_MEMBER_OPTIONS_FRAGMENT = gql`
  fragment BoardMemberOptionsFields on BoardMemberType {
    id
    star
    starredAt
    viewOptions
    groupBy
    lastActiveAt
  }
`
