<template>
  <MoreActionDropdown
    ref="moreActionsRef"
    :action-items="actionItems"
    :button-props="buttonProps"
    :active-class="activeClass"
    :in-active-class="inActiveClass"
    class="has-[[data-popper-placement]]:!z-30 z-10"
    data-test="more-actions-button"
    @update:open="onMoreActionOpen"
    @update:open-quick-action="$emit('update:openQuickAction', $event)"
    @contextmenu.prevent.stop
  >
    <template #default="{ onUpdateOpen }">
      <!-- Others Actions -->
      <slot :on-update-open="onUpdateOpen" />
      <LazyStatusSelectPopover
        ref="statusPopoverRef"
        :selected-status-id="task.status?.id"
        :parent-id="task.boardId"
        source="board"
        container-class="!absolute inset-0"
        @change="handleUpdateStatus($event)"
        @click.prevent.stop
        @update:open="onUpdateOpen"
      >
        <div class="h-6" />
      </LazyStatusSelectPopover>
      <LazyTaskAssigneePopover
        ref="assigneePopoverRef"
        :current-assignee="task.assignee"
        :board-id="task.boardId"
        container-class="!absolute inset-0"
        @select="(assignee) => handleUpdateAssignee(assignee)"
        @remove="handleUpdateAssignee(null)"
        @click.prevent.stop
        @update:open="onUpdateOpen"
      >
        <div class="h-6" />
      </LazyTaskAssigneePopover>

      <LazyLabelListPopover
        ref="labelPopoverRef"
        source="board"
        heading="Add labels"
        container-class="!absolute inset-0"
        :selected-ids="map(task.labels, 'id')"
        :parent-id="task.boardId"
        :task-id="task.id"
        @select="onToggleLabels"
        @remove="onToggleLabels"
        @update:open="onUpdateOpen"
      >
        <div class="h-6" />
      </LazyLabelListPopover>
      <LazyTaskDatePickerPopover
        ref="datePopoverRef"
        :start-date="task.startDate"
        :due-date="task.dueDate"
        container-class="!absolute inset-0"
        @change="(dates) => handleUpdateDate(dates)"
        @click.prevent.stop
        @update:open="onUpdateOpen"
      >
        <div class="h-6" />
      </LazyTaskDatePickerPopover>
      <!-- Change task type -->
      <LazyTaskTypeSelectPopover
        ref="taskTypePopoverRef"
        source="board"
        container-class="!absolute inset-0"
        :parent-id="task.boardId"
        :selected-task-type-id="task.type?.id"
        :accept-levels="[task.type?.level]"
        @change="handleUpdateTaskType($event)"
        @update:open="onUpdateOpen"
        @click.prevent.stop
      >
        <div class="size-6" />
      </LazyTaskTypeSelectPopover>

      <!-- Set module -->
      <LazyTaskListModuleSelectPopover
        v-if="task.level === TaskLevel.TASK"
        ref="modulePopoverRef"
        :selected-task-id="task.parentId"
        :board-id="task.boardId"
        container-class="!absolute inset-0"
        :enable-unlink="!!task.parentId"
        @update:open="onUpdateOpen"
        @click.prevent.stop
        @change="handleUpdateParent($event ?? null)"
      >
        <div class="size-6" />
      </LazyTaskListModuleSelectPopover>
      <LazyTaskListParentSelectPopover
        v-if="task.level === TaskLevel.SUBTASK"
        ref="parentPopoverRef"
        :selected-task-id="task.parentId"
        :board-id="task.boardId"
        :section-id="task.sectionId"
        :accept-level="[TaskLevel.TASK]"
        @change="($event) => handleUpdateParent($event?.id ?? null)"
        @update:open="onUpdateOpen"
        @click.prevent.stop
      >
        <div class="size-6" />
      </LazyTaskListParentSelectPopover>

      <!-- Task move -->
      <LazyTaskMovePopover
        ref="movePopoverRef"
        :workspace-id="currentWorkspace.id"
        :tasks="[task]"
        class="absolute inset-0"
        :ui-menu="uiMenu"
        @click.prevent.stop
        @update:open="onUpdateOpen"
      >
        <div class="size-6" />
      </LazyTaskMovePopover>

      <!-- Task copy -->
      <LazyTaskCopyPopover
        ref="copyPopoverRef"
        :workspace-id="currentWorkspace.id"
        :tasks="[task]"
        class="absolute inset-0"
        :ui-menu="uiMenu"
        @click.prevent.stop
        @update:open="onUpdateOpen"
      >
        <div class="size-6" />
      </LazyTaskCopyPopover>
    </template>
  </MoreActionDropdown>
</template>

<script lang="ts" setup>
import { map } from 'lodash-es'
import type { TaskItem } from '#task/types'
import type { TaskTypeDetail } from '#task-type/types'
import { TaskLevel, TaskRowLayout } from '#task/constant'
import { ModuleActionKey, TaskActionKey } from '#board/constant'
import type { TaskActionItem } from '#board/types'
import type { Button } from '#ui/types'
import type { Label } from '#label/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  additionalActions: {
    type: Array as PropType<TaskActionItem[]>,
    default: () => [],
  },
  uiMenu: {
    type: Object as PropType<{
      container: string
    }>,
  },
  activeClass: {
    type: String,
  },
  inActiveClass: {
    type: String,
  },
  buttonProps: {
    type: Object as PropType<
      Button & {
        class?: string
        text?: string
        ui?: Record<string, string>
        popper?: {
          placement?: string
          arrow?: boolean
        }
        shortcuts?: string[]
      }
    >,
  },
  layout: {
    type: String,
    default: TaskRowLayout.TASK,
  },
  showRename: {
    type: Boolean,
    default: false,
  },
})

const { updateTask, updateTasks, toggleLabels } = useUpdateTask()
const { currentWorkspace } = useWorkspaceSharedState()
const toast = useToast()
const { archiveTask } = useArchiveTask()
const { selectedTaskIds, showCreateGroupRow } = useListViewSharedState()

const isMoreActionOpen = ref(false)
const movePopoverRef = ref()
const parentPopoverRef = ref()
const statusPopoverRef = ref()
const labelPopoverRef = ref()
const assigneePopoverRef = ref()
const datePopoverRef = ref()
const taskTypePopoverRef = ref()
const copyPopoverRef = ref()
const moreActionsRef = ref()
const modulePopoverRef = ref()
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'changeTaskType', type: TaskTypeDetail): void
  (e: 'update:openQuickAction', value: boolean): void
  (e: 'onRename'): void
}>()

const actionItems = computed((): TaskActionItem[] => {
  const menu = []

  const commonActions = [
    ...props.additionalActions,
    {
      key: TaskActionKey.RENAME,
      label: 'Rename',
      icon: 'heroicons:pencil-square',
      hidden: !props.showRename,
      click: () => {
        emit('onRename')
      },
    },
    {
      key: TaskActionKey.STATUS,
      label: 'Set status',
      icon: 'leanbase:status-not-started',
      click: () => {
        statusPopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.ASSIGNEE,
      label: 'Assignee',
      icon: 'heroicons:user-circle',
      click: () => {
        assigneePopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.LABEL,
      label: 'Add label',
      icon: 'heroicons:tag',
      click: () => {
        labelPopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.DATE,
      label: 'Edit date',
      icon: 'heroicons:calendar',
      click: () => {
        datePopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.SET_PARENT,
      label: 'Set parent',
      icon: 'heroicons:cube',
      hidden: ![TaskLevel.SUBTASK].includes(props.task.level),
      click: () => {
        parentPopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.SET_MODULE,
      label: 'Set module',
      icon: 'heroicons:cube',
      hidden: ![TaskLevel.TASK].includes(props.task.level),
      click: () => {
        modulePopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.CHANGE_TASK_TYPE,
      label: 'Change task type',
      icon: 'i-heroicons-pencil-square-16-solid',
      hidden: ![TaskLevel.TASK].includes(props.task.level),
      click: () => {
        taskTypePopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.MOVE,
      label: 'Move',
      icon: 'heroicons:arrow-right-circle',
      hidden: ![TaskLevel.TASK, TaskLevel.MODULE].includes(props.task.level),
      click: () => {
        movePopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.CONVERT_TO_TASK,
      label: 'Convert to task',
      icon: 'heroicons:arrow-path',
      hidden: ![TaskLevel.SUBTASK].includes(props.task.level),
      click: () => {
        onConvertToTask()
      },
    },
    {
      key: TaskActionKey.COPY,
      label: 'Copy',
      icon: 'i-heroicons-document-duplicate',
      click: () => {
        copyPopoverRef.value?.open()
      },
    },
  ].filter((item) => !item.hidden)

  menu.push(commonActions)

  if (
    props.layout === TaskRowLayout.GROUP &&
    props.task.level === TaskLevel.MODULE
  ) {
    const moduleGroupActions = [
      {
        key: ModuleActionKey.ADD_ABOVE,
        label: 'Add module above',
        icon: 'heroicons:arrow-up',
        click: () => {
          showCreateGroupRow(props.task.id, 'left')
        },
      },
      {
        key: ModuleActionKey.ADD_BELOW,
        label: 'Add module below',
        icon: 'heroicons:arrow-down',
        click: () => {
          showCreateGroupRow(props.task.id, 'right')
        },
      },
    ]
    menu.push(moduleGroupActions)
  }

  menu.push([
    {
      key: TaskActionKey.ARCHIVE,
      label: 'Archive',
      icon: 'heroicons:archive-box-arrow-down',
      click: () => {
        onArchiveTask()
      },
    },
  ])

  return menu
})

const taskIds = computed(() =>
  selectedTaskIds.value.has(props.task.id)
    ? Array.from(selectedTaskIds.value)
    : [props.task.id]
)

const onArchiveTask = () => {
  archiveTask(props.task)
}

const onToggleLabels = (label: Label) => {
  toggleLabels({
    taskIds: taskIds.value,
    detachLabelIds: [],
    labelIds: [label.id],
  })
}

const handleUpdateStatus = (status: string) => {
  updateTasks(taskIds.value, { status })
}

const handleUpdateAssignee = (assignee: string | null) => {
  updateTasks(taskIds.value, { assignee })
}

const handleUpdateDate = (date: { dueDate?: string; startDate?: string }) => {
  updateTasks(taskIds.value, date)
}

const handleUpdateParent = (parent: string | null) => {
  updateTask(props.task.id, { parent })
}

const handleUpdateTaskType = (type: TaskTypeDetail) => {
  updateTask(props.task.id, { type: type.id })
}

const onConvertToTask = async () => {
  if (props.task.level !== TaskLevel.SUBTASK) return

  await updateTask(props.task.id, { level: TaskLevel.TASK, parent: null })

  toast.add({
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: 'Convert to task successfully',
  })
}

const onMoreActionOpen = (value: boolean) => {
  isMoreActionOpen.value = value
  emit('update:open', value)
}

defineExpose({
  close: () => {
    moreActionsRef.value?.close()
  },
  open: () => {
    moreActionsRef.value?.open()
  },
  openLabelPopover: () => {
    labelPopoverRef.value?.open()
  },
  openDatePopover: () => {
    datePopoverRef.value?.open()
  },
  openStatusPopover: () => {
    statusPopoverRef.value?.open()
  },
  openAssigneePopover: () => {
    assigneePopoverRef.value?.open()
  },
  openModulePopover: () => {
    modulePopoverRef.value?.open()
  },
})

defineShortcuts({
  escape: {
    whenever: [isMoreActionOpen],
    handler: () => {
      moreActionsRef.value?.close()
    },
  },
})
</script>
