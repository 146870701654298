<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-64', base: 'overflow-visible', trigger: 'flex' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    v-bind="$attrs"
  >
    <template #panel>
      <div class="py-3 px-4" data-test="task-copy-popover">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-sm">Copy task</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            data-test="task-copy-popover-close-button"
            @click.prevent.stop="open = false"
          />
        </div>
        <div class="space-y-3 mt-3">
          <UForm ref="form" :schema="schema" :state="formState" data-test="task-copy-form">
            <!-- Copy task name -->
            <UFormGroup
              v-if="showNameInput"
              label="New task title" name="name"
            >
              <UInput
                v-model="formState.name"
                placeholder="Task name"
                autofocus
                data-test="task-copy-name-input"
              />
            </UFormGroup>
            <!-- Keep subtasks -->
            <div
              class="mt-4"
              @click.prevent.stop="
                formState.keepSubtasks = !formState.keepSubtasks
              "
            >
              <UCheckbox
                v-if="!onlyOneSubtask"
                v-model="formState.keepSubtasks"
                label="Keep subtasks"
                data-test="task-copy-keep-subtasks"
                @click.stop="formState.keepSubtasks = !formState.keepSubtasks"
              />
            </div>
            <!-- Keep assignee -->
            <div
              class="mt-2"
              @click.prevent.stop="formState.keepAssignee = !formState.keepAssignee"
            >
              <Checkbox
                label="Keep assignee"
                :checked="formState.keepAssignee"
                data-test="task-copy-keep-assignee"
                @click.stop="formState.keepAssignee = !formState.keepAssignee"
              />
            </div>
            <!-- Keep attachments -->
            <div
              class="mt-2"
              @click.prevent.stop="
                formState.keepAttachments = !formState.keepAttachments
              "
            >
              <Checkbox
                label="Keep attachments"
                :checked="formState.keepAttachments"
                data-test="task-copy-keep-attachments"
                @click.stop="formState.keepAttachments = !formState.keepAttachments"
              />
            </div>
            <!-- Move form -->
            <TaskMoveForm
              v-if="open"
              :task="tasks[0]"
              :workspace-id="workspaceId"
              :disable-change-section="onlyOneSubtask"
              :ui-menu="uiMenu"
            >
              <template #footer="{ state }">
                <div class="flex justify-end mt-4 gap-2">
                  <UButton
                    color="gray"
                    variant="soft"
                    @click.prevent.stop="open = false"
                  >
                    Cancel
                  </UButton>
                  <UButton
                    :disabled="!state.canMove"
                    :loading="loading"
                    data-test="task-copy-submit-button"
                    @click.prevent.stop="onCopyTask(state)"
                  >
                    Create task
                  </UButton>
                </div>
              </template>
            </TaskMoveForm>
          </UForm>
        </div>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import { first } from 'lodash-es'
import { z } from 'zod'
import type { CopyMoveTaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'

const props = defineProps({
  tasks: {
    type: Array as PropType<CopyMoveTaskItem[]>,
    required: true,
  },
  workspaceId: {
    type: String,
    required: true,
  },
  uiMenu: {
    type: Object as PropType<{
      container: string
    }>,
  },
})

const toast = useToast()
const { copyTasks } = useCreateTask()

const open = ref(false)
const loading = ref(false)
const form = ref()

const schema = z.object({
  name: z.string().min(1, 'Task name is required'),
})

const showNameInput = computed(() => props.tasks.length === 1)
const onlyOneSubtask = computed(() => props.tasks.length == 1 && first(props.tasks)?.level === TaskLevel.SUBTASK)

const formState = reactive({
  name: showNameInput.value ? first(props.tasks)?.name : '',
  keepSubtasks: true,
  keepAssignee: true,
  keepAttachments: true,
})

const onCopyTask = (state: Record<string, unknown>) => {
  form.value.validate().then(async () => {
    loading.value = true
    const { tasks } = props

    const name = tasks.length > 1 ? null : formState.name
    await copyTasks({
      tasks,
      boardId: state.selectedBoard as string,
      sectionId: state.selectedSection as string,
      name,
      keepAssignee: formState.keepAssignee,
      keepAttachments: !onlyOneSubtask.value && formState.keepSubtasks,
      keepSubtasks: formState.keepAttachments,
      onSuccess: () => {
        loading.value = false
        toast.add({
          color: 'green',
          title: 'Task copied successfully',
        })
        open.value = false
      },
      onError: () => {
        toast.add({
          color: 'red',
          title: 'Something went wrong. Please try again.',
        })
      },
    })
  }).catch(() => {
    loading.value = false
  })
}

defineExpose({ open: () => (open.value = true) })
</script>
