<template>
  <UDropdown
    :items="[items]"
    :popper="{ placement: 'bottom-start' }"
    v-bind="$attrs"
  >
    <template #item="{ item }">
      <span class="truncate text-sm text-gray-500 space-x-1">
        <span class="text-gray-900">
          {{ getTaskDependencyHint(item.value) }}
        </span>
        <span>.</span>
        <span>{{ getTaskDependencyHint(item.value, true) }}</span>
      </span>
      <UIcon
        v-if="item.value === currentType"
        name="i-heroicons-check"
        class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto"
      />
    </template>
    <template #remove="{ item }">
      <span class="truncate text-sm text-red-500">{{ item.label }}</span>
    </template>
    <slot v-if="$slots.default" />
    <UButton
      v-else
      color="white"
      trailing-icon="i-heroicons-chevron-down-20-solid"
    >
      {{ getTaskDependencyHint(currentType, true) }}
    </UButton>
  </UDropdown>
</template>

<script lang="ts" setup>
import { TaskDependencyType } from '#task/constant'
import type { DropdownItem } from '#ui/types'

const props = defineProps({
  currentType: {
    type: Number as PropType<TaskDependencyType>,
    required: true,
  },
  removeable: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['change-type', 'remove'])

const items = computed(() => {
  const baseItems: Array<DropdownItem & { value?: TaskDependencyType }> = [
    {
      label: '',
      value: TaskDependencyType.START_TO_START,
      click: () => emit('change-type', TaskDependencyType.START_TO_START),
    },
    {
      label: '',
      value: TaskDependencyType.START_TO_FINISH,
      click: () => emit('change-type', TaskDependencyType.START_TO_FINISH),
    },
    {
      label: '',
      value: TaskDependencyType.FINISH_TO_START,
      click: () => emit('change-type', TaskDependencyType.FINISH_TO_START),
    },
    {
      label: '',
      value: TaskDependencyType.FINISH_TO_FINISH,
      click: () => emit('change-type', TaskDependencyType.FINISH_TO_FINISH),
    },
  ]
  if (props.removeable) {
    baseItems.push({
      label: 'Remove',
      slot: 'remove',
      click: () => emit('remove'),
    })
  }

  return baseItems
})
</script>
