import type {
  MoveTasksParams,
  TaskItem,
  UpdateTaskPositionParams,
} from '#task/types'
import { TaskLevel } from '#task/constant'
import {
  TASK_FRAGMENT,
} from '#task/fragment'

export const useTaskMove = () => {
  const { client } = useApolloClient()
  const { getTaskIdentifier } = useTaskDataConvert()
  const { currentBoard } = useWorkspaceSharedState()

  const updateTaskPosition = async (payload: UpdateTaskPositionParams) => {
    const {
      taskIds,
      sectionId,
      parentId = null,
    } = payload
    const index = payload.index ?? 1e9

    logger.log('Update task position', payload)
    let positions: number[] | null = null
    for (const taskId of taskIds) {
      const taskIdentifier = getTaskIdentifier(taskId)
      const task = client.readFragment(
        {
          id: taskIdentifier,
          fragment: TASK_FRAGMENT,
          fragmentName: 'Task',
        },
        true
      )
      if (!task) {
        return logger.log('Task not found')
      }

      let position: number | null = null
      // Get exact position of task in the list and process optimistically
      const tasks = useReadTasksFromCache(currentBoard.value.id)

      const isMoveWithinList = task.sectionId === sectionId
      const numTasksInTargetSection = tasks.length
      const targetIndex = !task.closed
        ? Math.min(
          isMoveWithinList
            ? numTasksInTargetSection - 1
            : numTasksInTargetSection,
          index
        )
        : index
      if (isMoveWithinList && task.parentId === parentId) {
        const currentIndex = tasks.findIndex(({ id }) => id === taskId)
        if (currentIndex === targetIndex) {
          return logger.log('Task is already in the correct position', {
            currentIndex,
            targetIndex,
          })
        }
      }

      position = calculateItemPosition(targetIndex, tasks, task)
      positions = [...(positions ?? []), position]
      const parent = tasks.find((task) => task.id === parentId)
      let level = task.level
      if (parent && parent.id !== task.parentId) {
        switch (true) {
          case parent.level === TaskLevel.MODULE:
            level = TaskLevel.TASK
            break
          case parent.level === TaskLevel.TASK:
            level = TaskLevel.SUBTASK
            break
        }
      }

      // Optimistically update task position
      client.cache.modify({
        id: taskIdentifier,
        fields: {
          sectionId: () => sectionId,
          position: () => position,
          parentId: () => parentId,
          level: () => level,
        },
        optimistic: true,
      })
    }

    const { mutate, error } = useUpdateTaskPositionMutation({
      variables: {
        taskIds,
        sectionId,
        position: positions,
        parentId,
      },
    })
    const data = await mutate()
    if (!data?.data?.updateTaskPosition?.success || error.value) {
      // Revert to previous data if failed
      for (const taskId of taskIds) {
        const taskIdentifier = getTaskIdentifier(taskId)
        const task = client.readFragment(
          {
            id: taskIdentifier,
            fragment: TASK_FRAGMENT,
            fragmentName: 'Task',
          },
          true
        )
        if (!task) {
          return logger.log('Task not found')
        }

        client.cache.modify({
          id: taskIdentifier,
          fields: {
            sectionId: () => task.sectionId,
            position: () => task.position,
            parentId: () => task.parentId,
            level: () => task.level,
          },
          optimistic: true,
        })
      }

      return data
    }

    return data
  }

  const moveTasks = async ({
    taskIds,
    sectionId,
    onSuccess,
    onError,
  }: MoveTasksParams) => {
    const { mutate, error } = useMoveTasksMutation({
      variables: {
        taskIds,
        sectionId,
      },
      update: (_, { data }) => {
        if (data?.moveTasks.success) {
          const { listCache } = useTaskSync()
          data.moveTasks.tasks.forEach((task: TaskItem) => {
            // If task not in this board
            if (task.boardId !== currentBoard.value.id) {
              // Remove from cache
              listCache.delete(currentBoard.value.id, task.id)
            }
            // Else
            // Cached are updated automatically, do nothing
          })
        }
      },
    })

    const data = await mutate()
    if (!error.value && data?.data?.moveTasks.success) {
      onSuccess?.()

      return data
    }

    onError?.()
  }

  return {
    updateTaskPosition,
    moveTasks,
  }
}
