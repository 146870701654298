import { AUTOMATION_FRAGMENT, AUTOMATION_ACTION_FRAGMENT, AUTOMATION_CONDITION_FRAGMENT, AUTOMATION_TRIGGER_FRAGMENT } from '#automation/fragment'

export const AUTOMATION_RULES_QUERY = gql`
  ${AUTOMATION_ACTION_FRAGMENT}
  ${AUTOMATION_CONDITION_FRAGMENT}
  ${AUTOMATION_TRIGGER_FRAGMENT}
  query AutomationRules(
    $taskId: String
    $source: String
    $parentId: String
    $isActive: Boolean
    $isButton: Boolean
  ) {
    automationRules(
      taskId: $taskId
      source: $source
      parentId: $parentId
      isActive: $isActive
      isButton: $isButton
    ) {
      id
      name
      type
      isActive
      source
      parentId
      modified
      actions {
        ...AutomationAction
      }
      condition {
        ...AutomationCondition
      }
      trigger {
        ...AutomationTrigger
      }
      taskTypes
    }
  }
`

export const AUTOMATION_RULE_QUERY = gql`
  ${AUTOMATION_ACTION_FRAGMENT}
  ${AUTOMATION_CONDITION_FRAGMENT}
  ${AUTOMATION_TRIGGER_FRAGMENT}
  query AutomationRule($id: ID!) {
    automationRule(id: $id) {
      id
      name
      type
      isActive
      source
      parentId
      actions {
        ...AutomationAction
      }
      condition {
        ...AutomationCondition
      }
      trigger {
        ...AutomationTrigger
      }
      taskTypes
    }
  }
`

export const UPDATE_CREATE_AUTOMATION_RULE = gql`
  ${AUTOMATION_FRAGMENT}
  mutation AddUpdateRule($params: AutomationRuleInput!) {
    addUpdateAutomationRule(params: $params) {
      rule {
        ...Automation
      }
    }
  }
`
export const TOGGLE_ACTIVE_AUTOMATION_RULE = gql`
  mutation ToggleActiveAutomationRule($id: ID!, $isActive: Boolean!) {
    toggleActiveAutomationRule(id: $id, isActive: $isActive) {
      success
    }
  }
`

export const DUPLICATE_AUTOMATION_RULE = gql`
  ${AUTOMATION_ACTION_FRAGMENT}
  ${AUTOMATION_CONDITION_FRAGMENT}
  ${AUTOMATION_TRIGGER_FRAGMENT}
  mutation DuplicateAutomationRule($id: ID!) {
    duplicateAutomationRule(id: $id) {
      automationRule {
        id
        name
        type
        isActive
        source
        parentId
        modified
        actions {
          ...AutomationAction
        }
        condition {
          ...AutomationCondition
        }
        trigger {
          ...AutomationTrigger
        }
        taskTypes
      }
    }
  }
`

export const DELETE_AUTOMATION_RULE = gql`
  mutation DeleteAutomationRule($id: ID!) {
    deleteAutomationRule(id: $id) {
      success
    }
  }
`

export const EXECUTE_AUTOMATION_RULE = gql`
  mutation ExecuteAutomationRule($ruleId: ID!, $taskId: ID!) {
    executeAutomationRule(ruleId: $ruleId, taskId: $taskId) {
      success
    }
  }
`
