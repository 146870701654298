import type * as Apollo from '@apollo/client'
import type {
  AttachFieldToTaskTypeParams,
  UnttachFieldFromTaskTypeParams,
  BaseTaskType,
  TaskTypeDetail,
  UpdateTaskTypeFieldPosParams,
  DeleteTaskTypeParams,
} from '#task-type/types'
import {
  ATTACH_FIELD_TO_TASK_TYPE_MUTATION,
  UNATTACH_FIELD_FROM_TASK_TYPE_MUTATION,
  ADD_UPDATE_TASK_TYPE_MUTATION,
  UPDATE_TASK_TYPE_FIELD_POSITION_MUTATION,
  TASK_TYPE_DETAIL_QUERY,
  TASK_TYPES_QUERY,
  TASK_TYPES_WITH_FIELDS_QUERY,
  DELETE_TASK_TYPE_MUTATION,
} from '#task-type/schema'

export const useTaskTypesQuery = (params: {
  parentId: string
  source: MorphSource
  loadFull?: boolean
}) => {
  return useQuery<{ taskTypes?: BaseTaskType[] }>(TASK_TYPES_QUERY, params)
}

export const useTaskTypesLazyQuery = (params: {
  parentId: string
  source: MorphSource
  loadFull?: boolean
}) => {
  return useLazyQuery<{ taskTypes?: BaseTaskType[] }>(TASK_TYPES_QUERY, params)
}

export const useTaskTypesWithFieldsLazyQuery = (params: {
  parentId: string
  source: MorphSource
}) => {
  return useLazyQuery<{ taskTypes?: TaskTypeDetail[] }>(
    TASK_TYPES_WITH_FIELDS_QUERY,
    params
  )
}

export const useTaskTypeQuery = (id: string) => {
  return useQuery<{ taskType?: TaskTypeDetail }>(TASK_TYPE_DETAIL_QUERY, {
    id,
  })
}

export const useTaskTypeLazyQuery = (id: string) => {
  return useLazyQuery<{ taskType?: TaskTypeDetail }>(TASK_TYPE_DETAIL_QUERY, {
    id,
  })
}

export const useUpdateTaskTypeFieldPositionMutation = (
  params: UpdateTaskTypeFieldPosParams
) => {
  return useMutation(UPDATE_TASK_TYPE_FIELD_POSITION_MUTATION, {
    variables: params,
    throws: 'never',
  })
}

export const useAddUpdateTaskTypeMutation = (
  options: Apollo.MutationHookOptions<{
    addUpdateTaskType: { taskType: TaskTypeDetail }
  }>
) => {
  return useMutation(ADD_UPDATE_TASK_TYPE_MUTATION, {
    throws: 'never',
    ...options,
  })
}

export const useAttachFieldToTaskTypeMutation = (
  params: AttachFieldToTaskTypeParams,
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation(ATTACH_FIELD_TO_TASK_TYPE_MUTATION, {
    variables: params,
    throws: 'never',
    ...options,
  })
}

export const useUnattachFieldFromTaskTypeMutation = (
  params: UnttachFieldFromTaskTypeParams,
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation(UNATTACH_FIELD_FROM_TASK_TYPE_MUTATION, {
    variables: params,
    throws: 'never',
    ...options,
  })
}

export const useDeleteTaskTypeMutation = (
  params: DeleteTaskTypeParams,
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation(DELETE_TASK_TYPE_MUTATION, {
    variables: params,
    throws: 'never',
    ...options,
  })
}
