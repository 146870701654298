<template>
  <div v-if="subtask && subtaskId" class="relative">
    <div
      v-if="closestEdge === 'top'"
      class="w-full border-t border-primary-500 absolute top-0 left-0 right-0"
    />
    <div class="relative group">
      <!-- More actions -->
      <div class="absolute left-full top-0 bottom-0 z-10 px-1 pt-1.5 has-[[data-popper-placement]]:z-20">
        <TaskActions
          ref="taskActionsRef"
          :task="subtask"
          :additional-actions="additionalActions"
          :button-props="{
            class: 'hover:bg-gray-100',
          }"
          active-class="bg-gray-100"
          in-active-class="group-hover:opacity-100 opacity-0"
        />
      </div>
      <div
        ref="rowRef"
        :class="[
          'group-hover:bg-gray-100 cursor-pointer',
          {
            'group-hover:bg-gray-100': !isNameEditing,
            'bg-gray-100': isNameEditing,
            'cursor-not-allowed': !can('dashboard.data.manage_tasks_sections'),
          },
        ]"
        :draggable="can('dashboard.data.manage_tasks_sections')"
        data-test="subtask-row-btn"
        @click.right="onRightClick"
        @contextmenu.prevent
      >
        <a
          :href="makeTaskUrl(subtask)"
          class="flex items-baseline justify-between pl-1.5"
          :draggable="false"
          @click.prevent="onClickTaskLink"
        >
          <div class="flex items-baseline gap-1">
            <div
              class="absolute right-full top-0 bottom-0 z-10 px-1.5 pt-1.5"
              @click.prevent.stop
            >
              <UButton
                ref="dragHandleRef"
                class="size-3 p-0 items-center justify-center"
                variant="ghost"
                size="sm"
                color="gray"
                :disabled="!can('dashboard.data.manage_tasks_sections')"
              >
                <IconDrag
                  class="hidden group-hover:flex"
                />
              </UButton>
            </div>
            <div class="mr-0.5">
              <template v-if="subtask.level === TaskLevel.TASK">
                <TaskTypeSelectPopover
                  v-if="subtask.type"
                  source="board"
                  :parent-id="subtask.boardId"
                  :selected-task-type-id="subtask.type.id"
                  :accept-levels="[subtask.level]"
                  :disabled="!can('dashboard.data.manage_tasks_sections')"

                  @click.prevent.stop
                  @change="onChangeTaskType"
                  @mousedown.prevent
                >
                  <div
                    class="p-1 bg-white border border-white group-hover:border-gray-300 rounded-md hover:!border-gray-400"
                  >
                    <TaskTypeIcon
                      :background="subtask.type.background"
                      :icon="subtask.type.icon"
                      size="xs"
                      :class="
                        {
                          'cursor-not-allowed': !can('dashboard.data.manage_tasks_sections'),
                        }"
                    />
                  </div>
                </TaskTypeSelectPopover>
              </template>
              <template v-else>
                <div class="rounded-md p-1">
                  <TaskTypeIcon
                    :background="subtask.type.background"
                    :icon="subtask.type.icon"
                    size="xs"
                  />
                </div>
              </template>
            </div>
            <p class="text-xs text-gray-500 relative -top-[1px]">
              #{{ subtask.number }}
            </p>

          </div>
          <div class="flex items-start justify-between pr-2 relative flex-1 ml-1 py-1.5">
            <p
              class="h-full break-words px-2 py-0.5 min-h-4 font-medium text-sm tracking-normal leading-5 whitespace-pre-wrap flex-1"
              :style="{ wordBreak: 'break-word' }"
              data-test="subtask-name"
            >
              {{ subtask.name }}
            </p>

            <div v-if="isNameEditing" class="absolute inset-0 z-10">
              <div class="relative min-h-full">
                <p
                  class="h-full break-words pl-2 py-2 pr-14 min-h-full font-medium text-sm tracking-normal leading-5 whitespace-pre-wrap "
                  :style="{ wordBreak: 'break-word' }"
                >
                  {{ formState.name || 'Write a task name' }}
                </p>
                <UButton
                  size="2xs"
                  color="primary"
                  class="absolute top-1.5 right-1 z-20"
                  @click.prevent.stop="onUpdateSubtaskName"
                  @mousedown.prevent
                >
                  Save
                </UButton>
                <UTextarea
                  ref="taskNameRef"
                  v-model="formState.name"
                  placeholder="Write a task name"
                  autofocus
                  autoresize
                  :ui="{
                    wrapper: 'absolute inset-0',
                    color: {
                      white: {
                        outline:
                          'shadow-none text-gray-700 ring-0 rounded-md',
                      },
                    },
                    rounded: 'rounded-none',
                    base: 'resize-none w-full outline-none focus:!ring-primary-500',
                    padding: {
                      sm: 'pl-2 py-2 pr-14',
                    },
                    placeholder: 'font-normal',
                  }"
                  textarea-class="font-medium text-sm tracking-normal leading-5 min-h-full h-full focus:!ring-1 "
                  data-test="subtask-name-input"
                  :disabled="!can('dashboard.data.manage_tasks_sections')"
                  @click.prevent.stop
                  @keydown.enter.prevent.stop="onUpdateSubtaskName"
                />
              </div>
            </div>

            <!-- More actions -->
            <div
              class="flex items-start gap-1 ml-2 flex-wrap max-w-52 justify-end relative"
              data-test="task-row-data"
              @click.prevent.stop
            >
              <div class="absolute inset-0 flex items-center max-h-6" @click.prevent.stop="onOpenTask" />
              <TaskDatePickerPopover
                :start-date="subtask.startDate"
                :due-date="subtask.dueDate"
                container-class="items-center flex max-h-6"
                :disabled="!can('dashboard.data.manage_tasks_sections')"
                button-class="flex"
                @change="(dates) => updateTask(subtaskId, dates)"
              >
                <UButton
                  size="2xs"
                  variant="soft"
                  color="gray"
                  :class="{
                    'px-1 invisible group-hover:visible relative duration-0 h-full':
                      !subtask.startDate && !subtask.dueDate,
                    visible: !!subtask.assignee,
                  }"
                  :disabled="!can('dashboard.data.manage_tasks_sections')"
                  :ui="{
                    rounded: 'rounded-full',
                  }"
                >
                  <TaskDatesFormat
                    v-if="subtask.startDate || subtask.dueDate"
                    :class="[
                      'text-gray-900',
                      {
                        'cursor-not-allowed': !can('dashboard.data.manage_tasks_sections'),
                      },
                    ]"
                    :start="subtask.startDate"
                    :end="subtask.dueDate"
                    :show-time="false"
                    :format="{
                      month: 'MMM',
                    }"
                  />
                  <Icon v-else name="i-heroicons-calendar" :size="16" />
                </UButton>
              </TaskDatePickerPopover>
              <TaskAssigneePopover
                :current-assignee="subtask.assignee"
                :board-id="subtask.boardId"
                :disabled="!can('dashboard.data.manage_tasks_sections')"
                container-class="items-center flex max-h-6"
                @select="(assignee) => updateTask(subtaskId, { assignee })"
                @remove="updateTask(subtaskId, { assignee: null })"
              >
                <UButton
                  v-if="subtask.assignee"
                  size="2xs"
                  variant="soft"
                  color="gray"
                  :padded="false"
                  class="max-h-6"
                  :ui="{
                    rounded: 'rounded-full',
                  }"
                  :disabled="!can('dashboard.data.manage_tasks_sections')"
                >
                  <template #leading>
                    <Avatar
                      :id="subtask.assignee.id"
                      :src="subtask.assignee.photo"
                      :name="subtask.assignee.fullName"
                      img-class="hover:brightness-90"
                      class="hover:brightness-90 "
                      :ui-size="{
                        xs: 'size-6',
                      }"
                    />
                  </template>
                </UButton>
                <UButton
                  v-else
                  class="group-hover:visible relative top-[1px] !p-1 duration-0 max-h-6"
                  :class="{
                    invisible: !subtask.startDate && !subtask.dueDate,
                  }"
                  :disabled="!can('dashboard.data.manage_tasks_sections')"
                  size="xs"
                  variant="soft"
                  color="gray"
                  icon="i-heroicons-user-circle"
                  :ui="{
                    rounded: 'rounded-full',
                  }"
                />
              </TaskAssigneePopover>
              <StatusSelectPopover
                v-if="subtask.status"
                :selected-status-id="subtask.status.id"
                :parent-id="subtask.boardId"
                container-class="items-center flex max-h-6"
                source="board"
                :disabled="!can('dashboard.data.manage_tasks_sections')"
                @change="updateTask(subtaskId, { status: $event })"
              >
                <StatusBadge
                  v-if="subtask.status"
                  :class="{
                    'cursor-not-allowed': !can('dashboard.data.manage_tasks_sections'),
                  }"
                  class="flex items-center gap-1 text-xs max-w-24 max-h-6"
                  :type="subtask.status.type"
                  :label="subtask.status.name"
                />
              </StatusSelectPopover>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div
      v-if="closestEdge === 'bottom'"
      class="w-full border-b border-primary-500 absolute bottom-0 left-0 right-0"
    />
    <Teleport v-if="state.type === 'preview'" :to="state.container" defer>
      <div
        class="box-border relative bg-white border border-gray-200 shadow-2xl h-8 px-2 w-[300px] truncate text-xs leading-8 -rotate-2 cursor-grabbing"
      >
        {{ subtask.name }}
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { TaskLevel } from '#task/constant'
import type { TaskItemLoader } from '#task/types'
import { TaskActionKey } from '#board/constant'
import type { BaseTaskType } from '#task-type/types'

const props = defineProps({
  subtaskId: {
    type: String,
    required: true,
  },
})

const { watchTaskFromId } = useBoardTasksLoader()

// Watch task from cache
const { snapshot } = watchTaskFromId(props.subtaskId)
const subtask = computed(() => {
  if (!snapshot.value) return null

  return snapshot.value.data as TaskItemLoader
})

const { updateTask } = useUpdateTask()
const { setCurrentTask } = useWorkspaceSharedState()
const { can } = useBoardAbility()
const toast = useToast()

const rowRef = ref()
const dragHandleRef = ref()
const taskNameRef = ref()
const isNameEditing = ref(false)
const taskActionsRef = ref()
const formState = reactive({
  name: subtask.value?.name,
})

onClickOutside(rowRef, () => {
  if (isNameEditing.value) {
    onUpdateSubtaskName()

    isNameEditing.value = false
  }
})

const additionalActions = computed(() => {
  return [
    {
      key: TaskActionKey.OVERVIEW,
      label: 'Overview',
      icon: 'heroicons:list-bullet',
      click: () => {
        if (!subtask.value) return

        const taskUrl = makeTaskUrl(subtask.value)
        window.open(`/list${taskUrl}`, '_blank')
      },
    },
    {
      key: TaskActionKey.RENAME,
      label: 'Rename',
      icon: 'leanbase:pen-line',
      click: (close: () => void) => {
        isNameEditing.value = true
        close()
      },
    },
    {
      key: TaskActionKey.UNLINK_PARENT,
      label: 'Unlink parent',
      icon: 'leanbase:unlink',
      hidden: subtask.value?.level !== TaskLevel.TASK,
      click: () => {
        handleUnlinkParent()
      },
    },
  ]
})

const { state, closestEdge, } = useTaskAsDropTarget({
  targetElement: rowRef,
  dragHandleTarget: rowRef,
  item: subtask.value as TaskItemLoader,
  modifiers: [],
  canDrag: () => {
    return !isNameEditing.value
  }
})

const onUpdateSubtaskName = async (event?: Event) => {
  event?.preventDefault()

  if (!formState.name) {
    formState.name = subtask.value?.name
  }

  if (formState.name !== subtask.value?.name && subtask.value?.id) {
    await updateTask(subtask.value.id, { name: formState.name })
  }

  isNameEditing.value = false
  taskNameRef.value.$el.querySelector('textarea')?.blur()
}

const handleUnlinkParent = () => {
  if (!subtask.value?.id) return

  updateTask(subtask.value.id, { parent: null })

  toast.add({
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: 'Unlink parent successfully',
  })
}

const onRightClick = () => {
  taskActionsRef.value?.open()
}

const onChangeTaskType = (type: BaseTaskType) => {
  if (!subtask.value?.id) return

  updateTask(subtask.value.id, { type: type.id })
  if (isNameEditing.value) {
    taskNameRef.value?.$el?.querySelector('textarea')?.focus()
  }
}

const onOpenTask = () => {
  if (isNameEditing.value || !subtask.value?.id) {
    isNameEditing.value = false
    return
  }

  const prefix = /^(.*)\/t\/.+$/.exec(window.location.pathname)?.[1]

  updateTaskUrl(subtask.value, prefix)
  setCurrentTask(subtask.value)
}

const onClickTaskLink = (event: MouseEvent) => {
  if ((event.ctrlKey || event.metaKey) && subtask.value) {
    window.open(makeTaskUrl(subtask.value), '_blank')
    return
  }

  onOpenTask()
}

watch(
  () => subtask.value?.name,
  (name) => {
    formState.name = name
  }
)
</script>
