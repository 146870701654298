import { SECTION_LOADER_FRAGMENT } from '#section/loader_fragment'

export const BOARD_ARCHIVED_SECTIONS_QUERY = gql`
  ${SECTION_LOADER_FRAGMENT}
  query BoardArchivedSectionsQuery($boardId: ID!, $filter: ArchivedSectionFilterInput) {
    boardArchivedSections(boardId: $boardId, filter: $filter) {
      sections {
        ...SectionLoader
      }
      nextCursor
    }
  }
`

export const ADD_UPDATE_SECTION_MUTATION = gql`
  ${SECTION_LOADER_FRAGMENT}
  mutation AddUpdateSectionMutation($input: AddUpdateSectionInput!) {
    addUpdateSection(input: $input) {
      section {
        ...SectionLoader
      }
    }
  }
`

export const UPDATE_SECTION_POSITION_MUTATION = gql`
  mutation UpdateSectionPositionMutation(
    $sectionId: ID!
    $position: Float!
  ) {
    updateSectionPosition(
      sectionId: $sectionId
      position: $position
    ) {
      success
      section {
        id
        position
      }
    }
  }
`

export const DELETE_SECTION_MUTATION = gql`
  mutation DeleteSectionMutation($sectionId: ID!) {
    deleteSection(sectionId: $sectionId) {
      success
      id
    }
  }
`
