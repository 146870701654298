<script setup lang="ts">
import type {
  DatePickerDate,
  DatePickerModel,
  DatePickerProps,
  DatePickerRangeObject,
  UpdateOptions,
} from 'v-calendar/dist/types/src/use/datePicker.js'
import { DatePicker as VCalendarDatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'

const calendarRef = ref()

const props = defineProps({
  modelValue: {
    type: [Number, String, Date, Object] as PropType<DatePickerModel>,
    default: null,
  },
  isRange: {
    type: Boolean,
    default: false,
  },
  attributes: {
    type: Array as PropType<DatePickerProps['attributes']>,
    default: () => [],
  },
})

const emit = defineEmits(['update:model-value', 'close'])

const date = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:model-value', value)
    emit('close')
  },
})

const attrs = {
  'transparent': true,
  'borderless': true,
  'color': 'primary',
  'is-dark': { selector: 'html', darkClass: 'dark' },
  'first-day-of-week': 2,
}

const DEFAULT_ATTRIBUTES = [
  {
    highlight: true,
    dates: new Date(),
  },
]

defineExpose({
  setDragEnd: (date: Date) => {
    nextTick(() => {
      calendarRef.value?.setDragEnd(date)
    })
  },
  moveToValue: (value: 'start' | 'end') => {
    nextTick(() => {
      calendarRef.value?.moveToValue(value)
    })
  },
  updateValue: (value: DatePickerDate | DatePickerRangeObject | null, options: Partial<UpdateOptions>) => {
    nextTick(() => {
      calendarRef.value?.updateValue(value, options)
    })
  },
})
</script>

<template>
  <VCalendarDatePicker
    ref="calendarRef"
    v-model="date"
    :model-modifiers="{
      range: isRange,
    }"
    v-bind="{ ...attrs, ...$attrs }"
    :attributes="[DEFAULT_ATTRIBUTES, ...attributes]"
    @mousedown.prevent
  />
</template>

<style lang="scss">
:root {
  --vc-gray-50: rgb(var(--color-gray-50));
  --vc-gray-100: rgb(var(--color-gray-100));
  --vc-gray-200: rgb(var(--color-gray-200));
  --vc-gray-300: rgb(var(--color-gray-300));
  --vc-gray-400: rgb(var(--color-gray-400));
  --vc-gray-500: rgb(var(--color-gray-500));
  --vc-gray-600: rgb(var(--color-gray-600));
  --vc-gray-700: rgb(var(--color-gray-700));
  --vc-gray-800: rgb(var(--color-gray-800));
  --vc-gray-900: rgb(var(--color-gray-900));
}

.vc-container {
  width: 100%;
}
.vc-primary {
  --vc-accent-50: rgb(var(--color-primary-50));
  --vc-accent-100: rgb(var(--color-primary-100));
  --vc-accent-200: rgb(var(--color-primary-200));
  --vc-accent-300: rgb(var(--color-primary-300));
  --vc-accent-400: rgb(var(--color-primary-400));
  --vc-accent-500: rgb(var(--color-primary-500));
  --vc-accent-600: rgb(var(--color-primary-600));
  --vc-accent-700: rgb(var(--color-primary-700));
  --vc-accent-800: rgb(var(--color-primary-800));
  --vc-accent-900: rgb(var(--color-primary-900));
}

.vc-highlight-bg-outline {
  background-color: var(--vc-highlight-solid-bg) !important;
  @apply text-white outline-none border-none !important;
}

.vc-highlight-content-outline {
  @apply text-white !important;
}
.is-today:not(:has(.vc-primary)) {

  .vc-highlight-bg-solid {
    @apply bg-white;
  }
  .vc-day-content {
    @apply bg-white text-primary-500;
  }
}
</style>
