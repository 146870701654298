<template>
  <div class="size-6 min-w-6 bg-gray-100 flex items-center justify-center rounded">
    <Icon class="size-4" :name="icon" />
  </div>
</template>

<script lang="ts" setup>
import type { FieldType } from '#field/constant'

const props = defineProps<{
  type: FieldType
}>()

const icon = computed(() => `leanbase:${props.type}`)
</script>
