<template>
  <UCheckbox
    v-model="value"
    :indeterminate="indeterminate"
    v-bind="$attrs"
    @update:model-value="onUpdate"
  />
</template>

<script lang="ts" setup>
import { debounce } from 'lodash-es'

const props = defineProps({
  checked: {
    type: Boolean,
    default: false,
  },
  indeterminate: {
    type: Boolean,
    default: false,
  },
})

const value = ref(props.checked)
const indeterminate = ref(props.indeterminate)

syncRef(
  computed(() => props.checked),
  value,
  {
    direction: 'ltr',
  }
)

syncRef(
  computed(() => props.indeterminate),
  indeterminate,
  {
    direction: 'ltr',
  }
)

const onUpdate = debounce(() => {
  value.value = props.checked
})
</script>
