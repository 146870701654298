import { ACTION_FRAGMENT, COMMENT_FRAGMENT } from '#action/fragment'

export const TASK_ACTION_QUERY = gql`
${ACTION_FRAGMENT}
query TaskActions($filter: TaskActionFilterInput!) {
  taskActions(
    filter: $filter
  ) {
    nextCursor
    items {
      ...Action
    }
  }
}
`

export const BOARD_ACTION_QUERY = gql`
${ACTION_FRAGMENT}
query BoardActions($filter: BoardActionFilterInput!) {
  boardActions(
    filter: $filter
  ) {
    nextCursor
    items {
      ...Action
    }
  }
}
`

export const ADD_UPDATE_COMMENT_MUTATION = gql`
${COMMENT_FRAGMENT}
mutation AddUpdateComment($input: AddUpdateCommentInput!) {
  addUpdateComment(
    input: $input
  ) {
    comment {
      ...Comment
    }
  }
}
`

export const DELETE_COMMENT_MUTATION = gql`
mutation DeleteComment($commentId: ID!) {
    deleteComment(commentId: $commentId) {
      actionId
    }
}
`

export const TOGGLE_COMMENT_REACTION_MUTATION = gql`
${ACTION_FRAGMENT}
mutation ToggleReaction($commentId: ID!, $emoji: String!) {
  toggleReaction(
    commentId: $commentId
    emoji: $emoji
  ) {
    action {
      ...Action
    }
  }
}
`
