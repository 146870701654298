<template>
  <div v-if="preview" class="p-8 bg-gray-100 flex items-center justify-center">
    <div
      v-if="formState.name"
      class="w-full rounded-md py-1.5 px-2.5 text-white text-sm"
      :style="{ background: preset?.backgroundColor, color: preset?.color, wordBreak: 'break-word' }"
    >
      {{ formState.name }}
    </div>
  </div>
  <UForm
    ref="form"
    :class="['px-4 py-3', formClass]"
    :schema="schema"
    :state="formState"
    @submit="$emit('submit', formState)"
  >
    <UFormGroup label="Label name" name="name" class="w-full" @click.prevent.stop>
      <UInput
        v-model="formState.name"
        class="w-full"
        placeholder="Enter your label name"
        maxlength="255"
        autofocus
        data-test="label-name-input"
        @keydown.enter.prevent.stop="$emit('submit', formState)"
      />
    </UFormGroup>
    <UFormGroup label="Select color" name="color" class="mt-4" @click.prevent.stop>
      <div class="grid grid-cols-7 gap-2">
        <div
          v-for="colorPreset in ColorPresets"
          :key="colorPreset.name"
          class="h-6 min-w-6 w-full rounded relative cursor-pointer"
          :style="{
            backgroundColor: colorPreset.backgroundColor,
          }"
          @click="formState.color = colorPreset.name"
        >
          <Icon
            v-if="colorPreset.name === formState.color"
            name="heroicons:check"
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            :style="{
              color: colorPreset.color,
            }"
          />
        </div>
      </div>
    </UFormGroup>
    <slot name="footer" />
  </UForm>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type { Label } from '#label/types'
import { ColorPresets } from '#core/constant'

const props = defineProps({
  preview: {
    type: Boolean,
    default: true,
  },
  initialValue: {
    type: Object as PropType<Partial<Label>>,
    default: () => ({}),
  },
  formClass: {
    type: String,
    default: '',
  },
})

defineEmits<{
  (e: 'submit', state: typeof formState): void
}>()

const form = ref()

const schema = z.object({
  name: z.string().trim().min(1).max(255),
  color: z.string(),
})

const defaultState = () => ({
  name: '',
  color: 'green',
  ...props.initialValue,
})

const formState = reactive(defaultState())

const preset = computed(() =>
  ColorPresets.find((preset) => preset.name === formState.color)
)

defineExpose({
  form,
  resetForm: () => {
    extend(formState, defaultState())
  },
})
</script>
