import { LABEL_FRAGMENT } from '#label/fragment'

export const LABELS_QUERY = gql`
  ${LABEL_FRAGMENT}
  query LabelsQuery($parentId: ID!, $source: String, $loadFull: Boolean!) {
    labels(parentId: $parentId, source: $source, loadFull: $loadFull) {
      ...Label
      settingsPack @include(if: $loadFull) {
        id
        name
      }
    }
  }
`

export const ADD_UPDATE_LABEL_MUTATION = gql`
  ${LABEL_FRAGMENT}
  mutation AddUpdateLabelMutation($input: AddUpdateLabelInput!) {
    addUpdateLabel(input: $input) {
      label {
        ...Label
      }
    }
  }
`

export const DELETE_LABEL_MUTATION = gql`
  mutation DeleteLabelMutation($id: ID!) {
    deleteLabel(id: $id) {
      success
    }
  }
`
