<template>
  <DeferredPopover
    ref="popoverRef"
    :ui="{
      width: 'w-[280px]',
      wrapper: 'h-full',
      trigger: `h-full flex items-center ${
        disabled ? '!cursor-not-allowed' : ''
      }`,
    }"
    v-bind="$attrs"
    @update:open="$emit('update:open', $event)"
  >
    <template #content>
      <PopoverContentTaskListSelect
        :heading="heading"
        :search-placeholder="searchPlaceholder"
        :accept-levels="acceptLevels"
        class="w-full"
        :board-id="boardId"
        :section-id="sectionId"
        :selected-task-id="selectedTaskId"
        v-bind="$attrs"
        read-only
        :show-create-form="showCreateForm"
        :close-on-selected="closeOnSelected"
        @close="popoverRef.close()"
        @change="$emit('change', $event)"
        @cancel="showCreateForm = false"
      >
        <template #empty>
          <div class="px-4">
            <p class="text-sm">No module has been created in this project. Create your first module</p>
          </div>
        </template>
        <template v-if="showUnlink || !!selectedTaskId" #footer>
          <div class="pr-2">
            <UButton
              class="w-full flex justify-center"
              variant="soft"
              color="gray"
              @click="onShowCreateForm"
            >
              Create module
            </UButton>
            <UButton
              variant="soft"
              color="gray"
              class="w-full my-3"
              block
              @click.prevent.stop="$emit('change', null)"
            >
              Unlink parent
            </UButton>
          </div>
        </template>
      </PopoverContentTaskListSelect>
    </template>
    <slot />
  </DeferredPopover>
</template>

<script lang="ts" setup>
import type { TaskLevel } from '#task/constant'
import type { TaskItem } from '#task/types'

defineProps({
  selectedTaskId: {
    type: String as PropType<string | null>,
  },
  boardId: {
    type: String,
    required: true,
  },
  sectionId: {
    type: String,
    required: false,
  },
  heading: {
    type: String,
    default: '',
  },
  acceptLevels: {
    type: Array as PropType<TaskLevel[]>,
    default: () => [],
  },
  searchPlaceholder: {
    type: String,
    default: 'Search...',
  },
  emptyMessage: {
    type: String,
    default: 'No tasks found',
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  filter: {
    type: Function as PropType<(task: TaskItem) => boolean>,
    default: () => () => true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  showUnlink: {
    type: Boolean,
    required: false,
  },
})

defineEmits(['change', 'update:open'])

const popoverRef = ref()
provide('popoverRef', popoverRef)

const showCreateForm = ref(false)

const onShowCreateForm = () => {
  showCreateForm.value = true
}

defineExpose({
  open: () => {
    popoverRef.value?.open()
  }
})
</script>
