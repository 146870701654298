import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_mdhds4e6aco467nnq4wc3qpeoe/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_change-case@5.4.4_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22._lw4jjadml5s44vuj4qmie6nnwm/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}