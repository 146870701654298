/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

import { TASK_TYPE_FRAGMENT } from '#task-type/fragment'

export const TASK_TYPE_LOADER_FRAGMENT = gql`
  ${TASK_TYPE_FRAGMENT}
  fragment TaskTypeLoader on TaskTypeType {
    ...TaskType
    settingsPack @client {
      ...SettingsPackLoader
    }
    fields {
      ...Field
    }
  }
`
