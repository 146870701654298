<template>
  <div>
    <div
      class="relative w-screen h-screen flex justify-center items-center cursor-zoom-out"
      @click="destroy"
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-gray-400 opacity-50"
      />
      <img :src="imageUrl" class="h-screen z-30" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  imageUrl: string
  destroy: () => void
}>()

defineShortcuts({
  escape: {
    usingInput: true,
    handler: () => {
      props.destroy()
    },
  },
})
</script>
