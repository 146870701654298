<template>
  <div />
</template>

<script lang="ts" setup>
import { ref, } from 'vue'
import type { Editor } from '@tiptap/vue-3'
import { isNodeSelection, posToDOMRect, VueRenderer } from '@tiptap/vue-3'
import tippy, { type Instance, type Content } from 'tippy.js'
import LinkEditFormVue from '#core/components/editor/extensions/LinkEditForm.vue'
import { useEditorContext } from '#core/editor_context'

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
})

const linkEditFormInstance = ref<Instance>()
const { isShowLinkEditForm } = useEditorContext()

const getRange = (editor: Editor) => {
  const { ranges } = editor.state.selection
  const from = Math.min(...ranges.map((range) => range.$from.pos))
  const to = Math.max(...ranges.map((range) => range.$to.pos))
  return { from, to }
}

const getNodeDom = (editor: Editor) => {
  const { from } = getRange(editor)
  return editor.view.nodeDOM(from)?.parentElement as HTMLElement
}

const getBoundingClientRect = (editor: Editor) => {
  const { from, to } = getRange(editor)

  if (isNodeSelection(editor.state.selection)) {
    const node = editor.view.nodeDOM(from) as HTMLElement
    if (node) {
      return node.getBoundingClientRect()
    }
  }

  return posToDOMRect(editor.view, from, to)
}

function showLinkEditForm(): void {
  if (!linkEditFormInstance.value) {
    const node = getNodeDom(props.editor)
    if (node instanceof HTMLElement) {
      linkEditFormInstance.value = tippy(node, {
        content: new VueRenderer(LinkEditFormVue, {
          props: {
            onHide: () => {
              linkEditFormInstance.value?.destroy()
              linkEditFormInstance.value = undefined
            },
            editor: props.editor,
          },
          editor: props.editor,
        }).element as Content,
        trigger: 'manual',
        interactive: true,
        placement: 'bottom',
        appendTo: document.body,
        getReferenceClientRect: () => getBoundingClientRect(props.editor),
        delay: 200,
        duration: 150,
      })
    }
  } else {
    linkEditFormInstance.value?.popperInstance?.forceUpdate()
  }

  linkEditFormInstance.value?.show()
}

watch(isShowLinkEditForm, (value) => {
  if (!value) {
    linkEditFormInstance.value?.hide()
    return
  }

  showLinkEditForm()
})

onBeforeUnmount(() => {
  linkEditFormInstance.value?.destroy()
  linkEditFormInstance.value = undefined
})
</script>
