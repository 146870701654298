<template>
  <div class="relative">
    <UInput
      ref="inputRef"
      v-bind="$attrs"
      v-model="search"
      :placeholder="placeholder"
      input-class="pr-8"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @keydown="onKeydown"
    />

    <UButton
      v-if="search"
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark"
      size="2xs"
      class="absolute right-1 top-1/2 -translate-y-1/2 z-20"
      @click="onClear"
    />

    <div
      v-if="open && filteredOptions.length"
      class="absolute p-1 pr-0 top-full translate-y-2 left-0 right-0 w-full z-50 ring-gray-200 ring-1 bg-white shadow-lg rounded-md"
    >
      <div class="overflow-y-auto max-h-60 scroll-stable minimal-scrollbar pr-0.5 auto-hide-scrollbar">
        <p
          v-for="(option, index) in filteredOptions"
          :key="option.value"
          ref="optionRefs"
          :class="[
            'cursor-pointer select-none relative flex items-center justify-between',
            'gap-1 rounded-md px-1.5 py-1.5 text-sm text-gray-900',
            ' dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700',
            {
              'bg-gray-100 dark:bg-gray-700': focusIndex === index,
            },
          ]"
          @mouseenter="focusIndex = index"
          @mouseleave="focusIndex = -1"
          @mousedown.prevent="selectOption(option)"
        >
          {{ option.label }}
          <Icon v-if="option.value === selectedValue" name="i-heroicons-check" class="w-5 h-5" />
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  options: {
  label: string
  value: string
}[]
  placeholder?: string
  value: string
}>()

const emit = defineEmits<{
  (e: 'change', value: string): void
  (e: 'clear'): void
}>()

const open = ref(false)
const search = ref(props.value)
const inputRef = ref()
const selectedValue = ref<string>(
  props.value
)

const filteredOptions = computed(() => {
  if (!search.value) return props.options

  return props.options.filter(option =>
    option.value.toLowerCase().includes(search.value.toLowerCase()) ||
    option.label.toLowerCase().includes(search.value.toLowerCase())
  )
})

const { optionRefs, focusIndex } = useDropdownShortcutKey({
  whenever: open,
  options: filteredOptions,
  onEscape: () => {
    onBlur()
  },
  onEnter: (option: typeof filteredOptions.value[number]) => {
    selectOption(option)
    inputRef.value?.$el?.querySelector('input')?.blur()
  },
})

const onFocus = () => {
  open.value = true
}

const onBlur = () => {
  open.value = false
  search.value = selectedValue.value
}

const onKeydown = () => {
  open.value = true
}

const onClear = () => {
  search.value = ''
  selectedValue.value = ''
  emit('clear')
}

const onInput = (event: Event) => {
  search.value = (event.target as HTMLInputElement).value
}

const selectOption = (option: typeof props.options[number]) => {
  selectedValue.value = option.value
  search.value = option.value
  open.value = false
  emit('change', option.value)
}

watch(() => props.value, (value) => {
  search.value = value
})

defineExpose({
  focus: () => {
    inputRef.value?.$el?.querySelector('input')?.focus()
  },
})
</script>
