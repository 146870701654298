<template>
  <div class="pt-3 w-44">
    <template v-for="(actionGroup, groupIndex) in actionGroupLists" :key="actionGroup.title">
      <p v-if="actionGroup.actions.length" :class="['font-semibold text-gray-900 text-sm mb-2', { 'mt-6': groupIndex !== 0 }]">
        {{ actionGroup.title }}
      </p>
      <div class="flex flex-col gap-2">
        <template v-for="action in actionGroup.actions" :key="action.key">
          <component
            :is="action.component"
            v-if="!!action.component && (action.show ?? true)"
            :ref="action.ref"
            v-bind="action.popoverProps"
          >
            <TaskDetailTooltipButton
              :tooltip-text="action.tooltip"
              :shortcuts="action?.shortcuts"
              :icon="action.icon"
              :disabled="action.disabled"
              :text="action.text"
            />
          </component>
          <TaskDetailTooltipButton
            v-else-if="action.show ?? true"
            :tooltip-text="action.tooltip"
            :shortcuts="action.shortcuts"
            :icon="action.icon"
            :disabled="action.disabled"
            :text="action.text"
            v-bind="action.buttonProps"
            @click="action.onClick"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type {
  TaskDetail,
} from '#task/types'

const props = defineProps({
  taskDetail: {
    type: Object as PropType<TaskDetail>,
    required: true,
  },
})
const emit = defineEmits(['close', 'click-dependency', 'click-subtasks'])

const { actionGroupLists, expose } = useTaskDetailSidebar(
  props,
  emit
)

defineExpose(expose)
</script>
