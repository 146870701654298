<template>
  <DeferredPopover
    ref="popoverRef"
    :ui="{
      width: 'max-w-[280px] min-w-52',
    }"
    @update:open="$emit('update:open', $event)"
  >
    <template #content>
      <PopoverContentStatusSelect
        :selected-status-id="selectedStatusId"
        :parent-id="parentId"
        :source="source"
        :accept-types="acceptTypes"
        :close-on-selected="closeOnSelected"
        :enable-remove="enableRemove"
        :disable-setting="disableSetting"
        @close="popoverRef.close()"
        @change="$emit('change', $event)"
        @remove="$emit('remove')"
      />
    </template>
    <slot />
  </DeferredPopover>
</template>

<script lang="ts" setup>
import { type STATUS_TYPE } from '#status/constant'

defineProps({
  selectedStatusId: {
    type: String,
  },
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
  acceptTypes: {
    type: Array as PropType<STATUS_TYPE[]>,
    default: () => [],
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  enableRemove: {
    type: Boolean,
    default: false,
  },
  disableSetting: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['change', 'remove', 'update:open'])

const popoverRef = ref()

defineExpose({
  open: () => {
    popoverRef.value?.open()
  }
})
</script>
