export const getImageAvgColorHex = (imgSrc: string): Promise<string> => {
  return new Promise((resolve) => {
    const blockSize = 5
    const defaultHex = '#000000'
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      return resolve(defaultHex)
    }

    try {
      const imgEl = document.createElement('img')
      imgEl.src = imgSrc
      imgEl.crossOrigin = 'anonymous'
      imgEl.onload = () => {
        const height = (canvas.height =
          imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height)
        const width = (canvas.width =
          imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width)

        context.drawImage(imgEl, 0, 0)
        const data = context.getImageData(0, 0, width, height)
        const rgb = { r: 0, g: 0, b: 0 }
        let count = 0
        const length = data.data.length
        for (let i = 0; i < length; i += blockSize * 4) {
          count++
          rgb.r += data.data[i]
          rgb.g += data.data[i + 1]
          rgb.b += data.data[i + 2]
        }

        rgb.r = Math.round(rgb.r / count)
        rgb.g = Math.round(rgb.g / count)
        rgb.b = Math.round(rgb.b / count)

        const toHex = (value: number) => value.toString(16).padStart(2, '0')
        const hex = `#${toHex(rgb.r)}${toHex(rgb.g)}${toHex(rgb.b)}`
        imgEl.remove()
        resolve(hex)
      }
    } catch (e) {
      resolve(defaultHex)
    }
  })
}
