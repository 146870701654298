export const FIELD_FRAGMENT = gql`
  fragment Field on FieldType {
    id
    name
    type
    description
    default
    options
  }
`

export const CUSTOM_FIELD_FRAGMENT = gql`
  ${FIELD_FRAGMENT}
  fragment CustomField on TaskFieldType {
    id
    value
    field {
      ...Field
    }
    fieldId
  }
`
