import {
  UPDATE_USER_PROFILE_MUTATION,
  UPDATE_USER_SECURITY_MUTATION,
  CURRENT_USER_QUERY,
  WORKSPACE_MEMBERS_QUERY,
  BOARD_MEMBERS_QUERY,
  ROLES_QUERY,
  UPDATE_WORKSPACE_MEMBER_MUTATION,
  WORKSPACE_MEMBER_QUERY,
  INVITE_MEMBERS_MUTATION,
  UPDATE_BOARD_MEMBER_MUTATION,
  ACCEPT_INVITATION_MUTATION,
  DECLINE_INVITATION_MUTATION,
  INVITATION_QUERY,
  DELETE_BOARD_MEMBER_MUTATION,
  BOARD_MEMBER_QUERY,
  DELETE_ROLE_MUTATION,
  VERIFY_EMAIL_MUTATION,
  USER_PROFILE_QUERY,
  UPDATE_USER_PROFILE_PHOTO_MUTATION,
} from '#auth/schema'
import type {
  UserProfile,
  UpdateUserMutationType,
  WorkspaceMember,
  Role,
  MemberFilterInput,
  InviteEntry,
  BoardMember,
  InvitationType,
  UpdateUserProfilePhotoParams,
} from '#auth/types'

export const useUserProfileQuery = (id: string, workspaceId: string) => {
  return useQuery<{ user: UserProfile }>(USER_PROFILE_QUERY, {
    id,
    workspaceId,
  })
}

export const useUpdateUserMutation = ({ input, photo }: UpdateUserProfilePhotoParams) => {
  const { client } = useApolloClient()

  const schema = photo ? UPDATE_USER_PROFILE_PHOTO_MUTATION : UPDATE_USER_PROFILE_MUTATION

  const variables: UpdateUserProfilePhotoParams = {
    input,
  }
  if (photo) {
    variables.photo = photo
  }

  return useMutation<UpdateUserMutationType>(schema, {
    variables,
    refetchQueries: [
      {
        query: CURRENT_USER_QUERY,
      },
    ],
    async onQueryUpdated() {
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'currentUser' })
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'user' })
    },
  })
}

export const useUpdateUserSecurityMutation = (input: Partial<UserProfile>) => {
  const { client } = useApolloClient()
  return useMutation<UpdateUserMutationType>(UPDATE_USER_SECURITY_MUTATION, {
    variables: {
      input,
    },
    refetchQueries: [
      {
        query: CURRENT_USER_QUERY,
      },
    ],
    async onQueryUpdated() {
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'currentUser' })
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'user' })
    },
  })
}

export const useListWorkspaceMembersQuery = (
  variables: {
    workspaceId: string
    filter?: MemberFilterInput
  } | null = null,
  options = {}
) => {
  return useLazyQuery<{
    workspaceMembers?: {
      items: WorkspaceMember[]
      total: number
    }
  }>(WORKSPACE_MEMBERS_QUERY, variables, options)
}

export const useListBoardMembersQuery = (
  variables: {
    boardId: string
    filter?: MemberFilterInput
  } | null = null,
  options = {}
) => {
  return useLazyQuery<{
    boardMembers?: {
      items: BoardMember[]
      total: number
    }
  }>(BOARD_MEMBERS_QUERY, variables, { ...options, fetchPolicy: 'cache-first', })
}

export const removeListBoardMembersCache = (boardId: string) => {
  const { client } = useApolloClient()

  client.cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'boardMembers',
    args: { boardId },
  })
}

export const useGetBoardMemberLazyQuery = (
  variables: {
    id?: string
    boardId?: string
  } | null = null,
  options = {}
) => {
  return useLazyQuery<{ boardMember: BoardMember }>(
    BOARD_MEMBER_QUERY,
    variables,
    options,
  )
}

export const useRolesQuery = (
  type: string,
  id: string | null = null,
  options = {}
) => {
  return useQuery<{ roles: Role[] }>(ROLES_QUERY, { type, id }, options)
}

export const useInvitationQuery = () => {
  return useLazyQuery<{ invitation: InvitationType }>(INVITATION_QUERY)
}

export const useRolesLazyQuery = (
  type: string,
  id: string | null = null,
  options = {}
) => {
  return useLazyQuery<{ roles: Role[] }>(ROLES_QUERY, { type, id }, options)
}

export const useUpdateWorkspaceMemberMutation = () => {
  return useMutation<{
    updateWorkspaceMember: { workspaceMember: WorkspaceMember }
  }>(UPDATE_WORKSPACE_MEMBER_MUTATION)
}

export const useWorkspaceMemberQuery = () => {
  return useLazyQuery(WORKSPACE_MEMBER_QUERY)
}

export const useUpdateBoardMemberMutation = () => {
  return useMutation<{
    updateBoardMember: { boardMember: BoardMember }
  }>(UPDATE_BOARD_MEMBER_MUTATION)
}

export const useInviteMemberMutation = (variables: {
  workspaceId?: string
  boardId?: string
  workspaceRoleId?: string
  boardRoleId?: string
  entries: InviteEntry[]
}) => {
  const { client } = useApolloClient()
  return useMutation<{
    inviteMembers: {
      success: boolean
      memberFound: InviteEntry[]
      invalid: InviteEntry[]
      invited: InviteEntry[]
    }
  }>(INVITE_MEMBERS_MUTATION, {
    variables,
    refetchQueries: [{
      query: BOARD_MEMBERS_QUERY,
      variables: {
        boardId: variables.boardId,
        filter: {
          search: '',
          roleId: ''
        }
      },
    }],
    async onQueryUpdated(observableQuery) {
      if (variables.workspaceId) {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'workspaceMembers' })
      }

      if (variables.boardId) {
        client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'boardMembers',
          args: {
            boardId: variables.boardId,
          },
        })
      }

      observableQuery.refetch()
    },
  })
}

export const useAcceptInvitationMutation = () => {
  return useMutation<{
    success: boolean
  }>(ACCEPT_INVITATION_MUTATION)
}

export const useDeclineInvitationMutation = () => {
  return useMutation<{
    success: boolean
  }>(DECLINE_INVITATION_MUTATION)
}

export const useDeleteBoardMemberMutation = () => {
  return useMutation<{
    deleteBoardMember: {
      success: boolean
    }
  }>(DELETE_BOARD_MEMBER_MUTATION)
}

export const useDeleteRoleMutation = () => {
  return useMutation<{
    deleteRole: { success: boolean }
  }>(DELETE_ROLE_MUTATION)
}

export const useVerifyEmailMutation = () => {
  return useMutation<{
    verifyEmail: { success: boolean }
  }>(VERIFY_EMAIL_MUTATION)
}
