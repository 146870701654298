<template>
  <UPopover v-model:open="open" v-bind="$attrs">
    <template #panel="panel">
      <slot name="panel" v-bind="panel" />
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['open', 'close'])

const open = ref(props.visible)

watch(
  () => open.value,
  (isOpen) => {
    const event = isOpen ? 'open' : 'close'
    emit(event)
  }
)

watch(
  () => props.visible,
  (visible) => {
    open.value = visible
  }
)
</script>
