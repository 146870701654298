<template>
  <div
    v-if="icon"
    class="rounded flex items-center justify-center"
    :class="[sizeWrapper && sizeClass.wrapper, backgroundClass]"
    :style="wrapperStyle"
  >
    <Icon :class="[sizeClass.icon, iconClass]" :name="icon" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  background: {
    type: String,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  backgroundClass: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    required: false,
  },
  iconClass: {
    type: String,
  },
  size: {
    type: String as PropType<'xxs' | 'xs' | 'sm' | 'md' | 'lg'>,
    default: 'md',
  },
  sizeWrapper: {
    type: Boolean,
    default: true,
  },
})

const wrapperStyle = computed(() => {
  if (props.transparent) {
    return {
      backgroundColor: 'transparent',
    }
  }

  return getColorPreset(props.background || '', true)
})

const sizeClass = computed(() => {
  const sizeMap = {
    xxs: {
      wrapper: 'size-3.5',
      icon: 'size-2',
    },
    xs: {
      wrapper: 'size-4 min-w-4',
      icon: 'size-2.5',
    },
    sm: {
      wrapper: 'size-5 min-w-5',
      icon: 'size-3',
    },
    md: {
      wrapper: 'size-7 min-w-7',
      icon: 'size-4',
    },
    lg: {
      wrapper: 'size-10 min-w-10',
      icon: 'size-6',
    },
  }
  return sizeMap[props.size]
})
</script>
