<template>
  <DeferredPopover
    ref="popoverRef"
    v-model:open="open"
    :ui="{ width: 'w-[280px]' }"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <template #content>
      <PopoverContentTaskAssignee
        v-if="open"
        ref="popoverContentRef"
        :board-id="boardId"
        :current-assignee="currentAssignee"
        :close-on-selected="closeOnSelected"
        @close="open = false"
        @select="$emit('select', $event)"
        @remove="$emit('remove')"
      />
    </template>
    <slot />
  </DeferredPopover>
</template>

<script lang="ts" setup>
import type { TaskDetail } from '#task/types'

defineProps({
  boardId: {
    type: String,
    required: false,
  },
  currentAssignee: {
    type: Object as PropType<TaskDetail['assignee']>,
    default: () => ({}),
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['select', 'remove'])

const open = ref(false)
const popoverRef = ref()
const popoverContentRef = ref()

defineExpose({
  open: () => (open.value = true),
})
</script>
