<template>
  <div v-if="renderCustomFields.length" class="mt-6 relative">
    <Icon
      name="leanbase:custom-fields"
      class="absolute top-0.5 -left-8"
      :size="20"
    />
    <p class="text-gray-900 font-semibold">Custom fields</p>
    <div class="border rounded mt-3">
      <div
        v-for="(customField, index) in renderCustomFields"
        :key="customField.field.id"
        class="grid grid-cols-5 border-b last:border-0 group"
        data-test="field"
      >
        <div
          :class="[
            'flex items-center justify-between px-4 py-2 col-span-2 text-sm cursor-pointer border-r ring-gray-200',
            {
              'rounded-tl': index === 0,
              'rounded-bl': index === renderCustomFields.length - 1,
            },
          ]"
          @click="onFocusField(customField.field.id)"
        >
          <Tooltip
            :text="customField.field?.name"
            :description="customField.field?.description"
            class="flex items-center gap-2 w-fit"
            :ui="{
              base: 'h-fit text-wrap max-w-44 break-words',
              background: 'bg-white',
            }"
          >
            <template #default="{ getTextRef }">
              <FieldIcon :type="customField.field.type" />
              <span
                :ref="!customField.field?.description ? getTextRef : undefined"
                class="line-clamp-1 break-all"
              >{{ customField.field?.name }}</span>
            </template>
          </Tooltip>
          <IconSettingPack v-if="customField.field?.settingsPack" />
        </div>
        <div
          :class="[
            'col-span-3 flex items-center text-sm ring-gray-200 hover:ring-1 hover:ring-gray-400 hover:bg-gray-100',
            {
              'rounded-tr': index === 0,
              'rounded-br': index === renderCustomFields.length - 1,
            },
            ...activeCustomFieldClass(index, renderCustomFields.length),
          ]"
        >
          <Suspense v-if="customField.component">
            <component
              :is="customField.component"
              :ref="(el: TaskFieldRef) => getFieldListRef(el, customField.field)"
              class="w-full h-full text-gray-900 flex justify-center flex-col"
              :label="customField.field.name"
              :content-class="contentClass(index, renderCustomFields.length)"
              :value="customField.value"
              :description="customField.field.description"
              :options="customField.field.options"
              :disabled="!can('dashboard.data.manage_tasks_sections')"
              disable-setting
              show-full-label
              container-class="break-words cursor-pointer"
              data-test="value"
              @change="(value: string) => $emit('change', { ...omit(customField, 'component'), value })"
            />
          </Suspense>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ObservableSubscription } from '@apollo/client'
import { omit, uniqBy } from 'lodash-es'
import type { TaskCustomField } from '#task/types'
import { FieldPreviewComponents } from '#field/constant'
import { BOARD_DATA_LOADER_QUERY } from '#board/loader_schema'
import type { BaseTaskType } from '#task-type/types'
import type { Field, TaskFieldRef } from '#field/types'
import type { AsyncComponent } from '#core/types'
import { TaskLevel } from '#task/constant'

const props = defineProps({
  taskFields: {
    type: Array as PropType<TaskCustomField[]>,
    default: () => [],
  },
  boardId: {
    type: String,
    required: true,
  },
  typeId: {
    type: String,
    required: true,
  },
})
defineEmits(['change'])

const { client } = useApolloClient()
const { can } = useBoardAbility()
const unsubscribe = ref<ObservableSubscription>()
const typeFields = ref<Field[]>([])
const fieldListRefs = ref<Record<string, TaskFieldRef>>({})

const observer = client.watchQuery({
  query: BOARD_DATA_LOADER_QUERY,
  variables: {
    boardId: props.boardId,
  },
  fetchPolicy: 'cache-first',
})

const renderCustomFields = computed(() => {
  /**
   * Create field position map for prevent re-positioning
   */
  const fieldPosition: Record<string, number> = {}
  typeFields.value.forEach((field, index) => {
    fieldPosition[field.id] = index
  })

  const taskFieldValues = props.taskFields.reduce((acc, curr) => {
    if (curr.field?.type) {
      acc.push({
        ...curr,
        component: FieldPreviewComponents.get(curr.field.type),
      })
    }

    return acc
  }, [] as Array<TaskCustomField & { component: AsyncComponent | undefined }>)
  const taskFieldIds = taskFieldValues.map((field) => field.field.id)
  const typeFieldValues = typeFields.value.reduce((acc, curr) => {
    if (!taskFieldIds.includes(curr.id)) {
      acc.push({
        field: curr,
        value: curr.default || '',
        component: FieldPreviewComponents.get(curr.type),
      })
    } else {
      const taskField = taskFieldValues.find(
        (field) => field.field.id === curr.id
      )
      if (taskField && curr.settingsPack) {
        taskField.field = {
          ...taskField.field,
          settingsPack: curr.settingsPack,
        }
      }
    }

    return acc
  }, [] as Array<{ field: Field; value: string; component: AsyncComponent | undefined }>)

  return [...taskFieldValues, ...typeFieldValues].sort(
    (a, b) => fieldPosition[a.field.id] - fieldPosition[b.field.id]
  )
})

const setTypeFields = () => {
  const { data } = observer.getCurrentResult()
  if (!data?.boardData) return

  const { taskTypes, settingsPacks }: { taskTypes: Array<BaseTaskType & { fields: Field[] }>, settingsPacks: Array<{ id: string, name: string }> } = data.boardData

  const settingsPackMap = new Map(
    settingsPacks?.map((pack) => [pack.id, pack])
  )

  const typeField = taskTypes.find((type) => type.id === props.typeId)
  if (!typeField) return

  // Handle tasks and relative-tasks separately
  if (typeField.level === TaskLevel.TASK) {
    const settingsPack = typeField.settingsPackId
      ? settingsPackMap.get(typeField.settingsPackId)
      : undefined

    typeFields.value = typeField.fields.map((field: Field) => ({
      ...field,
      settingsPack,
    }))
    return
  }

  // Handle relative task-types
  const relativeTaskTypes = taskTypes.filter(
    (type) => type.level === typeField.level && type.id !== typeField.id
  )

  if (!relativeTaskTypes.length) {
    typeFields.value = typeField.fields
    return
  }

  // Process relative fields
  const relativeFields = relativeTaskTypes.flatMap(taskType => {
    if (!taskType.fields?.length || !taskType.settingsPackId) return []

    const settingsPack = settingsPackMap.get(taskType.settingsPackId)
    return taskType.fields.map(field => ({
      ...field,
      settingsPack,
    }))
  })

  typeFields.value = uniqBy(
    [...typeField.fields, ...relativeFields],
    'id'
  )
}

const contentClass = (index: number, length: number) => {
  return `leading-6 !max-w-full
  ${index === 0 ? 'rounded-tr' : ''}
  ${index === length - 1 ? 'rounded-br' : ''}
    `
}

const activeCustomFieldClass = (index: number, length: number) => {
  return [
    'has-[[data-headlessui-state="open"]]:[&>div]:!ring-primary-500 has-[[data-headlessui-state="open"]]:[&>div]:ring-1 has-[[data-headlessui-state="open"]]:[&>div]:bg-white',
    {
      'has-[[data-headlessui-state="open"]]:[&>div]:rounded-tr': index === 0,
      'has-[[data-headlessui-state="open"]]:[&>div]:rounded-br':
        index === length - 1,
    },
  ]
}

const getFieldListRef = (ref: TaskFieldRef, field: Field) => {
  nextTick(() => {
    extend(fieldListRefs.value, { [field.id]: ref })
  })
}

watch(
  () => props.typeId,
  () => {
    setTypeFields()
  }
)

const onFocusField = (id: string) => {
  if (can('dashboard.data.manage_tasks_sections')) {
    fieldListRefs.value?.[id]?.focus()
  }
}

onMounted(() => {
  if (props.boardId) {
    unsubscribe.value = observer.subscribe({
      next: (result) => {
        if (result.data?.boardData) {
          setTypeFields()
        }
      },
    })
  }
})

onUnmounted(() => {
  unsubscribe.value?.unsubscribe()
})
</script>
