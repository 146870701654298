<template>
  <div class="divide-y -ml-10 relative">
    <TaskSubtasksTableRow
      v-for="subtaskId in subtaskIds"
      :key="subtaskId"
      :subtask-id="subtaskId"
    />
    <div
      v-if="createSubtaskVisible && lastTaskType"
      class="flex items-center group px-1.5 py-2 bg-gray-100 gap-1"
    >
      <template v-if="isTaskLevel">
        <TaskTypeSelectPopover
          ref="taskTypeSelectPopover"
          source="board"
          :selected-task-type-id="lastTaskType.id"
          :parent-id="parent.boardId"
          :accept-levels="[level]"
          @change="lastTaskType = $event"
        >
          <div
            class="p-1 border gap-1 bg-white flex items-center border-gray-300 rounded-md hover:border-gray-400"
          >
            <TaskTypeIcon
              :background="lastTaskType.background"
              :icon="lastTaskType.icon"
              size="xs"
            />
            <span class="text-xs text-gray-900">{{ lastTaskType.name }}</span>
            <Icon name="heroicons:chevron-down-20-solid" />
          </div>
        </TaskTypeSelectPopover>
      </template>
      <template v-else>
        <div class="rounded-md p-1">
          <TaskTypeIcon
            :background="lastTaskType.background"
            :icon="lastTaskType.icon"
            size="xs"
          />
        </div>
      </template>
      <div class="relative ml-2 w-fit min-w-32">
        <p
          class="h-full w-fit break-words px-2 py-0.5 min-h-4 font-medium text-sm tracking-normal leading-5 whitespace-pre-wrap"
          :style="{ wordBreak: 'break-word' }"
        >
          {{ formState.name || 'Write a task name' }}
        </p>
        <UTextarea
          ref="createSubtaskInput"
          v-model="formState.name"
          :rows="1"
          placeholder="Write a task name"
          autoresize
          :ui="{
            wrapper: 'absolute inset-0',
            color: {
              white: {
                outline:
                  'shadow-none text-gray-700 ring-0 focus:!bg-white group-hover:ring-1 focus:!ring-2 rounded-sm',
              },
            },
            rounded: 'rounded-none',
            base: 'min-h-4 overflow-hidden resize-none w-full outline-none border-none !border-gray-100 !bg-gray-100 focus:!ring-primary-500  group-hover:ring-gray-300 ',
            padding: {
              sm: 'px-2 py-0.5',
            },
            placeholder: 'font-normal',
          }"
          textarea-class="font-medium text-sm tracking-normal leading-5 min-h-4"
          data-test="subtask-input"
          @click.prevent.stop
          @blur="onCreateSubtask"
          @keydown.enter.prevent.stop="$event.target?.blur()"
        />
      </div>
    </div>
    <div class="py-2 flex items-center">
      <UButton
        variant="ghost"
        size="xs"
        color="gray"
        icon="heroicons:plus-small"
        :disabled="!can('dashboard.data.manage_tasks_sections')"
        data-test="add-subtask-btn"
        class="mr-2"
        @click="showCreateSubtask"
      >
        Add {{ isTaskLevel ? 'task' : 'subtask' }}
      </UButton>
      <template v-if="parent.level === TaskLevel.MODULE">
        <div class="border-r border-gray-300 h-4 w-[1px]" />
        <LinkTaskModulePopover
          container-class="pl-2"
          :board-id="parent.boardId"
          :selected-task-ids="subtaskIds"
          :parent-id="parent.id"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { TaskDetail, TaskItemLoader } from '#task/types'
import { TaskLevel, TaskSortType } from '#task/constant'
import type { BaseTaskType } from '#task-type/types'
import { SortDirection } from '#core/constant'

const props = defineProps({
  parent: {
    type: Object as PropType<
      Pick<TaskDetail, 'id' | 'level' | 'boardId' | 'sectionId' | 'closed'>
    >,
    required: true,
  },
})

const emit = defineEmits(['created-subtask'])

const { tasks } = useBoardTasksLoader()
const { load: loadSubtasks, result: resultSubtasks } = useLazyTasksQuery(
  props.parent.boardId,
  {}
)
const { createTask } = useCreateTask()
const { can } = useBoardAbility()
const { getDefaultTaskType, getDefaultStatus } = useBoardSharedState()

const taskTypeSelectPopover = ref()
const createSubtaskVisible = ref(false)
const createSubtaskInput = ref()
const lastTaskType = ref<BaseTaskType>()
const formState = reactive({
  name: '',
})

const subtaskIds = computed(() => {
  if (props.parent.closed) {
    return ((resultSubtasks.value?.tasks?.tasks as TaskItemLoader[]) || []).map(
      (task) => task.id
    )
  }

  return tasks.value
    .filter((task) => task.parentId === props.parent.id)
    .map((task) => task.id)
})

const level = computed(() =>
  props.parent.level === TaskLevel.MODULE ? TaskLevel.TASK : TaskLevel.SUBTASK
)
const isTaskLevel = computed(() => level.value === TaskLevel.TASK)

const showCreateSubtask = async () => {
  createSubtaskVisible.value = true
  if (!lastTaskType.value) {
    lastTaskType.value = getDefaultTaskType(level.value)
  }

  nextTick(() => {
    createSubtaskInput.value?.$el.querySelector('textarea')?.focus()
  })
}

const onCreateSubtask = async (event: Event) => {
  event.preventDefault()
  if (!taskTypeSelectPopover.value?.isOpen) {
    if (!formState.name) {
      createSubtaskVisible.value = false
      return
    }

    const defaultStatus = getDefaultStatus()
    const defaultTaskType = getDefaultTaskType(level.value)

    createTask({
      board: props.parent.boardId,
      section: props.parent.sectionId,
      name: formState.name,
      level: level.value,
      parent: props.parent.id,
      status: defaultStatus?.id,
      type: lastTaskType.value?.id || defaultTaskType?.id,
    })
    formState.name = ''
    showCreateSubtask()
    emit('created-subtask')
  }
}

onMounted(() => {
  if (props.parent.closed) {
    loadSubtasks(null, {
      boardId: props.parent.boardId,
      filter: { parentId: props.parent.id },
      sort: TaskSortType.POSITION,
      SortDirection: SortDirection.ASC,
    })
  }
})

defineExpose({
  showCreateSubtask,
})
</script>
